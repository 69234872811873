import { CiClock1 } from 'react-icons/ci';
import { FaUser } from 'react-icons/fa';
import RequestDetailsAccordion from './RequestDetailsAccordion';
import { FaStar } from 'react-icons/fa6';
import { Rate } from 'antd';
import React, { Fragment, useState, useEffect } from 'react';
import PhotoGalleryAccordion from './PhotoGalleryAccordion';
import { formatDateTime } from 'helpers/common.helpers';
import { calculateDistance } from 'helpers/common.helpers';
import { ModalSendRequestSMS } from 'components/DashboardRepair/ModalSendRequestSMS';
import { Modal } from 'antd';
import api from 'api';
import { selectMechanics } from 'redux/mechanics/selectors';
import { useSelector } from 'react-redux';
// import { getAllRequestPhotos } from 'redux/fleet';
import { ServiceProvidersService } from 'services/service-providers';
import { ServiceRequestService } from 'services/service-requests.service';
import { Loader } from 'components/common';
import { toast } from 'react-toastify';
import { ChatLog } from './ChatLog';
import {
  IoChatbubbleEllipsesOutline,
  IoDocumentTextOutline,
} from 'react-icons/io5';
import { Button } from 'component-library';
import { ModalRemove } from 'components/common';
import { RequestDetailsForm } from './RequestDetailsForm';
import { mapRequestRepairType } from 'helpers/common.helpers';
import { Notes } from './Notes';

/**
 * Renders the view for a specific request.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.request - The request object.
 * @param {Function} props.handleView - The function to handle view action.
 * @param {Function} props.handleDelete - The function to handle delete action.
 * @param {Function} props.handleMouseOut - The function to handle mouse out event.
 * @param {Function} props.handleMouseOver - The function to handle mouse over event.
 * @param {Function} props.handleUnassignMechanic - The function to handle unassign mechanic action.
 * @param {boolean} props.isHistoryView - Indicates if the view is used in History tab.
 * @returns {JSX.Element} The rendered component.
 */
const RequestCard = ({
  request,
  handleView,
  // handleDelete,
  // handleMouseOut,
  // handleMouseOver,
  handleUnassignMechanic,
  isHistoryView,
}) => {
  console.log('request', request.ratings);
  const requestOwnerId = request.requestOwnerId;
  const [loading, setLoading] = useState(false);
  const waiting = request.address ? false : true;
  const companyName = request?.additionalInformation?.companyName;
  const isLite = request?.serviceRequestToken ? true : false;
  const [notes, setNotes] = useState([]);
  const [chatLogs, setChatLogs] = useState([]);
  const [reason, setReason] = useState('');
  const handleReasonInputChange = event => {
    const inputValue = event.target.value;
    setReason(inputValue);
  };
  const [isDelete, setIsDelete] = useState(false);

  // TODO this state should probably be elsewhere
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [isRequestInfoModalOpen, setIsRequestInfoModalOpen] = useState(false);
  const [isRequestNotesModalOpen, setIsRequestNotesModalOpen] = useState(false);
  const [isRequestDetailsModalOpen, setIsRequestDetailsModalOpen] =
    useState(false);

  const handleOpenChatModal = () => {
    setIsChatModalOpen(true);
  };
  const handleCloseChatModal = () => {
    setIsChatModalOpen(false);
  };

  const handleRequestNotesModal = () => {
    setIsRequestNotesModalOpen(true);
  };

  const handleCloseRequestNotesModal = () => {
    setIsRequestNotesModalOpen(false);
  };

  const handleSendSMS = async (values, request) => {
    const { nameOfInfoProvider, phoneNumber } = values;
    try {
      await ServiceRequestService.sendSingleSmsNotification(
        request.serviceRequestToken,
        nameOfInfoProvider,
        phoneNumber
      );
      toast.success('SMS sent successfully');
    } catch (error) {
      console.error('Error sending SMS:', error);
      toast.error('Error sending SMS');
    }
    setIsRequestInfoModalOpen(false);
  };

  const handleDeleteRequest = async (reason, serviceRequestId) => {
    setIsDelete(false);
    try {
      let response;
      setLoading(true);
      if (isLite) {
        response = await ServiceProvidersService.cancelRequestLite({
          serviceRequestId,
          reason,
        });
      } else {
        response = await ServiceRequestService.rejectRequest({
          serviceRequestId,
          reason,
        });
      }
      if (response.status) {
        toast.success('You have successfully canceled the request.');
      }
    } finally {
      setLoading(false);
    }
  };

  const ratingsReceived = ratings => {
    const scores = ratings?.map(rating => rating.score) || [];
    const averageScore =
      scores.length > 0
        ? scores.reduce((sum, score) => sum + score, 0) / scores.length
        : 0;
    const formattedAverage =
      averageScore % 1 === 0 ? `${averageScore}.0` : averageScore.toFixed(1);
    return formattedAverage;
  };

  const getStatusLabel = status => {
    switch (status) {
      case 'SUBMITTED':
      case 'RECEIVED':
      case 'VIEWED':
      case 'SCHEDULED':
      case 'IN_PROGRESS':
      case 'INITIATED':
      case 'REJECTED_BY_MECHANIC':
      case 'APPROVED_BY_FLEET_MANAGER':
        return 'New Request';
      case 'NOTIFICATION_SMS_SENT':
        return 'SMS Sent';
      case 'ASSIGNED_TO_MECHANIC':
        return 'Assigned';
      case 'ACCEPTED':
        return 'Accepted';
      case 'UNASSIGNED_FROM_MECHANIC':
        return 'Unassigned';
      case 'MECHANIC_ON_THE_WAY':
        return 'On the Way';
      case 'CANCELLED_BY_REPAIR_SHOP':
        return 'Cancelled';
      case 'COMPLETED':
        return 'Completed';
      default:
        return 'Unknown Status';
    }
  };

  const handleAllPhotosDownload = async requestId => {
    try {
      setLoading(true);
      const response = await ServiceProvidersService.downloadAllPictures(
        requestId
      );
      if (response?.status) {
        window.open(response.data.zipUrl, '_blank', 'noopener,noreferrer');
      }
    } finally {
      setLoading(false);
    }
  };

  // const modalRef = useRef(null);

  const fetchNotes = async () => {
    setLoading(true);
    try {
      const response = await ServiceRequestService.getServiceRequestNotes(
        request.id
      );
      setNotes(response.data.notes);
    } catch (error) {
      toast.error('Error fetching notes');
    } finally {
      setLoading(false);
    }
  };

  const fetchChatLogs = async () => {
    try {
      const response = await api.get('/chat/service-request-messages', {
        params: {
          roomId: request?.serviceRequestToken,
          page: 1,
          perPage: 1,
        },
      });
      if (response.data.data.data.length > 0) {
        setChatLogs(response.data.data.data);
      }
    } catch (error) {
      console.error('Error fetching chat logs:', error);
    }
  };

  useEffect(() => {
    fetchNotes();
    fetchChatLogs();
  }, [request]);

  return (
    <div
      className="p-5 rounded-2xl mb-[20px] font-poppins flex flex-col gap-3"
      style={{ boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)' }}
      // onMouseOut={() => handleMouseOut(request)}
      // onMouseOver={() => handleMouseOver(request)}
    >
      {loading && <Loader />}
      {companyName && companyName !== 'Not specified' && (
        <p className="text-primary-3 bg-accent-4 p-[4px] px-[8px] rounded-[100px] text-[12px] w-fit">
          {companyName}
        </p>
      )}
      <div className="lg:flex justify-between">
        <div className="w-full flex flex-col gap-2">
          <div className="flex">
            <div className="w-[70px] mr-[10px]">
              {request?.images?.length > 0 ? (
                <img
                  src={request.images[request.images?.length - 1].url}
                  alt="User"
                  className="mr-[10px] w-[70px] h-[70px] rounded-[10px] bg-accent-2 object-cover"
                />
              ) : (
                <div className=" flex justify-center items-center mr-[10px] w-[70px] h-[70px] rounded-[10px] bg-accent-2 object-cover">
                  <FaUser className="h-[40px] w-[40px] fill-accent-1" />
                </div>
              )}
            </div>

            <div className="w-full">
              <p
                className="text-[18px] font-medium "
                style={{ lineHeight: '23px' }}
              >
                {request.requestOwner.firstName} {request.requestOwner.lastName}
              </p>

              <p className="text-[14px] mb-[3px] break-words whitespace-normal ">
                {request.address}
              </p>
              <a
                href={'tel:' + request.requestOwner.phoneNo}
                className="flex items-center"
              >
                <p className="text-[12px] mb-[3px] text-primary-3">
                  {request.requestOwner.phoneNo}
                </p>
              </a>
              <p className="text-xs text-grey-2 ">
                {formatDateTime(
                  request.StatusChanges[request.StatusChanges?.length - 1]
                    .createdAt
                )}
              </p>
            </div>
          </div>

          {waiting ? (
            <div className="flex  mt-[20px] items-center">
              <CiClock1 />
              <p className=" ml-[5px] text-[12px]  text-primary-2 text-right">
                Waiting for data
              </p>
            </div>
          ) : (
            <p className="p-[3px] px-[10px] lg:mb-0 inline-block text-[12px] items-center rounded-[23px]  bg-accent-2 text-accent-1 w-fit">
              {mapRequestRepairType(request.type)}
            </p>
          )}
        </div>

        <div className="lg:flex flex-col lg:items-end w-[30%]">
          <p className="text-[12px] lh-[16.56px]">Order Number</p>
          <p className="text-[17px]">#{request.id}</p>
          <p className="text-[12px] lh-[21.675px] p-[3px] px-[10px] bg-green-2 text-green-1 rounded-[23px] inline-block ">
            {getStatusLabel(
              request.StatusChanges[request.StatusChanges?.length - 1]?.status
            )}
          </p>
        </div>
      </div>

      {/**Mechanic's Profile */}
      {request?.user && !isHistoryView && (
        <div className="lg:flex justify-between items-center">
          <div className="flex">
            {request.assignedEntities[request.assignedEntities?.length - 1]
              .profileImage?.url ? (
              <img
                src={
                  request.assignedEntities[request.assignedEntities.length - 1]
                    .profileImage.url
                }
                alt="Mechanic"
                className="mr-[10px] w-[70px] h-[70px] rounded-[10px] bg-accent-2 object-cover"
              />
            ) : (
              <div className=" flex justify-center items-center mr-[10px] w-[70px] h-[70px] rounded-[10px] bg-accent-2 object-cover">
                <FaUser className="h-[40px] w-[40px] fill-accent-1" />
              </div>
            )}

            <div>
              <p
                className="text-[18px] font-[500] mb-[5px]"
                style={{ lineHeight: '23px' }}
              >
                {request.user.firstName}{' '}
                {request.user.lastName ? request.user.lastName : ''}
              </p>
              <div className="flex items-center mb-[5px]">
                <FaStar className="text-base text-accent-1 w-[12px] h-[12px]" />
                <p className="text-[14px] ml-[3px]">
                  {ratingsReceived(request?.user.ratingsReceived) || 0}
                </p>
                <p className="text-[13px] ml-[3px] text-primary-3">
                  ({request?.user.ratingsReceived.length} reviews)
                </p>
              </div>

              {request?.user &&
                // currentMechanic?.length > 0 &&
                request?.requestOwner.locationLat &&
                request?.requestOwner.locationLong && (
                  <p className="text-[14px] text-grey-2 break-words whitespace-normal ">
                    {calculateDistance(
                      request.user.locationLat,
                      request.user.locationLong,
                      // currentMechanic[0].locationLat,
                      // currentMechanic[0].locationLong,
                      request.requestOwner.locationLat,
                      request.requestOwner.locationLong
                    )}
                  </p>
                )}
            </div>
          </div>
          <div>
            <Button
              onClick={() =>
                handleUnassignMechanic(request.id, request.user.id)
              }
              title="Unassign"
              variant="transparent-text"
            />
          </div>
        </div>
      )}

      {!waiting && (
        <RequestDetailsAccordion
          request={request}
          handleView={handleView}
          // handleDelete={handleDelete}
        />
      )}

      {request.ServiceRequestPhotos.length > 0 && (
        <PhotoGalleryAccordion
          photos={request.ServiceRequestPhotos}
          onAllPhotosDownload={() => handleAllPhotosDownload(request.id)}
        />
      )}

      {request.ratings.length > 0 && (
        <div className="flex flex-col gap-2">
          <p className="font-medium lg:text-[16px] text-[14px]">Review</p>
          <Rate
            allowHalf
            defaultValue={request.ratings[0].score}
            disabled
            className="text-accent-1 text-[14px]"
            character={<FaStar />}
          />
          <div className="bg-accent-4 rounded-[10px] p-[12px]">
            <p className="text-primary-2 text-[14px]">
              {request.ratings[0].comment}
            </p>
          </div>
        </div>
      )}

      <div className={!waiting && !isHistoryView ? 'flex gap-3 mt-3' : 'flex'}>
        {!waiting && !isHistoryView && (
          <Fragment>
            {request.status === 'SUBMITTED' ||
            request.status === 'RECEIVED' ||
            request.status === 'REJECTED_BY_MECHANIC' ? (
              <Button
                onClick={() => handleView(request, false)}
                title="Accept"
                variant="secondary"
              />
            ) : null}
          </Fragment>
        )}
        {!isHistoryView && (
          <Fragment>
            <div className="flex w-full gap-3">
              <Button
                onClick={() => setIsDelete(true)}
                title="Decline"
                variant="danger"
              />
              {request.status === 'SUBMITTED' ||
              request.status === 'INITIATED' ||
              request.status === 'NOTIFICATION_SMS_SENT' ? (
                <Fragment>
                  <Button
                    onClick={() => {
                      setIsRequestDetailsModalOpen(true);
                    }}
                    title="Add Info"
                  />
                  <Button
                    onClick={() => setIsRequestInfoModalOpen(true)}
                    title="Send to Driver "
                    variant="secondary"
                  />
                </Fragment>
              ) : (
                <></>
              )}
            </div>
          </Fragment>
        )}
      </div>
      {isHistoryView && request?.serviceRequestToken && (
        <div className="flex w-full items-center gap-3">
          <div className="flex items-center gap-1">
            <IoChatbubbleEllipsesOutline
              size={24}
              className={`${
                chatLogs.length !== 0
                  ? 'text-accent-1 fill-accent-1'
                  : 'text-primary-3 fill-primary-3'
              }`}
            />
            <button
              type="button"
              onClick={handleOpenChatModal}
              className={`inline-block flex justify-start text-[14px] ${
                chatLogs.length !== 0 ? 'text-accent-1' : 'text-primary-3'
              } font-[500]`}
              disabled={chatLogs.length === 0}
            >
              View Chat Logs
            </button>
          </div>
          <div className="flex items-center gap-1">
            <IoDocumentTextOutline
              size={24}
              className={`${
                notes.length !== 0
                  ? 'text-accent-1 fill-accent-1'
                  : 'text-primary-3 fill-primary-3'
              }`}
            />
            <button
              type="button"
              onClick={handleRequestNotesModal}
              className="inline-block flex justify-start"
              disabled={notes.length === 0}
            >
              <p
                className={`text-[14px] ${
                  notes.length !== 0 ? 'text-accent-1' : 'text-primary-3'
                } font-[500]`}
              >
                View Notes
              </p>
            </button>
          </div>
        </div>
      )}

      {/* M O D A L S */}

      <Modal
        open={isChatModalOpen}
        onCancel={handleCloseChatModal}
        footer={null}
        className="font-poppins"
        style={{ maxHeight: '70vh', overflowY: 'auto' }}
        // ref={modalRef}
      >
        <ChatLog
          requestOwnerId={requestOwnerId}
          roomId={request?.serviceRequestToken}
        />
      </Modal>

      <Modal
        open={isRequestNotesModalOpen}
        onCancel={handleCloseRequestNotesModal}
        footer={null}
        // ref={modalRef}
      >
        <Notes notes={notes} />
      </Modal>

      <Modal
        open={isRequestInfoModalOpen}
        onCancel={() => {
          setIsRequestInfoModalOpen(false);
        }}
        footer={false}
        zIndex={1002}
      >
        <ModalSendRequestSMS
          close={() => {
            setIsRequestInfoModalOpen(false);
          }}
          handleSendSMS={handleSendSMS}
          request={request}
        />
      </Modal>

      <ModalRemove
        isOpen={isDelete}
        setIsOpen={setIsDelete}
        title="Decline Request"
        subTitle="Are you sure you want to decline this request?"
        removeButtonTitle="Decline"
        handleRemove={() => handleDeleteRequest(reason, request.id)}
        children={
          <div className="rounded-[10px] p-[13px] w-full flex items-center bg-gray-100">
            <input
              placeholder="Enter reason"
              className="pl-[11px] text-primary-2 w-[100%] bg-gray-100 focus:outline-none placeholder:text-secondary-1"
              onChange={handleReasonInputChange}
              value={reason}
            />
          </div>
        }
      />

      <Modal
        open={isRequestDetailsModalOpen}
        onCancel={() => {
          setIsRequestDetailsModalOpen(false);
        }}
        footer={false}
        zIndex={1002}
      >
        <div className="flex flex-col w-full items-center justify-center gap-y-4 transition-all py-2">
          <div className="flex flex-col w-full gap-y-3 items-center">
            <span className="text-[26px] text-primary-2 font-semibold text-center">
              Add Request Details
            </span>
            <span className="text-base font-normal text-center text-grey-2">
              Please add request details
            </span>
          </div>
          <RequestDetailsForm
            setIsOpen={setIsRequestDetailsModalOpen}
            serviceRequestToken={request?.serviceRequestToken}
          />
        </div>
      </Modal>
    </div>
  );
};

export default RequestCard;
