import * as Yup from 'yup';

export const fields = {
  firstName: {
    name: 'firstName',
    placeholder: 'Enter your first name.',
    type: 'text',
    label: 'First Name:',
  },
  lastName: {
    name: 'lastName',
    placeholder: 'Enter your last name.',
    type: 'text',
    label: 'Last Name:',
  },
  email: {
    name: 'email',
    placeholder: 'Enter your email address.',
    type: 'email',
    label: 'Email:',
  },
  phoneNo: {
    name: 'phoneNo',
    placeholder: 'Enter your phone number.',
    type: 'text',
    label: 'Phone:',
  },
  company: {
    name: 'company',
    placeholder: 'Enter your company name.',
    type: 'text',
    label: 'Company Name:',
  },
  address: {
    name: 'address',
    placeholder: 'Enter your address.',
    type: 'text',
    label: 'Address:',
  },
  about: {
    name: 'about',
    placeholder: 'Enter your company information.',
    type: 'text',
    label: 'About:',
  },
  rate: {
    name: 'rate',
    placeholder: 'Enter your company rate.',
    type: 'number',
    label: 'Rate:',
  },
};

export const initialValues = {
  [fields.company.name]: '',
  [fields.address.name]: '',
  [fields.firstName.name]: '',
  [fields.lastName.name]: '',
  [fields.email.name]: '',
  [fields.phoneNo.name]: '',
  [fields.about.name]: '',
  [fields.rate.name]: '',
};

export const validationSchema = Yup.object().shape({
  [fields.phoneNo.name]: Yup.string()
    .matches(/^$|^[+]?[0-9]{5,}$/, 'Phone is invalid')
    .nullable(true),
  [fields.firstName.name]: Yup.string().required('Required'),
  [fields.lastName.name]: Yup.string().required('Required'),
  [fields.email.name]: Yup.string()
    .matches(/\S+@\S+\.\S+/, 'Email is invalid')
    .required('Required'),
  [fields.company.name]: Yup.string().required('Required'),
  [fields.address.name]: Yup.string().required('Required'),
  [fields.rate.name]: Yup.string()
    .matches(/^[0-9]*$/, 'Must be a number')
    .nullable(),
});
