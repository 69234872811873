import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { lazy } from 'react';
import { NotFound } from 'pages/NotFound/NotFound';
import { routes } from 'constants/routes.constants';
import Dashboard from 'pages/Dashboard/Dashbord';
import { SharedLayout } from 'components';
import useNotificationAudioPlayer from 'hooks/useNotificationAudioPlayer';
import { SocketProvider } from 'context/SocketContext';

const Home = lazy(() => import('pages/Home/Home'));
const Support = lazy(() => import('pages/Support/Support'));
const SupportLocation = lazy(() => import('pages/LiteFlow/SupportLocation'));
const SignUpForCompany = lazy(() =>
  import('pages/Auth/SignUpForCompany/SignUpForCompany')
);
const VerifyEmail = lazy(() => import('pages/Auth/VerifyEmail'));
const ProfileDriver = lazy(() => import('pages/ProfileDriver'));
const SearchResults = lazy(() => import('./pages/SearchResults'));
const SingleVendorPage = lazy(() =>
  import('./pages/SingleVendorPage/SingleVendorPage')
);
const RepairShopDashboard = lazy(() =>
  import('./pages/DashboardRepair/DashbordRepair')
);
const FleetDriverInvitePage = lazy(() => import('./pages/Fleet/Invites'));
const MainLite = lazy(() => import('./pages/LiteFlow/MainLite'));

export default function App() {
  useNotificationAudioPlayer();
  return (
    <SocketProvider>
      <Router>
        <Routes>
          <Route path={routes.root} element={<SharedLayout />}>
            <Route path={routes.home} element={<Home />} />
            <Route path={routes.companySignUp} element={<SignUpForCompany />} />
            <Route path={routes.verifyEmail} element={<VerifyEmail />} />
            <Route path={routes.driverProfile} element={<ProfileDriver />} />
            <Route path={routes.fleetDashboard} element={<Dashboard />} />
            <Route
              path={routes.repairShopDashboard}
              element={<RepairShopDashboard />}
            />
            <Route path={routes.searchResults} element={<SearchResults />} />
            <Route
              path={routes.fleetDriverInvite}
              element={<FleetDriverInvitePage />}
            />
            <Route
              path={routes.singleVendorPage()}
              element={<SingleVendorPage />}
            />
            <Route path={routes.support} element={<Support />} />
            <Route
              path={routes.supportLocation}
              element={<SupportLocation />}
            />
            <Route path={routes.mainLite()} element={<MainLite />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </SocketProvider>
  );
}
