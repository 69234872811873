import styled from '@emotion/styled';

export const Box = styled.div`
position: fixed;
width: 100vw;
height: 100vh;
top: 0;
left: 0;
display: flex;
align-items: center;
justify-content: center;
z-index: 10000;
animation: bg 1s;
`
