import React, { useState } from 'react';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';
import { FaUser } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa6';

const requestData = {
  id: 1,
  images: [
    {
      url: 'https://img.freepik.com/free-photo/handsome-bearded-guy-posing-against-white-wall_273609-20597.jpg?size=626&ext=jpg&ga=GA1.1.1448711260.1706400000&semt=sph',
    },
  ],
  typeDriver: 'Truck Repair',
  name: 'Sam Smith',
  rating: 4,
  review: 7,
  address: '1200 E Jefferson Blvd, Dallas, TX',
  description: 'I have a problem....',
};

export const RequestDetails = ({ driver }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-[16px]">
      {driver ? (
        <div
          className="flex justify-between p-4 cursor-pointer"
          onClick={toggleAccordion}
        >
          <h2 className="mb-[15px] text-[16px] font-medium">
            View Request Details:
          </h2>
          {isOpen ? (
            <SlArrowUp className="fill-grey-2" />
          ) : (
            <SlArrowDown className="fill-grey-2" />
          )}
        </div>
      ) : (
        <p>Not specified</p>
      )}
      {isOpen && (
        <div className="mb-[25px]">
          <div className="w-full flex">
            {requestData?.images?.length > 0 ? (
              <img
                src={requestData.images[requestData.images.length - 1].url}
                alt="User"
                className="mr-[10px] w-[60px] h-[60px] rounded-[10px] bg-accent-2 object-cover"
              />
            ) : (
              <div className="flex justify-center items-center mr-[10px] w-[70px] h-[70px] rounded-[10px] bg-accent-2 object-cover">
                <FaUser className="h-[40px] w-[40px] fill-accent-1" />
              </div>
            )}
            <div>
              <p className="text-[16px] font-medium">{requestData.name}</p>
              <p className="text-[14px]">{requestData.typeDriver}</p>
              <div className="flex items-center mb-[10px]">
                <FaStar className="text-base text-accent-1 w-[12px] h-[12px]" />
                <span className="text-[14px] ml-[3px]">
                  {requestData?.rating || 0} ({requestData?.review} Reviews)
                </span>
              </div>
            </div>
          </div>
          <div>
            <p className="text-primary-3 text-[14px]">{driver.address}</p>
            <p>{requestData.description}</p>
          </div>
        </div>
      )}
    </div>
  );
};
