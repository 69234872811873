import React, { Fragment } from 'react';
import {
  FormikInput,
  FormikPhoneInput,
  FormikSelectInputTechs,
} from 'component-library';
import { Field } from 'formik';
import CustomUpload from '../../../../../components/common/CustomUpload';
import { options } from '../formData';

/**
 * Renders the personal information tab in the My Technicians section.
 *
 * @param {Object} props - The component props.
 * @param {Function} handleImageUpload - The function to handle image upload.
 * @param {Function} setFieldValue - The function to set form field value.
 * @returns {JSX.Element} The rendered personal information tab component.
 */
export const PersonalInformationTab = ({
  handleImageUpload,
  setFieldValue,
  setFieldTouched,
}) => {
  return (
    <Fragment>
      <div className={`flex w-full flex-wrap justify-start gap-4`}>
        <div className="flex-1">
          <FormikInput
            name={`firstName`}
            label={`First Name`}
            placeholder={`First name`}
            labelClassName="font-light text-sm leading-6 text-grey-2"
            inputClassName="font-normal text-base leading-6 text-primary-2"
          />
        </div>
        <div className="flex-1">
          <FormikInput
            name={`lastName`}
            label={`Last Name`}
            placeholder={`Last name`}
            labelClassName="font-light text-sm leading-6 text-grey-2"
            inputClassName="font-normal text-base leading-6 text-primary-2"
          />
        </div>
        <div className="w-full">
          <label className={'text-sm font-normal text-grey-2 mb-2'}>
            Technician's Phone Number
          </label>
          <Field name="phoneNumber" component={FormikPhoneInput} disabled={true} />
        </div>
      </div>
      <div className={`w-full flex flex-col`}>
        <label className={`font-light text-sm leading-6 text-grey-2`}>
          Capabilities
        </label>
        <FormikSelectInputTechs
          options={options}
          name="serviceCategoryIDs"
          onChange={(e) => {
            setFieldValue('serviceCategoryIDs', e.target.value);
            setFieldTouched('serviceCategoryIDs', true, true);
          }}
        />
      </div>
      <div className={`w-full flex flex-col `}>
        <label className={`font-light text-sm text-grey-2 leading-6`}>
          Profile Image (optional)
        </label>
        <div className={`flex justify-between content-center`}>
          <CustomUpload
            selectedFile={null}
            inputName="image"
            allowedTypes={['image/jpeg', 'image/png', 'image/svg+xml']}
            setSelectedFile={e => {
              setFieldValue('image', e);
              handleImageUpload(e);
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};