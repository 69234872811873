import React from 'react';

export const ButtonGroup = ({ isSelected, setIsSelected, title1, title2 }) => {
  return (
    <div
      className=" rounded-[7px] flex flex-row justify-between  items-center h-9"
      style={{ backgroundColor: 'rgba(152, 152, 160, 0.12)' }}
    >
      <div
        onClick={() => setIsSelected(1)}
        className={`w-[50%] h-full  flex justify-center items-center cursor-pointer ${
          isSelected === 1
            ? 'border-[1px] border-[#2D6DCA] rounded-[7px] bg-[white]'
            : ''
        }`}
      >
        <span
          className={`text-sm ${
            isSelected === 1
              ? 'text-accent-1 font-medium '
              : 'font-medium text-primary-2'
          }`}
        >
          {title1}
        </span>
      </div>
      <div
        onClick={() => setIsSelected(0)}
        className={`w-[50%] h-full  flex justify-center items-center cursor-pointer ${
          isSelected === 0
            ? 'border-[1px] border-[#2D6DCA] rounded-[7px] bg-[white]'
            : ''
        }`}
      >
        <span
          className={`text-sm ${
            isSelected === 0
              ? 'text-accent-1 font-medium '
              : 'font-medium text-primary-2'
          }`}
        >
          {title2}
        </span>
      </div>
    </div>
  );
};
