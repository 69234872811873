import { routes } from './routes.constants';

export const GMAPS_API_KEY = 'my_mechanics_gmaps_api_key';
export const SEARCH_RESULT_KEY = 'my_mechanic_vendor_search_results';
export const SERVICE_CATEGORIES_KEY = 'my_mechanic_service_categories_key';
export const SEARCH_QUERY_KEY = 'my_mechanic_vendor_search_query';
export const ACCESS_TOKEN_STORAGE_KEY = 'my_mechanics_access_token';
export const USER_DATA = 'my_mechanics_user_data';
export const REFRESH_TOKEN_STORAGE_KEY = 'my_mechanics_refresh_token';

export const authExcludedRoutes = [
  routes.companySignUp,
  routes.support,
  routes.supportLocation,
  routes.verifyEmail,
  routes.home,
  routes.root,
  routes.verifyEmail,
  routes.searchResults,
];
