const { createSlice } = require("@reduxjs/toolkit");

const audioSlicer = createSlice({
    name:'audio',
    initialState: {
        tryPlayAudio:''
    },
    reducers:{
        disposeSound:(state) => {
            state.tryPlayAudio = Math.random();
        }
    }
})

export const {disposeSound} = audioSlicer.actions;

export default audioSlicer.reducer