import React from 'react';

export const MarkerUnit = ({ index }) => {
    return (
      <div
        role="tooltip"
        className="absolute  shadow-lg  group-hover:block bg-[white] text-primary-2 font-semibold px-5 py-3 text-[13px] right-10  mx-auto w-max -top-14 rounded-2xl before:w-4 before:h-4 before:rotate-45 before:bg-[white] before:absolute  before:-bottom-1 before:left-0  before:right-0 before:mx-auto before:z-10 left-[-15px]"
      >
        <span className="text-[16px] font-medium">Unit {index + 1}</span>
  
        <div className="tooltip-arrow" data-popper-arrow></div>
      </div>
    );
  };