import * as Yup from 'yup';

export const fields = {
  vin: {
    name: 'vin',
    placeholder: 'Enter the VIN of your truck.',
    type: 'text',
    label: 'VIN Number:',
  },
};

export const initialValues = {

  
  [fields.vin.name]: '',
};

export const validationSchema = Yup.object().shape({
  [fields.vin.name]: Yup.string()
    .length(17, 'Enter a valid VIN number')
    .matches(/\d/gi, 'Enter a valid VIN number')
    .required('Required'),
});
