import api from 'api';
import { toast } from 'react-toastify';

const serviceRequestRoutes = {
  requestMechanic: 'service-requests/to-company',
  assignMechanic: 'service-requests/assign-mechanic',
  unassignMechanic: 'service-requests/unassign-mechanic',
  myServiceRequests: 'service-requests/my-service-requests',
  myServiceRequestsPagination: 'service-requests/my-service-requests',
  availableRequests: 'service-requests/available-requests',
  rejectRequest: 'service-requests/reject-request',
  findMechanics: 'vendors/search-mechanics',
  serviceRequestNotes: 'service-requests',
};

export class ServiceRequestService {
  /**
   *
   * @param {{type: string, description: string, vin: string, address: string, companyId: any, mechanicId: string, serviceCategoryId: string, pictures: string[]}} data
   * @returns
   */
  static async requestMechanic(data) {
    try {
      const { data: responseData } = await api.post(
        `${serviceRequestRoutes.requestMechanic}`,
        data
      );

      return responseData;
    } catch (error) {
      console.log('🥲🥲 ServiceRequestService.requestMechanic2', {
        error: error?.response?.data,
      });

      return { success: false, data: error?.response?.data?.message };
    }
  }

  /**
   *
   * @param {{"latitude": number, "longitude": number, "typeOfBreakdown": any, "radius": number, "perPage": "20", "page": "0"}} data
   * @returns
   */
  static async findMechanics(data) {
    try {
      const { data: responseData } = await api.post(
        `${serviceRequestRoutes.findMechanics}`,
        data
      );

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 ServiceRequestService.findMechanics', { error });

      return null;
    }
  }

  /**
   *
   * @param {{"serviceRequestId": string, "mechanicId": string}} data
   * @returns
   */
  static async assignMechanic(data) {
    try {
      const { data: responseData } = await api.post(
        `${serviceRequestRoutes.assignMechanic}/${data.serviceRequestId}/${data.mechanicId}`
      );

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 ServiceRequestService.assignMechanic', { error });
      toast.error(error.response.data.message);
      return null;
    }
  }

  /**
   *
   * @param {{"serviceRequestId": string, "mechanicId": string}} data
   * @returns
   */
  static async unassignMechanic(data) {
    try {
      const { data: responseData } = await api.post(
        `${serviceRequestRoutes.unassignMechanic}/${data.serviceRequestId}/${data.mechanicId}`
      );

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 ServiceRequestService.unassignMechanic', { error });
      toast.error(error.response.data.message);
      return null;
    }
  }

  /**
   *
   * @param {{}}
   * @returns
   */
  static async myServiceRequests() {
    try {
      const { data: responseData } = await api.get(
        `${serviceRequestRoutes.myServiceRequests}`
      );

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 ServiceRequestService.myServiceRequests', { error });
      toast.error('Something went wrong, please try again');
      return null;
    }
  }

  /**
   *
   * @param {{"serviceRequestId": string, "mechanicId": string}} data
   * @returns
   */
  static async myServiceRequestsPagination(data) {
    try {
      const { data: responseData } = await api.get(
        `${serviceRequestRoutes.myServiceRequestsPagination}?page=${data.page}&perPage=${data.perPage}`
      );

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 ServiceRequestService.myServiceRequestsPagination', {
        error,
      });
      toast.error('Something went wrong, please try again');
      return null;
    }
  }

  /**
   *
   * @param {{}}
   * @returns
   */
  static async availableRequests() {
    try {
      const { data: responseData } = await api.get(
        `${serviceRequestRoutes.availableRequests}`
      );

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 ServiceRequestService.availableRequests', { error });
      toast.error('Something went wrong, please try again');
      return null;
    }
  }

  /**
   *
   * @param {{"serviceRequestId": number, "reason": string}} data
   * @returns
   */
  static async rejectRequest(data) {
    try {
      const { data: responseData } = await api.post(
        `${serviceRequestRoutes.rejectRequest}/${data.serviceRequestId}`,
        data
      );

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 ServiceRequestService.rejectRequest', {
        error,
      });
      toast.error('Something went wrong, please try again');
      return null;
    }
  }

  /**
   *
   * @param {string} serviceRequestToken
   * @param {string} name
   * @param {string} phoneNumber
   * @returns
   */
  static async sendSingleSmsNotification(
    serviceRequestToken,
    name,
    phoneNumber
  ) {
    try {
      const data = {
        token: serviceRequestToken,
        recipients: [
          {
            recipientName: name,
            phoneNumber: phoneNumber,
          },
        ],
      };

      const { data: responseData } = await api.post(
        '/service-requests-lite/send-sms-notification',
        data
      );

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 ServiceRequestService.sendSmsNotification', { error });
      toast.error('Something went wrong, please try again');
      return null;
    }
  }

  static async getServiceRequestNotes(id) {
    try {
      const { data: response } = await api.get(
        `${serviceRequestRoutes.serviceRequestNotes}/${id}/notes`,
        id
      );
      return response;
    } catch (error) {
      console.log('ServiceRequestService.getServiceRequestNotes', { error });
      toast.error(
        Array.isArray(error.response.data.message)
          ? error.response.data.message[0]
          : error.response.data.message
      );
      return null;
    }
  }
}
