export const dashboardKeys = {
  home: 'home',
  trucks: 'trucks',
  history: 'history',
  // payment: 'payment',
  // company: 'company',
  settings: 'settings',
  support: 'support',
  products: 'products',
  requests: 'requests',
  notifications: 'notifications',
  parts: 'parts',
  chat: 'chat',
  technicians: 'technicians',
};

export const dashboardRepairTabs = [
  {
    label: 'Home',
    key: dashboardKeys.home,
  },
  {
    label: 'Requests',
    key: dashboardKeys.requests,
  },
  {
    label: 'Past Calls',
    key: dashboardKeys.history,
  },
  {
    label: 'Notifications',
    key: dashboardKeys.notifications,
  },
  {
    label: 'Parts',
    key: dashboardKeys.parts,
  },
  {
    label: 'Chat',
    key: dashboardKeys.chat,
  },
  {
    label: 'My Technicians',
    key: dashboardKeys.technicians,
  },

  {
    label: 'Settings',
    key: dashboardKeys.settings,
  },
  {
    label: 'Support',
    key: dashboardKeys.support,
  },
  {
    label: 'myProducts',
    key: dashboardKeys.products,
  },
];

export const dashboardFleetTabs = [
  {
    label: 'Home',
    key: dashboardKeys.home,
  },
  {
    label: 'My Trucks',
    key: dashboardKeys.trucks,
  },
  {
    label: 'Requests',
    key: dashboardKeys.requests,
  },
  // {
  //   label: 'My Company',
  //   key: dashboardKeys.company,
  // },
  {
    label: 'History',
    key: dashboardKeys.history,
  },
  // {
  //   label: 'Payment',
  //   key: dashboardKeys.payment,
  // },
  {
    label: 'Settings',
    key: dashboardKeys.settings,
  },
  {
    label: 'Support',
    key: dashboardKeys.support,
  },
  {
    label: 'myProducts',
    key: dashboardKeys.products,
  },
];
