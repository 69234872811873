import React, { useEffect, useState } from 'react';
import { ServiceProvidersService } from 'services/service-providers';
import { formatToTitleCase } from 'helpers/common.helpers';
import { ProfileService } from 'services/profile.service';
import { Loader } from 'components/common';

const PhotoCategory = () => {
  const [allCategories, setAllCategories] = useState([]);
  const [companyCategories, setCompanyCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (allCategories.length === 0) {
      getAllCategories();
    }
  }, []);

  const getAllCategories = async () => {
    try {
      setLoading(true);
      await getCompanyCategories();
      const response =
        await ServiceProvidersService.pictureCategoriesSettings();
      if (response?.status) {
        setAllCategories(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getCompanyCategories = async () => {
    try {
      const response = await ProfileService.pictureCategoriesForCompany();
      if (response?.status) {
        const arreyId = response.data?.map(
          category => category.pictureCategorySettingId
        );
        setCompanyCategories(arreyId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggle = async (isActive, id) => {
    try {
      setLoading(true);
      if (isActive) {
        const response = await ServiceProvidersService.removePictureCategories({
          pictureCategoriesSettingIds: [id],
        });
        if (response?.status) {
          await getCompanyCategories();
        }
      } else {
        const response = await ServiceProvidersService.selectPictureCategories({
          pictureCategoriesSettingIds: [id],
        });
        if (response?.status) {
          await getCompanyCategories();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <div className="bg-accent-4 p-[16px]  rounded-[16px] inline-block lg:w-[60%]">
        <p className="text-primary-3 text-[20px] font-[500] mb-[20px]">
          Categories
        </p>
        {allCategories?.map(category => (
          <label
            key={category.id}
            className="flex justify-between gap-[20px] mb-[27px] cursor-pointer"
          >
            <div className="ml-3 text-[16px]">
              <p className="text-[18px]">{formatToTitleCase(category.name)}</p>
              <p className="text-[16px] text-grey-2">{category.description}</p>
            </div>
            <div className="relative">
              <input
                type="checkbox"
                className="sr-only"
                checked={companyCategories.includes(category.id)}
                onChange={() =>
                  handleToggle(
                    companyCategories.includes(category.id),
                    category.id
                  )
                }
              />
              <div
                className={`block w-14 h-8 rounded-full ${
                  companyCategories.includes(category.id)
                    ? 'bg-green-500'
                    : 'bg-gray-600'
                }`}
              ></div>
              <div
                className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform duration-200 ease-in-out ${
                  companyCategories.includes(category.id)
                    ? 'transform translate-x-6'
                    : ''
                }`}
              ></div>
            </div>
          </label>
        ))}
      </div>
    </div>
  );
};

export default PhotoCategory;
