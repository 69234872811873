import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { Button } from 'component-library';
import User from '../../../../assets/user.svg';

export const InviteAllModal = ({ isModalOpen, setIsModalOpen }) => {
  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(false);
    }
  };
  if (!isModalOpen) return;
  return (
    <div
      className=" fixed inset-0 z-[100000000] flex items-center justify-center bg-black bg-opacity-60"
      onClick={handleOverlayClick}
    >
      <div className="bg-white rounded-[32px] relative  px-7  pt-[78px] pb-16  flex-col flex items-center justify-center">
        <img alt="User" src={User} className=" w-[106px] h-[106px]" />
        <div className="w-full flex flex-col justify-center mb-[30px]">
          <p className="mt-6 mb-2.5  font-semibold text-[26px] text-center text-primary-2">
            Send an invite{' '}
          </p>
          <p className=" text-base font-normal text-center text-primary-2">
            Drivers wil receive an invitation link via SMS
          </p>
        </div>
        <Button title="Invite all" onClick={() => {}} />
        <div
          className="h-8 w-8 bg-white  shadow-md  flex justify-center items-center rounded-full absolute right-4 top-4 cursor-pointer"
          onClick={() => setIsModalOpen(false)}
        >
          <IoMdClose size={20} color="#1D1D1D" />
        </div>
      </div>
    </div>
  );
};
