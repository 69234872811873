import { Suspense, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  SEARCH_QUERY_KEY,
  SEARCH_RESULT_KEY,
  authExcludedRoutes,
} from 'constants/auth.constants';

import { routes } from 'constants/routes.constants';
import { Footer, HomeHeader, Loader } from '..';
import { getProfile } from 'redux/auth/operations';

export const SharedLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const initialized = useRef(false);

  const accessToken = window.localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

  useEffect(() => {
    if (
      location.pathname !== routes.searchResults ||
      location.pathname.includes('vendors')
    ) {
      window.localStorage.removeItem(SEARCH_QUERY_KEY);
      window.localStorage.removeItem(SEARCH_RESULT_KEY);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location?.pathname) {
      if (
        !accessToken &&
        !authExcludedRoutes.includes(location.pathname) &&
        !location.pathname.includes('vendors') &&
        !location.pathname.includes('token')
      ) {
        navigate(routes.home, { replace: true });
      }
    }
  }, [navigate, location, accessToken]);
  useEffect(() => {
    if (!initialized.current) {
      if (accessToken) {
        dispatch(getProfile());
      }

      initialized.current = true;
    }
  }, [dispatch, accessToken, initialized]);
  return (
    <div className="scrollbar-thin scrollbar-thumb-slate-50 flex flex-col justify-between sm:min-h-screen">
      <div className="flex flex-col">
        {![
          routes.support,
          routes.supportLocation,
          routes.companySignUp,
          routes.fleetDashboard,
          routes.repairShopDashboard,
        ].includes(location.pathname) &&
        !location.pathname.includes('token') ? (
          <HomeHeader />
        ) : (
          ''
        )}

        <div className="">
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
      {![
        routes.home,
        routes.companySignUp,
        routes.fleetDashboard,
        routes.repairShopDashboard,
        routes.searchResults,
        routes.support,
        routes.supportLocation,
      ].includes(location.pathname) && !location.pathname.includes('token') ? (
        <Footer />
      ) : (
        ''
      )}
    </div>
  );
};
