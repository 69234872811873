import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'antd';

export const ModalAddDriver = ({ handleSearch, isOpen, onCancel }) => {
  const [number, setNumber] = useState('+');
  const phoneValidation = () => {
    const regex = /^[+]?\d+[0-9]{5}$/g;
    return !(!number || regex.test(number) === false);
  };
  const handleInputChange = event => {
    const inputValue = event.target.value;
    setNumber(inputValue);
  };
  const modalStyles = {
    width: '30vw',
    '@media (maxWidth: 1024px)': {
      width: '40vw',
    },
    '@media (maxWidth: 767px)': {
      width: '90vw',
    },
  };
  return (
    <Modal
      title=""
      open={isOpen}
      onCancel={onCancel}
      footer={false}
      zIndex={1002}
      style={modalStyles}
      transitionName=""
    >
      <div className="flex flex-col gap-y-8">
        <h2 className="text-lg font-semibold text-center mt-[30px]">
          Add driver by phone number
        </h2>

        <div className="w-full lg:flex">
          <div className="rounded-[10px] p-[13px] w-auto flex flex-grow items-center  h-12 bg-gray-100 mb-[10px]">
            <input
              type="tel"
              placeholder="+18005555555"
              className="pl-[11px] text-primary-2  w-[100%] bg-gray-100 focus:outline-none placeholder:text-secondary-1"
              onChange={handleInputChange}
              value={number}
            />
          </div>
          <button
            type="button"
            onClick={() => {
              if (phoneValidation()) {
                handleSearch(number);
              } else {
                toast.error('Invalid phone number');
              }
            }}
            className="ml-[5px] w-[150px] h-12 text-[16px] font-[15px] px-[15px] py-[7px] rounded-[8px] border border-solid border-gray-300 focus:outline-none"
          >
            Add
          </button>
        </div>
      </div>
    </Modal>
  );
};
