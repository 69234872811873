import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { motion } from 'framer-motion';
import useWindowSize from 'hooks/useWindowSize';
import { IoIosSearch } from 'react-icons/io';
import { FaClockRotateLeft } from 'react-icons/fa6';
import RequestCard from 'components/profile/FleetDashboardNavigation/RequestsTab/components/RequestCard';
import { ServiceRequestService } from 'services/service-requests.service';
import { List, Skeleton } from 'antd';
import { Button } from 'component-library';

const PastCallsTab = React.memo(({ offset }) => {
  const { isMobile, isTablet, isSmallTablet, isMediumTablet } = useWindowSize();
  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(1);
  const mountedRef = useRef(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  const [totalRequests, setTotalRequests] = useState(0);
  const [filteredRequests, setFilteredRequests] = useState([]);

  const fetchRequests = useCallback(
    async (currentPage, loadAll = false) => {
      if (!mountedRef.current) return [];

      try {
        setLoadingMore(true);
        const result = await ServiceRequestService.myServiceRequestsPagination({
          page: currentPage,
          perPage: loadAll ? totalRequests : 10,
        });
        setTotalRequests(result.serviceRequestsPaginated.total);
        setFilteredRequests(result.serviceRequestsPaginated.data);
        return result.serviceRequestsPaginated.data;
      } catch (error) {
        console.error('Error fetching requests:', error);
        return [];
      } finally {
        setLoadingMore(false);
      }
    },
    [totalRequests]
  );

  const loadMoreRequests = useCallback(async () => {
    if (!mountedRef.current || loadingMore) return;

    setLoadingMore(true);
    const newRequests = await fetchRequests(page);
    setRequests(prevRequests => [...prevRequests, ...newRequests]);
    setPage(prevPage => prevPage + 1);
    setLoadingMore(false);
    if (newRequests.length < 10) {
      setAllLoaded(true);
    }
  }, [mountedRef, loadingMore, fetchRequests, page]);

  const loadAllRequests = useCallback(async () => {
    if (!mountedRef.current || loadingMore) return;

    setLoadingMore(true);
    const allRequests = await fetchRequests(1, true);
    setRequests(allRequests);
    setAllLoaded(true);
    setLoadingMore(false);
  }, [mountedRef, loadingMore, fetchRequests]);

  useEffect(() => {
    mountedRef.current = true;

    const initialFetch = async () => {
      setLoadingMore(true);
      const initialRequests = await fetchRequests(1);
      setRequests(initialRequests);
      setPage(2);
      setLoadingMore(false);
    };

    initialFetch();

    return () => {
      mountedRef.current = false;
    };
  }, [fetchRequests]);

  const handleSearch = useCallback(
    event => {
      const inputValue = event.target.value.trim().toLowerCase();
      setFilteredRequests(
        inputValue === ''
          ? requests
          : requests.filter(
              item =>
                item.requestOwner.firstName
                  ?.toLowerCase()
                  .includes(inputValue) ||
                item.requestOwner.lastName
                  ?.toLowerCase()
                  .includes(inputValue) ||
                item.requestOwner.phoneNo?.toLowerCase().includes(inputValue) ||
                item.id.toString().includes(inputValue)
            )
      );
    },
    [requests]
  );

  const animationProps = useMemo(
    () => ({
      initial: {
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : -1000,
      },
      animate: {
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : offset,
      },
      exit: {
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : -1000,
      },
      transition: { bounce: 0 },
    }),
    [isMobile, isTablet, isMediumTablet, isSmallTablet, offset]
  );

  return (
    <motion.div
      {...animationProps}
      className="h-screen overflow-y-scroll scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-slate-300 sm:p-8 p-4 py-8 min-h-screen absolute w-full sm:w-[60vw] lg:max-w-2xl flex flex-col gap-y-12 rounded-e-2xl left-0 top-0 z-[1000] bg-white"
    >
      <div className="flex flex-col h-full">
        <div className="flex items-end mb-[35px]">
          <div className="rounded-[30px] p-[13px] mt-3 w-full flex flex-initial items-center h-12 bg-gray-100">
            <IoIosSearch className="text-[25px] text-primary-3" />
            <input
              type="text"
              placeholder="Search Requests"
              className="pl-[11px] text-primary-2 bg-gray-100 focus:outline-none placeholder:text-secondary-1 w-full"
              onChange={handleSearch}
            />
          </div>
        </div>

        {requests.length > 0 ? (
          <div className="flex-grow">
            <List
              dataSource={filteredRequests}
              renderItem={(request, index) => (
                <RequestCard
                  key={request.id || index}
                  request={request}
                  handleMouseOut={() => {}}
                  handleMouseOver={() => {}}
                  isHistoryView
                />
              )}
            />
            {loadingMore && (
              <Skeleton
                avatar
                paragraph={{
                  rows: 1,
                }}
                active
              />
            )}
            {!allLoaded && (
              <div className="flex justify-center my-6 gap-3 ">
                <Button
                  title="View More"
                  onClick={loadMoreRequests}
                  disabled={loadingMore}
                />
                <Button
                  title="View All"
                  onClick={loadAllRequests}
                  disabled={loadingMore}
                />
              </div>
            )}
          </div>
        ) : loadingMore ? (
          <Skeleton
            avatar
            paragraph={{
              rows: 4,
            }}
            active
          />
        ) : (
          <div className="flex flex-col text-center gap-x-4 items-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] opacity-70">
            <FaClockRotateLeft className="text-primary-3 text-9xl" />
            <span className="text-2xl text-primary-3">
              You have no history yet.
            </span>
          </div>
        )}
      </div>
    </motion.div>
  );
});

export default PastCallsTab;
