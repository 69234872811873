/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'antd';
// import { RequestDetails } from 'components/DashboardRepair/RequestDetails';
import { MechanicCardBasic } from 'components/DashboardRepair/MechanicCardBasic';
import { ModalMechanic } from 'components/DashboardRepair/ModalMechanic';
import { IoIosSearch } from 'react-icons/io';
import { ModalAddMechanic } from 'components/DashboardRepair/ModalAddMechanic';
import { HomeServices } from 'services/home.service';
import { Loader } from 'components/common';
import { ServiceRequestUser } from 'services/user.service';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { selectMechanics } from 'redux/mechanics/selectors';
import { ModalDelete } from 'components/DashboardRepair/ModalDelete';
import { setMechanics } from 'redux/mechanics/mechanic.slice';
import { get } from 'lodash';
import { getServiceCategories } from 'redux/reference/operations';

export const RepairSideBar = () => {
  const dispatch = useDispatch();
  const [isAddMechanicModalOpen, setIsAddMechanicModalOpen] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [option, setOption] = useState(null);
  const [chosenMech, setChosenMech] = useState([]);
  const [user, setUser] = useState([]);
  const mechanics = useSelector(selectMechanics);
  const [loader, setLoader] = useState(false);
  const [mechanicDeleteId, setMechanicDeleteId] = useState('');
  const [mechanicDeleteName, setMechanicDeleteName] = useState('');
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      dispatch(getServiceCategories());
      initialized.current = true;
    }
  }, [initialized, dispatch]);

  const getMechanics = async () => {
    setLoader(true);
    const response = await HomeServices.getUsers({
      page: 1,
      perPage: 10,
    });
    setLoader(false);
    dispatch(setMechanics(response));
  };

  const handleInputChange = event => {
    const inputValue = event.target.value;

    const filteredData = inputValue
      ? mechanics?.filter(
          item =>
            (item.firstName &&
              item.firstName
                .toLowerCase()
                .includes(inputValue.toLowerCase())) ||
            (item.lastName &&
              item.lastName.toLowerCase().includes(inputValue.toLowerCase()))
        )
      : [];
    setUser(filteredData);
  };

  const handleView = (array, details) => {
    setChosenMech(array);
    setIsRequestModalOpen(true);
    setOption(details);
  };

  const modalStyles = {
    width: '30vw',
    '@media (maxWidth: 1024px)': {
      width: '40vw',
    },
    '@media (maxWidth: 767px)': {
      width: '90vw',
    },
  };

  const showDelete = (id, name) => {
    setMechanicDeleteName(name);
    setMechanicDeleteId(id);
    setIsRequestModalOpen(false);
    setShowModalDelete(true);
  };

  const handleDelete = async () => {
    try {
      setLoader(true);
      await HomeServices.removeUser(mechanicDeleteId);
      setShowModalDelete(false);
      getMechanics();
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="h-screen  w-full flex flex-col gap-y-4 pt-4">
      <div className="w-full flex flex-col sm:px-6 px-4">
        <div className="flex mb-[15px]">
          {loader && <Loader />}
          <Modal
            title=""
            open={showModalDelete}
            onCancel={() => {
              setShowModalDelete(false);
            }}
            footer={false}
            zIndex={1002}
            style={modalStyles}
          >
            <ModalDelete
              handleDelete={handleDelete}
              handleCancel={() => {
                setShowModalDelete(false);
              }}
              isMechanic={mechanicDeleteName}
            />
          </Modal>
        </div>

        <div className="rounded-[10px] p-[13px] mt-3 flex items-center w-auto h-12 bg-gray-100 mb-[25px]">
          <IoIosSearch className="text-[25px] text-primary-3" />
          <input
            type="text"
            placeholder="Search"
            className="pl-[11px] text-primary-2 bg-gray-100 focus:outline-none placeholder:text-secondary-1"
            onChange={handleInputChange}
          />
        </div>

        {user.length > 0 && (
          <>
            <p className="text-[16px] font-medium mb-[15px]">
              Mechanic Information:
            </p>
            {user.map((item, index) => (
              <div key={index}>
                {item.role !== 'REPAIR_SHOP_MANAGER' && (
                  <MechanicCardBasic
                    user={item}
                    color={
                      item.status === 'AVAILABLE'
                        ? '#53CD58'
                        : item.status === 'BUSY'
                        ? '#DA0000'
                        : '#888A8C'
                    }
                    handleView={handleView}
                  />
                )}
              </div>
            ))}
          </>
        )}

        {/* <RequestDetails /> */}

        <div className="w-full flex flex-col ">
          {mechanics?.some(
            mechanic =>
              mechanic.status === 'AVAILABLE' &&
              mechanic.role !== 'REPAIR_SHOP_MANAGER'
          ) && (
            <>
              <p className="text-[16px] font-medium mb-[15px]">Available:</p>
              {mechanics?.map(mechanic => (
                <React.Fragment key={mechanic.id}>
                  {mechanic.role !== 'REPAIR_SHOP_MANAGER' &&
                    mechanic.status === 'AVAILABLE' && (
                      <MechanicCardBasic
                        user={mechanic}
                        color="#53CD58"
                        handleView={handleView}
                      />
                    )}
                </React.Fragment>
              ))}
              <div className=" mb-[25px] h-[1px] bg-grey-1"></div>
            </>
          )}

          {mechanics?.some(mechanic => mechanic.status === 'BUSY') && (
            <>
              <p className="text-[16px] font-medium mb-[15px]">Busy:</p>
              {mechanics?.map(mechanic => (
                <React.Fragment key={mechanic.id}>
                  {mechanic.role !== 'REPAIR_SHOP_MANAGER' &&
                    mechanic.status === 'BUSY' && (
                      <MechanicCardBasic
                        user={mechanic}
                        color="#DA0000"
                        handleView={handleView}
                      />
                    )}
                </React.Fragment>
              ))}
              <div className=" mb-[25px] h-[1px] bg-grey-1"></div>
            </>
          )}

          {mechanics?.some(mechanic => mechanic.status === 'OFFLINE') && (
            <>
              <p className="text-[16px] font-medium mb-[15px]">
                Offline Mechanics:
              </p>
              {mechanics?.map(mechanic => (
                <React.Fragment key={mechanic.id}>
                  {mechanic.role !== 'REPAIR_SHOP_MANAGER' &&
                    mechanic.status === 'OFFLINE' && (
                      <MechanicCardBasic
                        user={mechanic}
                        color={'grey'}
                        handleView={handleView}
                      />
                    )}
                </React.Fragment>
              ))}
              <div className=" mb-[25px] h-[1px] bg-grey-1"></div>
            </>
          )}
        </div>

        <Modal
          title=""
          open={isRequestModalOpen}
          onCancel={() => {
            setIsRequestModalOpen(false);
          }}
          footer={false}
          zIndex={1002}
          style={modalStyles}
        >
          <ModalMechanic
            mechanic={chosenMech}
            option={option}
            close={() => {
              setIsRequestModalOpen(true);
            }}
            showDelete={showDelete}
          />
        </Modal>
      </div>
    </div>
  );
};
