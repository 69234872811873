import React, { createContext, useContext, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { Button } from 'component-library';
import { InitiateRequestForm } from './InitiateRequestForm';
import { toast } from 'react-toastify';
import { ServiceRequestService } from 'services/service-requests.service';
import { LiteService } from 'services/lite.service';
import { RequestDetailsForm } from './RequestDetailsForm';

const ModalContext = createContext();
export const useModalContext = () => useContext(ModalContext);

/**
 * AddNewRequestModal Component
 *
 * This component renders a modal for adding new service requests. It manages the state
 * of the request creation process, including multiple steps and form submissions.
 *
 * @component
 * @param {Object} props - The component props
 * @param {boolean} props.isOpen - Controls whether the modal is open or closed
 * @param {function} props.setIsOpen - Function to set the open/closed state of the modal
 * @exports
 */

const AddNewRequestModal = ({ isOpen, setIsOpen }) => {
  const [step, setStep] = useState(1);
  const [serviceRequestToken, setServiceRequestToken] = useState(null);
  const [request, setRequest] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSendSMS = async () => {
    setIsLoading(true);
    try {
      const { firstName, lastName, phoneNo } = request.requestOwner;
      await ServiceRequestService.sendSingleSmsNotification(
        serviceRequestToken,
        `${firstName} ${lastName}`,
        phoneNo
      );
      toast.success('SMS sent successfully');
      setIsOpen(false);
    } catch (error) {
      console.error('Error sending SMS:', error);
      toast.error('Error sending SMS');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Get the request details when the serviceRequestToken is set after creating a new request
    const getRequest = async () => {
      try {
        const { data } = await LiteService.getServiceRequest(
          serviceRequestToken
        );
        setRequest(data.serviceRequest);
      } catch (error) {
        console.error('Error getting available requests:', error);
        toast.error('Error getting available requests');
      }
    };

    if (serviceRequestToken) {
      getRequest();
    }
  }, [serviceRequestToken]);

  const renderModalContent = () => {
    switch (step) {
      case 1:
        return (
          <ModalStep
            title="Add New Request"
            subtitle="Enter driver's information"
            content={<InitiateRequestForm />}
          />
        );
      case 2:
        return (
          <ModalStep
            title="Add New Request"
            subtitle="Would you like enter the request details manually or send it to driver?"
            content={
              <div className="flex w-full gap-4">
                <Button onClick={() => setStep(3)} title="Add Info" />
                <Button
                  onClick={handleSendSMS}
                  title="Send to Driver"
                  variant="secondary"
                  loading={isLoading}
                />
              </div>
            }
          />
        );
      case 3:
        return (
          <ModalStep
            title="Add Request Details"
            subtitle="Please add request details"
            content={<RequestDetailsForm />}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        setIsOpen,
        setStep,
        serviceRequestToken,
        setServiceRequestToken,
      }}
    >
      <Modal
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={false}
        zIndex={1002}
        className="font-poppins"
      >
        {renderModalContent()}
      </Modal>
    </ModalContext.Provider>
  );
};

export const ModalStep = ({ title, subtitle, content }) => (
  <div className="flex flex-col w-full items-center justify-center gap-y-4 transition-all py-2">
    <div className="flex flex-col w-full gap-y-3 items-center">
      <span className="text-[26px] text-primary-2 font-semibold text-center">
        {title}
      </span>
      <span className="text-base font-normal text-center text-primary-2">
        {subtitle}
      </span>
    </div>
    {content}
  </div>
);

export default AddNewRequestModal;
