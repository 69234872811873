import { useEffect, useMemo } from 'react'
import {tryPlayAudioSelector} from '../redux/audio'
import sound from '../assets/correct_answer.mp3'
import { useSelector } from 'react-redux'

const NOOP = () => {}

let isValid = false
const useNotificationAudioPlayer = () => {
    const audio = useMemo(() => new Audio(sound),[])
    const tryPlayAudio = useSelector(tryPlayAudioSelector);

    useEffect(() => {
        const clickHandler = () => {
            isValid = true;
            window.document.removeEventListener('click',clickHandler )
        }
        window.document.addEventListener('click',clickHandler )
    },[])

    useEffect(() => {
        if(tryPlayAudio) {
            isValid && audio.play().catch(NOOP)
        }
    },[tryPlayAudio,audio])
}
 
export default useNotificationAudioPlayer;