import React, { useState } from 'react';
import { List } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MdTireRepair } from 'react-icons/md';
import { HiOutlineCog } from 'react-icons/hi';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';
import { FaTrailer } from 'react-icons/fa';
import moment from 'moment';

/**
 * Renders the service history tab component.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.data - The array of service history data.
 * @returns {JSX.Element} The rendered component.
 */
export const ServiceHistoryTab = ({ data }) => {
  console.log(data);
  const [expandedItemId, setExpandedItemId] = useState(null);
  const toggleMore = itemId => {
    setExpandedItemId(expandedItemId === itemId ? null : itemId);
  };

  const iconMap = {
    tire_repair: <MdTireRepair className="h-[40px] w-[40px] fill-accent-1" />,
    truck_repair: <HiOutlineCog className="h-[40px] w-[40px] text-accent-1" />,
    trailer_repair: <FaTrailer className="h-[40px] w-[40px] fill-accent-1" />,
  };

  return (
    <div
      className="h-[400px] overflow-auto w-full p-4 bg-[#f0f3f7] rounded-[15px]"
      id="scrollableDiv"
    >
      <InfiniteScroll dataLength={data.length} scrollableTarget="scrollableDiv">
        <List
          dataSource={data}
          renderItem={item => (
            <List.Item
              key={item.request.id}
              className="flex flex-col bg-white rounded-lg mb-4 !px-2"
            >
              <List.Item.Meta />

              <div className="flex gap-2 w-full">
                <div className="flex w-full">
                  {item.request.profileImage ? (
                    <img
                      className="w-[59px] h-[59px] rounded-md mr-1"
                      alt="profile"
                      src={item.request.profileImage}
                    />
                  ) : (
                    <div className=" flex justify-center items-center w-[59px] h-[59px] rounded-[10px] bg-accent-2 mr-1">
                      {iconMap[item.request.type]}
                    </div>
                  )}
                  <div className="flex flex-col">
                    <p className="text-primary-2 text-base font-medium">
                      {`${item.request.requestOwner?.firstName} ${item.request.requestOwner?.lastName}`}
                    </p>
                    <div className="flex items-center gap-3">
                      <p className={`text-sm text-primary-3`}>
                        {item.request.vin}
                      </p>
                      <p className={`text-sm text-primary-3`}>
                        {moment(
                          item.request.StatusChanges.find(
                            status => status.status === 'INITIATED'
                          )?.createdAt
                        ).format('MMMM Do, YYYY')}
                      </p>
                    </div>
                    <p className="text-accent-1 text-sm font-normal">
                      {item.request.serviceCategory.title}
                    </p>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => toggleMore(item.request.id)}
                >
                  {expandedItemId === item.request.id ? (
                    <FaChevronDown size={16} />
                  ) : (
                    <FaChevronUp size={16} />
                  )}
                </button>
              </div>
              {expandedItemId === item.request.id && (
                <div className="w-full flex flex-col gap-2 p-2 mt-2 bg-grey-4 rounded-lg flex-col justify-start items-start gap-1 inline-flex">
                  <div>
                    <div className="text-grey-2 text-sm font-medium">
                      Description
                    </div>
                    <div className="text-primay-2 text-sm font-normal">
                      {item.request.description}
                    </div>
                  </div>
                  <div>
                    <div className="text-grey-2 text-sm font-medium">
                      Address
                    </div>
                    <div className="text-primay-2 text-sm font-normal">
                      {item.request.address}
                    </div>
                  </div>
                </div>
              )}
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );
};
