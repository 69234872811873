import { ErrorMessage, Field } from 'formik';
import React, { Fragment } from 'react';
import { IoMdInformationCircle } from 'react-icons/io';

const FormikInput = ({
  suffix = '',
  error,
  label,
  htmlFor,
  className,
  inputClassName,
  labelClassName,
  name,
  ...props
}) => {
  return (
    <div className="flex flex-col">
      <label htmlFor={htmlFor} className={labelClassName || ''}>
        {label}
        {props.required && (
          <span className="font-semibold text-[13px] leading-5 text-[#DA1414]">
            *
          </span>
        )}
      </label>
      <div className="flex flex-col gap-y-1">
        <div
          className={`w-full py-[13px] px-[15px] text-[0.875rem] leading-[1.38em] rounded-xl border-primary-3 border flex flex-row ${
            className || ''
          }`}
        >
          <Field
            name={name}
            className={`w-full justify-center focus-visible:outline-none focus-within:outline-none ${
              inputClassName || ''
            }`}
            {...props}
          />
          {suffix}
        </div>
        <ErrorMessage
          name={name}
          component="div"
          render={msg => (
            <div className="bg-[#FEEFEF] py-1.5 px-4 flex items-start rounded-[8px]">
              <IoMdInformationCircle
                color="#DA1414"
                className="flex-none mt-0.5"
              />
              <span className="text-[13px] text-[#DA1414] font-normal ml-1">
                {msg}
              </span>
            </div>
          )}
        />
        {/* <ErrorMessage
          name={name}
          className="text-error-1 text-[10px]"
          component="span"
        /> */}
      </div>
    </div>
  );
};

export default FormikInput;
