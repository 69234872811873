import React, { useState } from 'react';
import { Button } from 'component-library';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from 'constants/routes.constants';
import { ACCESS_TOKEN_STORAGE_KEY } from 'constants/auth.constants';
import { selectUser } from 'redux/auth/selectors';
import { SignInModal } from 'components/sign/SignInModal';
import { Login } from './Login';
import { signIn } from 'redux/auth/operations';
import { toast } from 'react-toastify';
import { saveAuthTokensToStorage } from 'helpers/auth.helpers';
import { ProfileService } from 'services/profile.service';
import { updateProfileState } from 'redux/profile/profile.slice';
import { ForgotPassword } from './ForgotPassword';
import { EmailOnTheWay } from './EmailOnTheWay';
import { generatePasswordResetLink } from 'redux/auth/operations';
import { ResetPassword } from './ResetPassword';
import { resetPassword } from 'redux/auth/operations';
import { SuccessPasswordReset } from './SuccessPasswordReset';

/**
 * Sign In component.
 *
 * @component
 * @param {boolean} isLoginModalOpen - Indicates whether the sign in modal is open or not.
 * @param {function} setIsLoginModalOpen - Function to set the state of the sign in modal.
 * @returns {JSX.Element} Sign In component.
 */
export const SignIn = ({
  isLoginModalOpen,
  setIsLoginModalOpen,
  setIsSignUpModalOpen,
}) => {
  const user = useSelector(selectUser);
  const accessToken = window.localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [isRemember, setIsRemember] = useState(false);
  const [loading, setLoading] = useState(false);

  const openModal = () => {
    setIsLoginModalOpen(true);
  };

  const returnTitle = () => {
    switch (step) {
      case 1:
        return 'Welcome back';
      case 2:
        return 'Forgot password?';
      case 3:
        return 'Email on the way!';
      case 4:
        return 'Reset password';
      case 5:
        return 'Password reset successfully!';
      default:
        return '';
    }
  };

  const returnButtonText = () => {
    return accessToken && accessToken !== 'undefined' ? 'Dashboard' : 'Sign In';
  };

  const handleClick = () => {
    if (!accessToken || accessToken === 'undefined') {
      openModal();
    } else {
      if (user?.role === 'DRIVER') {
        navigate(routes.driverProfile);
      } else if (['FLEET_MANAGER', 'FLEET_ADMIN'].includes(user?.role)) {
        navigate(routes.fleetDashboard);
      } else if (
        ['REPAIR_SHOP_MANAGER', 'REPAIR_SHOP_ADMIN'].includes(user?.role)
      ) {
        navigate(routes.repairShopDashboard);
      }
    }
  };

  const renderBody = () => {
    switch (step) {
      case 1:
        return (
          <Login
            isRemember={isRemember}
            setIsRemember={setIsRemember}
            handleLogin={handleLogin}
            handleSignUp={() => {
              setIsLoginModalOpen(false);
              setIsSignUpModalOpen(true);
            }}
            handleForgot={() => setStep(2)}
            handleReset={() => setStep(4)}
          />
        );
      case 2:
        return (
          <ForgotPassword handleSendPasswordResetLink={sendPasswordResetLink} />
        );

      case 3:
        return <EmailOnTheWay setStep={setStep} />;
      case 4:
        return <ResetPassword handlePasswordReset={handlePasswordReset} />;
      case 5:
        return <SuccessPasswordReset setStep={setStep} />;
      default:
        return <></>;
    }
  };

  const sendPasswordResetLink = async data => {
    setLoading(true);
    try {
      const response = await dispatch(generatePasswordResetLink(data.email));
      if (!response.payload) {
        toast.error(
          'An error occurred while sending the password reset link. Please try again later.'
        );
        return;
      }
      if (response?.payload?.status) {
        setStep(3);
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async data => {
    setLoading(true);
    try {
      const response = await dispatch(
        resetPassword({
          email: data.email,
          password: data.password,
          otp: data.code,
        })
      );
      if (!response.payload) {
        toast.error(
          'An error occurred while resetting the password. Please try again later.'
        );
        return;
      }
      if (response?.payload?.status) {
        setStep(5);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async data => {
    setLoading(true);
    try {
      const response = await dispatch(
        signIn({
          email: data.email,
          password: data.password,
        })
      );

      if (!response.payload) {
        toast.error(
          'An error occurred while signing in. Please try again later'
        );
        return;
      }

      response?.payload?.data?.accessToken &&
        saveAuthTokensToStorage(
          response?.payload?.data?.accessToken,
          response?.payload?.data?.refreshToken
        );

      if (response?.payload?.data) {
        const result = await ProfileService.getUserProfile();
        if (result) {
          dispatch(updateProfileState(result.userDetails));
          if (result.userDetails?.role === 'DRIVER') {
            navigate(routes.driverProfile);
          } else if (
            ['FLEET_MANAGER', 'FLEET_ADMIN'].includes(result.userDetails?.role)
          ) {
            navigate(routes.fleetDashboard);
          } else if (
            [
              'REPAIR_SHOP_MANAGER',
              'REPAIR_SHOP',
              'REPAIR_SHOP_ADMIN',
            ].includes(result.userDetails?.role)
          ) {
            navigate(routes.repairShopDashboard);
          }
          setIsLoginModalOpen(false);
        } else {
          toast.error(
            'An error occurred while signing in. Please try again later.'
          );
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button onClick={handleClick} title={returnButtonText()} />
      <SignInModal
        isLoginModalOpen={isLoginModalOpen}
        setIsLoginModalOpen={setIsLoginModalOpen}
        step={step}
        setStep={setStep}
        title={returnTitle(step)}
        isGoBack={step === 2 || step === 4}
        goBack={() => {
          setStep(1);
        }}
      >
        {renderBody()}
      </SignInModal>
    </div>
  );
};
