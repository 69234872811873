import React from 'react';
import {
  IoIosCheckmark,
  IoIosClose,
  IoMdInformationCircle,
} from 'react-icons/io';

/**
 * Component for managing company ownership selection.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.isSuperAdmin - The current super admin status.
 * @param {function} props.setIsSuperAdmin - The function to update the super admin status.
 * @param {string} props.isOwner - The current owner status.
 * @param {function} props.setIsOwner - The function to update the owner status.
 * @returns {JSX.Element} The rendered component.
 */
export const CompanyOwnerShip = ({
  isSuperAdmin,
  setIsSuperAdmin,
  isOwner,
  onOwnerChange,
}) => {
  return (
    <div className="w-full mt-8">
      <div className="w-full flex flex-row justify-between items-center">
        <div
          onClick={() => {
            onOwnerChange(true);
          }}
          className={`w-[48%]  py-9    rounded-[20px]  flex justify-center items-center cursor-pointer ${
            isOwner
              ? 'border-[2px] border-accent-1'
              : 'border-secondary-1 border-[1px]'
          }`}
        >
          <div className="h-6 w-6 bg-accent-2  rounded-full flex justify-center items-center">
            <IoIosCheckmark color="#2D6DCA" size={20} />
          </div>
          <span className="text-base font-normal text-primary-2 ml-2">Yes</span>
        </div>
        <div
          onClick={() => {
            onOwnerChange(false);
          }}
          className={`w-[48%] py-9 rounded-[20px] flex justify-center items-center cursor-pointer ${
            !isOwner
              ? 'border-[2px] border-accent-1'
              : 'border-secondary-1 border-[1px]'
          }`}
        >
          <div className="h-6 w-6 bg-[#FFB8B885]  rounded-full flex justify-center items-center ">
            <IoIosClose color="#FF5252" size={20} />
          </div>
          <span className="text-base font-normal text-primary-2 ml-2">No</span>
        </div>
      </div>
      {!isOwner && (
        <div
          className={`bg-[#FEEFEF] mt-2 py-2 flex items-center w-full rounded-[8px]`}
        >
          <IoMdInformationCircle color="#DA1414" size="20" className="ml-2" />
          <span className="text-[13px] text-[#DA1414] font-normal ml-1">
            Please contact your General Manager to create an account.
          </span>
        </div>
      )}

      {/* {isOwner === 'yes' && (
        <div className="flex flex-row mt-5">
        {!isSuperAdmin ? (
          <div
            className="border-secondary-1 w-6 h-6 rounded-[4px] border-[1px] cursor-pointer"
            onClick={() => {
              setIsSuperAdmin(true);
            }}
          />
        ) : (
          <div
            onClick={() => {
              setIsSuperAdmin(false);
            }}
            className="w-6 h-6 rounded-[4px] border-accent-1 bg-accent-1 flex justify-center items-center cursor-pointer"
          >
            <IoIosCheckmark color="white" size={20} />
          </div>
        )}
        <span className="px-1 text-base font-normal text-primary-2 ml-2 cursor-pointer" onClick={() => setIsSuperAdmin(!isSuperAdmin)}>
          Make me super admin
        </span>
      </div>
      )} */}
    </div>
  );
};
