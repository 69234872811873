import { Formik, Form, Field } from 'formik';
import { FormikInput, FormikPhoneInput, Button } from 'component-library';
import { toast } from 'react-toastify';
import { LiteService } from 'services/lite.service';
import { useModalContext } from './AddNewRequestModal';
import {
  initiateRequestValidationSchema,
  initiateRequestInitialValues,
} from '../formData';

/**
 * @component
 * @description A form component for initiating a new request.
 */
export const InitiateRequestForm = () => {
  const { setIsOpen, setStep, setServiceRequestToken } = useModalContext();

  const handleAddRequest = async data => {
    try {
      const response = await LiteService.createRequestLite({
        nameOfDriver: `${data.firstName} ${data.lastName}`,
        phoneNumber: data.phoneNumber,
      });
      if (response) {
        setServiceRequestToken(response.data.token);
        setStep(2);
      }
    } catch (error) {
      toast.error('An error occurred.');
      setIsOpen(false);
    }
  };

  return (
    <Formik
      initialValues={initiateRequestInitialValues}
      validationSchema={initiateRequestValidationSchema}
      validateOnMount
      onSubmit={async (values, { resetForm }) => {
        await handleAddRequest(values);
        resetForm();
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form className="w-full flex flex-col">
          <div className="w-full pb-6">
            <div className={`flex w-full flex-wrap justify-start gap-4`}>
              <div className="flex-1">
                <FormikInput
                  name={`firstName`}
                  label={`First Name`}
                  placeholder={`First name`}
                  labelClassName="font-light text-sm leading-6 text-grey-2"
                  inputClassName="font-normal text-base leading-6 text-primary-2"
                />
              </div>
              <div className="flex-1">
                <FormikInput
                  name={`lastName`}
                  label={`Last Name`}
                  placeholder={`Last name`}
                  labelClassName="font-light text-sm leading-6 text-grey-2"
                  inputClassName="font-normal text-base leading-6 text-primary-2"
                />
              </div>
              <div className="w-full">
                <label className={'text-sm font-normal text-grey-2 mb-2'}>
                  Driver's Phone Number
                </label>
                <Field name="phoneNumber" component={FormikPhoneInput} />
              </div>
            </div>
          </div>
          <div className="flex w-full gap-4">
            <Button
              title="Next"
              type="submit"
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
