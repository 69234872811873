import React, { useState } from 'react';
import { motion } from 'framer-motion';
import useWindowSize from 'hooks/useWindowSize';
import { LogoLoader } from 'components/common';
import photo1 from 'assets/products-driver.png';
import photo2 from 'assets/products-mechanic.png';
import photo3 from 'assets/products-companies.png';
import ItemView from './ItemView';

const Products = ({ offset }) => {
  const { isMobile, isSmallTablet, isTablet, isMediumTablet } = useWindowSize();
  const [isPageLoading] = useState(false);

  return (
    <motion.div
      initial={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : -1000,
      }}
      animate={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : offset,
      }}
      exit={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : -1000,
      }}
      transition={{ bounce: 0 }}
      className="
       h-screen 
       overflow-y-scroll 
       scrollbar-thin 
       scrollbar-track-slate-100 
       scrollbar-thumb-slate-300 
       sm:p-8 
       p-4 
       py-8 
       min-h-screen 
       absolute 
       w-full 
       sm:w-[60vw] 
       lg:w-[86vw] 
       flex 
       flex-col 
       gap-y-12 
       rounded-e-2xl 
       bg-white 
       left-0 
       top-0 
       z-[1000]"
    >
      {isPageLoading ? (
        <LogoLoader />
      ) : (
        <>
          <div
            className="
              flex 
              flex-col 
              h-fit 
              gap-y-16"
          >
            <ItemView
              title="App for Drivers"
              subtitle="Connect truck drivers to local mechanics."
              img={photo1}
              list={
                'Request submitted, Find A Local Shop, Location Sharing, Request Tow Truck'
              }
            />
            <ItemView
              title="App for Mechanics"
              subtitle="Connect local mechanics to broken truck drivers."
              img={photo2}
              list={
                'Easy Registration, Profile Features, Promotion opportunities, Payments'
              }
              reverse={true}
            />
            <ItemView
              title="Platform for Truck Companies"
              subtitle="Connect truck drivers to local mechanics."
              img={photo3}
              list={'Request submitted, Find A Local Shop, Location Sharing'}
            />
          </div>
        </>
      )}
    </motion.div>
  );
};

export default Products;
