import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion } from 'framer-motion';
import useWindowSize from 'hooks/useWindowSize';
import { LogoLoader } from 'components/common';
import { IoIosSearch } from 'react-icons/io';
import { FaClockRotateLeft } from 'react-icons/fa6';
import { ServiceRequestService } from 'services/service-requests.service';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAvailableRequests,
  selectMyServiceRequests,
} from 'redux/requestData/selectors';
import { Button } from 'component-library';
import RequestCard from './components/RequestCard';
import AddNewRequestModal from './components/AddNewRequestModal';
import ModalAssignMechanic from '../../../DashboardRepair/ModalAssignMechanic';
import { getServiceCategories } from 'redux/reference/operations';
import { setRequest } from 'redux/requestData/actions';
import { toast } from 'react-toastify';
import { List, Skeleton } from 'antd';

const RequestsTab = ({ offset }) => {
  const dispatch = useDispatch();
  const { isMobile, isSmallTablet, isTablet, isMediumTablet } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [isAddRequest, setIsAddRequest] = useState(false);
  const [isAssignMechanicModalOpen, setIsAssignMechanicModalOpen] =
    useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const selfResult = useSelector(selectMyServiceRequests);
  const autoResult = useSelector(selectAvailableRequests);
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      dispatch(getServiceCategories());
      initialized.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    const mergeData = () => {
      const selfDataAll =
        selfResult?.filter(item =>
          [
            'SUBMITTED',
            'NOTIFICATION_SMS_SENT',
            'RECEIVED',
            'VIEWED',
            'ACCEPTED',
            'SCHEDULED',
            'IN_PROGRESS',
            'ASSIGNED_TO_MECHANIC',
            'MECHANIC_ON_THE_WAY',
            'APPROVED_BY_FLEET_MANAGER',
            'REJECTED_BY_MECHANIC',
          ].includes(item.status)
        ) || [];

      const autoDataAll =
        autoResult?.filter(item =>
          ['SUBMITTED', 'ASSIGNED_TO_MECHANIC'].includes(item.status)
        ) || [];

      const mergedData = [...selfDataAll, ...autoDataAll];
      setFilteredRequests(mergedData);
      setRequests(mergedData);
    };
    mergeData();
    setLoading(false);
  }, [selfResult, autoResult]);

  const handleUnassignMechanic = useCallback(
    async (serviceRequestId, mechanicId) => {
      try {
        setLoading(true);
        await ServiceRequestService.unassignMechanic({
          serviceRequestId,
          mechanicId,
        });
        toast.success('Request unassigned');
        // setIsAssignMechanicModalOpen(false);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const handleView = useCallback(data => {
    setSelectedRequest(data);
    setIsAssignMechanicModalOpen(true);
  }, []);

  const handleInputChange = useCallback(
    event => {
      const inputValue = event.target.value.trim().toLowerCase();
      setFilteredRequests(
        inputValue === ''
          ? requests
          : requests.filter(
              item =>
                item.requestOwner.firstName
                  ?.toLowerCase()
                  .includes(inputValue) ||
                item.requestOwner.lastName?.toLowerCase().includes(inputValue)
            )
      );
    },
    [requests]
  );

  const handleMouseOver = useCallback(
    item => {
      dispatch(setRequest(item?.requestOwner));
    },
    [dispatch]
  );

  const handleMouseOut = useCallback(() => {
    dispatch(setRequest(null));
  }, [dispatch]);

  return (
    <motion.div
      initial={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : -1000,
      }}
      animate={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : offset,
      }}
      exit={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : -1000,
      }}
      transition={{ bounce: 0 }}
      className="h-screen overflow-y-scroll scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-slate-300 sm:p-8 p-4 py-8 min-h-screen absolute w-full sm:w-[60vw] lg:max-w-2xl flex flex-col gap-y-12 rounded-e-2xl left-0 top-0 z-[1000] bg-white"
      style={{ boxShadow: '0px -4px 88px 0px rgba(58, 58, 58, 0.10)' }}
    >
      <div className="flex flex-col h-fit gap-y-16">
        <div className="w-full flex-col justify-between">
          <div className="flex items-end mb-[35px]">
            <div className="rounded-[30px] p-[13px] mt-3 mr-3 w-full flex flex-initial items-center h-12 bg-gray-100">
              <IoIosSearch className="text-[25px] text-primary-3" />
              <input
                type="text"
                placeholder="Search Requests"
                className="pl-[11px] text-primary-2 bg-gray-100 focus:outline-none placeholder:text-secondary-1"
                onChange={handleInputChange}
              />
            </div>
            <Button
              title="+ Add New Request"
              size="rounded"
              onClick={() => setIsAddRequest(true)}
            />
          </div>

          {requests.length > 0 ? (
            <div className="flex-grow">
              <List
                dataSource={filteredRequests}
                renderItem={(request, index) => (
                  <RequestCard
                    key={request.id || index}
                    request={request}
                    handleMouseOut={() => {}}
                    handleMouseOver={() => {}}
                    handleView={handleView}
                    handleUnassignMechanic={handleUnassignMechanic}
                  />
                )}
              />
            </div>
          ) : loading ? (
            <Skeleton active paragraph={{ rows: 4 }} />
          ) : (
            <div className="flex flex-col text-center gap-x-4 items-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] opacity-70">
              <FaClockRotateLeft className="text-primary-3 text-9xl" />
              <span className="text-2xl text-primary-3">
                You have no new request yet.
              </span>
            </div>
          )}

          {isAddRequest && (
            <AddNewRequestModal
              isOpen={isAddRequest}
              setIsOpen={setIsAddRequest}
            />
          )}
          {isAssignMechanicModalOpen && selectedRequest && (
            <ModalAssignMechanic
              isOpen={isAssignMechanicModalOpen}
              setIsOpen={setIsAssignMechanicModalOpen}
              request={selectedRequest}
            />
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default RequestsTab;
