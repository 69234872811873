import React from 'react';
import { IoMdClose } from 'react-icons/io';
import backgroundImage from '../../assets/illustrated-v2.png';
import logo from '../../assets/icon.png';
import { FaArrowLeft } from 'react-icons/fa6';
import useWindowSize from 'hooks/useWindowSize';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

export const SignInModal = ({
  isLoginModalOpen,
  setIsLoginModalOpen,
  children,
  title,
  step,
  setStep,
  goBack,
  isGoBack,
}) => {
  const { isMobile, isTablet, isMediumTablet, isSmallTablet } = useWindowSize();

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      setStep(1);
      setIsLoginModalOpen(false);
    }
  };

  if (!isLoginModalOpen) return;

  return (
    <div
      className="fixed inset-0 z-[999] flex items-center justify-center bg-black bg-opacity-40 backdrop-blur font-poppins"
      onClick={handleOverlayClick}
    >
      <div className=" flex items-center justify-center overflow-x-hidden overflow-y-auto scrollbar-none outline-none focus:outline-none rounded-[32px] lg:w-[76%] w-[84%] mx-[12%] lg:h-[80vh] h-[55vh]  top-[10vh] bg-white min-h-[550px]">
        <div className="flex relative flex-col-reverse w-full lg:flex-row lg:h-full ">
          <div className="w-full   lg:w-[60%] flex flex-col items-center justify-center h-full px-4 mt-16 mb-2  lg:mt-0 lg:mb-0 lg:px-14 md:px-16 xl:px-24">
            <img
              src={logo}
              alt="MyMechanic logo"
              className="w-[44px] h-[66px]  "
            />
            <h1
              className={`font-medium text-center text-2xl whitespace-pre-line ${
                `lg:w-[75%]`
                // [6,8,9].includes(step) ? 'lg:w-[75%] ' : 'lg:w-[65%] ' <-not sure why we have that
              }`}
            >
              {title}
            </h1>
            {children}
          </div>
          {!isMobile && !isTablet && !isSmallTablet && !isMediumTablet && (
            <div className=" relative rounded-[32px] lg:w-[45.5%] h-full">
              <div
                className={` bg-no-repeat w-full  bg-cover rounded-[32px] h-full`}
                style={{
                  backgroundImage: `url(${backgroundImage})`,
                }}
              />
              {/* {isStep && (
                <div className=" absolute bottom-0 rounded-b-[32px] bg-accent-1 w-full h-[10%] flex flex-row justify-center items-center">
                  {Array.apply(null, Array(6)).map((_, index) => {
                    console.log(isStep);
                    return (
                      <div className="flex-row flex items-center" key={index}>
                        {step - 1 > index ? (
                          <div className="w-5 h-5 rounded-full bg-[white] mx-1.5 flex justify-center items-center">
                            <div className="w-1.5 h-1.5 rounded-full bg-accent-1" />
                          </div>
                        ) : (
                          <div className="bg-[#578ad5] mx-1.5 w-2 h-2 rounded-full" />
                        )}
                        {index !== 5 && (
                          <div className="w-5 h-[1px] bg-[#578ad5] " />
                        )}
                      </div>
                    );
                  })}
                </div>
              )} */}
            </div>
          )}
          <div
            className="h-9 w-9 bg-white opacity-50  backdrop-blur-sm flex justify-center items-center rounded-full absolute right-6 top-6 cursor-pointer"
            onClick={() => setIsLoginModalOpen(false)}
          >
            <IoMdClose size={20} color="black" />
          </div>
          {isGoBack && (
            <div
              className="h-9 w-9 bg-white opacity-50 shadow-lg flex justify-center items-center rounded-full absolute left-6 top-6 cursor-pointer"
              onClick={goBack}
            >
              <FaArrowLeft size={15} color="black" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
