export const VerificationModal = ({  handleClick }) => {
  
  return (
    <div className=" mt-10  text-center">
      <h1 className=" font-medium text-3xl text-primary-2 text-center">
        Chose the way of Verification
      </h1>
      <p className="text-center text-[15px]  font-normal text-primary-3  mt-2">
        Verify your email or phone to streamline your services and stay connected.
      </p>
      <button
        onClick={handleClick}
        className={` mt-10 mb-5 flex rounded-2xl text-center py-[14px] w-full text-[0.875rem] leading-[1.38em] bg-accent-1 text-white  border border-transparent  transition-all duration-200 justify-center'
        }`}
      >
        Change me to email
      </button>
      <button className={` text-base text-accent-1 text-center`}>
        Change me to phone
      </button>
    </div>
  );
};
