import { Switch } from 'antd';
import React from 'react';

export const TurnOn = ({ isPushNotAllow, isSmsNotAllow, setIsPushNotAllow ,setIsSmsNotAllow}) => {
  return (
    <div className=" mt-10">
      <p className=" font-medium text-3xl text-primary-2 text-center">
      Turn on notifications
      </p>
      <p className="text-center text-[15px]  font-normal text-primary-2  mt-2">
      Control how your receive updates and alerts 
      </p>
    <div className='w-full flex justify-between items-center mt-8'>
        <span>Enable push notification </span>
        <Switch
          checked={isPushNotAllow}
          onChange={setIsPushNotAllow}
          className="bg-[gray]"
        />
    </div>
    <div className='w-full flex justify-between items-center mt-8'>
        <span>Enable SMS notification </span>
        <Switch
          checked={isSmsNotAllow}
          onChange={setIsSmsNotAllow}
          className="bg-[gray]"
        />
    </div>
    <button
        // onClick={handleClick}
        // disabled={!isChecked}
        className={` mt-10 mb-5 flex rounded-2xl text-center py-[14px] w-full text-[0.875rem] leading-[1.38em] bg-accent-1 text-white  border border-transparent  transition-all duration-200 justify-center 
   
        `}
      >
        Save
      </button>
    </div>
  );
};
