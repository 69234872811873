import { createSlice } from '@reduxjs/toolkit';
import { signIn, getProfile, logoutUser } from './operations';

const initialState = {
  user: {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    company: [],
    role: null,
    services: [],
    phoneNo: null,
    images: null,
    address: null,
  },
  isLoggedIn: false,
  accessToken: null,
  googleMapsAPIKey: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateAccessToken: (state, { payload }) => {
      state.accessToken = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(signIn.fulfilled, (state, action) => {
        state.user = action.payload.data.userDetails;
        state.isLoggedIn = true;
        state.accessToken = action.payload.data.accessToken;
        state.googleMapsAPIKey = action.payload.data.googleMapsAPIKey;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.user = action.payload.data.userDetails;
        state.isLoggedIn = true;
        state.googleMapsAPIKey = action.payload.data.googleMapsAPIKey;
      })
      .addCase(logoutUser.fulfilled, (state, _) => {
        state.user.id = null;
        state.user.firstName = null;
        state.user.lastName = null;
        state.user.email = null;
        state.user.company = null;
        state.user.role = null;
        state.user.services = null;
        state.user.phoneNo = null;
        state.user.images = null;
        state.address = null;
        state.isLoggedIn = false;
        state.accessToken = null;
      });
  },
});

export const { updateAccessToken } = authSlice.actions;

export default authSlice.reducer;
