import React, { useState } from 'react';
import { motion } from 'framer-motion';
import useWindowSize from 'hooks/useWindowSize';
import { LogoLoader } from 'components/common';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';

const ItemView = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-[16px]">
      <div
        className="flex justify-between p-4 cursor-pointer"
        onClick={toggleAccordion}
      >
        <h2 className="mb-[15px] font-bold lg:text-[18px] text-[16px]">
          {title}
        </h2>
        {isOpen ? (
          <SlArrowUp className="fill-grey-2" />
        ) : (
          <SlArrowDown className="fill-grey-2" />
        )}
      </div>
      {isOpen && <p className="p-4">{content}</p>}
      <div className=" mb-[28px] h-[1px] bg-grey-1"></div>
    </div>
  );
};

const Support = ({ offset }) => {
  const { isMobile,isTablet,isMediumTablet,isSmallTablet } = useWindowSize();
  const [isPageLoading] = useState(false);

  return (
    <motion.div
      initial={{ x:( isMobile ||isTablet||isMediumTablet||isSmallTablet ) ? 0 : -1000 }}
      animate={{ x: ( isMobile ||isTablet||isMediumTablet||isSmallTablet )? 0 : offset }}
      exit={{ x: ( isMobile ||isTablet||isMediumTablet||isSmallTablet ) ? 0 : -1000 }}
      transition={{ bounce: 0 }}
      className="h-screen overflow-y-scroll scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-slate-300 sm:p-8 p-4 py-8 min-h-screen absolute w-full sm:w-[60vw] lg:w-[86vw] flex flex-col gap-y-12 rounded-e-2xl bg-white left-0 top-0 z-[1000]"
    >
      {isPageLoading ? (
        <LogoLoader />
      ) : (
        <>
          <div className="">
            <p className="mb-[16px] font-bold lg:text-[40px] text-[36px]">
              Account support
            </p>
            <p className="text-[16px] mb-[52px]">
              If you needed help related to your account, we can help you.
            </p>

            <div className=" mb-[28px] h-[1px] bg-grey-1"></div>

            {/* <ItemView
              title="General help"
              content={
                <>
                  Visit our{' '}
                  <a
                    href="https://www.google.co.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-accent-1"
                  >
                    help center
                  </a>{' '}
                  to learn how to get started successfully using our platform or
                  any other technical questions.
                </>
              }
            /> */}
            <ItemView
              title="Contact myMechanic Support"
              content={
                <>
                  Write us an email{' '}
                  <a
                    href="mailto:support@mymechanic.app"
                    className="text-accent-1"
                  >
                    support@mymechanic.app
                  </a>
                </>
              }
            />
          </div>
        </>
      )}
    </motion.div>
  );
};

export default Support;
