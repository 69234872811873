import { Modal } from 'antd';
import { ScrollTab } from 'components/DashboardRepair/ScrollTab';
import useWindowSize from 'hooks/useWindowSize';

export const ReviewsModal = ({ isOpen, setIsOpen, feedbacks }) => {
  const { isMobile, isTablet, isMediumTablet } = useWindowSize();

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        setIsOpen(false);
      }}
      width={
        isMobile ? '85%' : isMediumTablet ? '60%' : isTablet ? '50%' : '35%'
      }
      footer={null}
      title={false}
      centered
      classNames={{
        content:
          'max-h-[90vh] transition-all overflow-y-scroll scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-slate-300 rounded-3xl',
        body: 'overflow-y-hidden',
        mask: '',
      }}
      zIndex={1005}
    >
      <div className="w-full">
        <span className="text-[26px] font-semibold">Reviews</span>
        <div className="flex flex-col">
          {feedbacks.length > 0 ? (
            feedbacks.map((item, index) => {
              return (
                <div
                  key={index}
                  className="min-w-[75%] md:min-w-[45%] lg:min-w-[25%] flex justify-center items-center"
                >
                  <ScrollTab data={item} />
                </div>
              );
            })
          ) : (
            <p>No reviews</p>
          )}
        </div>
      </div>
    </Modal>
  );
};
