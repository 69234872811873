export const routes = {
  root: '/',
  home: '/',
  driverProfile: '/driver/profile',
  fleetDashboard: '/fleet/dashboard',
  fleetDriverInvite: '/fleet/invites/:companyId',
  repairShopDashboard: '/repair-shop/dashboard',
  companySignUp: '/company/signup',
  verifyEmail: '/verify/email',
  searchResults: '/search',
  mainLite: token => `/token/${token || ':token'}`,
  singleVendorPage: id => `/vendors/${id || ':id'}`,
  support: '/support',
  supportLocation: '/support-location',
};
