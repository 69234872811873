import axios from 'axios';
import { ACCESS_TOKEN_STORAGE_KEY } from 'constants/auth.constants';

// Part of the rollout of new API_BASE_ROOT_URL env var. If it exists, use that. if not, use the old REACT_APP_API_URL or hardcoded value.
export const URL =
  process.env.REACT_APP_API_BASE_ROOT_URL ??
  process.env.REACT_APP_API_URL ??
  'https://development-api.mymechanic.app';

console.log('URL', URL);
console.log(
  'process.env.REACT_APP_API_BASE_ROOT_URL',
  process.env.REACT_APP_API_BASE_ROOT_URL
);
console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL);

export const apiBaseRootURL = URL;
export const apiV2BaseUrl = `${apiBaseRootURL}/api/v2`;

/**
 * Defines an axios instance
 */
const api = axios.create({
  baseURL: apiV2BaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
api.interceptors.request.use(
  config => {
    // sets Authorization header with airtable token
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      ACCESS_TOKEN_STORAGE_KEY
    )}`;
    return config;
  },
  // Do something with request error
  error => Promise.reject(error)
);

export default api;
