import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { FaStar, FaUser } from 'react-icons/fa';
import { AiFillDollarCircle } from 'react-icons/ai';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi2';
import { Button } from 'component-library';
import { useSelector, useDispatch } from 'react-redux';
import { selectBreakdowns } from 'redux/reference/selectors';
import { ReviewCard } from '../../../profile/FleetDashboardNavigation/MyTechniciansTab/components/ReviewCard';
import { getMechanicProfile } from 'redux/mechanics/operations';
import ModalShowReviews from './ModalShowReviews/ModalShowReviews';
import { getDistance } from 'utils/utils';
import { statusMarkerColors } from 'constants/style.constants';
import { calculateAverageRating } from 'helpers/common.helpers';

/**
 * MechanicCard Component
 *
 * This component displays information about a mechanic, including their profile image,
 * name, rating, hourly rate, distance, and provides functionality to view more details
 * and select the mechanic for assignment.
 *
 * @param {Object} props - The component props
 * @param {Object} props.mechanic - The mechanic's information
 * @param {Object} props.request - The repair request information
 * @param {boolean} props.isSelected - Whether this mechanic is currently selected
 * @param {function} props.onSelect - Function to call when selecting this mechanic
 *
 * @returns {JSX.Element} The MechanicCard component
 */
export const MechanicCard = ({ mechanic, request, isSelected, onSelect }) => {
  const dispatch = useDispatch();
  const [expandedItemId, setExpandedItemId] = useState(null);
  const [serviceRequests, setServiceRequests] = useState([]);
  const [isModalShowReviewsOpen, setIsModalShowReviewsOpen] = useState(false);

  const serviceCategories = useSelector(selectBreakdowns);
  const rating = calculateAverageRating(mechanic.ratingsReceived);
  const reviewsWithFeedback = serviceRequests.filter(
    review => review.feedback !== null
  );
  const distance = getDistance(
    request.requestOwner.locationLat,
    request.requestOwner.locationLong,
    mechanic.locationLat,
    mechanic.locationLong
  );

  const toggleMore = useCallback(itemId => {
    setExpandedItemId(prevId => (prevId === itemId ? null : itemId));
  }, []);

  useEffect(() => {
    const getServiceRequests = async () => {
      try {
        const response = await dispatch(getMechanicProfile(mechanic.id));
        setServiceRequests(response.payload.data.serviceRequests);
      } catch (error) {
        console.error('Error fetching mechanic:', error);
      }
    };
    getServiceRequests();
  }, [mechanic.id, dispatch]);

  return (
    <Fragment>
      <div className="flex gap-3 w-full items-center">
        <div className="flex w-full gap-2">
          {mechanic.profileImage ? (
            <img
              className="w-[59px] h-[59px] rounded-md object-cover"
              alt="profile"
              src={mechanic.profileImage.url}
            />
          ) : (
            <div className=" flex justify-center items-center w-[59px] h-[59px] rounded-[10px] bg-accent-2 mr-1">
              <FaUser className="fill-accent-1" size={40} />
            </div>
          )}
          <div className="flex flex-col">
            <div className="flex items-center gap-1">
              <p className="text-primary-2 text-base font-medium">
                {`${mechanic.firstName} ${mechanic.lastName}`}
              </p>
              <div
                style={{
                  backgroundColor: statusMarkerColors[mechanic.status],
                }}
                className="h-3 w-3 rounded-full"
              ></div>
            </div>

            <div className="flex space-x-2 items-center">
              {mechanic.rate && (
                <Fragment>
                  <div className="flex items-center gap-x-2">
                    <FaStar className="text-base text-accent-1" />
                    <span className="text-sm">
                      {rating}
                      <span className=" text-primary-3 text-xs font-normal ml-1">
                        ({mechanic.ratingsReceived.length} reviews)
                      </span>
                    </span>
                  </div>
                  <div className="flex items-center gap-x-1">
                    <AiFillDollarCircle className="text-base text-accent-1" />
                    <span className=" text-sm">
                      {mechanic.rate}$
                      <span className=" text-primary-3 text-xs font-normal ml-1">
                        /1h
                      </span>
                    </span>
                  </div>
                </Fragment>
              )}
            </div>
            <span className="text-sm text-grey-2">{distance} miles away</span>
          </div>
        </div>
        <Button
          title="Select"
          variant="select"
          size="default"
          classNames="!w-1/3 !py-1.5"
          onClick={onSelect}
          selected={isSelected}
        />
        <button type="button" onClick={() => toggleMore(mechanic.id)}>
          {expandedItemId === mechanic.id ? (
            <HiChevronUp size={24} />
          ) : (
            <HiChevronDown size={24} />
          )}
        </button>
      </div>
      {expandedItemId === mechanic.id && (
        <div className="w-full flex flex-col gap-3 p-2 justify-start items-start border-t-[1px] mt-3 pt-3">
          <div>
            <label className="text-grey-2 text-sm font-medium">About:</label>
            <div className="text-primay-2 text-sm font-normal">
              {mechanic.about}
            </div>
          </div>
          <div className="flex flex-col">
            <span className="font-medium text-grey-2 ">Capabilities: </span>
            <div className="flex flex-wrap self-stretch items-start content-start gap-2">
              {serviceCategories &&
                mechanic.services.map(service => (
                  <div
                    key={service.id}
                    className="py-0.5 px-4 border-[1px] border-accent-1 rounded-[30px] bg-accent-2"
                  >
                    <span className="text-xs text-accent-1 font-medium">
                      {
                        serviceCategories.find(
                          category => category.id === service.serviceCategoryId
                        ).title
                      }
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <div className="w-full flex flex-col">
            <label className="text-grey-2 text-sm font-medium">Reviews</label>
            <div className="flex items-center gap-x-2 mb-[5px]">
              <FaStar className="text-base text-accent-1" />
              <p className="text-sm">
                {rating}
                <span className=" text-primary-3 text-xs font-normal ml-1">
                  ({mechanic.ratingsReceived.length} reviews)
                </span>
              </p>
              {reviewsWithFeedback.length > 1 && (
                <Button
                  title="View All"
                  variant="text"
                  size="default"
                  classNames="text-sm text-accent-1 ml-auto !w-fit"
                  onClick={() => setIsModalShowReviewsOpen(true)}
                />
              )}
            </div>
            {reviewsWithFeedback.length > 0 && (
              <ReviewCard data={reviewsWithFeedback[0].feedback} />
            )}
          </div>
        </div>
      )}
      {isModalShowReviewsOpen && (
        <ModalShowReviews
          isOpen={isModalShowReviewsOpen}
          setIsOpen={setIsModalShowReviewsOpen}
          reviews={reviewsWithFeedback}
          mechanic={mechanic}
          ratings={mechanic.ratingsReceived}
          rating={rating}
          distance={distance}
        />
      )}
    </Fragment>
  );
};
