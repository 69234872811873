import {
  ACCESS_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from 'constants/auth.constants';

export const saveAuthTokensToStorage = (accessToken, refreshToken) => {
  window.localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
  window.localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
};

export const logout = () => {
  window.localStorage.clear();
};
