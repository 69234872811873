import { Progress, Rate } from 'antd';

/**
 * RatingDistribution Component
 *
 * This component displays the rating distribution for a mechanic.
 *
 * @param {Object} props - The component props
 * @param {Array} props.ratingDistribution - Array of rating distribution objects
 * @param {number} props.rating - Overall rating of the mechanic
 * @param {number} props.ratingsCount - Number of ratings for the mechanic
 *
 * @returns {JSX.Element} The RatingDistribution component
 */
export const RatingDistribution = ({
  ratingDistribution,
  rating,
  ratingsCount,
}) => (
  <div className="flex gap-6 w-full">
    <div className="flex flex-col w-3/4">
      {ratingDistribution.map(({ score, percentage }) => (
        <div key={score} className="flex gap-3 items-center">
          <span className="w-2">{score}</span>
          <Progress
            percent={percentage}
            showInfo={false}
            strokeColor="#2D6DCA"
            trailColor="##F1F3F8"
            className="m-0 h-6"
            size={['100%', 11]}
          />
        </div>
      ))}
    </div>
    <div className="flex flex-col items-center justify-center gap-1 w-1/4">
      <p className="text-[32px] font-normal">{rating}</p>
      <Rate
        disabled
        defaultValue={rating}
        allowHalf
        className=" text-sm w-full text-accent-1"
      />
      <p className="text-primary-3 font-normal">{ratingsCount} reviews</p>
    </div>
  </div>
);
