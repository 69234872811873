import React from 'react';
import { FaStar } from 'react-icons/fa6';
import { FaPhoneAlt } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import profileImagePlaceholder from '../../assets/profile-image-placeholder.jpg';

export const MechanicDetails = ({ data, index, onClick }) => {
    return (
      <div
        role="tooltip"
        className="absolute  shadow-lg  group-hover:block bg-[white] text-primary-2 pr-12 pb-5 font-semibold px-5 py-3 text-[13px] right-10  mx-auto w-max -top-36 rounded-2xl before:w-4 before:h-4 before:rotate-45 before:bg-[white] before:absolute  before:-bottom-1 before:left-0  before:right-0 before:mx-auto before:z-10 left-[-100px]"
      >
        <button
          // disabled={!isMobile && !isTablet}
          onClick={onClick}
          className="flex items-center"
        >
          <div className="flex gap-x-4">
            <div className="flex flex-col justify-start items-start">
              <img
                src={
                  data.images[0] ? data.images[0].url : profileImagePlaceholder
                }
                alt={data.name}
                className="w-[60px] h-[60px] object-cover rounded-[10%]"
              />
              <span className="text-accent-1 text-sm">{data.distance.toFixed(2)}mi</span>
            </div>
            <div className="flex flex-col items-start ">
              <span className="font-bold text-sm">
                {index + 1}. {data.name}
              </span>
              <span className="text-semibold text-sm">
                {' '}
                ${data.rate ?? 'not specified'}
              </span>
              <div className="flex flex-col sm:flex-row gap-y-1 gap-x-1 items-center mt-[8px]">
                <div className="flex gap-x-1 items-center">
                  <FaStar className="text-base text-accent-1" />
                  <span className="">{data.rating.toFixed(2) || 0}</span>
                </div>
                <span>{`(${data.jobCount} Jobs)`}</span>
              </div>
              <span className="text-primary-3 text-xs">{data.companyName}</span>
              <a
                href={'tel:' + data?.phoneNo}
                className={` mt-[8px] py-1 px-4 rounded-[20px] ${'bg-accent-1 text-white'}  transition-all w-full flex items-center`}
              >
                <FaPhoneAlt className="text-[14px]  text-white mr-1" />
                Call Up
              </a>
            </div>
          </div>
  
          <div className="absolute right-2 top-2">
            <IoMdClose color="#B8B8B8" height={10} width={10} />
          </div>
        </button>
        <div className="tooltip-arrow" data-popper-arrow></div>
      </div>
    );
  };