import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const options = [
  { value: 1, label: 'Tire replacement' },
  { value: 2, label: 'Trailer repair' },
  { value: 3, label: 'Truck repair' },
];

export const initialFields = {
  firstName: {
    name: 'firstName',
    placeholder: 'First Name',
    type: 'text',
    label: 'First Name',
  },
  lastName: {
    name: 'lastName',
    placeholder: 'Last name',
    type: 'text',
    label: 'Last name',
  },
  phoneNumber: {
    name: 'phoneNumber',
    placeholder: 'Enter phone number',
    type: 'text',
    label: 'Phone Number',
  },
  serviceCategoryIDs: {
    name: 'serviceCategoryIDs',
    placeholder: 'Select capabilities',
    type: 'text',
    label: 'Capabilities',
  },
  about: {
    name: 'about',
    placeholder: 'Enter a short description',
    type: 'textarea',
    label: 'About Technician',
  },
  rate: {
    name: 'rate',
    placeholder: 'Enter rate',
    type: 'number',
    label: 'Rate',
  },
  specialization: {
    name: 'specialization',
    placeholder: 'Enter specialization',
    type: 'text',
    label: 'specialization',
  },
  image: {
    name: 'image',
    placeholder: 'Upload profile image',
    type: 'file',
    label: 'Profile Image',
  },
};

export const validationSchema = Yup.object().shape({
  [initialFields.firstName.name]: Yup.string()
    .trim()
    .min(2, `Minimum length for driver's name is 2 characters`)
    .max(30, `Maximum length for driver's name is 30 characters`)
    .required('First name is required'),
  [initialFields.lastName.name]: Yup.string()
    .trim()
    .min(2, `Minimum length for driver's name is 2 characters`)
    .max(30, `Maximum length for driver's name is 30 characters`)
    .nullable(), // Allow blank value
  [initialFields.phoneNumber.name]: Yup.string()
    .required('Phone number is required')
    .test('is-valid-phone', 'Phone number is invalid', value => {
      return isValidPhoneNumber(value || '');
    }),
  [initialFields.serviceCategoryIDs.name]: Yup.array(),
  [initialFields.image.name]: Yup.string().optional().nullable(),
});