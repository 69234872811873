import { Button, FormikInput } from 'component-library';
import { ErrorMessage, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { fields, initialValues, validationSchema } from './formData';
import { HomeServices } from 'services/home.service';
import { toast } from 'react-toastify';

export const Suggestions = () => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    setIsFormSubmitting(true);

    const response = await HomeServices.submitSuggestion(values);

    if (!response) {
      setIsFormSubmitting(false);
      toast.error('An error occurred. Please try again.');

      return;
    }

    toast('Success. We will be in touch!');
    resetForm();
    setIsFormSubmitting(false);

    console.log({ values });
  };
  return (
    <div className="flex flex-col w-full px-4 lg:px-12 sm:px-8 gap-y-8">
      <div className="flex justify-between items-end">
        <h3 className="text-[24px] md:text-[28px] font-semibold pr-2">
          Want to join our wait list?
          <br />
          Sign up for a demo!
        </h3>
      </div>
      <div className="flex gap-x-8">
        {/* Form */}
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className="w-full flex flex-col gap-y-8">
              <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-y-8 gap-x-10">
                {Object.keys(fields).map(field => {
                  const fieldData = fields[field];

                  if (fieldData.type === 'textarea') {
                    return (
                      <div
                        className="w-full flex flex-col gap-y-[10px]"
                        key={fieldData.name}
                      >
                        <label className="font-semibold">Message</label>
                        <textarea
                          className="w-full resize-none bg-[#f2f7fc] rounded-xl focus-within:outline-none focus-visible:outline-none py-[13px] px-[15px] text-[0.875rem] leading-[1.38em]"
                          rows={6}
                          value={values[fieldData.name]}
                          onChange={({ target }) => {
                            setFieldValue(fieldData.name, target.value);
                          }}
                        />
                        <ErrorMessage
                          name={fieldData.name}
                          className="text-error-1 text-[10px]"
                          component="span"
                        />
                      </div>
                    );
                  } else {
                    return (
                      <FormikInput
                        label={fieldData.label}
                        placeholder={fieldData.placeholder}
                        className="border-none bg-[#f2f7fc]"
                        inputClassName="bg-[#f2f7fc]"
                        labelClassName="font-semibold"
                        name={fieldData.name}
                        key={fieldData.name}
                        type={fieldData.type}
                      />
                    );
                  }
                })}
              </div>

              <div className="lg:max-w-[10rem]">
                <Button
                  type="submit"
                  title="Send a Message"
                  loading={isFormSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
        {/* Truck Image */}
      </div>
    </div>
  );
};
