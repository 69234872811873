import React from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from 'react-icons/fa6';
import { ImAppleinc } from 'react-icons/im';
import { SiAndroid } from 'react-icons/si';
import { Link } from 'react-router-dom';

export const HomeFooter = () => {
  return (
    <div className="w-full flex flex-col gap-y-6 px-4 lg:px-12 sm:px-8 lg:pb-4">
      <div className="w-full flex flex-col-reverse lg:flex-row items-center gap-y-12 lg:gap-y-0 py-12 border-t border-b border-primary-3">
        <div className="flex flex-col gap-y-10 w-full">
          <h2 className="font-bold text-[30px]">myMechanic</h2>
          <div className="grid grid-cols-3 gap-y-4">
            <Link>
              <span className="text-primary-3 hover:underline text-sm sm:text-base">
                Promotion
              </span>
            </Link>
            <Link>
              <span className="text-primary-3 hover:underline text-sm sm:text-base">
                Home
              </span>
            </Link>
            <Link>
              <span className="text-primary-3 hover:underline text-sm sm:text-base">
                Join Us
              </span>
            </Link>
            <Link>
              <span className="text-primary-3 hover:underline text-sm sm:text-base">
                Our Products
              </span>
            </Link>
            <Link>
              <span className="text-primary-3 hover:underline text-sm sm:text-base">
                Sponsorships
              </span>
            </Link>
            <Link>
              <span className="text-primary-3 hover:underline text-sm sm:text-base">
                Search
              </span>
            </Link>
            <Link>
              <span className="text-primary-3 hover:underline text-sm sm:text-base">
                Send a Request
              </span>
            </Link>
            <Link>
              <span className="text-primary-3 hover:underline text-sm sm:text-base">
                Terms & Conditions
              </span>
            </Link>
            <Link>
              <span className="text-primary-3 hover:underline text-sm sm:text-base">
                Privacy Policy
              </span>
            </Link>
          </div>
        </div>
        <div className="w-full flex flex-col items-center lg:items-start gap-y-8 border-b border-primary-3 lg:border-none pb-12 lg:pb-0">
          <div className="text-[30px] text-center lg:text-start font-semibold lg:max-w-[60%]">
            <span className="text-center lg:text-start">
              Its suitable to all devices and screens
            </span>
          </div>
          <div className="flex flex-col sm:flex-row gap-y-4 sm:gap-y-0 gap-x-4 items-center">
            <a href="https://google.com">
              <div className="flex items-center gap-x-3 px-10 py-5 rounded-2xl text-[18px] font-medium bg-[#2d6dca] text-white">
                <ImAppleinc />
                <span>App Store</span>
              </div>
            </a>
            <a href="https://google.com">
              <div className="flex items-center gap-x-3 px-10 py-5 rounded-2xl text-[18px] font-medium border border-[#2d6dca] text-[#2d6dca]">
                <SiAndroid />
                <span>Play Store</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col sm:flex-row gap-y-4 sm:gap-y-0 justify-between md:justify-center items-center relative">
        <div className="flex items-center gap-x-8 sm:gap-x-3 md:absolute left-0">
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noreferrer"
            className="text-[24px]"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://x.com"
            target="_blank"
            rel="noreferrer"
            className="text-[24px]"
          >
            <FaTwitter />
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noreferrer"
            className="text-[24px]"
          >
            <FaLinkedinIn />
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noreferrer"
            className="text-[24px]"
          >
            <FaInstagram />
          </a>
        </div>
        <span className="text-center text-primary-3 text-sm sm:text-base">
          Copyright © myMechanic 2023 | All rights reserved.
        </span>
        <div></div>
      </div>
    </div>
  );
};
