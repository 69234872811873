import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from 'component-library';
import { AiOutlineLoading } from 'react-icons/ai';
import { OnboardingService } from 'services/onboarding.service';

const fields = {
  ein: {
    name: 'ein',
    placeholder: 'Enter your 9 digit EIN',
    type: 'text',
    label: 'EIN Number',
    maxLength: 9,
  },
};

const initialValues = {
  [fields.ein.name]: '',
};

const validationSchema = Yup.object().shape({
  [fields.ein.name]: Yup.string()
    .required('Required')
    .matches(/^\d{9}$/, { message: 'Please enter a valid EIN' }),
});

const Ein = ({ onFinish }) => {
  const submitEin = async (values, { setSubmitting }) => {
    const einRes = await OnboardingService.submitEin(values.ein);

    if (einRes) {
      onFinish();
    } else {
      setSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col w-full items-center justify-center py-16 px-6 gap-y-6 transition-all">
      <img
        src="https://mymechanicimages.s3.amazonaws.com/web-assets/service-provider/ein-modal-icon.svg"
        alt="main_icon"
      />
      <div className="flex flex-col w-full gap-y-3 items-center">
        <span className="text-[32px] leading-10 font-medium text-center max-w-[75%]">
          Please enter the EIN of your company
        </span>
      </div>
      <div className="w-full max-w-[70%]">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitEin}
        >
          {({ errors, handleChange, isValid, isSubmitting }) => (
            <Form className="w-full flex flex-col gap-y-6">
              <FormikInput
                name={fields.ein.name}
                htmlFor={fields.ein.name}
                label={fields.ein.label}
                placeholder={fields.ein.placeholder}
                maxLength={fields.ein.maxLength}
                labelClassName="font-medium text-base leading-6 text-primary-2"
                inputClassName="font-normal text-base leading-6 text-primary-2"
              />
              <button
                disabled={!isValid || isSubmitting}
                className={`rounded-2xl h-[52px] bg-[#2D6DCA] w-full text-white flex items-center justify-center text-base leading-6 font-medium ${
                  !isValid || isSubmitting ? 'opacity-40' : ''
                } transition-all`}
                type="submit"
                // onClick={verifyOtp}
              >
                {isSubmitting ? (
                  <AiOutlineLoading className="animate-spin text-center" />
                ) : (
                  <span>Continue</span>
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Ein;
