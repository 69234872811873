import React, { useEffect, useState } from 'react';
import { Button } from 'component-library';
import { SignUpModal } from 'components/sign/SignUpModal';
import { getServiceCategories } from 'redux/reference/operations';
import { CompanyCategory } from './CompanyCategory';
import { CompanyName } from './CompanyName';
import { CompanyAddress } from './CompanyAddress';
import { CompanyInformation } from './CompanyInformation';
import { CompanyJobsAndTools } from './CompanyJobsAndTools';
import { CompanyOwnerShip } from './CompanyOwnerShip';
import { CompanyPhoneNumberNoCountry } from './CompanyPhoneNumberNoCountry';
import { signUpCompanyV2 } from '../../../redux/auth/operations';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { getInitialSignUpState, signUpSchema, stepTitles } from './formData';

/**
 * Sign up component for creating a new account.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setIsLoginModalOpen - Function to set the state of the login modal.
 * @returns {JSX.Element} The sign up component.
 */
export const SignUp = ({
  setIsLoginModalOpen,
  isSignUpModalOpen,
  setIsSignUpModalOpen,
}) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [disableButton, setIsDisableButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState(getInitialSignUpState());
  const setInfoData = data => {
    setInfo({ ...info, ...data });
  };

  // Fetch service categories and set them in the session storage.
  useEffect(() => {
    dispatch(getServiceCategories());
  });

  useEffect(() => {
    signUpSchema(step)
      .validate(info)
      .then(valid => setIsDisableButton(!valid))
      .catch(() => setIsDisableButton(true));
  }, [step, info]);

  /** Renders the body of the modal based on the current step */
  const renderBody = () => {
    switch (step) {
      case 1:
        return (
          <CompanyCategory
            category={info.isServiceProvider}
            onCategoryChange={value =>
              setInfoData({ isServiceProvider: value })
            }
          />
        );
      case 2:
        return (
          <CompanyName
            name={info.companyName}
            setIsValid={value => setIsDisableButton(!value)}
            onNameChange={value => {
              setInfoData({ companyName: value });
            }}
            validationSchema={() => signUpSchema(2)}
          />
        );
      case 3:
        return (
          <CompanyOwnerShip
            isSuperAdmin={info.isSuperAdmin}
            isOwner={info.isCompanyOwner}
            onOwnerChange={value => setInfoData({ isCompanyOwner: value })}
            onSuperAdminChange={value => setInfoData({ isSuperAdmin: value })}
          />
        );
      case 4:
        return (
          <CompanyAddress
            onAddressChange={values => setInfoData({ ...info, ...values })}
            setIsValid={setIsDisableButton}
            validationSchema={() => signUpSchema(4)}
            stateId={info.stateId}
            zip={info.zipCode}
            address={info.address}
            cityId={info.cityId}
          />
        );
      case 5:
        return (
          <CompanyPhoneNumberNoCountry
            phone={info.phoneNo}
            onPhoneChange={value => setInfoData({ phoneNo: value })}
            validationSchema={() => signUpSchema(5)}
            setIsValid={setIsDisableButton}
          />
        );
      case 6:
        return (
          <CompanyInformation
            name={info.name}
            password={info.password}
            email={info.email}
            confirmPassword={info.confirmPassword}
            validationSchema={() => signUpSchema(6)}
            setIsValid={value => setIsDisableButton(value)}
            onInformationChange={values => setInfoData({ ...info, ...values })}
          />
        );
      case 7:
        return (
          <CompanyJobsAndTools
            onJobsAndToolsChange={values => setInfoData({ ...info, ...values })}
          />
        );
      default:
        return <></>;
    }
  };

  /** Submits the company registration form */
  const submitCompanyRegistration = async () => {
    try {
      setIsDisableButton(true);
      const companyDTO = {
        ...info,
        type: info.isServiceProvider ? 'REPAIR_SHOP' : 'FLEET',
        isCompanyOwner: info.isCompanyOwner === 'yes' ? true : false,
      };
      const response = await dispatch(signUpCompanyV2(companyDTO));
      if (response.payload.status) {
        toast.success(response.payload.message);
        setIsSignUpModalOpen(false);
        setInfoData(getInitialSignUpState());
        setIsLoginModalOpen(true);
        setStep(1);
      } else {
        const errorMsg = _.isArray(response.payload.message)
          ? response.payload.message[0]
          : response.payload.message;
        toast.error(errorMsg);
      }
    } finally {
      setIsDisableButton(false);
    }
  };

  return (
    <div>
      <Button
        onClick={() => {
          setIsSignUpModalOpen(true);
        }}
        title="Sign Up"
        variant="text"
      />
      <SignUpModal
        isLoading={isLoading}
        isModalOpen={isSignUpModalOpen}
        setIsModalOpen={setIsSignUpModalOpen}
        step={step}
        isStep={step !== 1}
        title={stepTitles(step)}
        goBack={() => {
          setStep(step - 1);
        }}
        resetForm={() => {
          setStep(1);
          setInfoData(getInitialSignUpState());
        }}
        handleClick={async () => {
          if (step === 7) {
            try {
              setIsLoading(true);
              await submitCompanyRegistration();
            } finally {
              setIsLoading(false);
            }
          } else setStep(step + 1);
        }}
        isGoBack={step !== 1}
        disableButton={disableButton}
        buttonText={step === 8 ? 'Complete Profile' : 'Continue'}
        loading={isLoading}
      >
        {renderBody()}
      </SignUpModal>
    </div>
  );
};
