import React, { useState } from 'react';
import { Switch } from 'antd';

export const AllowNotification = ({  handleClick }) => {
    const [isChecked, setIsChecked]=useState(false);

  return (
    <div className=" mt-10  text-center">
      <p className=" font-medium text-3xl text-primary-2 text-center">
        Allow notifications?
      </p>
      <p className="text-center text-[15px]  font-normal text-primary-3  mt-2">
        Would you like to receive notifications? So <br /> that you don’t miss a
        new requrst
      </p>
      <div className=" mt-8 flex flex-row items-center justify-center">
        <p className="text-sm font-normal text-primary-2  opacity-60 mr-8">
          Disable
        </p>
        <Switch
          checked={isChecked}
          onChange={setIsChecked}
          className="bg-[gray]"
        />
      </div>
      <button
        onClick={handleClick}
        disabled={!isChecked}
        className={` mt-10 mb-5 flex rounded-2xl text-center py-[14px] w-full text-[0.875rem] leading-[1.38em] bg-accent-1 text-white  border border-transparent  transition-all duration-200 justify-center ${
          !isChecked && 'bg-accent-3'
        }`}
      >
        Save
      </button>
      <button className={` text-base text-accent-1 text-center`}>
        I’ll do it later
      </button>
    </div>
  );
};
