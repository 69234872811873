import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const findMechanicByPhoneNumber = createAsyncThunk(
  'service-providers/mechanic-by-phone/open-ended',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.get(
        `service-providers/mechanic-by-phone/open-ended?phoneNumber=${credentials}`
      );
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getMechanicProfile = createAsyncThunk(
  'service-providers/mechanic',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.get(
        `service-providers/mechanic/mechanicId?mechanicId=${credentials}`
      );
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const sendInviteToMechanic = createAsyncThunk(
  'service-providers/send-invite',
  async (phoneNumber, thunkAPI) => {
    try {
      const res = await api.post(
        `service-providers/send-invite?phoneNumber=${phoneNumber}`
      );
      console.log('res', res);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addMechanicByPhoneNumber = createAsyncThunk(
  'user/add-mechanic-to-repair-shop',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post(
        `user/add-mechanic-to-repair-shop?phoneNumber=${credentials}`
      );
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addMechanicToCompany = createAsyncThunk(
  'service-providers/mechanic',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post('service-providers/mechanic', credentials);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const editMechanic = createAsyncThunk(
  'service-providers/mechanic',
  async (credentials, thunkAPI) => {
    console.log(credentials);
    try {
      const res = await api.patch('service-providers/mechanic', credentials);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const requestMechanic = createAsyncThunk(
  'service-requests/to-company',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post('service-requests/to-company', credentials);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const findMechanics = createAsyncThunk(
  'vendors/search-mechanics',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post('vendors/search-mechanics', credentials);
      return res.data;
    } catch (error) {
      console.log(
        '🚀 ~ file: operations.js:31 ~ error:',
        error.response.data.message
      );
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const findLocalShops = createAsyncThunk(
  'vendors/search-mechanics',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post('vendors/search-localshops', credentials);
      return res.data;
    } catch (error) {
      console.log(
        '🚀 ~ file: operations.js:31 ~ error:',
        error.response.data.message
      );
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const assignMechanic = createAsyncThunk(
  'service-requests/assign-mechanic',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post(
        `service-requests/assign-mechanic/${credentials.serviceRequestId}/${credentials.mechanicId}`
      );
      return res.data;
    } catch (error) {
      console.log(
        '🚀 ~ file: operations.js:46 ~ error:',
        error.response.data.message
      );
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const unassignMechanic = createAsyncThunk(
  'service-requests/unassign-mechanic',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post(
        `service-requests/unassign-mechanic/${credentials.serviceRequestId}/${credentials.mechanicId}`
      );
      return res.data;
    } catch (error) {
      console.log(
        '🚀 ~ file: operations.js:62 ~ error:',
        error.response.data.message
      );
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const myServiceRequests = createAsyncThunk(
  'service-requests/my-service-requests',
  async (_, thunkAPI) => {
    try {
      const res = await api.get('service-requests/my-service-requests');
      return res.data;
    } catch (error) {
      console.log(
        '🚀 ~ file: operations.js:78 ~ error:',
        error.response.data.message
      );
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const availableRequests = createAsyncThunk(
  'service-requests/available-requests',
  async (_, thunkAPI) => {
    try {
      const res = await api.get('service-requests/available-requests');
      return res.data;
    } catch (error) {
      console.log(
        '🚀 ~ file: operations.js:94 ~ error:',
        error.response.data.message
      );
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const rejectRequest = createAsyncThunk(
  'service-requests/reject-request',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post(
        `service-requests/reject-request/${credentials.serviceRequestId}`
      );
      return res.data;
    } catch (error) {
      console.log(
        '🚀 ~ file: operations.js:110 ~ error:',
        error.response.data.message
      );
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getFeedBackForUser = createAsyncThunk(
  '/user/get-feedback-for-user',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.get(`/user/get-feedback-for-user/${credentials}`);
      return res.data;
    } catch (error) {
      console.log(
        '🚀 ~ file: operations.js:31 ~ error:',
        error.response.data.message
      );
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const feedbackByMechanic = createAsyncThunk(
  '/service-requests/feedback-by-mechanic',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post(
        `/service-requests/feedback-by-mechanic/`,
        credentials
      );
      return res.data;
    } catch (error) {
      console.log(
        '🚀 ~ file: operations.js:167 ~ error:',
        error.response.data.message
      );
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
