import React from 'react';
import logo from '../../../assets/logo.svg';

export const LogoLoader = () => {
  return (
    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] animate-pulse">
      <img
        src={logo}
        alt="MyMechanic logo"
        className="w-[150px] h-auto sm:w-[180px] sm:h-auto grayscale opacity-40"
      />
    </div>
  );
};
