import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { IoIosCheckmark, IoMdInformationCircle } from 'react-icons/io';
import { AiOutlineEye } from 'react-icons/ai';
import { RiEyeCloseLine } from 'react-icons/ri';
import { Button } from 'component-library';

export const ResetPassword = ({ handlePasswordReset }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const initialFields = {
    email: {
      name: 'email',
      placeholder: 'Email',
      type: 'text',
      label: 'Email',
    },
    password: {
      name: 'password',
      placeholder: 'Password (Min 8 character)',
      type: 'password',
      label: 'New Password',
    },
    confirmPassword: {
      name: 'confirmPassword',
      placeholder: 'Password',
      type: 'password',
      label: 'Repeat Password',
    },
    code: {
      name: 'code',
      placeholder: 'Code',
      type: 'number',
      label: 'Code',
    },
  };

  const initialValues = {
    [initialFields.email.name]: '',
    [initialFields.password.name]: '',
    [initialFields.confirmPassword.name]: '',
    [initialFields.code.name]: '',
  };

  const strongPasswordRefExp = new RegExp(
    /(?=.*\d)(?=.*\W+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
    'gi'
  );

  const validationSchema = Yup.object().shape({
    [initialFields.email.name]: Yup.string()
      .matches(/\S+@\S+\.\S+/, 'Please enter a valid email')
      .required('Required'),
    [initialFields.password.name]: Yup.string()
      .required('Required')
      .min(8, `Min 8 character`)
      .matches(strongPasswordRefExp, {
        message:
          'Password must contain a number, an uppercase letter and a special character',
      }),
    [initialFields.confirmPassword.name]: Yup.string()
      .required('Required')
      .oneOf([Yup.ref(initialFields.password.name)], 'Passwords must match'),
    [initialFields.code.name]: Yup.string()
      .matches(/^[0-9]*$/, 'Must be a number')
      .required('Required')
      .length(4, 'Code must be 4 characters'),
  });

  return (
    <div className="mt-1 w-full text-center">
      <span className=" text-base text-primary-3 font-normal  text-center">
        Enter your email, password and code and you’re good to go
      </span>
      <div className="text-left">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => handlePasswordReset(values)}
        >
          {({ isValid, errors, touched, handleChange }) => (
            <Form className="w-full flex flex-col ">
              <div className="w-full  pt-6  grid grid-cols-1">
                {Object.keys(initialFields).map((field, index) => {
                  const fieldData = initialFields[field];
                  const isPassword = fieldData.type === 'password';
                  return (
                    <div className={`flex flex-col`} key={index}>
                      <label
                        className={'text-base font-normal text-primary-2 my-1'}
                      >
                        {fieldData.label}
                        <label className="text-[#DA1414]"> *</label>
                      </label>
                      <div className="flex flex-col gap-y-[2px]">
                        <div
                          className={`w-full py-[13px] px-[15px] text-[0.875rem] leading-[1.38em] rounded-xl  flex flex-row ${
                            errors[fieldData.name] && touched[fieldData.name]
                              ? 'bg-[#FEEFEF] border-[#DA1414] border-[1px]'
                              : 'border-primary-3 border'
                          }`}
                        >
                          <Field
                            name={fieldData.name}
                            className={`w-full justify-center focus-visible:outline-none focus-within:outline-none ${
                              touched[fieldData.name] &&
                              errors[fieldData.name] &&
                              'bg-[#FEEFEF] border-[#DA1414]'
                            } `}
                            placeholder={fieldData.placeholder}
                            type={
                              isPassword
                                ? fieldData.name === initialFields.password.name
                                  ? showPassword
                                    ? 'text'
                                    : 'password'
                                  : showConfirmPassword
                                  ? 'text'
                                  : 'password'
                                : fieldData.type
                            }
                            onChange={e => {
                              handleChange(e);
                            }}
                          />
                          {isPassword ? (
                            <button
                              type="button"
                              onClick={
                                fieldData.name === initialFields.password.name
                                  ? togglePasswordVisibility
                                  : toggleConfirmVisibility
                              }
                            >
                              {fieldData.name ===
                              initialFields.password.name ? (
                                showPassword ? (
                                  <AiOutlineEye
                                    className="text-lg"
                                    color="#858C94"
                                  />
                                ) : (
                                  <RiEyeCloseLine
                                    className="text-lg"
                                    color="#858C94"
                                  />
                                )
                              ) : showConfirmPassword ? (
                                <AiOutlineEye
                                  className="text-lg"
                                  color="#858C94"
                                />
                              ) : (
                                <RiEyeCloseLine
                                  className="text-lg"
                                  color="#858C94"
                                />
                              )}
                            </button>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      {errors[fieldData.name] && touched[fieldData.name] && (
                        <div
                          className={`bg-[#FEEFEF] py-2 px-4 flex flex-row items-center rounded-[8px]`}
                        >
                          <IoMdInformationCircle color="#DA1414" />
                          <span className="text-[13px] text-[#DA1414] font-normal ml-1">
                            {errors[fieldData.name]}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <Button title="Reset Password" classNames="mt-8" type="submit" />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
