import React, { useState } from 'react';
import { Button } from 'component-library';

/**
 * Renders the component that displays a message when the password has been successfully reset.
 * @param {Object} props - The component props.
 * @param {Function} props.setStep - The function to set the current step.
 * @returns {JSX.Element} - The rendered component.
 */
export const SuccessPasswordReset = ({ setStep }) => {
  return (
    <div className="mt-1 w-full text-center">
      <span className=" text-base text-primary-3 font-normal  text-center">
        Your password has been reset. Please login with your new password.
      </span>
      <Button
        onClick={() => {
          setStep(1);
        }}
        classNames="mt-8"
        title="Back to login"
      />
    </div>
  );
};
