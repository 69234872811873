import { FaStar } from 'react-icons/fa6';
import { FaUser } from 'react-icons/fa';
import moment from 'moment';

export const ScrollTab = ({ data }) => {
  return (
    <div className="bg-accent-4 rounded-[15px] p-[15px] lg:w-[400px] w-[200px] ">
      <div className="w-full flex relative mb-[10px]">
        {data.ratingByUser?.profileImage ? (
          <img
            src={data.ratingByUser?.profileImage}
            alt="User"
            className="mr-[10px] w-[60px] h-[60px] rounded-[10px] bg-accent-2 object-cover"
          />
        ) : (
        <div className=" flex justify-center items-center mr-[10px] w-[70px] h-[70px] rounded-[10px] bg-accent-2 object-cover">
          <FaUser className="h-[40px] w-[40px] fill-accent-1" />
        </div>
         )}

        <div className=" ">
          <p className="text-sm font-semibold mb-[5px]">
            {data.ratingByUser?.firstName}  {data.ratingByUser?.lastName}
          </p>

          <div className="flex justify-between">
            {data.score && (
              <div className="flex items-center gap-x-2 mb-[5px]">
                {Array.from({ length: Math.floor(data.score) }, (_, index) => (
                  <FaStar key={index} className="text-base text-accent-1" />
                ))}
              </div>
            )}
          </div>
          <p className="text-sm mb-[5px]">{moment(data.updatedAt).format('MMMM Do YYYY, h:mm')}</p>
        </div>
      </div>

      <div className=" text-primary-2 text-sm">{data.comment}</div>
    </div>
  );
};
