import React, { useCallback, useEffect, useState } from 'react';
import { IoIosCheckmark } from 'react-icons/io';
import _ from 'lodash';
import { SERVICE_CATEGORIES_KEY } from '../../../constants/auth.constants';

/**
 * Component for displaying and selecting jobs and tools.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onJobsAndToolsChange - The function to set values.
 * @returns {JSX.Element} The rendered component.
 */
export const CompanyJobsAndTools = ({ onJobsAndToolsChange }) => {
  const storedServiceCategories = JSON.parse(
    window.sessionStorage.getItem(SERVICE_CATEGORIES_KEY)
  );
  const jobs = storedServiceCategories;
  const tools = ['Diag laptop', 'Air compressor', 'Welder'];
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectedTools, setSelectedTools] = useState([]);

  const addItemInJobs = useCallback(
    newItem => {
      setSelectedJobs([...selectedJobs, newItem]);
    },
    [selectedJobs]
  );

  const removeItemInJobs = itemToRemove => {
    const newItemsArray = _.filter(selectedJobs, item => item !== itemToRemove);
    setSelectedJobs(newItemsArray);
  };

  const addItemInTools = useCallback(
    newITool => setSelectedTools([...selectedTools, newITool]),
    [selectedTools]
  );

  const removeItemInTools = itemToRemove => {
    const newItemsArray = _.filter(
      selectedTools,
      item => item !== itemToRemove
    );
    setSelectedTools(newItemsArray);
  };

  const checkIsItemExists = (array, itemToCheck) => {
    return array.includes(itemToCheck);
  };

  useEffect(() => {
    const handleSetValues = () => {
      onJobsAndToolsChange({ serviceCategoryIDs: selectedJobs.join(',') });
    };

    handleSetValues();
  }, [selectedJobs]);

  return (
    <div className="mt-8 flex flex-row justify-between items-start w-full">
      <div className="flex flex-col items-start justify-start">
        <span className="mb-5 font-normal text-xl text-primary-2 ">Jobs</span>
        {jobs.length &&
          jobs.map((job, index) => {
            return (
              <div key={index} className="flex flex-row items-center mt-4">
                {!checkIsItemExists(selectedJobs, job.id) ? (
                  <div
                    className="border-secondary-1 w-6 h-6 rounded-[4px] border-[1px] cursor-pointer"
                    onClick={() => {
                      addItemInJobs(job.id);
                    }}
                  />
                ) : (
                  <div
                    onClick={() => {
                      removeItemInJobs(job.id);
                    }}
                    className="w-6 h-6 rounded-[4px] border-accent-1 bg-accent-1 flex justify-center items-center cursor-pointer"
                  >
                    <IoIosCheckmark color="white" size={20} />
                  </div>
                )}
                {/*TODO: Add click function in span*/}
                <span className="ml-4 font-normal text-sm text-primary-2 ">
                  {job.title}
                </span>
              </div>
            );
          })}
        {!jobs && (
          <div className={`flex text-center w-full font-medium text-xl`}>
            Ops, No jobs found.
          </div>
        )}
      </div>
      {/*<div className="flex flex-col items-start justify-start">*/}
      {/*  <span className='mb-5 font-normal text-xl text-primary-2 '>Tools</span>*/}
      {/*  {tools.map((tool, index) => {*/}
      {/*    return (*/}
      {/*      <div className="flex flex-row items-center mt-4" key={index}>*/}
      {/*        {!checkIsItemExists(selectedTools, tool) ? (*/}
      {/*          <div*/}
      {/*            className="border-secondary-1 w-6 h-6 rounded-[4px] border-[1px] cursor-pointer"*/}
      {/*            onClick={() => {*/}
      {/*              addItemInTools(tool);*/}
      {/*            }}*/}
      {/*          />*/}
      {/*        ) : (*/}
      {/*          <div*/}
      {/*            onClick={() => {*/}
      {/*              removeItemInTools(tool);*/}
      {/*            }}*/}
      {/*            className="w-6 h-6 rounded-[4px] border-accent-1 bg-accent-1 flex justify-center items-center cursor-pointer"*/}
      {/*          >*/}
      {/*            <IoIosCheckmark color="white" size={20} />*/}
      {/*          </div>*/}
      {/*        )}*/}
      {/*        <span className='ml-4 font-normal text-sm text-primary-2 '>{tool}</span>*/}
      {/*      </div>*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</div>*/}
    </div>
  );
};
