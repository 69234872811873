import * as Yup from 'yup';

export const fields = {
  firstName: {
    name: 'firstName',
    placeholder: 'Enter your first name',
    type: 'text',
    label: 'First Name',
  },
  lastName: {
    name: 'lastName',
    placeholder: 'Enter your last name',
    type: 'text',
    label: 'Last Name',
  },
  email: {
    name: 'email',
    placeholder: 'example@email.com',
    type: 'email',
    label: 'Email',
  },
  phone: {
    name: 'phone',
    placeholder: '+1 123 456 7890',
    type: 'text',
    label: 'Phone',
  },
  message: {
    name: 'message',
    placeholder: 'Leave us a message.',
    type: 'textarea',
    label: 'Message',
  },
};

export const initialValues = {
  [fields.firstName.name]: '',
  [fields.lastName.name]: '',
  [fields.email.name]: '',
  [fields.phone.name]: '',
  [fields.message.name]: '',
};

export const validationSchema = Yup.object().shape({
  [fields.firstName.name]: Yup.string().required('Required'),
  [fields.lastName.name]: Yup.string().required('Required'),
  [fields.email.name]: Yup.string()
    .matches(/\S+@\S+\.\S+/, 'Email is invalid')
    .required('Required'),
  [fields.phone.name]: Yup.string()
    .matches(/^[+]?\d+[0-9]{5}$/g, 'Phone is invalid')
    .required('Required'),
  [fields.message.name]: Yup.string().required('Required'),
});
