import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import api from 'api';
import { formatDateTime } from 'helpers/common.helpers';
import { Button } from 'component-library';

export const ChatLog = ({ requestOwnerId, roomId }) => {
  const [chatLogs, setChatLogs] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const fetchChatLogs = async () => {
    try {
      const response = await api.get('/chat/service-request-messages', {
        params: {
          roomId,
          page: 1,
          perPage: 500,
        },
      });
      setChatLogs(response.data.data.data);
      setHasMore(
        response.data.data.totalPages > response.data.data.currentPage
      );
    } catch (error) {
      console.error('Error fetching chat logs:', error);
    }
  };

  useEffect(() => {
    fetchChatLogs();
  }, []);

  const saveLogsToFile = () => {
    const logText = chatLogs
      .map(
        log =>
          `${getSenderLabel(log)}: ${log.message} (${formatDateTime(
            log.createdAt
          )})`
      )
      .join('\n');
    const blob = new Blob([logText], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'chat_logs.txt');
  };

  const getSenderLabel = log =>
    requestOwnerId === log.senderUserId ? 'Driver' : 'Technician';

  const renderChatLogs = () => (
    <ul className="flex flex-col gap-y-3">
      {chatLogs
        .map(log => (
          <li
            key={log.id}
            className="bg-primary-1 rounded-[10px] p-2 flex flex-col gap-y-1"
          >
            <p className="text-primary-2 text-base font-medium">
              {getSenderLabel(log)}
            </p>
            <p className="text-grey-2 text-sm font-normal">"{log.message}"</p>
            <p className="text-primary-3 text-xs font-normal">
              {formatDateTime(log.createdAt)}
            </p>
          </li>
        ))
        .reverse()}
      {/* Removing due to very odd bug where code behaves differently on localhost vs deployed
        I suspect something related to our passing of the parrent ref combined with how we build
        for deploy. Will be detailed on the PR associated with this comment
        <InfiniteScroll
          pageStart={0}
          isReverse={true}
          loadMore={fetchChatLogs}
          hasMore={hasMore}
          loader={<div className="loader" key={0}>Loading ...</div>}
          useWindow={false}
          getScrollParent={() => modalRef.current}
        > 
          {reverseChatLogsRenderItems}
         </InfiniteScroll> */}
    </ul>
  );

  return (
    <div className="flex flex-col w-full items-center justify-center gap-y-4 transition-all py-2">
      <div className="flex flex-col w-full gap-y-3 items-center">
        <span className="text-[26px] text-primary-2 font-semibold text-center">
          Chat Logs
        </span>
      </div>
      <div
        id="scrollableDiv"
        className="bg-grey-1 rounded-[10px] max-h-[400px] overflow-auto flex flex-col-reverse p-3 w-full"
      >
        {renderChatLogs()}
      </div>
      <Button
        onClick={saveLogsToFile}
        title="Download Logs"
        classNames="max-w-fit"
      />
    </div>
  );
};
