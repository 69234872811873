import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { SERVICE_CATEGORIES_KEY } from 'constants/auth.constants';

export const getServiceCategories = createAsyncThunk(
  'reference/get-service-categories',
  async (_, thunkAPI) => {
    try {
      const { data: responseData } = await api.get(
        'reference/get-service-categories'
      );
      window.sessionStorage.setItem(
        SERVICE_CATEGORIES_KEY,
        JSON.stringify(responseData.data.serviceCategories)
      );

      return responseData.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getStates = createAsyncThunk(
  'reference/get-states',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post('reference/get-states', credentials);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getCountries = createAsyncThunk(
  'reference/get-countries',
  async (_, thunkAPI) => {
    try {
      const res = await api.get('reference/get-countries');
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getCities = createAsyncThunk(
  'reference/get-cities',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post('reference/get-cities', credentials);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
