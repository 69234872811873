import React from 'react';
import { Field, Form, Formik } from 'formik';
import { IoMdInformationCircle } from 'react-icons/io';

/**
 * Renders a form component for entering company's name.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onNameChange - Callback function to handle name change.
 * @param {string} props.name - The initial value for the name field.
 * @returns {JSX.Element} The rendered component.
 */
export const CompanyName = ({ onNameChange, name, validationSchema }) => {
  const initialFields = {
    companyName: {
      name: 'companyName',
      placeholder: 'Enter company name',
      type: 'text',
      label: 'Company Name',
    },
  };
  const initialValues = {
    [initialFields.companyName.name]: name,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema}>
      {({ isValid, errors, handleChange }) => (
        <Form className="w-full flex flex-col">
          <div className="w-full  pt-6">
            {Object.keys(initialFields).map((field, index) => {
              const fieldData = initialFields[field];
              return (
                <div className="flex flex-col gap-y-1" key={index}>
                  <label
                    className={'text-base font-normal text-primary-2  my-1'}
                  >
                    {fieldData.label}
                    <label className="text-[#DA1414]"> *</label>
                  </label>
                  <div className="flex flex-col gap-y-[2px]">
                    <div
                      className={`w-full py-[13px] px-[15px] text-[0.875rem] leading-[1.38em] rounded-xl  flex flex-row ${
                        isValid
                          ? 'border-primary-3 border'
                          : 'bg-[#FEEFEF] border-[#DA1414] border-[1px]'
                      }`}
                    >
                      <Field
                        name={fieldData.name}
                        className={`w-full justify-center focus-visible:outline-none focus-within:outline-none ${
                          !isValid && 'bg-[#FEEFEF] '
                        } `}
                        placeholder={fieldData.placeholder}
                        onChange={e => {
                          handleChange(e);
                          onNameChange(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {errors[fieldData.name] && (
                    <div
                      className={`bg-[#FEEFEF] py-2 px-4 flex flex-row items-center rounded-[8px]`}
                    >
                      <IoMdInformationCircle color="#DA1414" />
                      <span className="text-[13px] text-[#DA1414] font-normal ml-1">
                        {errors[fieldData.name]}
                      </span>
                    </div>
                  )}
                  {/* {errors[fieldData.name] ? (
                    <div
                      className={`bg-[#FEEFEF] py-1.5 px-4 flex flex-row items-center rounded-[8px]`}
                    >
                      <IoMdInformationCircle color="#DA1414" />
                      <span className="text-[13px] text-[#DA1414] font-normal ml-1">
                        {errors[fieldData.name]}
                      </span>
                    </div>
                  ) : (
                    <div className={`py-[15.75px] px-4`}></div>
                  )} */}
                </div>
              );
            })}
          </div>
        </Form>
      )}
    </Formik>
  );
};
