// padding: 24px 0;
// text-align: center;
// background-color: ${p => `${p.theme.colors.accent}`};
// color: ${p => `${p.theme.colors.whiteText}`};
// font-family: 'Roboto', sans-serif;
// font-size: 16px;
export const Footer = () => {
  return (
    <div className="bg-accent-1 py-4 sm:py-6 text-center text-primary-1 text-sm sm:text-base px-4">
      <p>Copyright © myMechanic 2023 | All rights reserved.</p>
      {/* <p>All rights reserved.</p> */}
    </div>
  );
};
