import { Modal } from 'antd';
import { Button, FormikInput } from 'component-library';
import { FiUploadCloud } from 'react-icons/fi';
import useWindowSize from 'hooks/useWindowSize';
import React, { useState } from 'react';
import { Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileService } from 'services/profile.service';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { fields, initialValues, validationSchema } from './truckFormData';
import VehiclesSample from '../../../assets/vehicles.csv';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { bulkUploadVehicles } from 'redux/fleet/operations';

const { Dragger } = Upload;

export const SingleUploadTab = ({ reload, setIsOpen }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onFormSubmit = async values => {
    setIsSubmitting(true);
    const response = await ProfileService.addVehicle('TRUCK', {
      ...values,
    });

    if (!response) {
      setIsSubmitting(false);
      setIsOpen(false);
      toast.error(
        'An error occurred while adding that vehicle. Please try again later.'
      );
    } else {
      setIsSubmitting(false);
      setIsOpen(false);
      reload();
      toast('Successfully added truck!');
    }
  };
  return (
    <Formik
      onSubmit={onFormSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {() => (
        <Form className="flex flex-col gap-y-6">
          <div className=" gap-x-4 gap-y-6">
            {Object.keys(fields).map(field => {
              const fieldData = fields[field];

              return (
                <FormikInput
                  key={fieldData.name}
                  label={fieldData.label}
                  placeholder={fieldData.placeholder}
                  name={fieldData.name}
                  className="py-[10px] px-[12px] rounded-lg"
                />
              );
            })}
          </div>
          {/* <div className="flex flex-col gap-y-[10px]">
            <label className="font-medium">Truck Color</label>
            <ColorPicker
              onChange={value => {
                setColor(value.toHexString());
              }}
              format="hex"
            />
          </div> */}
          <Button
            loading={isSubmitting}
            title="Save a new truck"
            type="submit"
          />
        </Form>
      )}
    </Formik>
  );
};

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

export const BulkUploadTab = ({ onUpload }) => {
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [isFileAdded, setIsFileAdded] = useState(false);

  console.log({ isFileAdded });

  const auth = useSelector(state => state.auth);

  const upload = async info => {
    if (info.file?.status === 'uploading') {
      setIsFileAdded(true);
      setIsUploading(true);

      if (onUpload) {
        onUpload(info.file);
      } else {
        const response = await dispatch(
          bulkUploadVehicles(info.file, auth.user.company.contactEmail)
        );

        if (!response) {
          setIsUploading(false);

          toast.error(
            'An error occurred while uploading that file. Please try again later.'
          );
        }
      }
    } else if (info.file?.status === 'removed') {
      setIsFileAdded(false);
    }
  };

  return (
    <div className="w-full flex flex-col gap-y-2">
      <Dragger
        accept=".csv"
        name={'file'}
        multiple={false}
        onChange={upload}
        customRequest={dummyRequest}
        disabled={isFileAdded || isUploading}
      >
        {isUploading ? (
          <div className="w-full flex flex-col items-center justify-center py-6">
            <AiOutlineLoading3Quarters className="text-4xl text-primary-3 animate-spin" />
          </div>
        ) : (
          <div className="w-full flex items-center flex-col gap-y-4 p-6">
            <FiUploadCloud className="text-6xl text-accent-1" />
            <div>
              <p className="text-xs">
                Click or drag file to this area to upload
              </p>
            </div>
          </div>
        )}
      </Dragger>
      <p className="text-sm leading-[17.5px] text-[#A6A9AD]">
        Supports csv/text in the structure of{' '}
        <a href={VehiclesSample} download className="text-accent-1 underline">
          this sample
        </a>
        .
      </p>
    </div>
  );
};

export const AddTruckModal = ({ isOpen, setIsOpen, reload }) => {
  const tabs = [
    {
      key: '1',
      label: 'Single Upload',
      children: <SingleUploadTab setIsOpen={setIsOpen} reload={reload} />,
    },
    {
      key: '2',
      label: 'Bulk Upload',
      children: <BulkUploadTab />,
    },
  ];
  const { isMobile, isTablet, isMediumTablet } = useWindowSize();
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        setActiveTab(tabs[0].key);
        setIsOpen(false);
      }}
      width={
        isMobile ? '85%' : isMediumTablet ? '60%' : isTablet ? '50%' : '35%'
      }
      footer={null}
      title={false}
      centered
      classNames={{
        content:
          'max-h-[90vh] transition-all overflow-y-scroll scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-slate-300 rounded-3xl',
        body: 'overflow-y-hidden',
        mask: '',
      }}
      zIndex={1005}
    >
      {/* <Tabs defaultActiveKey="1" items={tabs} /> */}
      <div className="w-full flex flex-col gap-y-8 items-center">
        <div className="flex w-full flex-col text-center gap-y-6">
          <div className="w-full flex flex-col items-center">
            <span className="text-[26px] font-semibold">Add a New Truck</span>
            <span className="text-[12px] max-w-[72%]">
              You can add a track to the platform or massively upload all the
              trucks of your company in a couple of clicks.
            </span>
          </div>
          <div className="flex flex-col lg:flex-row w-full rounded-lg bg-grey-4 border border-grey-4">
            <button
              onClick={() => {
                setActiveTab(tabs[0].key);
              }}
              className={`w-full rounded-lg px-2 py-[9px] text-[13px] transition-all font-medium leading-5 ${
                activeTab === tabs[0].key
                  ? 'border-accent-1 text-accent-1 border-[0.5px] bg-white'
                  : ''
              } `}
            >
              Add a Truck
            </button>
            <button
              onClick={() => {
                setActiveTab(tabs[1].key);
              }}
              className={`w-full rounded-lg px-2 py-[9px] text-[13px] transition-all font-medium leading-5 ${
                activeTab === tabs[1].key
                  ? 'border-accent-1 text-accent-1 border-[0.5px] bg-white'
                  : ''
              } `}
            >
              Mass Upload
            </button>
          </div>
          {/* Tab Selector Buttons */}
        </div>
        <div className="w-full">
          {tabs.find(tab => tab.key === activeTab).children}
        </div>
      </div>
    </Modal>
  );
};
