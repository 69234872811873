import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authSlice from './auth/slice';
import { profileReducer } from './profile/profile.slice';
import { fleetReducer } from './fleet/fleet.slice';
import requestDataReducer from './requestData/reducers';
import audioReducer from './audio/audio.slice';
import { mechanicReducer } from './mechanics/mechanic.slice';
import { homeReducer } from './home/home.slice';
import { ReferenceReducer } from './reference/reference.slice';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'isLoggedIn'],
};

export const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, authSlice),
    profile: profileReducer,
    fleet: fleetReducer,
    requestData: requestDataReducer,
    audio: audioReducer,
    mechanics: mechanicReducer,
    home: homeReducer,
    reference: ReferenceReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);
