import React from 'react';
import { IoMdClose } from 'react-icons/io';
import backgroundImage from '../../assets/illustrated-v2.png';
import logo from '../../assets/icon.png';
import { FaArrowLeft } from 'react-icons/fa6';
import useWindowSize from 'hooks/useWindowSize';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Button } from 'component-library';

/**
 * Sign up modal component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isModalOpen - Flag indicating whether the modal is open or not.
 * @param {function} props.setIsModalOpen - Function to set the modal open state.
 * @param {ReactNode} props.children - The content inside the modal.
 * @param {string} props.title - The title of the modal.
 * @param {function} props.handleClick - Function to handle button click.
 * @param {function} props.goBack - Function to go back to previous step.
 * @param {boolean} props.isGoBack - Flag indicating whether to show the go back button or not.
 * @param {boolean} props.disableButton - Flag indicating whether the button is disabled or not.
 * @param {number} props.step - The current step of the sign up process.
 * @param {string} props.buttonText - The text to display on the button.
 * @param {boolean} props.isStep - Flag indicating whether to show the step indicator or not.
 * @param {boolean} props.loading - Flag indicating whether the button is in loading state or not.
 * @param {function} props.resetForm - Function to reset the form.
 * @returns {JSX.Element} The sign up modal component.
 */
export const SignUpModal = ({
  isModalOpen,
  setIsModalOpen,
  children,
  title,
  handleClick,
  goBack,
  isGoBack,
  disableButton,
  step,
  buttonText,
  isStep,
  loading,
  resetForm,
}) => {
  const { isMobile, isTablet, isMediumTablet, isSmallTablet } = useWindowSize();

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(false);
      if (typeof resetForm === 'function') {
        resetForm();
      }
    }
  };

  if (!isModalOpen) return;

  return (
    <div
      className="fixed inset-0 z-[999] flex items-center justify-center bg-black bg-opacity-40 backdrop-blur font-poppins"
      onClick={handleOverlayClick}
    >
      <div className=" flex items-center justify-center overflow-x-hidden overflow-y-auto scrollbar-none outline-none focus:outline-none rounded-[32px] lg:w-[76%] w-[84%] mx-[12%] lg:h-[80vh] h-[55vh]  top-[10vh] bg-white min-h-[550px] max-w-[1080px]">
        <div className="flex relative flex-col-reverse w-full lg:flex-row lg:h-full ">
          <div className="w-full   lg:w-[60%] flex flex-col items-center justify-center h-full px-4 mt-16 mb-2  lg:mt-0 lg:mb-0 lg:px-14 md:px-16 xl:px-24">
            <img src={logo} alt="MyMechanic logo" className="h-[66px]  " />
            <h2
              className={`font-medium text-center text-3xl whitespace-pre-line`}
            >
              {title}
            </h2>
            {children}
            {/* <button
              onClick={handleClick}
              disabled={disableButton}
              className={`flex rounded-2xl text-center py-[14px] w-full text-[0.875rem] leading-[1.38em] bg-accent-1 text-white  border border-transparent  transition-all duration-200 justify-center mt-8 ${
                (disableButton || loading) && 'bg-accent-3'
              }`}
            >
              {loading ? (
                <AiOutlineLoading3Quarters className="animate-spin" />
              ) : (
                buttonText
              )}
            </button> */}
            <Button
              onClick={handleClick}
              disabled={disableButton}
              title={buttonText}
              classNames="mt-8"
            />
          </div>
          {!isMobile && !isTablet && !isSmallTablet && !isMediumTablet && (
            <div className=" relative rounded-[32px] lg:w-[45.5%] h-full">
              <div
                className={` bg-no-repeat w-full  bg-cover rounded-[32px] h-full`}
                style={{
                  backgroundImage: `url(${backgroundImage})`,
                }}
              />
              {isStep && (
                <div className=" absolute bottom-0 rounded-b-[32px] bg-accent-1 w-full h-[10%] flex flex-row justify-center items-center">
                  {Array.apply(null, Array(6)).map((_, index) => {
                    return (
                      <div className="flex-row flex items-center" key={index}>
                        {step - 1 > index ? (
                          <div className="w-5 h-5 rounded-full bg-[white] mx-1.5 flex justify-center items-center">
                            <div className="w-1.5 h-1.5 rounded-full bg-accent-1" />
                          </div>
                        ) : (
                          <div className="bg-[#578ad5] mx-1.5 w-2 h-2 rounded-full" />
                        )}
                        {index !== 5 && (
                          <div className="w-5 h-[1px] bg-[#578ad5] " />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          <div
            className="h-9 w-9 bg-white opacity-50  backdrop-blur-sm flex justify-center items-center rounded-full absolute right-6 top-6 cursor-pointer"
            onClick={() => setIsModalOpen(false)}
          >
            <IoMdClose size={20} color="black" />
          </div>
          {isGoBack && (
            <div
              className="h-9 w-9 bg-white opacity-50 shadow-lg flex justify-center items-center rounded-full absolute left-6 top-6 cursor-pointer"
              onClick={goBack}
            >
              <FaArrowLeft size={15} color="black" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
