import React, { Fragment, useEffect } from 'react';
import 'react-phone-number-input/style.css';
import Input from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'libphonenumber-js';

/**
 * Renders a component for entering and selecting a company phone number. Simple version without country selection. US is set by default
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.phone - The current phone number value.
 * @param {function} props.onPhoneChange - A function to update the phone number value.
 * @param {function} props.setIsValid - A function to update the validity of the phone number.
 * @returns {JSX.Element} The rendered component.
 */
export const CompanyPhoneNumberNoCountry = ({
  phone,
  onPhoneChange,
  setIsValid,
}) => {
  const handlePhoneNumberChange = value => {
    onPhoneChange(value ?? '');
    setIsValid(value ? !isValidPhoneNumber(value) : true);
  };

  useEffect(() => {
    setIsValid(phone ? !isValidPhoneNumber(phone) : true);
  }, [phone, setIsValid]);

  return (
    <div className="mt-8 w-full text-center">
      <span className="text-center text-primary-3 text-[15px] font-normal">
        Your phone number helps us verify your identity and ensure the security
        of your account
      </span>
      <div className="mt-8 text-left">
        <div className="mb-1">
          <label className={'text-base font-normal text-primary-2 '}>
            Phone number
            <label className="text-[#a05555]"> *</label>
          </label>
        </div>
        <Input
          placeholder="Enter phone number"
          country="US"
          value={phone}
          onChange={handlePhoneNumberChange}
          className={`w-[100%]  ${
            phone.length > 0 ? 'border-primary-2' : ' border-primary-3'
          } border rounded-[15px] h-[52px] flex justify-center items-center px-4 focus-visible:outline-none focus-within:outline-none text-base text-primary-2 placeholder-primary-3`}
        />
      </div>
    </div>
  );
};
