import { Modal } from 'antd';
import { Button } from 'component-library';

/**
 * Renders a modal for deleting a mechanic.
 *
 * @component
 * @param {boolean} isOpen - Determines whether the modal is open or not.
 * @param {function} setIsOpen - Callback function to set the open state of the modal.
 * @param {function} handleDeleteMechanic - Callback function to handle the mechanic deletion.
 * @returns {JSX.Element} The delete mechanic modal component.
 */
export const DeleteMechanicModal = ({
  isOpen,
  setIsOpen,
  handleDeleteMechanic,
}) => {
  return (
    <Modal
      title=""
      open={isOpen}
      footer={false}
      zIndex={1002}
      onCancel={() => setIsOpen(false)}
      className="font-poppins "
      centered
      transitionName=""
      destroyOnClose
    >
      <div className="flex flex-col w-full items-center justify-center gap-y-6 transition-all py-4">
        <div className="flex flex-col w-full gap-y-3 items-center">
          <span className="text-[26px] text-error-2 font-semibold text-center">
            Remove Technician?
          </span>
          <span className="text-base font-normal text-center text-grey-2">
            Are you sure you want to remove this mechanic? You cannot undo this
            action.
          </span>
        </div>
        <div className="flex w-full gap-4">
          <Button
            title="Cancel"
            onClick={() => setIsOpen(false)}
            variant="grey"
          />
          <Button
            title="Remove"
            onClick={handleDeleteMechanic}
            variant="danger"
          />
        </div>
      </div>
    </Modal>
  );
};
