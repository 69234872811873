import api from 'api';
import { toast } from 'react-toastify';

const serviceProvidersRoutes = {
  cancelRequestLite: 'service-providers/cancel-service-request-lite',
  pictureCategoriesSettings: 'service-providers/picture-categories-settings',
  removePictureCategories: 'service-providers/remove-picture-categories',
  selectPictureCategories: 'service-providers/select-picture-categories',
  downloadAllPictures: 'service-providers/pictures',
};

export class ServiceProvidersService {
  /**
   *
   * @param {{serviceRequestId: number, reason: string}} data
   */
  static async cancelRequestLite(data) {
    try {
      const { data: responseData } = await api.post(
        serviceProvidersRoutes.cancelRequestLite,
        data
      );

      return responseData;
    } catch (error) {
      console.log('ServiceProvidersService.cancelRequestLite', { error });
      toast.error(error.response.data.message);
      return null;
    }
  }

  static async removeMechanicByPhoneNumber(phoneNumber) {
    try {
      const { data: responseData } = await api.delete(
        `service-providers/remove-mechanic-by-phone?phoneNumber=${phoneNumber}`
      );

      return responseData;
    } catch (error) {
      console.log('ServiceProvidersService.removeMechanicByPhoneNumber', {
        error,
      });
      toast.error(error.response.data.message);
      return null;
    }
  }

  static async pictureCategoriesSettings() {
    try {
      const { data } = await api.get(
        `${serviceProvidersRoutes.pictureCategoriesSettings}`
      );

      return data;
    } catch (error) {
      console.log('ServiceProvidersService.pictureCategoriesSettings', {
        error,
      });
      throw new Error(error?.response?.data?.message || 'Request failed');
    }
  }

  /**
   *
   * @param {{pictureCategoriesSettingIds: number[]}} data
   */
  static async removePictureCategories(data) {
    try {
      const { data: responseData } = await api.post(
        serviceProvidersRoutes.removePictureCategories,
        data
      );

      return responseData;
    } catch (error) {
      console.log('ServiceProvidersService.removePictureCategories', { error });
      toast.error(error.response.data.message);
      return null;
    }
  }

  /**
   *
   * @param {{pictureCategoriesSettingIds: number[]}} data
   */
  static async selectPictureCategories(data) {
    try {
      const { data: responseData } = await api.post(
        serviceProvidersRoutes.selectPictureCategories,
        data
      );

      return responseData;
    } catch (error) {
      console.log('ProfileService.selectPictureCategories', { error });
      toast.error(error.response.data.message);
      return null;
    }
  }

  /**
   *
   * @param {{pictureCategoriesSettingIds: number[]}} data
   */
  static async downloadAllPictures(serviceRequestId) {
    try {
      const { data: responseData } = await api.get(
        `${serviceProvidersRoutes.downloadAllPictures}/{serviceRequestId}?serviceRequestId=${serviceRequestId}`
      );

      return responseData;
    } catch (error) {
      console.log('ProfileService.downloadAllPictures', { error });
      toast.error(error.response?.data.message);
      return null;
    }
  }
}
