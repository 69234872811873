const initialState = {
  requestData: [],
  id: null,
  request: null,
  availableRequestData: null,
};

const requestDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FILTERED_DATA':
      return {
        ...state,
        requestData: action.payload,
      };
    case 'SET_REQUEST':
      return {
        ...state,
        request: action.payload,
      };
    case 'SET_AVAILABLE_REQUEST_DATA':
      return {
        ...state,
        availableRequestData: action.payload,
      };
    default:
      return state;
  }
};

export default requestDataReducer;
