import React, { useEffect, useRef, useState } from 'react';
import Call from '../../assets/Call.jpg';
import { IoMdClose } from 'react-icons/io';
import { AllowNotification } from './AllowNotification';
import { TurnOn } from './TurnOn';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'redux/auth/selectors';
import { getProfile } from 'redux/auth/operations';
import { VerificationModal } from './VerificationModal';

export const NotificationModal = ({ isModalOpen, setIsModalOpen }) => {
  const [isAllowNotPage, setIsAllowNotPage] = useState(true);
  const [isPushNotAllow, setIsPushNotAllow] = useState(false);
  const [isSmsNotAllow, setIsSmsNotAllow] = useState(false);

  const initialized = useRef(false);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!initialized.current && !user?.id) {
      dispatch(getProfile());
      initialized.current = true;
    }
  }, [dispatch, initialized, user]);

  console.log('User loaded', user);

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(false);
    }
  };

  if (!isModalOpen) return;

  const userIsVerified = user && (user.isEmailVerified || user.isPhoneVerified);

  return (
    <div
      className="fixed inset-0 z-[100000000] flex items-center justify-center bg-black bg-opacity-40"
      onClick={handleOverlayClick}
    >
      <div className="bg-white rounded-[32px] relative p-4 sm:p-16 flex-col flex items-center justify-center">
        {!userIsVerified && (
          <>
            <img
              alt="call"
              src="https://mymechanicimages.s3.amazonaws.com/web-assets/service-provider/verification-modal-icon.svg"
            />
            <VerificationModal handleClick={() => {}} />
          </>
        )}

        {userIsVerified && isAllowNotPage && (
          <>
            <img alt="call" src={Call} />
            <AllowNotification handleClick={() => setIsAllowNotPage(false)} />
          </>
        )}
        {userIsVerified && !isAllowNotPage && (
          <>
            <img alt="call" src={Call} />
            <TurnOn
              isPushNotAllow={isPushNotAllow}
              isSmsNotAllow={isSmsNotAllow}
              setIsPushNotAllow={setIsPushNotAllow}
              setIsSmsNotAllow={setIsSmsNotAllow}
            />
          </>
        )}
        <div
          className="h-8 w-8 bg-white  shadow-md  flex justify-center items-center rounded-full absolute right-4 top-4 cursor-pointer"
          onClick={() => setIsModalOpen(false)}
        >
          <IoMdClose size={20} color="#1D1D1D" />
        </div>
      </div>
    </div>
  );
};
