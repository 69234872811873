import { ACCESS_TOKEN_STORAGE_KEY } from 'constants/auth.constants';
import { useAuth } from 'context/SocketContext';
import { useCallback } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { disposeSound } from 'redux/audio/audio.slice';
import { selectUser } from 'redux/auth/selectors';
import {
  availableRequests,
  myServiceRequests,
} from 'redux/mechanics/operations';
import {
  setAvailableRequestData,
  setFilteredData,
} from 'redux/requestData/actions';

const useSocket = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const initialized = useRef(false);
  const { socket } = useAuth();

  const setMyServiceData = useCallback(
    data => {
      const requestId = JSON.parse(window.localStorage.getItem('requestId'));

      const isNewId = requestId !== (data?.length > 0 ? data[0].id : null);
      const isStatusNew =
        data &&
        data.length > 0 &&
        (data[0]?.status === 'RECEIVED' || data[0]?.status === 'SUBMITTED');

      if (isNewId && isStatusNew) {
        toast('You have a new request');
        dispatch(disposeSound());
      }
      window.localStorage.setItem(
        'requestId',
        data?.length > 0 ? data[0].id : JSON.stringify(null)
      );
      dispatch(setFilteredData(data));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!initialized.current && user.role) {
      const getRequestStatusFirstRun = async () => {
        const result = await dispatch(myServiceRequests());
        const allData = result?.payload?.data?.serviceRequestsPaginated.data;
        setMyServiceData(allData);
        if (
          user.role === 'REPAIR_SHOP' ||
          user.role === 'REPAIR_SHOP_MANAGER'
        ) {
          const resultAvailable = await dispatch(availableRequests());
          const data = resultAvailable?.payload?.data?.serviceRequests.data;
          dispatch(setAvailableRequestData(data));
        }
      };
      getRequestStatusFirstRun();
      initialized.current = true;
    }
  }, [initialized, user.role, dispatch, setMyServiceData]);
  useEffect(() => {
    if (socket) {
      socket.connect();

      socket.on('connect', () => {
        console.log('Socket.IO connection');
      });
      socket.on('connect_error', () => {
        console.log('Socket.IO connection error');
      });

      socket.on('disconnect', reason => {
        console.log('Socket.IO connection closed', reason);
        if (reason === 'io server disconnect') {
          // the disconnection was initiated by the server, need to manually reconnect
          socket.connect();
        }
      });
      socket.on('myServiceRequestsUpdates', result => {
        const data = result.data?.serviceRequestsPaginated?.data;
        setMyServiceData(data);
      });
      socket.on('availableServiceRequests', result => {
        const data = result.data.serviceRequests.data;
        dispatch(setAvailableRequestData(data));
      });
    }
  }, [socket]);
};

export default useSocket;
