import { setKey, fromLatLng, fromAddress } from 'react-geocode';

export const reverseGeocode = async ({ apiKey, location }) => {
  setKey(apiKey);

  try {
    const results = await fromLatLng(location.latitude, location.longitude);

    const reverseGeocodeLocation = results?.results[0]?.formatted_address;

    return reverseGeocodeLocation;
  } catch (err) {
    console.log('Error reverse geocoding mechanic location.', { err });

    return null;
  }
};

export const getAddressFromLocation = async ({ locationLat, locationLong }) => {
  let address = '';
  if (locationLat && locationLong) {
    address = await reverseGeocode({
      apiKey: 'AIzaSyAz-HYSMd9mpLWXkkK3utabiSpWmaOt7_M',
      location: {
        latitude: locationLat,
        longitude: locationLong,
      },
    });
  }
  return address;
};

export const getCoordinatesFromAddress = async address => {
  setKey('AIzaSyAz-HYSMd9mpLWXkkK3utabiSpWmaOt7_M');
  return await fromAddress(address);
};

export const getDistance = (lat1, lon1, lat2, lon2) => {
  const R = 3958.8;
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  const roundedNumber = round(d);
  return roundedNumber;
};
const deg2rad = deg => {
  return deg * (Math.PI / 180);
};
const round = num => {
  var m = Number((Math.abs(num) * 100).toPrecision(2));
  const result = (Math.round(m) / 100) * Math.sign(num);
  return result;
};
