import React from 'react';
import { Rate } from 'antd';
import { FaShop } from 'react-icons/fa6';

export const ShopRequestDetails = ({ data, index }) => {
  return (
    <div className="flex items-start justify-between gap-x-2 w-full">
      <div className="w-[60%] flex flex-col">
        <span className="font-bold text-sm">
          {index + 1}. {data.mechanic.name}
        </span>
        <div className="flex items-center mt-2.5">
          <Rate
            disabled
            defaultValue={
              data?.mechanic.ratingsReceived?.reduce((prev, curr) => {
                return prev + curr.score || 0;
              }, 0) / (data?.mechanic.ratingsReceived.length || 1) || 0
            }
            allowHalf
            style={{ color: '#2D6DCA' }}
            className="text-lg"
          />
          <span className="text-semibold text-sm text-secondary-1 pl-1">
            ({data?.mechanic.jobCount})
          </span>
        </div>
        <span className="font-normal text-xs text-secondary-1 w-[60%] mb-1.5 mt-3">
          {data.mechanic.address}
        </span>
        <a href={'tel:' + data?.contactPhoneNo}>
          <span className="font-normal text-xs">
            {data?.mechanic.contactPhoneNo}
          </span>
        </a>
      </div>

      <div className="w-[40%]">
        {data.mechanic.images[0] ? (
          <img
            src={data.mechanic.images[0].url}
            alt={data.mechanic.name}
            className="w-full h-[130px] object-cover rounded-[10%]"
          />
        ) : (
          <div className="w-full h-[130px] rounded-[10%] object-cover flex justify-center items-center bg-accent-2">
            <FaShop className="h-[60px] w-[60px] fill-accent-1" />
          </div>
        )}
        <div className="w-full flex justify-between mt-2">
          <span className="text-semibold text-sm">
            {' '}
            {data.mechanic.rate ? `$${data.mechanic.rate}` : 'not specified'}
          </span>
          <span className="text-accent-1 text-sm">
            {data.distance.toFixed(2)}mi
          </span>
        </div>
      </div>
    </div>
  );
};
