import * as Yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { Field, Form, Formik } from 'formik';
import { IoMdInformationCircle } from 'react-icons/io';
import Input from 'react-phone-number-input/input';


/**
 * ModalAddRequest component for creating a request.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.handleSendSMS - The function to handle adding a request.
 * @returns {JSX.Element} The ModalAddRequest component.
 */
export const ModalSendRequestSMS = ({ handleSendSMS, request }) => {
  const initialFields = {
    nameOfInfoProvider: {
      name: 'nameOfInfoProvider',
      placeholder: 'Enter Info Provider Name',
      type: 'text',
      label: 'Info Provider Name',
    },
    phoneNumber: {
      name: 'phoneNumber',
      placeholder: 'Enter phone number',
      type: 'text',
      label: 'Phone Number',
    },
  };

  const initialValues = {
    [initialFields.nameOfInfoProvider.name]:
      `${request.requestOwner.firstName} ${request.requestOwner.lastName}`,
    [initialFields.phoneNumber.name]: request.requestOwner.phoneNo,
  };

  const validationSchema = Yup.object().shape({
    [initialFields.nameOfInfoProvider.name]: Yup.string()
      .trim()
      .min(2, `Minimum length for info provider's name is 2 characters`)
      .max(30, `Maximum length for info provider's name is 30 characters`)
      .required('Info provider name is required'),
    [initialFields.phoneNumber.name]: Yup.string()
      .required('Phone number is required')
      .test('is-valid-phone', 'Phone number is invalid', value => {
        return isValidPhoneNumber(value || '');
      }),
  });

  //Custom phone input has to be passed as a component to Field to enable proper re-rendering after form submission
  const PhoneInput = ({ field, form, ...props }) => {
    return (
      <Input
        name={field.name}
        placeholder={'Enter phone number'}
        country="US"
        value={field.value}
        onChange={value => form.setFieldValue(field.name, value)}
        className={`w-full justify-center focus-visible:outline-none focus-within:outline-none ${
          form.touched[field.name] &&
          form.errors[field.name] &&
          'bg-[#FEEFEF] border-[#DA1414]'
        } `}
      />
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        await handleSendSMS(values, request);
        resetForm();
      }}
    >
      {({ isValid, errors, touched, handleChange, handleSubmit }) => (
        <Form className="w-full flex flex-col" onSubmit={handleSubmit}>
          <h2 className="text-lg font-semibold text-center mt-[30px]">
            Request Info for Request
          </h2>
          <div className="w-full  pt-6">
            {Object.keys(initialFields).map((field, index) => {
              const fieldData = initialFields[field];
              return (
                <div className="flex flex-col gap-y-1" key={index}>
                  <label
                    className={'text-base font-normal text-primary-2  my-1'}
                  >
                    {fieldData.label}
                    <label className="text-[#DA1414]"> *</label>
                  </label>
                  <div className="flex flex-col gap-y-[2px]">
                    <div
                      className={`w-full py-[13px] px-[15px] text-[0.875rem] leading-[1.38em] rounded-xl  flex flex-row ${
                        errors[fieldData.name] && touched[fieldData.name]
                          ? 'bg-[#FEEFEF] border-[#DA1414] border-[1px]'
                          : 'border-primary-3 border'
                      }`}
                    >
                      {fieldData.name === 'phoneNumber' ? (
                        <Field name={fieldData.name} component={PhoneInput} />
                      ) : (
                        <Field
                          name={fieldData.name}
                          className={`w-full justify-center focus-visible:outline-none focus-within:outline-none ${
                            touched[fieldData.name] &&
                            errors[fieldData.name] &&
                            'bg-[#FEEFEF] border-[#DA1414]'
                          } `}
                          placeholder={fieldData.placeholder}
                          onChange={e => {
                            handleChange(e);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {errors[fieldData.name] && (
                    <div
                      className={`bg-[#FEEFEF] py-2 px-4 flex flex-row items-center rounded-[8px]`}
                    >
                      <IoMdInformationCircle color="#DA1414" />
                      <span className="text-[13px] text-[#DA1414] font-normal ml-1">
                        {errors[fieldData.name]}
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <button
            type="submit"
            disabled={!isValid}
            className={`flex rounded-2xl text-center py-[14px] w-full text-[0.875rem] leading-[1.38em] bg-accent-1 text-white  border border-transparent  transition-all duration-200 justify-center mt-8 ${
              !isValid && 'bg-accent-3'
            }`}
          >
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
};
