import React, { useCallback, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { FaTruck } from 'react-icons/fa6';
import useWindowSize from 'hooks/useWindowSize';
import { LogoLoader } from 'components/common';
import { IoIosSearch } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { isArray } from 'lodash';
import { getVehicles } from 'redux/profile/operations';
import { selectLoadingProfile, selectTrucks } from 'redux/profile/selectors';
import { useSearchParams } from 'react-router-dom';
import { dashboardKeys } from 'constants/dashboard/tabs';
import { setViewDriverLocation } from 'redux/fleet/fleet.slice';
import { listDrivers } from 'redux/fleet/operations';
import UserSold from '../../../../assets/user-add-solid.svg';
import { SortButton } from './SortButton';
import { SingleTruck } from './SingleTruck';
import { InviteAllModal } from './InviteAllModal';

const MyTrucksDrawer = ({ offset }) => {
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();
  const { isMobile, isTablet, isMediumTablet, isSmallTablet } = useWindowSize();
  const initialized = useRef(false);
  const fleetState = useSelector(state => state.fleet);
  const trucks = useSelector(selectTrucks);
  const loading = useSelector(selectLoadingProfile);
  const [originalVehicles, setOriginalVehicles] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedOption, setSelectedOption] = useState('all');
  const [isModalOpenInviteAll, setIsModalOpenInviteAll] = useState(false);
  useEffect(() => {
    if (!loading && trucks.length > 0) {
      const parsedVehicles = [];
      if (isArray(trucks)) {
        trucks.forEach(vehicle => {
          const driver = isArray(fleetState.drivers)
            ? fleetState.drivers.find(
                driver =>
                  isArray(driver.vehicles) &&
                  driver.vehicles.some(iVehicle => vehicle.id === iVehicle.id)
              )
            : {};

          parsedVehicles.push({ ...vehicle, driver });
        });
      }
      setOriginalVehicles(parsedVehicles);
      setVehicles(parsedVehicles);
    }
  }, [fleetState.drivers, loading, trucks, setVehicles, setOriginalVehicles]);

  const loadTrucks = useCallback(() => {
    dispatch(getVehicles());
    dispatch(listDrivers());
  }, [dispatch]);

  useEffect(() => {
    if (!initialized.current) {
      loadTrucks();
      initialized.current = true;
    }
  }, [initialized, loadTrucks]);

  const handleSort = selectedOption => {
    const allData = originalVehicles;
    let filteredData;
    setSelectedOption(selectedOption);

    switch (selectedOption) {
      case 'all':
        setVehicles(allData);
        break;
      case 'active now':
        filteredData = allData.filter(item => item?.driver?.isActive === true);
        setVehicles(filteredData);
        break;
      case 'inactive now':
        filteredData = allData.filter(
          item => item?.driver?.isActive === false || item?.driver === undefined
        );
        setVehicles(filteredData);
        break;
      default:
        setVehicles(allData);
        break;
    }
  };

  const handleInputChange = event => {
    const searchText = event.target.value.toLowerCase();
    if (!searchText) {
      setVehicles(originalVehicles);
      handleSort('all');
      return;
    }

    let filteredData = [...originalVehicles];

    if (selectedOption !== 'all') {
      setSelectedOption('all');
    }

    filteredData = filteredData.filter(
      item =>
        item.driver?.firstName?.toLowerCase().includes(searchText) ||
        item.driver?.lastName?.toLowerCase().includes(searchText) ||
        item.vin?.toLowerCase().includes(searchText)
    );

    setVehicles(filteredData);
  };

  return (
    <motion.div
      initial={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : -1000,
      }}
      animate={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : offset,
      }}
      exit={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : -1000,
      }}
      transition={{ bounce: 0 }}
      className="h-screen overflow-y-scroll scrollbar-thin scrollbar-track-slate-100 px-4 pt-11  scrollbar-thumb-slate-300 min-h-screen absolute w-full sm:w-[60vw] lg:w-[45vw] flex flex-col  rounded-e-2xl bg-[#fefefe] left-0 top-0 z-[1000]"
    >
      {loading ? (
        <LogoLoader />
      ) : (
        <div className="flex flex-col">
          <InviteAllModal
            isModalOpen={isModalOpenInviteAll}
            setIsModalOpen={setIsModalOpenInviteAll}
          />
          <div className="rounded-[10px] p-[13px] mb-4  flex items-center h-12 bg-gray-100">
            <IoIosSearch className="text-[25px] text-primary-3" />
            <input
              type="text"
              placeholder="Search your tracks"
              className="pl-[11px] text-primary-2 bg-[#F1F1F1] focus:outline-none placeholder:text-secondary-1 w-[100%]"
              onChange={handleInputChange}
            />
          </div>
          <SortButton
            onSort={handleSort}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
          <div>
            <button
              onClick={() => {
                setIsModalOpenInviteAll(true);
              }}
              type="button"
              className="px-9 py-[5px] inline-flex justify-center items-center rounded-[20px] bg-accent-1  mt-4"
            >
              <img
                src={UserSold}
                className="w-5 h-5 mr-[6px]"
                alt="user_solid"
              />
              <span className="text-sm font-normal text-white">Invite all</span>
            </button>
          </div>
          {vehicles.length > 0 ? (
            vehicles.map((vehicle, index) => (
              <SingleTruck
                invite={() => {}}
                isInvite={false}
                index={index}
                vehicle={vehicle}
                key={index}
                setOpen={(isOpen, vehicle) => {}}
                setEdit={(isOpen, vehicle) => {}}
                viewOnMap={driverInfo => {
                  dispatch(
                    setViewDriverLocation({
                      lat: driverInfo?.locationLat,
                      lng: driverInfo?.locationLong,
                    })
                  );
                  setSearchParams({ tab: dashboardKeys.home });
                }}
              />
            ))
          ) : (
            <div className="flex flex-col text-center gap-x-4 items-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] opacity-70">
              <FaTruck className="text-primary-3 text-9xl" />
              <span className="text-2xl text-primary-3">No trucks.</span>
            </div>
          )}
        </div>
      )}
    </motion.div>
  );
};
export default MyTrucksDrawer;
