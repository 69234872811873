import styled from '@emotion/styled';

export const Box = styled.main`
  padding: 0 50px;
  min-height: calc(100vh - 107px);
  font-family: 'Roboto', sans-serif;
  font-size: 16px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const Message = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  font-family: 'Marko One', sans-serif;
  text-align: center;
  /* color: ${p => `${p.theme.colors.textSecondary}`}; */
  font-size: 40px;
`;