import React, { Fragment } from 'react';
import { ErrorMessage, Field } from 'formik';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useFormikContext } from 'formik';

/**
 * A custom select input component for Formik forms.
 *
 * @component
 * @param {string} name - The name of the input field.
 * @param {Array} options - The options for the select input.
 * @returns {JSX.Element} The custom select input component.
 */
const FormikSelectInput = ({ name, options, isMulti }) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext();
  const animatedComponents = makeAnimated();

  const customSelectStyle = {
    control: baseStyles => ({
      ...baseStyles,
      borderRadius: '15px',
      padding: '7px',
      borderColor: '#A6A9AD',
    }),

    multiValue: baseStyles => ({
      ...baseStyles,
      borderRadius: '8px',
      backgroundColor: '#F1F3F8',
    }),
    singleValue: baseStyles => ({
      ...baseStyles,
      fontSize: '16px',
    }),
    multiValueRemove: baseStyles => ({
      ...baseStyles,
      color: '#B8B8B8',
    }),
    placeholder: baseStyles => ({
      ...baseStyles,
      color: '#A6A9AD',
      fontSize: '16px',
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      padding: '10px 8px',
    }),
    option: (baseStyles, { isSelected }) =>
      isMulti
        ? {
            ...baseStyles,
            width: 'fit-content',
            borderRadius: '8px',
            backgroundColor: '#F1F3F8',
            transition: 'all 0.3s',
            ':hover': {
              ...baseStyles[':hover'],
              backgroundColor: '#DFECFB',
              cursor: 'pointer',
              transition: 'all 0.3s',
            },
          }
        : {
            ...baseStyles,
            backgroundColor: isSelected ? '#2D6DCA' : '#fff',
            ':hover': {
              ...baseStyles[':hover'],
              backgroundColor: isSelected ? '#2D6DCA' : '#DFECFB',
              cursor: 'pointer',
              transition: 'all 0.3s',
            },
          },
  };

  const handleChange = selectedOption => {
    setFieldTouched(name, true);
    setFieldValue(name, selectedOption);
  };

  return (
    <Fragment>
      <Select
        components={animatedComponents}
        name={name}
        value={values[name]}
        onChange={handleChange}
        isMulti={isMulti}
        styles={customSelectStyle}
        placeholder={'Select'}
        options={options}
      />
      <ErrorMessage
        name={name}
        className="text-error-1 text-[10px]"
        component="span"
      />
    </Fragment>
  );
};

export default FormikSelectInput;
