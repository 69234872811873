import { Fragment, useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa6';
import { AiFillDollarCircle } from 'react-icons/ai';
import { mapRepairType } from 'helpers/common.helpers';
import { Button } from 'component-library';
import { IoMdMore } from 'react-icons/io';
import { SlSettings } from 'react-icons/sl';
import { IoPersonRemoveOutline } from 'react-icons/io5';
import { CompanyService } from 'services/company.service';
import { InviteMechanicModal } from './InviteMechanicModal';
import { MechanicInformationModal } from './MechanicInformationModal';
import { DeleteMechanicModal } from './DeleteMechanicModal';
import { ServiceProvidersService } from 'services/service-providers';
import { sendInviteToMechanic } from 'redux/mechanics/operations';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
/**
 * Renders a card component for a mechanic.
 *
 * @component
 * @param {Object} mechanic - The mechanic object containing information about the mechanic.
 * @returns {JSX.Element} The rendered MechanicCard component.
 */
const MechanicCard = ({ mechanic }) => {
  const dispatch = useDispatch();
  const ratings = mechanic.ratingsReceived;
  const [rating, setRating] = useState(0);
  const [statusText, setStatusText] = useState('');
  const [isMoreModalOpen, setIsMoreModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isMechanicInformationModalOpen, setIsMechanicInformationModalOpen] =
    useState(false);
  const [isDeleteMechanicModalOpen, setIsDeleteMechanicModalOpen] =
    useState(false);

  const markerColors = {
    AVAILABLE: '#53CD58',
    BUSY: '#DA0000',
    OFFLINE: '#888A8C',
  };

  const modalBackdropStyles = {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
  };

  const modalStyles = {
    position: 'absolute',
    right: '24px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0px 2px 16px 0px #00000026',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '8px',
  };

  const handleMoreModal = () => {
    setIsMoreModalOpen(!isMoreModalOpen);
  };

  const handleInviteModal = () => {
    setIsInviteModalOpen(true);
  };

  const handleMechanicInformationModal = () => {
    setIsMechanicInformationModalOpen(true);
    setIsMoreModalOpen(false);
  };

  const handleDeleteMechanicModal = () => {
    setIsDeleteMechanicModalOpen(true);
    setIsMoreModalOpen(false);
  };

  const handleSendInvite = async () => {
    try {
      const response = await dispatch(
        sendInviteToMechanic(encodeURIComponent(mechanic.phoneNo))
      );
      if (response) {
        toast.success(response.payload.message);
        setIsInviteModalOpen(false);
      }
    } catch (error) {
      console.log('MyTechniciansTab.handleSendInvite', { error });
    }
  };

  const handleDeleteMechanic = async () => {
    try {
      const response =
        await ServiceProvidersService.removeMechanicByPhoneNumber(
          encodeURIComponent(mechanic.phoneNo)
        );
      if (response) {
        setIsDeleteMechanicModalOpen(false);
      }
    } catch (error) {
      console.log('MyTechniciansTab.handleDeleteMechanic', { error });
    }
  };

  useEffect(() => {
    if (ratings && ratings.length > 0) {
      const scores = ratings.map(rating => rating?.score || 0);
      const sum = scores.reduce((sum, score) => sum + score, 0);
      const averageScore = (sum / scores.length).toFixed(1);

      setRating(averageScore);
    }

    const createStatusText = () => {
      if (!mechanic.isPhoneVerified) {
        setStatusText('Pending phone verification');
      }

      if (mechanic.status === 'BUSY') {
        switch (mechanic.mostRecentServiceRequest?.status) {
          case 'MECHANIC_ON_THE_WAY':
            setStatusText(
              mapRepairType(mechanic.mostRecentServiceRequest.type)
            );
            break;
          case 'ACCEPTED':
            setStatusText('Accepted a service request');
            break;
          default:
            setStatusText('');
            break;
        }
      }

      if (mechanic.status === 'AVAILABLE' && mechanic.isPhoneVerified) {
        setStatusText('Free');
      }
    };

    createStatusText();
  }, [ratings]);

  return (
    <Fragment>
      <div className="w-full flex">
        {mechanic?.profileImage?.url ? (
          <img
            src={mechanic.profileImage?.url}
            alt="profile"
            className="flex-none mr-[10px] w-[60px] h-[60px] rounded-[10px] bg-accent-2 object-cover"
          />
        ) : (
          <div className="flex flex-none justify-center items-center rounded-[10px] bg-accent-2 w-[60px] h-[60px] mr-[10px] ">
            <FaUser className="fill-accent-1" size={40} />
          </div>
        )}
        <div className="flex w-full items-flex-start justify-between flex-col xl:flex-row">
          <div className="flex flex-col justify-center">
            <div className="flex gap-x-2 items-center ">
              <p className="text-base font-medium">
                {mechanic.firstName}&nbsp;{mechanic.lastName}
              </p>
              <div
                style={{ backgroundColor: markerColors[mechanic.status] }}
                className="h-3 w-3 rounded-full"
              ></div>
            </div>
            <div className=" flex space-x-2 items-center">
              {mechanic?.rate && (
                <Fragment>
                  <div className="flex items-center gap-x-2 mb-[5px]">
                    <FaStar className="text-base text-accent-1" />
                    <span className="text-sm">
                      {rating}
                      <span className=" text-primary-3 text-xs font-normal">
                        ({ratings?.length} reviews)
                      </span>
                    </span>
                  </div>
                  <div className="flex items-center gap-x-2 mb-[5px]">
                    <AiFillDollarCircle className="text-base text-accent-1" />
                    <span className=" text-sm">
                      {mechanic.rate}$
                      <span className=" text-primary-3 text-xs font-normal ml-1">
                        /1h
                      </span>
                    </span>
                  </div>
                </Fragment>
              )}
            </div>
            <span
              className={`text-sm ${
                mechanic.status === 'BUSY' ? 'text-accent-1' : 'text-primary-3'
              }`}
            >
              {statusText}
            </span>
          </div>
          <div className="flex items-center gap-3">
            {!mechanic.isPhoneVerified && (
              <Button
                title="Invite to myMechanic"
                variant="transparent-text"
                size="default"
                onClick={handleInviteModal}
              />
            )}
            <button onClick={handleMoreModal}>
              <IoMdMore className="text-xl" />
            </button>
            {isMoreModalOpen && (
              <Fragment>
                <div
                  style={modalBackdropStyles}
                  onClick={() => setIsMoreModalOpen(false)}
                ></div>
                <div style={modalStyles}>
                  <button
                    className="px-2 py-2 w-full"
                    onClick={handleMechanicInformationModal}
                  >
                    <div className="flex">
                      <SlSettings className="mt-1 mr-2" />
                      <span>View Profile</span>
                    </div>
                  </button>
                  <button
                    className="px-2 py-2 w-full text-error-2"
                    onClick={handleDeleteMechanicModal}
                  >
                    <div className="flex">
                      <IoPersonRemoveOutline className="mt-0.5 mr-2" />
                      <span>Remove Technician</span>
                    </div>
                  </button>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
      <div className="mt-5 mb-5 h-[1px] bg-grey-1"></div>
      <DeleteMechanicModal
        isOpen={isDeleteMechanicModalOpen}
        setIsOpen={setIsDeleteMechanicModalOpen}
        handleDeleteMechanic={handleDeleteMechanic}
      />
      <InviteMechanicModal
        isOpen={isInviteModalOpen}
        setIsOpen={setIsInviteModalOpen}
        handleSendInvite={handleSendInvite}
      />
      {isMechanicInformationModalOpen && (
        <MechanicInformationModal
          isOpen={isMechanicInformationModalOpen}
          setIsOpen={setIsMechanicInformationModalOpen}
          mechanic={mechanic}
          rating={rating}
        />
      )}
    </Fragment>
  );
};

export default MechanicCard;
