import { FaStar } from 'react-icons/fa';
import { PiCurrencyCircleDollarFill } from 'react-icons/pi';
import { selectBreakdowns } from 'redux/reference/selectors';
import { useSelector } from 'react-redux';
import { useState, Fragment } from 'react';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';
import { FaUser } from 'react-icons/fa';

/**
 * Component for displaying a mechanic preview upon fetch.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.mechanic - The mechanic object.
 * @returns {JSX.Element} The mechanic preview component.
 */
export const MechanicPreview = ({ mechanic }) => {
  const serviceCategories = useSelector(selectBreakdowns);
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  if (!mechanic) return null;

  return (
    <div className="w-full flex flex-col  bg-[#F1F3F8] rounded-lg p-4 gap-y-2">
      <div className="flex">
        <div className="w-full flex items-center ">
          {mechanic.profileImage ? (
            <img
              src={mechanic.profileImage.url}
              alt="profile"
              className="w-14 h-14 rounded-md mr-2"
            ></img>
          ) : (
            <FaUser
              className="fill-accent-1 rounded-md bg-white p-2 mr-2"
              size={56}
            />
          )}

          <div className="flex flex-col gap-y-1 flex-[1_0_0]">
            <span className="w-full text-[16px] font-medium ">
              {mechanic.mechanic.firstName} {mechanic.mechanic.lastName}
            </span>
            <div className="flex gap-x-2 self-stretch">
              <div className=" flex gap-x-2 items-center">
                <FaStar className="text-accent-1 mb-0.5" />
                <span>{mechanic.mechanic.averageRating}</span>
                <span className="text-primary-3 text-xs">
                  ({mechanic.mechanic.ratingsReceived.length} reviews)
                </span>
              </div>
              <div className=" flex gap-x-2 items-center">
                <PiCurrencyCircleDollarFill className="text-accent-1 " />
                <span>
                  ${mechanic.mechanic.rate}
                  <span className="text-primary-3 text-xs"> /h</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex justify-between p-4 cursor-pointer"
          onClick={toggleAccordion}
        >
          {isOpen ? <SlArrowUp /> : <SlArrowDown />}
        </div>
      </div>

      {isOpen && (
        <Fragment>
          <div className="flex flex-col">
            <span className="font-medium text-primary-3 ">About :</span>
            <span>{mechanic.mechanic.about}</span>
          </div>
          <div className="flex flex-col">
            <span className="font-medium text-primary-3 ">Capabilities: </span>
            <div className="flex flex-wrap self-stretch items-start content-start gap-2">
              {serviceCategories &&
                mechanic.mechanic.services.map(service => (
                  <div
                    className="py-1.5 px-4 border-[1px] border-accent-1 rounded-[30px] bg-accent-2"
                    key={service.serviceCategoryId}
                  >
                    <span className="text-xs text-accent-1 font-medium">
                      {
                        serviceCategories.find(
                          category => category.id === service.serviceCategoryId
                        ).title
                      }
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};
