import React, { useState } from 'react';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';

/**
 * Renders the accordion component for displaying request details.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.request - The request object containing details.
 * @param {Function} props.handleView - The function to handle view action.
 * @param {Function} props.handleDelete - The function to handle delete action.
 * @returns {JSX.Element} The rendered component.
 */
const RequestDetailsAccordion = ({ request, handleView, handleDelete }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const PaymentMethod = {
    CASH_CARD: 'Cash/card',
    LOCAL_HOUSE_ACCOUNT: 'Local/house account',
    NATIONAL_ACCOUNT: 'National account',
  };

  const formatPaymentMethod = key => {
    return PaymentMethod[key] || 'Unknown method';
  };

  const formatKeyToSentence = key => {
    const words = key.toLowerCase().split('_');
    const formattedWords = words.map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    });
    return formattedWords.join(' ');
  };

  return (
    <div>
      <div>
        <div
          className="flex justify-between items-center p-[12px] cursor-pointer bg-accent-4 rounded-[10px]"
          onClick={toggleAccordion}
        >
          <h2 className="font-[500] lg:text-[16px] text-[14px]">
            Request Details:
          </h2>
          {isOpen ? (
            <SlArrowUp className="fill-primary-2" />
          ) : (
            <SlArrowDown className="fill-primary-2" />
          )}
        </div>
        {isOpen && (
          <>
            <div className="mt-[-8px] mb-[11px] bg-accent-4 rounded-bl-[10px] rounded-br-[10px] p-[12px]">
              <div className="mb-[12px] flex justify-between gap-3">
                <div className="w-[50%]">
                  <p className="text-primary-3 text-[14px] mb-[6px]">Vin:</p>
                  <p className="text-[14px] rounded-[10px] bg-primary-1 pt-2 pr-4 pb-2 pl-4 break-words whitespace-normal">
                    {request.vin ?? 'Not specified'}
                  </p>
                </div>
                <div className="w-[50%]">
                  <p className="text-primary-3 text-[14px] mb-[6px]">
                    Unit number:
                  </p>
                  <p className="text-[14px] rounded-[10px] bg-primary-1 pt-2 pr-4 pb-2 pl-4 break-words whitespace-normal">
                    {request.unitNumber ?? 'Not specified'}
                  </p>
                </div>
              </div>

              <div className="mb-[12px]">
                <p className="text-primary-3 text-[14px] mb-[6px]">
                  Description:
                </p>
                <p className="text-[14px] w-[100%] rounded-[10px] bg-primary-1 pt-2 pr-4 pb-2 pl-4 break-words whitespace-normal">
                  {request.description ?? 'Not specified'}
                </p>
              </div>

              <div className="mb-[12px] flex justify-between gap-3">
                <div className="w-[50%]">
                  <p className="text-primary-3 text-[14px] mb-[6px]">
                    Type of vehicle:
                  </p>
                  <p className="text-[14px] rounded-[10px] bg-primary-1 pt-2 pr-4 pb-2 pl-4 break-words whitespace-normal">
                    {request.truckType
                      ? formatKeyToSentence(request.truckType)
                      : 'Not specified'}
                  </p>
                </div>
                <div className="w-[50%]">
                  <p className="text-primary-3 text-[14px] mb-[6px]">
                    Tire size:
                  </p>
                  <p className="text-[14px] rounded-[10px] bg-primary-1 pt-2 pr-4 pb-2 pl-4 break-words whitespace-normal">
                    {request.tireSize ?? 'Not specified'}
                  </p>
                </div>
              </div>

              <div className="mb-[12px] flex justify-between gap-3">
                <div className="w-[50%]">
                  <p className="text-primary-3 text-[14px] mb-[6px]">
                    Tire position:
                  </p>
                  <p className="text-[14px] rounded-[10px] bg-primary-1 pt-2 pr-4 pb-2 pl-4 break-words whitespace-normal">
                    {request.tirePosition
                      ? request.tirePosition
                      : 'Not specified'}
                  </p>
                </div>
                <div className="w-[50%]">
                  <p className="text-primary-3 text-[14px] mb-[6px]">
                    Payment method:
                  </p>
                  <p className="text-[14px] rounded-[10px] bg-primary-1 pt-2 pr-4 pb-2 pl-4 break-words whitespace-normal">
                    {request.paymentMethod
                      ? formatPaymentMethod(request.paymentMethod)
                      : 'Not specified'}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RequestDetailsAccordion;
