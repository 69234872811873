export const setFilteredData = data => ({
  type: 'SET_FILTERED_DATA',
  payload: data,
});
export const setRequest = data => ({
  type: 'SET_REQUEST',
  payload: data,
});
export const setAvailableRequestData = data => ({
  type: 'SET_AVAILABLE_REQUEST_DATA',
  payload: data,
});
