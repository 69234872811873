import * as Yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';

/**
 * Generates a Yup validation schema based on the current step.
 *
 * @param {number} currentStep - The current step in the sign-up process.
 * @returns {Yup.ObjectSchema} - The Yup validation schema for the current step.
 */
export const signUpSchema = currentStep => {
  let currentSchema = Yup.object();
  switch (currentStep) {
    case 2:
      return Yup.object().shape({
        companyName: Yup.string()
          .trim()
          .min(5, `Minimum length for company name is 5 characters`)
          .max(50, `Maximum length for company name is 50 characters`),
      });
    case 3:
      return Yup.object().shape({
        isCompanyOwner: Yup.boolean()
          .oneOf([true])
          .required('Please select an option'),
      });
    case 4:
      return Yup.object().shape({
        cityId: Yup.string().min(1),
        stateId: Yup.string().min(1),
        address: Yup.string()
          .trim()
          .min(5, `Minimum length for address is 5 characters`)
          .max(50, `Maximum length for address is 50 characters`),
        zipCode: Yup.string().trim().length(5, 'Please enter a valid zip code'),
      });
    case 5:
      return Yup.object().shape({
        phoneNo: Yup.string().test('', '', (val, ctx) =>
          isValidPhoneNumber(val)
        ),
      });
    case 6:
      return Yup.object().shape({
        name: Yup.string().trim().min(2, 'Please enter a valid name'),
        email: Yup.string()
          .trim()
          .matches(/\S+@\S+\.\S+/, 'Please enter a valid email'),
        password: Yup.string()
          .trim()
          .min(8, `Password must be at least 8 characters long`)
          .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).+$/, {
            message:
              'Password must contain a number, an uppercase letter and a special character',
          }),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'Password do not match'
        ),
      });
    default:
      return currentSchema;
  }
};

export const getInitialSignUpState = () => {
  return {
    // TODO: Will countryId ever change? No current option to change in UI
    countryId: 1,
    isServiceProvider: 1,
    isFleetManager: 0,
    companyName: '',
    name: '',
    isCompanyOwner: null,
    isSuperAdmin: false,
    phoneNo: '',
    file: null,
    cityId: '',
    stateId: '',
    address: '',
    zipCode: '',
    email: '',
    password: '',
    confirmPassword: '',
    serviceCategoryIDs: '',
  };
};

export const stepTitles = step => {
  switch (step) {
    case 1:
      return 'What category best describes your business?';
    case 2:
      return 'Create an Account \n for a Company';
    case 3:
      return 'Are you the owner of the company?';
    case 4:
      return 'Enter your company address';
    case 5:
      return 'Enter your company phone number';
    case 6:
      return 'Enter your sign in information';
    case 7:
      return `What are your company's main capabilities and services?`;
    default:
      return '';
  }
};
