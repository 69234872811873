import React, { useState } from 'react';
import { motion } from 'framer-motion';
import useWindowSize from 'hooks/useWindowSize';
import 'react-toggle/style.css';
import CompanyTab from './CompanyTab';
import PhotoCategory from './PhotoCategory';

/**
 * Renders the Settings component.
 *
 * @component
 * @param {Object} offset - The offset value for animation.
 * @returns {JSX.Element} The rendered Settings component.
 */

const Settings = ({ offset }) => {
  const { isMobile, isTablet, isMediumTablet, isSmallTablet } = useWindowSize();
  const [activeTab, setActiveTab] = useState('tabCompany');

  const handleTabClick = tab => {
    setActiveTab(tab);
  };

  return (
    <motion.div
      initial={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : -1000,
      }}
      animate={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : offset,
      }}
      exit={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : -1000,
      }}
      transition={{ bounce: 0 }}
      className="h-screen overflow-y-scroll scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-slate-300 sm:p-8 p-4 py-8 min-h-screen absolute w-full sm:w-[60vw] lg:w-[86vw] flex flex-col rounded-e-2xl bg-white left-0 top-0 z-[1000]"
    >
      <p className="mb-[30px] font-[500] lg:text-[40px] text-[32px]">
        Settings
      </p>
      <div className="mb-[30px] flex border-b-[1px] border-accent-4">
        <button
          onClick={() => handleTabClick('tabCompany')}
          className={`${
            activeTab === 'tabCompany'
              ? 'text-accent-1 border-b-[1px] border-accent-1'
              : 'text-secondary-1'
          } py-2 px-4 focus:outline-none`}
        >
          Company
        </button>
        <button
          onClick={() => handleTabClick('tabPhoto')}
          className={`${
            activeTab === 'tabPhoto'
              ? 'text-accent-1 border-b-[1px] border-accent-1'
              : 'text-secondary-1'
          } py-2 px-4 focus:outline-none`}
        >
          Photo categories
        </button>
      </div>

      <div>
        {activeTab === 'tabCompany' && <CompanyTab />}
        {activeTab === 'tabPhoto' && <PhotoCategory />}
      </div>
    </motion.div>
  );
};

export default Settings;
