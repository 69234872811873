import api from 'api';
import { toast } from 'react-toastify';

const homeRoutes = {
  submitSuggestion: 'general-remarks',
  searchVendors: 'vendors/search',
  getCompanies: 'companies/get-all',
  getUsers: 'companies/get-users',
  removeUser: 'companies/remove-user',
  selfRequestToggle: 'companies/allow-self-request-toggle',
  getDeals: 'deals/get-all',
  getCountries: 'reference/get-countries',
  getStates: 'reference/get-states',
};

export class HomeServices {
  // {
  //   "latitude": 38.8951,
  //   "longitude": -77.0364,
  //   "radius": 10,
  //   "perPage": "10",
  //   "page": "0"
  // }

  /**
   *
   * @param {{countryId: number, }} data
   * @returns
   */
  static async getStates(data) {
    try {
      const { data: responseData } = await api.post(homeRoutes.getStates, data);

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 HomeServices.getStates', { error });
      return null;
    }
  }
  /**
   * @returns
   */
  static async getCountries() {
    try {
      const { data: responseData } = await api.get(
        `${homeRoutes.getCountries}`
      );

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 HomeServices.getCountries', { error });
      return null;
    }
  }

  /**
   *
   * @param {{latitude: number, longitude: number, radius: string, page: string, perPage: string}} data
   */
  static async getRecommendedCompanies() {
    try {
      const { data } = await api.get(homeRoutes.getCompanies);
      return data.data;
    } catch (error) {
      console.log('🥲🥲 homeRoutes.getCompanies', { error });
      return null;
    }
  }

  /**
   *
   * @param {{latitude: number, longitude: number, radius: string, page: string, perPage: string, typeOfBreakdown: string, }} data
   * @returns
   */
  static async searchVendors(data) {
    try {
      const { data: responseData } = await api.post(
        homeRoutes.searchVendors,
        data
      );

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 HomeServices.searchVendors', { error });
      return null;
    }
  }
  /**
   *
   * @param {{latitude: number, longitude: number, radius: string, page: string, perPage: string, }} data
   * @returns
   */
  static async getDeals(data) {
    try {
      const { data: responseData } = await api.post(homeRoutes.getDeals, data);

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 HomeServices.getDeals', { error });
      return null;
    }
  }

  /**
   *
   * @param {{firstName: string, lastName: string, email: string, message: string, phone: string}} data
   * @returns
   */
  static async submitSuggestion(data) {
    try {
      const { data: responseData } = await api.post(
        homeRoutes.submitSuggestion,
        data
      );

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 HomeServices.submitSuggestion', { error });
      return null;
    }
  }

  /**
   * @returns
   */
  static async selfRequestToggle() {
    try {
      const { data: responseData } = await api.post(
        homeRoutes.selfRequestToggle
      );

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 HomeServices.selfRequestToggle', { error });
      return null;
    }
  }

  /**
   * @returns
   */
  static async getUsers() {
    try {
      const { data: responseData } = await api.get(`${homeRoutes.getUsers}`);

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 HomeServices.getUsers', { error });
      return null;
    }
  }

  /**
   *
   * @param {{data: string}}
   * @returns
   */
  static async removeUser(data) {
    try {
      const { data: responseData } = await api.delete(
        `${homeRoutes.removeUser}?userId=${data}`
      );

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 HomeServices.removeUser', { error });
      toast.error('Something went wrong, please try again later');
      return null;
    }
  }
}
