import api from 'api';
import { toast } from 'react-toastify';

const serviceRequestRoutes = {
  imageUpload: 'image-upload',
};

export class DefaultService {
  static async imageUpload(formData) {
    try {
      const { data: responseData } = await api.post(
        `${serviceRequestRoutes.imageUpload}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 ServiceRequestService.imageUpload', { error });
      toast.error(error.response.data.message);
      return null;
    }
  }
}
