import React, { useState, useEffect } from 'react';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';
import { Image, Space } from 'antd';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import SwapOutlined from '@ant-design/icons/SwapOutlined';
import RotateLeftOutlined from '@ant-design/icons/RotateLeftOutlined';
import RotateRightOutlined from '@ant-design/icons/RotateRightOutlined';
import ZoomOutOutlined from '@ant-design/icons/ZoomOutOutlined';
import ZoomInOutlined from '@ant-design/icons/ZoomInOutlined';
import UndoOutlined from '@ant-design/icons/UndoOutlined';
import { ServiceProvidersService } from 'services/service-providers';

/**
 * A component that displays a photo gallery, preview modal and allows for single and all photos downloads.
 *
 * @component
 * @param {Object[]} photos - An array of photo objects.
 * @returns {JSX.Element} The PhotoGalleryAccordion component.
 */
const PhotoGalleryAccordion = ({ photos, onAllPhotosDownload }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Group photos by category
  const photosGroupedByCategory = photos.reduce((acc, photo) => {
    const categoryName = photo.pictureCategory.pictureCategorySetting.name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    if (!acc[categoryName]) {
      acc[categoryName] = [];
    }
    acc[categoryName].push(photo);
    return acc;
  }, {});

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const onSinglePhotoDownload = src => {
    // TODO add error handling. Fail gracefully
    const extension = src.substring(src.lastIndexOf('.') + 1).toLowerCase();
    fetch(src, {
      headers: { 'Cache-Control': 'no-cache' },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = `image.${extension}`;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  // const onAllPhotosDownload =async() => {
  //   console.log('Download all photos');
  //   const zip = new JSZip();
  //   const folder = zip.folder('service-request-photos');

  //   Promise.all(
  //     photos.map(photo => {
  //       return fetch(photo.imageUrl)
  //         .then(response => response.blob())
  //         .then(blob => {
  //           folder.file(
  //             `${photo.pictureCategory.pictureCategorySetting.name}_${photo.pictureCategory.pictureCategorySetting.id}.png`,
  //             blob
  //           );
  //         });
  //     })
  //   ).then(() => {
  //     zip.generateAsync({ type: 'blob' }).then(content => {
  //       const url = URL.createObjectURL(content);
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.download = 'photos.zip';
  //       document.body.appendChild(link);
  //       link.click();
  //       URL.revokeObjectURL(url);
  //       link.remove();
  //     });
  //   });
  // };

  return (
    <div>
      <div className="mb-[8px]">
        <div
          className="flex justify-between items-center p-[12px] cursor-pointer bg-accent-4 rounded-[10px]"
          onClick={toggleAccordion}
        >
          <h2 className="font-[500] lg:text-[16px] text-[14px]">
            Photo Gallery
          </h2>
          {isOpen ? (
            <SlArrowUp className="fill-primary-2" />
          ) : (
            <SlArrowDown className="fill-primary-2" />
          )}
        </div>
        {isOpen && (
          <>
            <div className="mt-[-8px] mb-[11px] bg-accent-4 rounded-bl-[10px] rounded-br-[10px] p-[12px]">
              <div className="flex flex-wrap gap-2">
                <div className="flex flex-wrap">
                  {Object.entries(photosGroupedByCategory).map(
                    ([categoryName, photos]) => (
                      <div
                        key={categoryName}
                        className="flex flex-wrap gap-2 w-full"
                      >
                        <h3 className="w-full">{categoryName}</h3>
                        {photos.map((photo, index) => (
                          <div key={index}>
                            <Image
                              key={photo.id}
                              src={photo.imageUrl}
                              alt="Service Request Photo"
                              width={78}
                              height={78}
                              className="rounded-[10px] object-cover"
                              preview={{
                                toolbarRender: (
                                  _,
                                  {
                                    transform: { scale },
                                    actions: {
                                      onFlipY,
                                      onFlipX,
                                      onRotateLeft,
                                      onRotateRight,
                                      onZoomOut,
                                      onZoomIn,
                                      onReset,
                                    },
                                  }
                                ) => (
                                  <Space size={48} className="toolbar-wrapper">
                                    <DownloadOutlined
                                      onClick={() =>
                                        onSinglePhotoDownload(photo.imageUrl)
                                      }
                                    />
                                    <SwapOutlined
                                      rotate={90}
                                      onClick={onFlipY}
                                    />
                                    <SwapOutlined onClick={onFlipX} />
                                    <RotateLeftOutlined
                                      onClick={onRotateLeft}
                                    />
                                    <RotateRightOutlined
                                      onClick={onRotateRight}
                                    />
                                    <ZoomOutOutlined
                                      disabled={scale === 1}
                                      onClick={onZoomOut}
                                    />
                                    <ZoomInOutlined
                                      disabled={scale === 50}
                                      onClick={onZoomIn}
                                    />
                                    <UndoOutlined onClick={onReset} />
                                  </Space>
                                ),
                              }}
                            ></Image>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
              </div>
              <button
                className="mt-2 text-accent-1 text-sm"
                onClick={onAllPhotosDownload}
              >
                Download All
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PhotoGalleryAccordion;
