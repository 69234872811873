export const vendors = [
  {
    label: 'Local Shops',
    key: 'local_shops',
  },
  {
    label: 'Mechanics',
    key: 'mechanics',
  },
  {
    label: 'Tow Companies',
    key: 'tow_companies',
  },
];
