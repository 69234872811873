import { createSlice } from '@reduxjs/toolkit';
import {
  joinCompany,
  listDrivers,
  getCompanyPictureCategories,
} from './operations';

const initialState = {
  drivers: [],
  loading: false,
  isJoin: false,
  viewDriverLocation: {},
  companyPictureCategories: [],
};

export const fleetSlice = createSlice({
  name: 'fleet',
  initialState,
  reducers: {
    storeDrivers: (state, { payload }) => {
      state.drivers = payload;
    },
    setIsJoin: (state, { payload }) => {
      state.isJoin = payload;
    },
    setViewDriverLocation: (state, { payload }) => {
      state.viewDriverLocation = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(joinCompany.pending, (state, _) => {
      state.loading = true;
    });
    builder.addCase(joinCompany.fulfilled, (state, _) => {
      state.loading = false;
      state.isJoin = true;
    });
    builder.addCase(joinCompany.rejected, (state, _) => {
      state.loading = false;
      state.isJoin = false;
    });
    builder.addCase(listDrivers.fulfilled, (state, action) => {
      state.drivers = action.payload.data.fleetDrivers;
    });
    builder.addCase(getCompanyPictureCategories.fulfilled, (state, action) => {
      state.companyPictureCategories = action.payload.data;
    });
  },
});

export const { storeDrivers, setIsJoin, setViewDriverLocation } =
  fleetSlice.actions;

export const fleetReducer = fleetSlice.reducer;
