import { ButtonGroup } from 'components/buttonGroup/ButtonGroup';
import React from 'react';

/**
 * Renders a component for selecting a category of the company.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isSelected - Indicates whether an option is selected.
 * @param {function} props.onCategoryChange - A function to set the selected option.
 * @returns {JSX.Element} The rendered component.
 */
export const CompanyCategory = ({ category, onCategoryChange }) => {
  return (
    <div className="w-full mt-2 flex flex-col items-center justify-center">
      <span className=" text-base text-primary-3 font-normal  text-center">
        Please select an option below
      </span>
      {/* <div className="w-full mt-8 mb-[42px]"> */}
      <div className="w-full mt-8">
        <ButtonGroup
          isSelected={category}
          setIsSelected={onCategoryChange}
          title1={'Service Provider'}
          title2={'Trucking company'}
        />
      </div>
    </div>
  );
};
