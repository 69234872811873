import React, { Fragment } from 'react';
import { ErrorMessage, Field } from 'formik';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useFormikContext } from 'formik';

/**
 * A custom select input component for Formik forms.
 *
 * @component
 * @param {string} name - The name of the input field.
 * @param {Array} options - The options for the select input.
 * @returns {JSX.Element} The custom select input component.
 */
const FormikSelectInputTechs = ({ name, options }) => {
  const formikProps = useFormikContext();
  const animatedComponents = makeAnimated();

  const customSelectStyle = {
    control: baseStyles => ({
      ...baseStyles,
      borderRadius: '15px',
      padding: '7px 0',
      borderColor: '#A6A9AD',
    }),
    multiValue: baseStyles => ({
      ...baseStyles,
      borderRadius: '8px',
      backgroundColor: '#F1F3F8',
      fontSize: '17px',
    }),
    multiValueRemove: baseStyles => ({
      ...baseStyles,
      color: '#B8B8B8',
    }),
    placeholder: baseStyles => ({
      ...baseStyles,
      color: '#A6A9AD',
      fontSize: '16px',
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      padding: '10px 8px',
    }),
    option: baseStyles => ({
      ...baseStyles,
      width: 'fit-content',
      borderRadius: '8px',
      backgroundColor: '#F1F3F8',
      transition: 'all 0.3s',
      ':hover': {
        ...baseStyles[':hover'],
        backgroundColor: '#DFECFB',
        cursor: 'pointer',
        transition: 'all 0.3s',
      },
    }),
  };

  const SelectInput = () => (
    <Fragment>
      <Select
        components={animatedComponents}
        name={name}
        value={formikProps.values.serviceCategoryIDs}
        onChange={value => {
          formikProps.setFieldTouched(name, true);
          formikProps.setFieldValue(name, value);
        }}
        isMulti
        styles={customSelectStyle}
        placeholder={'Select'}
        options={options}
      />
      <ErrorMessage
        name={name}
        className="text-error-1 text-[10px]"
        component="span"
      />
    </Fragment>
  );

  return <Field name={name} component={SelectInput} {...formikProps} />;
};

export default FormikSelectInputTechs;
