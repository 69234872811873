import { createSlice } from '@reduxjs/toolkit';
import { getCities, getCountries, getServiceCategories, getStates } from './operations';

const initialState = {
  loading: false,
  breakdowns: [],
  states: [],
  countries: [],
  cities: [],
};

export const referenceSlice = createSlice({
  name: 'reference',
  initialState,
  extraReducers: builder => {
    builder.addCase(getServiceCategories.pending, (state, _) => {
      state.loading = true;
    });
    builder.addCase(getServiceCategories.fulfilled, (state, action) => {
      state.breakdowns = action.payload.serviceCategories;
      state.loading = false;
    });
    builder.addCase(getServiceCategories.rejected, (state, _) => {
      state.loading = false;
    });
    builder.addCase(getCountries.pending, (state, _) => {
      state.loading = true;
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload.data.countries;
      state.loading = false;
    });
    builder.addCase(getCountries.rejected, (state, _) => {
      state.loading = false;
    });

    builder.addCase(getStates.pending, (state, _) => {
      state.loading = true;
    });
    builder.addCase(getStates.fulfilled, (state, action) => {
      state.states = action.payload.data.states.map(state => ({
        key: state.id,
        label: state.name,
      }));
      state.loading = false;
    });
    builder.addCase(getStates.rejected, (state, _) => {
      state.loading = false;
    });
    // Cities
    builder.addCase(getCities.pending, (state, _) => {
      state.loading = true;
    });
    builder.addCase(getCities.fulfilled, (state, action) => {
      state.cities = action.payload.data.cities.map(city => ({
        key: city.id,
        label: city.name,
      }));
      state.loading = false;
    });
    builder.addCase(getCities.rejected, (state, _) => {
      state.loading = false;
    });
  },
});

export const ReferenceReducer = referenceSlice.reducer;
