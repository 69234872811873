import { dashboardKeys } from 'constants/dashboard/tabs';
import React from 'react';
import { GoHome, GoCreditCard } from 'react-icons/go';
import { TbBriefcase2, TbSettings } from 'react-icons/tb';
import { PiClockCounterClockwiseFill } from 'react-icons/pi';
import { BiSupport } from 'react-icons/bi';
import { SlLocationPin } from 'react-icons/sl';
import { RiAppsLine } from 'react-icons/ri';
import { FiBell } from 'react-icons/fi';
import { TbMessage2Exclamation } from 'react-icons/tb';
import { HiOutlineWrench } from 'react-icons/hi2';
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import { RiTeamLine } from 'react-icons/ri';

/**
 *
 * @param {{tab: {key: string, label: string}}} param0
 * @returns
 */
const GetTabIcon = ({ tab, className }) => {
  switch (tab.key) {
    case dashboardKeys.home:
      return <GoHome className={className || ''} />;
    case dashboardKeys.company:
      return <TbBriefcase2 className={className || ''} />;
    case dashboardKeys.payment:
      return <GoCreditCard className={className || ''} />;
    case dashboardKeys.products:
      return <RiAppsLine className={className || ''} />;
    case dashboardKeys.requests:
      return <TbMessage2Exclamation className={className || ''} />;
    case dashboardKeys.purchase:
      return <PiClockCounterClockwiseFill className={className || ''} />;
    case dashboardKeys.history:
      return <PiClockCounterClockwiseFill className={className || ''} />;
    case dashboardKeys.settings:
      return <TbSettings className={className || ''} />;
    case dashboardKeys.support:
      return <BiSupport className={className || ''} />;
    case dashboardKeys.trucks:
      return <SlLocationPin className={className || ''} />;
    case dashboardKeys.notifications:
      return <FiBell className={className || ''} />;
    case dashboardKeys.parts:
      return <HiOutlineWrench className={className || ''} />;
    case dashboardKeys.chat:
      return <IoChatbubbleEllipsesOutline className={className || ''} />;
    case dashboardKeys.technicians:
      return <RiTeamLine className={className || ''} />;
    default:
      return '';
  }
};

export default GetTabIcon;
