import { Modal } from 'antd';
import { useMemo } from 'react';
import { ReviewCard } from '../../../../profile/FleetDashboardNavigation/MyTechniciansTab/components/ReviewCard';
import { MechanicInfo } from './MechanicInfo';
import { RatingDistribution } from './RatingDistribution';

/**
 * ModalShowReviews Component
 *
 * This component displays a modal with detailed reviews for a mechanic.
 * It shows the mechanic's information, overall rating, rating distribution,
 * and individual reviews.
 *
 * @param {Object} props - The component props
 * @param {boolean} props.isOpen - Controls the visibility of the modal
 * @param {function} props.setIsOpen - Function to set the modal's open state
 * @param {Array} props.reviews - Array of review objects
 * @param {Object} props.mechanic - Mechanic's information
 * @param {number} props.rating - Overall rating of the mechanic
 * @param {Array} props.ratings - Array of rating objects
 * @param {number} props.distance - Distance to the mechanic
 *
 * @returns {JSX.Element} The ModalShowReviews component
 */
const ModalShowReviews = ({
  isOpen,
  setIsOpen,
  reviews,
  mechanic,
  rating,
  ratings,
  distance,
}) => {
  const ratingDistribution = useMemo(() => {
    const distribution = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    ratings.forEach(rating => {
      distribution[rating.score] += 1;
    });
    return Object.entries(distribution)
      .map(([score, count]) => ({
        score: Number(score),
        percentage: (count / ratings.length) * 100,
      }))
      .reverse(); // Reverse the array so 5 stars is first
  }, [ratings]);

  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={false}
      zIndex={1002}
      width={480}
      className="font-poppins"
      centered
    >
      <div className="flex flex-col w-full items-center justify-center gap-y-4 transition-all">
        {/* Mechanic Info */}
        <MechanicInfo
          mechanic={mechanic}
          rating={rating}
          ratings={ratings}
          distance={distance}
        />
        {/* Rating Distribution */}
        <RatingDistribution
          ratingDistribution={ratingDistribution}
          rating={rating}
          ratingsCount={ratings?.length}
        />
        {/* Reviews */}
        <div className="flex flex-col w-full">
          {reviews.map((review, index) => (
            <ReviewCard key={index} data={review.feedback} />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ModalShowReviews;
