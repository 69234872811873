import React, { useState } from "react";
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';

export const SortButton = ({ onSort, selectedOption, setSelectedOption }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const options = ['all', 'active now', 'inactive now'];
    const handleOptionClick = option => {
      setSelectedOption(option);
      setIsOpen(false);
      onSort(option);
    };
  
    return (
      <div className="relative inline-block text-left">
        <div>
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="h-[30px] px-[14px] py-[5px] inline-flex justify-center items-center rounded-[20px] border-gray-1 border text-[14px] text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500"
          >
            {isOpen ? (
              <SlArrowUp className="w-[11px] mr-[8px] fill-accent-1" />
            ) : (
              <SlArrowDown className="w-[11px] mr-[8px] fill-accent-1" />
            )}
            <span className="text-[14px]">Sort by: {selectedOption}</span>
          </button>
        </div>
  
        {isOpen && (
          <div className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {options.map(option => (
                <div
                  key={option}
                  onClick={() => handleOptionClick(option)}
                  className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };