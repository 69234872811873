import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { IoIosCheckmark, IoMdInformationCircle } from 'react-icons/io';
import { AiOutlineEye } from 'react-icons/ai';
import { RiEyeCloseLine } from 'react-icons/ri';
import { Button } from 'component-library';

/**
 * Renders the Login component.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setIsValid - The function to set the validity of the form.
 * @param {boolean} props.isRemember - The flag indicating whether the "Remember me" checkbox is checked.
 * @param {Function} props.setIsRemember - The function to set the value of the "Remember me" checkbox.
 * @param {Function} props.handleLogin - The function to handle the login action.
 * @param {Function} props.handleSignUp - The function to handle the "Sign Up" action.
 * @param {Function} props.handleForgot - The function to handle the "Forgot password" action.
 * @param {Function} props.handleReset - The function to handle the "Reset password" action.
 * @param {Function} props.setValues - The function to set the form values.
 * @returns {JSX.Element} The rendered Login component.
 */
export const Login = ({
  isRemember,
  setIsRemember,
  handleLogin,
  handleForgot,
  handleReset,
  handleSignUp,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const initialFields = {
    email: {
      name: 'email',
      placeholder: 'Email',
      type: 'text',
      label: 'Email',
    },
    password: {
      name: 'password',
      placeholder: 'Password (Min 8 character)',
      type: 'password',
      label: 'Password',
    },
  };

  const initialValues = {
    [initialFields.email.name]: '',
    [initialFields.password.name]: '',
  };

  const strongPasswordRefExp = new RegExp(
    /(?=.*\d)(?=.*\W+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
    'gi'
  );

  const validationSchema = Yup.object().shape({
    [initialFields.email.name]: Yup.string()
      .matches(/\S+@\S+\.\S+/, 'Please enter a valid email')
      .required('Required'),
    [initialFields.password.name]: Yup.string()
      .required('Required')
      .min(8, `Min 8 character`)
      .matches(strongPasswordRefExp, {
        message:
          'Password must contain a number, an uppercase letter and a special character',
      }),
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="mt-1 w-full text-center">
      <span>Don’t have an account yet? </span>
      <span
        className=" text-accent-1 font-normal cursor-pointer"
        onClick={handleSignUp}
      >
        Sign Up
      </span>
      <div className="text-left">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => {
            handleLogin(values);
          }}
        >
          {({ isValid, errors, handleChange }) => (
            <Form className="w-full flex flex-col ">
              <div className="w-full  pt-6  grid grid-cols-1">
                {Object.keys(initialFields).map((field, index) => {
                  const fieldData = initialFields[field];
                  const isPassword = fieldData.type === 'password';
                  return (
                    <div className={`flex flex-col gap-y-1 `} key={index}>
                      <label
                        className={'text-base font-normal text-primary-2 '}
                      >
                        {fieldData.label}
                        <label className="text-[#DA1414]"> *</label>
                      </label>
                      <div className="flex flex-col gap-y-[2px]">
                        <div
                          className={`w-full py-[13px] px-[15px] text-[0.875rem] leading-[1.38em] rounded-xl  flex flex-row ${
                            !errors[fieldData.name]
                              ? 'border-primary-3 border'
                              : 'bg-[#FEEFEF] border-[#DA1414] border-[1px]'
                          }`}
                        >
                          <Field
                            name={fieldData.name}
                            className={`w-full justify-center focus-visible:outline-none focus-within:outline-none ${
                              errors[fieldData.name] &&
                              'bg-[#FEEFEF] border-[#DA1414]'
                            } `}
                            placeholder={fieldData.placeholder}
                            type={
                              isPassword
                                ? showPassword
                                  ? 'text'
                                  : 'password'
                                : fieldData.type
                            }
                            onChange={e => {
                              handleChange(e);
                            }}
                          />
                          {isPassword ? (
                            <button
                              type="button"
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? (
                                <AiOutlineEye
                                  className="text-lg"
                                  color="#858C94"
                                />
                              ) : (
                                <RiEyeCloseLine
                                  className="text-lg"
                                  color="#858C94"
                                />
                              )}
                            </button>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      {errors[fieldData.name] && (
                        <div
                          className={`bg-[#FEEFEF] py-2 px-4 flex flex-row items-center rounded-[8px]`}
                        >
                          <IoMdInformationCircle color="#DA1414" />
                          <span className="text-[13px] text-[#DA1414] font-normal ml-1">
                            {errors[fieldData.name]}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-row justify-between w-full items-center mt-5">
                <div className="flex flex-row items-center">
                  <div
                    className={`w-6 h-6 rounded-[4px] border-[1px] cursor-pointer flex justify-center items-center
              ${
                !isRemember
                  ? ' border-secondary-1'
                  : 'border-accent-1 bg-accent-1'
              }`}
                    onClick={() => {
                      setIsRemember(!isRemember);
                    }}
                  >
                    {isRemember && <IoIosCheckmark color="white" size={20} />}
                  </div>
                  <span
                    className="text-sm font-normal text-primary-2 ml-4 cursor-pointer"
                    onClick={() => setIsRemember(!isRemember)}
                  >
                    Remember me
                  </span>
                </div>
                <div className="flex flex-col gap-y-2 items-end">
                  <span
                    className=" text-sm underline text-accent-1 font-normal cursor-pointer"
                    onClick={handleForgot}
                  >
                    Forgot password
                  </span>
                  <span
                    className=" text-sm underline text-accent-1 font-normal cursor-pointer"
                    onClick={handleReset}
                  >
                    Reset password
                  </span>
                </div>
              </div>
              <Button title="Login" type="submit" classNames="mt-8" />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
