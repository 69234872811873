/* eslint-disable react-hooks/exhaustive-deps */
import { Listbox, RadioGroup, Transition } from '@headlessui/react';
import { Button } from 'component-library';
import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi2';
import { MdCheck } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Modal } from 'antd';
import { MechanicRequestDetails } from 'components/mechanic';
import { RequestMechanic } from 'components/Dashboard';
// import { useSearchParams } from 'react-router-dom';
// import { dashboardKeys } from 'constants/dashboard/tabs';
import { getServiceCategories } from 'redux/reference/operations';
import { selectBreakdowns } from 'redux/reference/selectors';
import profileImagePlaceholder from '../../../assets/profile-image-placeholder.jpg';
import { getAddressFromLocation } from 'utils/utils';
import { IoIosSearch } from 'react-icons/io';
import { FaRegCircle } from 'react-icons/fa';
import { ModalAddDriver } from 'components/Dashboard/ModalAddDriver';
import { addDriverToFleet } from 'redux/fleet/operations';
import {
  findLocalShops,
  findMechanics,
  getFeedBackForUser,
} from 'redux/mechanics/operations';
import { selectLoading, selectUserFeedBacks } from 'redux/mechanics/selectors';
import { setFeedBackForUser } from 'redux/mechanics/mechanic.slice';
import { ShopRequestDetails } from 'components/localShops/ShopRequestDetails/ShopRequestDetails';

const options = [
  { label: 'Request', value: 'request' },
  { label: 'Local Shops', value: 'local_shops' },
  { label: 'Tow Truck', value: 'tow_truck' },
];

export const DriverBreakdownSideBar = ({
  units,
  unitSelected,
  setUnitSelected,
  onVendorSearch,
  vendors,
  setVendors,
}) => {
  const dispatch = useDispatch();
  const [requestType, setRequestType] = useState('request');
  const [selected, setSelected] = useState(
    unitSelected || {
      ...units?.filter(unit => unit.isRenderedOnMap)[0],
    }
  );
  const [serviceCategorySelected, setServiceCategorySelected] = useState();
  const [mechanicSelected, setMechanicSelected] = useState();
  const serviceCategories = useSelector(selectBreakdowns);
  const [isAddDriver, setIsAddDriver] = useState(false);
  // const [serviceCategories, setServiceCategories] = useState([]);
  const [isSearchingMechanics, setIsSearchingMechanics] = useState(false);
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const initialized = useRef(false);
  const initializedSelect = useRef(false);
  const [address, setAddress] = useState('');
  useEffect(() => {
    if (!initializedSelect.current && units.length > 0) {
      if (selected?.id !== unitSelected?.id && unitSelected) {
        setSelected(unitSelected);
        initializedSelect.current = true;
      } else if (!unitSelected) {
        setSelected({
          ...units.filter(unit => unit.isRenderedOnMap)[0],
        });
        initializedSelect.current = true;
      }
    }
  }, [unitSelected, units, selected, initializedSelect]);

  useEffect(() => {
    if (selected?.id !== unitSelected?.id && selected) {
      setUnitSelected(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (!initialized.current) {
      dispatch(getServiceCategories());
      initialized.current = true;
    }
  }, [initialized, dispatch]);

  const searchMechanics = async () => {
    setIsSearchingMechanics(true);

    if (!serviceCategorySelected) {
      toast.error('Please select a breakdown type.');
      setIsSearchingMechanics(false);
      return;
    }
    if (requestType.includes('request')) {
      const result = await dispatch(
        findMechanics({
          latitude: selected.locationLat,
          longitude: selected.locationLong,
          radius: 50,
          typeOfBreakdown: serviceCategorySelected,
        })
      );
      //  await ServiceRequestService.findMechanics({
      //   latitude: selected.locationLat,
      //   longitude: selected.locationLong,
      //   radius: 50,
      //   typeOfBreakdown: serviceCategorySelected,
      // });

      setIsSearchingMechanics(false);

      const populatedResults = result?.payload.data?.map(vendor => ({
        ...vendor,
        rating:
          vendor?.mechanic?.ratingsReceived?.reduce((prev, curr) => {
            return prev + curr.score || 0;
          }, 0) / (vendor?.mechanic?.ratingsReceived.length || 1) || 0,
      }));

      if (populatedResults?.length > 0) {
        onVendorSearch(populatedResults);
      } else {
        toast.error('No mechanics found near you');
      }
    } else if (requestType.includes('local_shops')) {
      const result = await dispatch(
        findLocalShops({
          latitude: selected.locationLat,
          longitude: selected.locationLong,
          radius: 50,
          typeOfBreakdown: serviceCategorySelected,
        })
      );
      const populatedResults = result?.payload.data;
      setIsSearchingMechanics(false);
      if (populatedResults?.length > 0) {
        onVendorSearch(populatedResults);
      } else {
        toast.error('No Local shops found near you');
      }
    }
  };

  useEffect(() => {
    if (selected.locationLat && selected.locationLong) {
      async function getAddress() {
        const addressRes = await getAddressFromLocation({
          locationLat: selected.locationLat,
          locationLong: selected.locationLong,
        });
        setAddress(addressRes);
      }

      getAddress();
    }
  }, [selected]);
  const feedbacks = useSelector(selectUserFeedBacks);
  const loading = useSelector(selectLoading);
  const handleAdd = value => {
    setIsAddDriver(false);
    dispatch(addDriverToFleet(value.replace('+', '')));
  };
  return (
    <div className="h-screen  w-full flex flex-col gap-y-4 pt-4 sm:pt-8 max-h-screen">
      <div className="w-full flex flex-col gap-y-3 sm:px-6 px-4">
        <button
          type="button"
          onClick={() => {
            setIsAddDriver(true);
          }}
          className="align-right ml-auto text-[14px] text-accent-1"
        >
          + Add driver
        </button>
        <div className="rounded-[10px] p-[13px] mb-[16px] mt-3 flex items-center w-auto h-12 bg-gray-100">
          <IoIosSearch className="text-[25px] text-primary-3" />
          <input
            type="text"
            placeholder="Search"
            className="pl-[11px] text-primary-2 bg-gray-100 focus:outline-none placeholder:text-secondary-1"
            // onChange={handleInputChange}
          />
        </div>
        <ModalAddDriver
          isOpen={isAddDriver}
          onCancel={() => {
            setIsAddDriver(false);
          }}
          handleSearch={handleAdd}
        />
        <span className="text-[16px] font-medium">
          What are you looking for?
        </span>
        <RadioGroup
          value={requestType}
          onChange={value => {
            setRequestType(value);
          }}
          className="flex sm:flex-row flex-col gap-y-4 w-full items-center gap-x-3"
          defaultValue={options[0].value}
          disabled={vendors.length > 0}
        >
          {options.map((option, index) => (
            <RadioGroup.Option
              key={option.value}
              value={option.value}
              className="w-full"
            >
              {({ checked }) => (
                <div
                  className={`${
                    checked
                      ? 'bg-accent-1 text-white'
                      : 'bg-primary-3/30 text-primary-2/60'
                  } py-2 px-4 text-xs w-full flex justify-center items-center rounded-lg transition-all cursor-pointer`}
                >
                  <span>{option.label}</span>
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </div>
      <hr className="sm:mx-6" />
      <div className="w-full flex flex-col gap-y-3 sm:px-6 px-4">
        <span className="text-[16px] font-medium">Select a unit:</span>
        {units.length > 0 ? (
          <div className="flex w-full flex-row justify-between  items-end">
            <Listbox
              value={selected}
              onChange={setSelected}
              defaultValue={unitSelected}
              disabled={vendors.length > 0}
            >
              <div className="relative mt-1 w-auto">
                <Listbox.Button className="relative cursor-default rounded-lg py-2 pl-5 pr-10 text-left sm:text-sm bg-accent-1 text-white ">
                  {selected ? (
                    <span className="block truncate">
                      {`U${selected.index + 1}`}
                    </span>
                  ) : (
                    'Choose an option'
                  )}
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <HiChevronDown
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {units
                      .filter(unit => unit.isRenderedOnMap)
                      .map((unit, index) => (
                        <Listbox.Option
                          key={unit.id}
                          className={() =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              selected?.id === unit.id
                                ? 'bg-accent-2 text-accent-1'
                                : 'text-gray-900'
                            }`
                          }
                          value={{ ...unit }}
                        >
                          {() => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected?.id === unit.id
                                    ? 'font-medium'
                                    : 'font-normal'
                                }`}
                              >
                                {`U${unit.index + 1}`}
                              </span>
                              {selected?.id === unit.id ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-accent-1">
                                  <MdCheck
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
            <div className="flex flex-row rounded-lg  px-2 sm:text-sm bg-[#F1F1F1] text-primary-2 items-center w-[76%] py-2">
              <FaRegCircle />
              <span className="pl-1 truncate ">{address}</span>
            </div>
          </div>
        ) : (
          <span className="text-[14px] ">No driver:</span>
        )}
      </div>
      <hr className="sm:mx-6" />
      {units?.length > 0 && selected.firstName && (
        <div className="w-full flex flex-col gap-y-3 sm:px-6 px-4">
          <span className="text-[16px] font-medium">Unit information</span>
          <div className="flex gap-x-2 sm:gap-x-4">
            <img
              src={
                selected.images[0]
                  ? selected.images[0].url
                  : profileImagePlaceholder
              }
              alt={selected.name}
              className="w-[60px] h-[60px] object-cover rounded-[10%]"
            />
            <div className="flex flex-col">
              <span className="font-bold text-sm">
                {selected.firstName} {selected?.lastName}
                <br />
                Unit {selected.index + 1}
              </span>
              {selected?.vehicles.length > 0 && (
                <span className="text-semibold text-sm text-">
                  {selected?.vehicles[0].vin}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
      <hr className="sm:mx-6" />
      <div className="w-full flex flex-col gap-y-3 sm:px-6 px-4">
        <span className="text-[16px] font-medium">Select a breakdown:</span>
        <div className="flex flex-col lg:flex-row gap-y-3 gap-x-3">
          {serviceCategories.map(category => (
            <button
              key={category.id}
              className={`py-2 px-4 rounded-[500px] ${
                serviceCategorySelected === category.id
                  ? 'bg-accent-1 text-white'
                  : 'bg-primary-3/30 text-primary-2/60'
              } text-xs transition-all w-full`}
              onClick={() => {
                setServiceCategorySelected(category.id);
              }}
              disabled={vendors.length > 0}
            >
              {category.title}
            </button>
          ))}
        </div>
      </div>
      <hr className="sm:mx-6" />
      {selected && !vendors.length ? (
        <div className="sm:px-6 px-4">
          <Button
            type="button"
            title={`Find a ${
              requestType.includes('request') ? 'Mechanic' : 'Local Shops'
            } to Unit ${selected.index + 1}`}
            loading={isSearchingMechanics}
            onClick={searchMechanics}
          />
        </div>
      ) : (
        ''
      )}
      {vendors.length ? (
        <div className="flex flex-col gap-y-4 sm:px-6 px-4">
          {vendors[0]?.mechanic.type
            ? vendors.map((shop, index) => (
                <div key={index} className="flex flex-col gap-y-4">
                  <ShopRequestDetails
                    key={shop.id}
                    data={shop}
                    index={index}
                    // onRequest={mechanic => {
                    //   setMechanicSelected({
                    //     ...mechanic,
                    //     ...mechanic.mechanic,
                    //   });
                    //   dispatch(getFeedBackForUser(mechanic?.id));
                    //   setIsRequestModalOpen(true);
                    // }}
                  />
                  <hr />
                </div>
              ))
            : vendors.map((mechanic, index) => (
                <div key={index} className="flex flex-col gap-y-4">
                  <MechanicRequestDetails
                    key={mechanic.id}
                    data={{
                      ...mechanic,
                      ...mechanic.mechanic,
                    }}
                    index={index}
                    onRequest={mechanic => {
                      setMechanicSelected({
                        ...mechanic,
                        ...mechanic.mechanic,
                      });
                      dispatch(getFeedBackForUser(mechanic?.id));
                      setIsRequestModalOpen(true);
                    }}
                  />
                  <hr />
                </div>
              ))}
        </div>
      ) : (
        ''
      )}
      <Modal
        title=""
        open={isRequestModalOpen}
        onCancel={() => {
          setIsRequestModalOpen(false);
        }}
        footer={false}
        zIndex={1002}
        width={'30vw'}
      >
        <RequestMechanic
          mechanic={mechanicSelected}
          loading={loading}
          feedbacks={feedbacks}
          driver={unitSelected}
          serviceCategory={serviceCategories.find(
            category => category.id === serviceCategorySelected
          )}
          close={() => {
            setIsRequestModalOpen(false);
            dispatch(setFeedBackForUser([]));
          }}
        />
      </Modal>
    </div>
  );
};
