import React, { useState } from 'react';
import profileImagePlaceholder from '../../../assets/profile-image-placeholder.jpg';
import { PiDotOutlineFill } from 'react-icons/pi';
import { Button } from 'component-library';
import { FaStar } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { getAddressFromLocation } from 'utils/utils';
import { ServiceRequestService } from 'services/service-requests.service';
import { ScrollTab } from 'components/DashboardRepair/ScrollTab';
import { Loader } from 'components/common';

export const RequestMechanic = ({
  mechanic,
  driver,
  serviceCategory,
  close,
  feedbacks,
  loading,
}) => {
  const [isRequesting, setIsRequesting] = useState(false);

  const handleRequestMechanic = async () => {
    try {
      setIsRequesting(true);
      const result = await ServiceRequestService.requestMechanic({
        address: await getAddressFromLocation({
          locationLat: driver.locationLat,
          locationLong: driver.locationLong,
        }),
        // companyId: driver.companyId,
        mechanicId: mechanic.id,
        serviceCategoryId: serviceCategory.id,
        vin: driver.vehicles[0].vin,
        type: serviceCategory.title.trim().toLowerCase().split(' ').join('_'),
        description: serviceCategory.title,
        autoAssign: true,
        latitude: driver.locationLat,
        longitude: driver.locationLong,
      });
      if (!result.status) {
        setIsRequesting(false);
        toast.error(
          typeof result?.data === 'string'
            ? result.data
            : 'Something went wrong. Please try again.'
        );
        return;
      }
      setIsRequesting(false);
      close();
      toast(
        'Successfully requested mechanic. Your driver will be notified with the mechanic details.'
      );
      return;
    } catch (err) {
      setIsRequesting(false);
      toast('An error occurred while making the request.');
      close();
    }
  };
  return (
    <div className="flex flex-col gap-y-8">
      <span className="text-[18px] font-semibold">Confirm Request</span>
      <div className="w-full flex flex-col gap-y-6">
        <span>Confirm you wish to proceed with this request</span>
        <div className="w-full flex flex-col gap-y-4">
          <div className="flex justify-between w-full">
            <div className="w-full flex flex-col gap-x-2">
              <span className="text-lg font-semibold">{mechanic?.name}</span>
              <div className="flex items-center gap-x-2">
                <FaStar className="text-base text-accent-1" />
                <span>
                  {mechanic?.rating || 0} ({mechanic?.jobCount} Jobs)
                </span>
              </div>
              <span>${mechanic?.rate}</span>
            </div>
            <div>
              <img
                src={mechanic?.images[0]?.url || profileImagePlaceholder}
                alt={mechanic.name}
                className="w-[100px] h-[100px] object-cover rounded-xl"
              />
              <span className="text-accent-1 text-sm">
                {mechanic?.distance.toFixed(2)}mi
              </span>
            </div>
          </div>
        </div>

        <hr className="text-primary-3" />
        <div className="py-4 px-6 bg-[#DFECFB] rounded-xl flex flex-col gap-y-3">
          <span>Mechanic Capabilities</span>
          <div className="flex flex-col gap-y-1">
            {mechanic?.services?.map(service => {
              return (
                <div className="flex items-center gap-x-2" key={service.id}>
                  <PiDotOutlineFill className="text" />
                  {service.serviceCategory.title}
                </div>
              );
            })}
          </div>
        </div>
        <hr className="text-primary-3" />
        <div className="w-full flex flex-col gap-y-4">
          <span className="w-full font-semibold">Reviews</span>
          <div className="flex gap-x-2 overflow-x-auto w-full ">
            {loading ? (
              <Loader />
            ) : feedbacks.length > 0 ? (
              feedbacks.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="min-w-[75%]  flex justify-center items-center mr-4"
                  >
                    <ScrollTab
                      data={{
                        ...item,
                        ratingByUser: {
                          ...item?.ratingByUser,
                          profileImage: item?.ratingByUserProfileImage?.url,
                        },
                      }}
                    />
                  </div>
                );
              })
            ) : (
              <p>No reviews</p>
            )}
          </div>
        </div>
        <div className="flex gap-x-12 justify-between items-center">
          {driver && (
            <div className="w-full">
              <Button
                title="Request Mechanic"
                loading={isRequesting}
                onClick={handleRequestMechanic}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
