import React, { useState, useMemo } from 'react';
import { Modal, List } from 'antd';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IoIosSearch } from 'react-icons/io';
import { Button } from 'component-library';
import { selectMechanics } from 'redux/mechanics/selectors';
import { selectBreakdowns } from 'redux/reference/selectors';
import { MechanicCard } from './components/MechanicCard';
import { ServiceRequestService } from 'services/service-requests.service';
import { toast } from 'react-toastify';

const ModalAssignMechanic = ({ isOpen, setIsOpen, request }) => {
  const [selectedMechanic, setSelectedMechanic] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setLoading] = useState(false);

  const serviceCategories = useSelector(selectBreakdowns);
  const selectedServiceCategory = serviceCategories.find(
    category => category.id === request.serviceCategoryId
  );

  const mechanics = useSelector(selectMechanics);

  const filteredMechanics = useMemo(() => {
    return mechanics.filter(
      mechanic =>
        mechanic.role !== 'REPAIR_SHOP_MANAGER' &&
        mechanic.services.some(
          service => service.serviceCategoryId === selectedServiceCategory.id
        ) &&
        (searchTerm === '' ||
          `${mechanic.firstName} ${mechanic.lastName}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))
    );
  }, [mechanics, selectedServiceCategory, searchTerm]);

  const handleDriverSelection = mechanic => {
    setSelectedMechanic(prevSelected =>
      prevSelected === mechanic.id ? null : mechanic.id
    );
  };

  const handleAssignMechanic = async (serviceRequestId, mechanicId) => {
    try {
      setLoading(true);
      await ServiceRequestService.assignMechanic({
        serviceRequestId,
        mechanicId,
      });

      toast.success('Technician assigned successfully');
      setIsOpen(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        setIsOpen(false);
      }}
      footer={false}
      zIndex={1002}
      width={650}
      className="font-poppins"
    >
      <div className="flex flex-col w-full items-center justify-center gap-y-4 transition-all py-2">
        <div className="flex flex-col w-full gap-y-3 items-center">
          <span className="text-[26px] text-primary-2 text-center font-semibold">
            Assign Technician
          </span>
          <span className="text-base font-normal text-center text-primary-2">
            Please, chose a technican
          </span>
          <div className="rounded-[30px] p-[13px] w-full flex flex-initial items-center h-12 bg-gray-100">
            <IoIosSearch className="text-[25px] text-primary-3" />
            <input
              type="text"
              placeholder="Search Technicians"
              className="pl-[11px] text-primary-2 bg-gray-100 focus:outline-none placeholder:text-secondary-1 w-full"
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="w-full flex gap-2 w-full">
            <p className="text-primary-2 text-base font-medium">
              Available Technicians for Repair Type:
            </p>
            <div className="py-0.5 px-4 rounded-[30px] bg-accent-2">
              <span className="text-xs text-accent-1 font-medium">
                {selectedServiceCategory.title}
              </span>
            </div>
            <p></p>
          </div>
          <div
            className="overflow-auto w-full p-4 bg-[#f0f3f7] rounded-[15px]"
            id="scrollableDiv"
          >
            <InfiniteScroll
              dataLength={filteredMechanics.length}
              scrollableTarget="scrollableDiv"
            >
              <List
                dataSource={filteredMechanics}
                renderItem={mechanic => (
                  <List.Item
                    key={mechanic.id}
                    className="flex flex-col bg-white rounded-lg mb-4 !px-2"
                  >
                    <MechanicCard
                      mechanic={mechanic}
                      request={request}
                      isSelected={selectedMechanic === mechanic.id}
                      onSelect={() => handleDriverSelection(mechanic)}
                    />
                  </List.Item>
                )}
              />
            </InfiniteScroll>
            <div className="relative group">
              <p className="text-center">
                <u>Not Seeing a Technician you expect?</u>
              </p>
              <div className="absolute bottom-full mb-2 hidden group-hover:block bg-white text-black text-sm p-2 rounded shadow-lg border border-gray-300">
                Please make sure the technician is available and has the right
                capability for the repair type.
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full gap-4">
          <Button
            title="Assign Technician"
            disabled={!selectedMechanic}
            onClick={() => handleAssignMechanic(request.id, selectedMechanic)}
            loading={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalAssignMechanic;
