import React from 'react';
import { FaCircle, FaUser } from 'react-icons/fa6';
import { IoLocationOutline } from 'react-icons/io5';
import { isEmpty } from 'lodash';
import { IoMdCheckmark } from 'react-icons/io';

export const SingleTruck = ({
  vehicle,
  viewOnMap,
  index,
  isInvite,
  invite
}) => {
  const textStyle = vehicle.driver?.isActive
    ? { color: '#53CD58' }
    : { color: '#A6A9AD' };

  return (
      <div className="flex flex-row justify-between w-full py-5 mt-5 border-t-[1px] border-t-[#E5E9EF]">
        <div className="flex flex-row items-start justify-start">
          {vehicle.driver?.images[0]?.url ? (
            <img
              src={vehicle.driver?.images[0]?.url}
              alt="User"
              className=" h-[52px] w-[52px] rounded-[6px] bg-accent-2 object-cover"
            />
          ) : (
            <div className=" flex justify-center items-center  w-[52px] h-[52px] rounded-[6px] bg-accent-2 object-cover">
              <FaUser className="h-[30px] w-[30px] fill-accent-1" />
            </div>
          )}
          <div className="flex flex-col ml-2.5 justify-start">
            <div className="flex flex-row items-center">
              <p className=" text-left text-lg font-medium text-primary-2">
                {vehicle.driver?.firstName ?? 'Not Assigned'}{' '}
                {vehicle.driver?.lastName ?? ''}
              </p>
              <FaCircle className="h-[9px] w-[9px] mx-1" style={textStyle} />
            </div>
            <p className="text-[15px] text-primary-2 mt-1 opacity-60 font-normal mb-5">
              {vehicle.vin}
            </p>
            {!isEmpty(vehicle?.driver) && vehicle?.driver?.isRenderedOnMap && (
              <div
                className=" flex items-center  cursor-pointer "
                onClick={() => viewOnMap(vehicle?.driver)}
              >
                <IoLocationOutline className="text-accent-1" />
                <p className="text-accent-1 text-sm font-medium">View on map</p>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col items-end justify-start">
          {true ? (
            <button
              disabled={isInvite}
              onClick={invite}
              type="button"
              className={`px-[13px] py-1 inline-flex justify-center items-center rounded-[20px] ${
                isInvite ? '  bg-accent-3' : 'bg-accent-1'
              }  my-4`}
            >
              {isInvite && (
                <IoMdCheckmark color="white" size={16} className=" mr-1.5" />
              )}
              <span className="text-sm font-normal text-white">
                {isInvite ? 'Invite Sent' : 'Invite to MyMechanic'}
              </span>
            </button>
          ) : (
            <p style={textStyle} className="text-[11px] font-normal ">
              {vehicle.driver?.isActive ? 'active now' : 'inactive'}
            </p>
          )}
          <p className="text-sm font-normal text-primary-2">
            #Unit {index + 1}
          </p>
        </div>
      </div>
  );
};
