import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';


export const imageUpload = createAsyncThunk(
  'image-upload',
  async (formData, thunkAPI) => {
    try {
      const res = await api.post('image-upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getRecommendedCompanies = createAsyncThunk(
  'companies/get-all',
  async (_, thunkAPI) => {
    try {
      const res = await api.get('companies/get-all');
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const searchVendors = createAsyncThunk(
  'vendors/search',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post('vendors/search', credentials);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getDeals = createAsyncThunk(
  'deals/get-all',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post('deals/get-all', credentials);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const submitSuggestion = createAsyncThunk(
  'general-remarks',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post('general-remarks', credentials);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getUsers = createAsyncThunk(
  'companies/get-users',
  async (_, thunkAPI) => {
    try {
      const res = await api.post('companies/get-users');
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const removeUser = createAsyncThunk(
  'companies/remove-user',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.delete(
        `companies/remove-user?userId=${credentials}`
      );
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
