import { Fragment, useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa6';
import { getAddressFromLocation } from '../../../utils/utils';
import { MechanicInformationModal } from '../../../components/profile/FleetDashboardNavigation/MyTechniciansTab/components/MechanicInformationModal';

export const MechanicCardBasic = ({ user, color }) => {
  const ratings = user.ratingsReceived;
  const [rating, setRating] = useState(0);
  const [address, setAddress] = useState('');
  const [isMechanicInformationModalOpen, setIsMechanicInformationModalOpen] =
    useState(false);

  useEffect(() => {
    if (ratings && ratings.length > 0) {
      const scores = ratings.map(rating => rating?.score || 0);
      const sum = scores.reduce((sum, score) => sum + score, 0);
      const averageScore = (sum / scores.length).toFixed(1);

      setRating(averageScore);

      const fetchAddress = async () => {
        const addressFromLocation = await getAddressFromLocation({
          locationLat: user.locationLat,
          locationLong: user.locationLong,
        });
        //remove country and postal code from address
        setAddress(
          addressFromLocation.replace(
            /, (\w{2}) \d{5}(?:-\d{4})?,\s*\w+$/,
            ', $1'
          )
        );
      };

      fetchAddress();
    }
  }, [ratings]);

  return (
    <Fragment>
      <div className="w-full flex mb-5">
        {user?.profileImage?.url ? (
          <img
            src={user.profileImage?.url}
            alt="User"
            className="mr-[10px] w-[60px] h-[60px] rounded-[10px] bg-accent-2 object-cover"
          />
        ) : (
          <div className=" flex justify-center items-center rounded-[10px] bg-accent-2 w-[73px] h-[60px] mr-[10px] ">
            <FaUser className="fill-accent-1" size={40} />
          </div>
        )}
        <div className="flex w-full items-flex-start justify-between flex-col xl:flex-row">
          <div className="mr-[3px] mb-2">
            <div className="flex gap-x-2 items-center ">
              <p className="text-base font-medium max-w-[145px]">
                {user.firstName} {user.lastName}
              </p>
              <div
                style={{ backgroundColor: color }}
                className="h-3 w-3 rounded-full"
              ></div>
            </div>
            <p className="text-primary-3 text-sm max-w-[145px]">
              {address ? address : 'unknown'}
            </p>
          </div>

          <div className="align-right ">
            <div className="flex items-center mb-1">
              <FaStar className="text-base text-accent-1 w-[12px] h-[12px]" />
              <span className="text-[14px] ml-[3px]">
                {rating} ({user?.jobCount} Jobs)
              </span>
            </div>

            {/* {color === 'red' ? (
              <button
                type="button"
                onClick={() => handleView(user, false)}
                className="w-max text-[15px] px-[14px] py-[7px] rounded-[8px] border border-solid border-gray-300 focus:outline-none font-medium"
              >
                View Details
              </button>
            ) : (
              <button
                type="button"
                onClick={() => handleView(user, false)}
                className="w-max text-[15px] px-[15px] py-[7px] rounded-[8px] border border-solid border-gray-300 focus:outline-none font-medium"
              >
                View Profile
              </button>
            )} */}

            <button
              type="button"
              onClick={() => setIsMechanicInformationModalOpen(true)}
              className="w-max text-[15px] px-[15px] py-[7px] rounded-[8px] border border-solid border-gray-300 focus:outline-none font-medium"
            >
              View Profile
            </button>
          </div>
        </div>
      </div>
      {isMechanicInformationModalOpen && (
        <MechanicInformationModal
          isOpen={isMechanicInformationModalOpen}
          setIsOpen={setIsMechanicInformationModalOpen}
          mechanic={user}
          rating={rating}
        />
      )}
    </Fragment>
  );
};
