import { Modal } from 'antd';
import { Button } from 'component-library';

/**
 * Renders a modal for sending an invitation to a mechanic.
 *
 * @component
 * @param {boolean} isOpen - Determines whether the modal is open or not.
 * @param {function} setIsOpen - Callback function to set the open state of the modal.
 * @param {function} handleSendInvite - Callback function to handle sending the invitation.
 * @returns {JSX.Element} The rendered modal component.
 */
export const InviteMechanicModal = ({
  isOpen,
  setIsOpen,
  handleSendInvite,
}) => {
  return (
    <Modal
      title=""
      open={isOpen}
      onCancel={() => {
        setIsOpen(false);
      }}
      footer={false}
      zIndex={1002}
      width={452}
      className="font-poppins "
      transitionName=""
      destroyOnClose
    >
      <div className="flex flex-col w-full items-center justify-center gap-y-6 transition-all py-2 ">
        <img
          src="https://mymechanicimages.s3.amazonaws.com/web-assets/service-provider/invite-modal-icon.svg"
          alt="main_icon"
        />
        <div className="flex flex-col w-full gap-y-3 items-center">
          <span className="text-[26px] leading-10 font-semibold text-center">
            Send an Invite
          </span>
          <span className="text-base font-normal text-center">
            Mechanic will receive an invitation link via SMS
          </span>
        </div>
        <Button title="Send Invite" onClick={handleSendInvite} />
      </div>
    </Modal>
  );
};
