/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Drawer, Popover } from 'antd';
import GoogleMap from 'google-maps-react-markers';
import { defaultStyle } from './mapStyles';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { dashboardKeys } from 'constants/dashboard/tabs';
import useWindowSize from 'hooks/useWindowSize';
import { AnimatePresence, motion } from 'framer-motion';
import { IoArrowBackOutline } from 'react-icons/io5';
import { useQuery } from 'react-query';
import {
  DriverBreakdownSideBar,
  FleetDashboardNavigation,
  FleetDashboardTopNav,
  LogoLoader,
} from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { isArray, isEmpty } from 'lodash';
import { storeDrivers } from 'redux/fleet/fleet.slice';
import { listDrivers } from 'redux/fleet/operations';
import { selectViewDriverLocation } from 'redux/fleet/selectors';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import { MechanicDetails } from './MechanicDetails';
import { MarkerDetails } from './MarkerDetails';
import { MarkerUnit } from './MarkerUnit';
import { ShopDetails } from './ShopDetails';
import useSocket from 'hooks/useSocket';
import { VerificationModal } from 'components/verification/Modal';
import { NotificationModal } from 'components/notification/Modal';

const MapMarker = forwardRef(
  (
    {
      index,
      data,
      onMarkerContentClick,
      markerIndexSelected,
      onMarkerClick,
      driver,
      vendors,
    },
    ref
  ) => {
    const [isBrokenDown, setIsBrokenDown] = useState(false);

    useEffect(() => {
      if (data) {
        if (isArray(data.vehicles)) {
          setIsBrokenDown(
            data.vehicles.some(vehicle => vehicle.isBrokenDown ?? false)
          );
        }
      }
    }, []);
    return (
      <Popover
        content={
          <MarkerDetails
            data={data}
            index={index}
            onClick={onMarkerContentClick}
          />
        }
        title={false}
        overlayStyle={{
          borderRadius: '15px',
        }}
        overlayInnerStyle={{
          padding: '0.7rem 1rem',
        }}
        trigger="click"
        zIndex={1000}
        open={markerIndexSelected === index}
      >
        <button
          className={`w-[48px] h-[48px] flex items-center justify-center rounded-[50%] ${
            isBrokenDown ? 'bg-red-500/40 animate-pulse' : 'bg-accent-3/40'
          }`}
          onClick={onMarkerClick}
          ref={ref}
        >
          <div
            className={`w-8 h-8 ${
              isBrokenDown ? 'bg-red-500' : 'bg-accent-1'
            } rounded-[50%] flex items-center justify-center`}
          >
            <span className="text-[15px] text-white font-medium">
              {index + 1}
            </span>
          </div>
        </button>
      </Popover>
    );
  }
);

const VendorMarker = ({ index, onMarkerClick }) => {
  return (
    <button
      className={`w-[48px] h-[48px] flex items-center justify-center rounded-[50%] ${'bg-accent-3/40'}`}
      onClick={onMarkerClick}
    >
      <div
        className={`w-8 h-8 ${'bg-accent-1'} rounded-[50%] flex items-center justify-center`}
      >
        <span className="text-[15px] text-white font-medium">{index + 1}</span>
      </div>
    </button>
  );
};

const Dashboard = () => {
  const [location, setLocation] = useState({
    lat: 32.734666,
    lng: -117.1151485,
  });
  // const [mapHeight, setMapHeight] = useState(window.innerHeight);
  // const sideNavigationRef = useRef();
  const topNavigationRef = useRef();
  const dispatch = useDispatch();
  const {
    isMobile,
    isSmallTablet,
    isMediumTablet,
    isTablet,
    isSmallDesktop,
    isDesktop,
  } = useWindowSize();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [drivers, setDrivers] = useState([]);
  const [isMechanicDetailsOpen, setIsMechanicDetailsOpen] = useState(false);
  const [driverSelected, setDriverSelected] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [markerIndexSelected, setMarkerIndexSelected] = useState(null);
  const [vendorIndexSelected, setVendorIndexSelected] = useState(null);
  const [markerRefs, setMarkerRefs] = useState([]);
  const [mapZoom, setMapZoom] = useState(14);
  const [fullScreenMap, setFullScreenMap] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const mapRef = useRef(null);
  const viewDriverLocation = useSelector(selectViewDriverLocation);
  useSocket();

  useEffect(() => {
    setActiveTab(searchParams.get('tab') ?? dashboardKeys.home);
  }, [searchParams]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleClickOutside = event => {
      if (markerIndexSelected !== null) {
        if (markerRefs[markerIndexSelected]) {
          if (
            markerRefs[markerIndexSelected].current &&
            !markerRefs[markerIndexSelected].current.contains(event.target)
          ) {
            setMarkerIndexSelected(null);
          }
        }
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [markerRefs, markerIndexSelected, vendors]);

  function success(position) {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;
    setLocation({ lat, lng });
  }

  const { isFetching: isPageLoading, data } = useQuery(
    'fleet-drivers',
    async () => {
      const result = await dispatch(listDrivers());
      return result.payload.data;
    },
    { refetchInterval: 2 * 60 * 1000, refetchIntervalInBackground: false }
  );

  useEffect(() => {
    if (!isPageLoading) {
      if (data?.fleetDrivers?.length) {
        const parsedDrivers = data?.fleetDrivers.map((driver, index) => ({
          ...driver,
          index,
          isRenderedOnMap: driver.locationLat && driver.locationLong,
        }));
        setDrivers(parsedDrivers);
        dispatch(storeDrivers(parsedDrivers));

        setMarkerRefs(prev =>
          Array(data.fleetDrivers.length)
            .fill()
            .map((_, i) => prev[i] || createRef())
        );
        let count = 0;

        const totalCoords = data.fleetDrivers.reduce(
          (prev, curr) => {
            if (curr.locationLong && curr.locationLat) {
              count += 1;
            }
            return {
              longTotal: prev.longTotal + (curr.locationLong || 0),
              latTotal: prev.latTotal + (curr.locationLat || 0),
            };
          },
          {
            longTotal: 0,
            latTotal: 0,
          }
        );

        const average = {
          lat: totalCoords.latTotal / count,
          lng: totalCoords.longTotal / count,
        };
        setLocation(average);
      } else {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(success);
        } else {
          console.log('Geolocation not supported');
        }
        if (isPageLoading) {
          toast('You do not have any drivers in your fleet');
        }
      }
    }
  }, [data?.fleetDrivers, isPageLoading]);

  const onGoogleApiLoaded = ({ map }) => {
    setLoading(true);
    mapRef.current = map;
  };

  useEffect(() => {
    if (!isEmpty(viewDriverLocation)) {
      mapRef.current.setCenter(viewDriverLocation);
      setMapZoom(20);
    }
  }, [viewDriverLocation]);

  const toggleFullScreenMap = () => {
    setFullScreenMap(!fullScreenMap);
  };
  if (isPageLoading) {
    return <LogoLoader />;
  }
  return (
    <div className="w-full flex flex-col lg:flex-row items-start min-h-screen">
      <NotificationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      {!fullScreenMap && (
        <>
          <FleetDashboardTopNav
            unitSelected={driverSelected}
            setUnitSelected={setDriverSelected}
            units={drivers}
            onVendorSearch={vendorsFound => {
              setVendors(vendorsFound);
              if (vendorsFound.length) {
                setMapZoom(8);
              }
            }}
            vendors={vendors}
            ref={topNavigationRef}
          />
          <div className="w-[16%] hidden lg:block  overflow-y-auto h-screen ">
            {!isMobile && !isSmallTablet && !isMediumTablet && !isTablet && (
              <FleetDashboardNavigation
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                // ref={sideNavigationRef}
              />
            )}
          </div>
          <Drawer
            title={false}
            placement="right"
            width={'80vw'}
            closable={false}
            open={isMechanicDetailsOpen}
            zIndex={1001}
            onClose={() => {
              setIsMechanicDetailsOpen(false);
            }}
            styles={{
              body: {
                padding: 0,
              },
            }}
            destroyOnClose
          >
            <DriverBreakdownSideBar
              unitSelected={driverSelected}
              setUnitSelected={setDriverSelected}
              units={drivers}
              onVendorSearch={vendorsFound => {
                setVendors(vendorsFound);
                if (vendorsFound.length) {
                  setMapZoom(8);
                }
              }}
              vendors={vendors}
              setVendors={() => {
                setVendors([]);
              }}
            />
          </Drawer>
        </>
      )}

      <div
        style={{
          //  height: mapHeight,
          width: fullScreenMap
            ? '100%'
            : isSmallDesktop || isDesktop
            ? activeTab === dashboardKeys.home
              ? '56%'
              : '86%'
            : '100%',
        }}
        className={`transition-all duration-200 relative h-screen`}
      >
        {!isMobile && (
          <button
            onClick={toggleFullScreenMap}
            className="absolute bottom-[5%] left-[10px] z-[1] flex items-center p-2 rounded-[3px] bg-white"
            title={fullScreenMap ? 'Exit Full Screen' : 'Full Screen'}
          >
            {fullScreenMap ? (
              <MdFullscreenExit size={30} color="#666666" />
            ) : (
              <MdFullscreen size={30} color="#666666" />
            )}
          </button>
        )}

        {vendors.length > 0 && activeTab === dashboardKeys.home && (
          <button
            onClick={() => {
              setVendors([]);
            }}
            className="absolute top-[5%] left-[2%] z-[1] flex gap-x-4 items-center"
          >
            <div className="flex items-center justify-center p-2 rounded-[50%] bg-white">
              <IoArrowBackOutline className="text-[24px] font-thin" />
            </div>
            <div className="py-2 px-4 text-center rounded-lg bg-white font-medium">
              Show Other Units
            </div>
          </button>
        )}

        <GoogleMap
          status={loading ? 'ready' : 'loading'}
          loadScriptExternally
          defaultCenter={location}
          options={{
            styles: defaultStyle,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            clickableIcons: false,
            disableDoubleClickZoom: true,
            zoom: mapZoom,
          }}
          onGoogleApiLoaded={onGoogleApiLoaded}
          loadingContent={<LogoLoader />}
        >
          {drivers
            .filter(driver => {
              if (vendors.length) {
                return driverSelected.id === driver.id;
              } else {
                return driver.isRenderedOnMap;
              }
            })
            .map(driver => {
              return (
                <div
                  lat={driver.locationLat}
                  lng={driver.locationLong}
                  index={driver.index}
                  key={driver.id}
                >
                  <MapMarker
                    lat={driver.locationLat}
                    lng={driver.locationLong}
                    index={driver.index}
                    data={driver}
                    key={driver.id}
                    ref={markerRefs[driver.index]}
                    onMarkerContentClick={() => {
                      if (isMobile) {
                        setIsMechanicDetailsOpen(true);
                      }
                      setDriverSelected({ ...driver });
                    }}
                    onMarkerClick={() => {
                      // if (vendors.length <= 0) {
                      if (markerIndexSelected !== driver.index) {
                        setMarkerIndexSelected(driver.index);
                      } else {
                        setMarkerIndexSelected(null);
                      }
                      // }
                    }}
                    markerIndexSelected={markerIndexSelected}
                    vendors={vendors}
                    driver={driverSelected}
                  />
                  <MarkerUnit index={driver.index} />
                </div>
              );
            })}

          {vendors.length > 0 && !vendors[0].mechanic.type
            ? vendors.map((vendor, index) => {
                return (
                  <div
                    key={vendor.mechanic.id}
                    lat={vendor.mechanic.locationLat}
                    lng={vendor.mechanic.locationLong}
                  >
                    <VendorMarker
                      key={vendor.mechanic.id}
                      lat={vendor.mechanic.locationLat}
                      lng={vendor.mechanic.locationLong}
                      index={index}
                      data={vendor}
                      onMarkerContentClick={() => {}}
                      onMarkerClick={() => {
                        // if (vendors.length <= 0) {
                        if (vendorIndexSelected !== index) {
                          setVendorIndexSelected(index);
                        } else {
                          setVendorIndexSelected(null);
                        }
                        // }
                      }}
                    />
                    {vendorIndexSelected === index && (
                      <MechanicDetails
                        key={index}
                        data={{
                          ...vendor,
                          ...vendor.mechanic,
                        }}
                        index={index}
                        onClick={() => {
                          setVendorIndexSelected(false);
                        }}
                      />
                    )}
                  </div>
                );
              })
            : vendors.map((vendor, index) => {
                return (
                  <div
                    key={vendor.mechanic.id}
                    lat={vendor.mechanic.locationLat}
                    lng={vendor.mechanic.locationLong}
                  >
                    <VendorMarker
                      key={vendor.mechanic.id}
                      lat={vendor.mechanic.locationLat}
                      lng={vendor.mechanic.locationLong}
                      index={index}
                      data={vendor}
                      onMarkerContentClick={() => {}}
                      onMarkerClick={() => {
                        // if (vendors.length <= 0) {
                        if (vendorIndexSelected !== index) {
                          setVendorIndexSelected(index);
                        } else {
                          setVendorIndexSelected(null);
                        }
                        // }
                      }}
                    />
                    {vendorIndexSelected === index && (
                      <ShopDetails
                        key={index}
                        data={vendor}
                        index={index}
                        onClick={() => {
                          setVendorIndexSelected(false);
                        }}
                      />
                    )}
                  </div>
                );
              })}
        </GoogleMap>
      </div>

      {!fullScreenMap && activeTab === dashboardKeys.home && (
        <AnimatePresence mode="sync">
          <motion.div
            initial={{ x: 1000 }}
            animate={{ x: 0 }}
            exit={{ x: 1000 }}
            transition={{ bounce: 0, duration: 0.1 }}
            // className="h-screen overflow-y-scroll scrollbar-t"
            className=" overflow-y-auto h-screen  w-[30%]"
          >
            <DriverBreakdownSideBar
              unitSelected={driverSelected}
              setUnitSelected={setDriverSelected}
              units={drivers}
              onVendorSearch={vendorsFound => {
                setVendors(vendorsFound);
                if (vendorsFound.length) {
                  setMapZoom(8);
                }
              }}
              vendors={vendors}
              setVendors={() => {
                setVendors([]);
              }}
            />
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};

export default Dashboard;
