// import { Button, ProfileInput } from 'component-library';
import { IoIosHeartEmpty } from 'react-icons/io';
import { favorites } from 'dummy-data/profile/favorites';
import React from 'react';
import { FaShop, FaStar } from 'react-icons/fa6';

export const FavoriteTile = ({ data }) => {
  const lastName = data.lastName ? data.lastName : '';
  const name = data.name ? data.name : data.firstName + ' ' + lastName;
  const ratings = data?.ratingsReceived || [];
  const allScores = ratings.map(rating => rating.score);
  const averageScore =
    allScores.length > 0
      ? (allScores.reduce((a, b) => a + b) / allScores.length).toFixed(1)
      : 0;

  const shortName = name => {
    if (name.length > 25) {
      return name.substring(0, 25) + '...';
    } else {
      return name;
    }
  };

  return (
    <div className="w-full relative">
      <button className="absolute top-2 right-2 text-2xl">
        <IoIosHeartEmpty className="text-white" />
      </button>
      {data.images[0]?.url ? (
        <img
          src={data.images[0]?.url}
          alt=""
          className="rounded-2xl  object-cover h-[30vh]  w-full"
        />
      ) : (
        <div className="h-[30vh] rounded-2xl object-cover flex justify-center items-center bg-accent-2">
          <FaShop className="h-[120px] w-[120px] fill-accent-1" />
        </div>
      )}

      <div className="flex flex-col gap-y-2 min-h-[12vh] mt-4">
        <div className="flex justify-between">
          <span className="text-sm font-semibold">{shortName(name)}</span>
          {data.ratingsReceived ? (
            <div className="flex items-center gap-x-1 text-sm">
              <FaStar />
              <span>{averageScore}</span>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="max-w-[70%] text-primary-3 text-sm">{data.address}</div>
      </div>
    </div>
  );
};

// const filters = {
//   rating: {
//     label: 'Rating',
//     value: 'rating',
//   },
//   breakdown: {
//     label: 'Type of Breakdown',
//     value: 'breakdown',
//   },
// };

const Filters = () => {
  // const [filter, setFilter] = useState();
  // return (
  //   <RadioGroup value={plan} onChange={setPlan}>
  //     <RadioGroup.Label>Plan</RadioGroup.Label>
  //     <RadioGroup.Option value="startup">
  //       {({ checked }) => (
  //         <span className={checked ? 'bg-blue-200' : ''}>Startup</span>
  //       )}
  //     </RadioGroup.Option>
  //     <RadioGroup.Option value="business">
  //       {({ checked }) => (
  //         <span className={checked ? 'bg-blue-200' : ''}>Business</span>
  //       )}
  //     </RadioGroup.Option>
  //     <RadioGroup.Option value="enterprise">
  //       {({ checked }) => (
  //         <span className={checked ? 'bg-blue-200' : ''}>Enterprise</span>
  //       )}
  //     </RadioGroup.Option>
  //   </RadioGroup>
  // );
  return <></>;
};

const Favorites = () => {
  return (
    <div className="px-8 sm:pl-6 py-8 md:py-6 flex flex-col gap-y-8 sm:gap-y-12 w-full pb-12">
      <h2 className="text-[32px] sm:text-[40px] font-semibold">Favorites</h2>
      <Filters />
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-8 lg:gap-x-12 gap-y-8 sm:gap-y-10">
        {favorites.map(favorite => (
          <FavoriteTile data={favorite} />
        ))}
      </div>
    </div>
  );
};

export default Favorites;
