import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import api from 'api';
import { formatDateTime } from 'helpers/common.helpers';
import { Button } from 'component-library';

export const Notes = ({ notes }) => {
  return (
    <div className="flex flex-col w-full items-center justify-center gap-y-4 transition-all py-2">
      <div className="flex flex-col w-full gap-y-3 items-center">
        <span className="text-[26px] text-primary-2 font-semibold text-center">
          Notes
        </span>
      </div>
      <div className="bg-grey-1 rounded-[10px] max-h-[400px] overflow-auto p-3 w-full">
        {notes}
      </div>
    </div>
  );
};
