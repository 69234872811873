import { FaStar, FaUser } from 'react-icons/fa';
import { AiFillDollarCircle } from 'react-icons/ai';
import { Fragment } from 'react';
import { statusMarkerColors } from 'constants/style.constants';

/**
 * MechanicInfo Component
 *
 * This component displays detailed information about a mechanic, including their
 * profile image, name, status, rating, hourly rate, and distance.
 *
 * @param {Object} props - The component props
 * @param {Object} props.mechanic - The mechanic's information
 * @param {number} props.rating - The mechanic's overall rating
 * @param {Array} props.ratings - Array of rating objects for the mechanic
 * @param {number} props.distance - Distance to the mechanic's location
 *
 * @returns {JSX.Element} The MechanicInfo component
 */
export const MechanicInfo = ({ mechanic, rating, ratings, distance }) => (
  <div className="flex w-full gap-2">
    {mechanic.profileImage ? (
      <img
        className="w-[59px] h-[59px] rounded-md object-cover"
        alt="profile"
        src={mechanic.profileImage.url}
      />
    ) : (
      <div className=" flex justify-center items-center w-[59px] h-[59px] rounded-[10px] bg-accent-2 mr-1">
        <FaUser className="fill-accent-1" size={40} />
      </div>
    )}
    <div className="flex flex-col">
      <div className="flex items-center gap-1">
        <p className="text-primary-2 text-base font-medium">
          {`${mechanic.firstName} ${mechanic.lastName}`}
        </p>
        <div
          style={{
            backgroundColor: statusMarkerColors[mechanic.status],
          }}
          className="h-3 w-3 rounded-full"
        ></div>
      </div>

      <div className="flex space-x-2 items-center">
        {mechanic.rate && (
          <Fragment>
            <div className="flex items-center gap-x-2">
              <FaStar className="text-base text-accent-1" />
              <span className="text-sm">
                {rating}
                <span className=" text-primary-3 text-xs font-normal ml-1">
                  ({ratings?.length} reviews)
                </span>
              </span>
            </div>
            <div className="flex items-center gap-x-1">
              <AiFillDollarCircle className="text-base text-accent-1" />
              <span className=" text-sm">
                {mechanic.rate}$
                <span className=" text-primary-3 text-xs font-normal ml-1">
                  /1h
                </span>
              </span>
            </div>
          </Fragment>
        )}
      </div>
      <span className="text-sm text-grey-2">{distance} miles away</span>
    </div>
  </div>
);
