import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Drawer } from 'antd';
import { RxHamburgerMenu } from 'react-icons/rx';
import { useSearchParams } from 'react-router-dom';
import logo from '../../../assets/logo.svg';
import useWindowSize from 'hooks/useWindowSize';
// import { dashboardKeys } from 'constants/dashboard/tabs';
import { FleetDashboardNavigation } from '..';

export const FleetDashboardTopNav = forwardRef(({unitSelected ,setUnitSelected,units,onVendorSearch,vendors,}, ref) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isTablet, isMobile, isSmallTablet, isMediumTablet } = useWindowSize();
  const [, setSearchParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState();
  const [searchParams] = useSearchParams();

  const navRef = useRef();

  useEffect(() => {
    if (
      (isTablet || isMobile || isSmallTablet || isMediumTablet) &&
      searchParams.get('tab')
    ) {
      setIsMenuOpen(true);
    }
    if (searchParams) {
      setActiveTab(searchParams.get('tab'));
    }
    if (!isTablet && !isMobile && !isSmallTablet && !isMediumTablet) {
      setIsMenuOpen(false);
    }
  }, [searchParams, isTablet, isMobile, isSmallTablet, isMediumTablet]);
  return (
    <div
      ref={ref}
      className="min-h-[61px] bg-white flex items-center justify-between py-4 px-4 sm:px-10 lg:hidden w-full"
    >
      <img
        src={logo}
        alt="MyMechanic logo"
        className="w-[150px] h-[29px] sm:w-[180px] sm:h-[39px]"
      />
      <button
        onClick={() => {
          setIsMenuOpen(true);
        }}
      >
        <RxHamburgerMenu className="text-3xl" />
      </button>
      <Drawer
        title={false}
        placement="left"
        width={'45vw'}
        closable={false}
        open={isMenuOpen}
        zIndex={1000}
        onClose={() => {
          // setActiveTab(dashboardKeys.home);
          // setSearchParams({ tab: dashboardKeys.home });
          setIsMenuOpen(false);
          searchParams.delete('tab');
          setSearchParams(searchParams);
        }}
        styles={{
          body: {
            padding: 0,
          },
        }}
        destroyOnClose
      >
        <FleetDashboardNavigation
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isSideBar
          unitSelected={unitSelected}
          setUnitSelected={setUnitSelected}
          units={units}
          onVendorSearch={onVendorSearch}
          vendors={vendors}
          ref={navRef}
        />
      </Drawer>
    </div>
  );
});
