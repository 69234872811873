/**
 * Renders the navigation tabs component.
 * @param {string} activeTab - The currently active tab.
 * @param {function} setActiveTab - The function to set the active tab.
 * @returns {JSX.Element} The navigation tabs component.
 */
export const NavigationTabs = ({ activeTab, setActiveTab }) => (
  <div className="flex w-full bg-grey-4 p-[1px] rounded-lg items-center h-[37px]">
    <button
      className={`w-full rounded-lg text-[13px] leading-5 h-full ${
        activeTab === 'personal'
          ? 'border-[0.5px] border-accent-1 bg-white text-accent-1'
          : 'border-[0.5px] border-transparent bg-transparent text-primary-2'
      }`}
      onClick={() => {
        setActiveTab('personal');
      }}
    >
      Personal Information
    </button>
    <button
      className={`w-full rounded-lg text-[13px] leading-5 h-full ${
        activeTab === 'profile'
          ? 'border-[0.5px] border-accent-1 bg-white text-accent-1'
          : 'border-[0.5px] border-transparent bg-transparent text-primary-2'
      }`}
      onClick={() => {
        setActiveTab('profile');
      }}
    >
      Employee Profile
    </button>
    <button
      className={`w-full rounded-lg text-[13px] leading-5 h-full ${
        activeTab === 'history'
          ? 'border-[0.5px] border-accent-1 bg-white text-accent-1'
          : 'border-[0.5px] border-transparent bg-transparent text-primary-2'
      }`}
      onClick={() => {
        setActiveTab('history');
      }}
    >
      Service history
    </button>
  </div>
);
