import { Rate } from 'antd';
import React from 'react';
import { FaShop } from 'react-icons/fa6';

import { IoMdClose } from 'react-icons/io';

export const ShopDetails = ({ data, index, onClick }) => {
  return (
    <div
      role="tooltip"
      className="absolute  shadow-lg  group-hover:block bg-[white] text-primary-2 pr-12 pb-5 font-semibold px-5 py-3 text-[13px] right-10  mx-auto w-max -top-36 rounded-2xl before:w-4 before:h-4 before:rotate-45 before:bg-[white] before:absolute  before:-bottom-1 before:left-0  before:right-0 before:mx-auto before:z-10 left-[-100px]"
    >
      <button
        // disabled={!isMobile && !isTablet}
        onClick={onClick}
        className="flex items-center"
      >
        <div className="flex gap-x-4">
          <div className="flex flex-col justify-start items-start">
            {data.mechanic.images[0] ? (
              <img
                src={data.mechanic.images[0].url}
                alt={data.mechanic.name}
                className="w-[60px] h-[60px] object-cover rounded-[3px]"
              />
            ) : (
              <div className="w-[60px] h-[60px] rounded-[3%] object-cover flex justify-center items-center bg-accent-2">
                <FaShop className="h-[30px] w-[30px] fill-accent-1" />
              </div>
            )}
            <span className="text-accent-1 text-sm mt-[5px]">
              {data?.distance.toFixed(2)}mi
            </span>
          </div>
          <div className="flex flex-col items-start ">
            <span className="font-medium text-sm">
              {index + 1}. {data.mechanic.name}
            </span>
            <span className="font-medium text-sm">
              {' '}
              {data.mechanic.rate ? `$ ${data.mechanic.rate}` : 'not specified'}
            </span>
            <div className="flex items-center mb-[3px]">
              <Rate
                disabled
                defaultValue={
                  data?.mechanic.ratingsReceived?.reduce((prev, curr) => {
                    return prev + curr.score || 0;
                  }, 0) / (data?.mechanic.ratingsReceived.length || 1) || 0
                }
                allowHalf
                style={{ color: '#2D6DCA' }}
                className="text-lg"
              />
              <span className="text-semibold text-sm text-secondary-1 pl-1">
                ({data?.mechanic.jobCount})
              </span>
            </div>
            <span className="font-normal text-xs text-secondary-1 text-left max-w-[180px]">
              {data.mechanic.address}
            </span>
          </div>
        </div>

        <div className="absolute right-2 top-2">
          <IoMdClose color="#B8B8B8" height={10} width={10} />
        </div>
      </button>
      <div className="tooltip-arrow" data-popper-arrow></div>
    </div>
  );
};
