import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FaCheck } from 'react-icons/fa6';

const Button = ({
  type = 'button',
  size = 'md',
  variant = 'primary',
  title = '',
  classNames = '',
  prefix = '',
  suffix = '',
  loading,
  disabled,
  selected,
  ...rest
}) => {
  let styles = '';
  switch (variant) {
    case 'primary':
      styles += `bg-accent-1 text-white border-transparent border  ${
        disabled
          ? 'bg-accent-3'
          : 'hover:text-accent-1 hover:bg-accent-2 hover:border-accent-2'
      }`;
      break;
    case 'secondary':
      styles += `bg-green-1 text-white border-transparent border  ${
        disabled ? 'bg-green-2' : 'hover:text-green-1 hover:bg-green-2'
      }`;
      break;
    case 'danger':
      styles +=
        'bg-error-2 text-primary-1 border-transparent hover:bg-error-4 hover:text-error-2';
      break;
    case 'grey':
      styles +=
        'border-transparent bg-accent-2 text-accent-1 hover:bg-accent-1 hover:text-white ';
      break;
    case 'text':
      styles += 'text-center hover:text-accent-1';
      break;
    case 'transparent-text':
      styles +=
        'text-sm text-accent-1 border-[1px] border-accent-1 px-3 py-1 rounded-3xl h-[28px]';
      break;
    case 'select':
      styles += `text-[15px] font-medium rounded-lg bg-accent-2 h-fit py-3 ${
        selected ? '!bg-accent-1' : ''
      }`;
      break;
    default:
      break;
  }

  switch (size) {
    case 'lg':
      styles +=
        ' rounded-[15px] font-medium text-[16px] py-[14px] min-h-[44px]';
      break;
    case 'md':
      styles +=
        ' rounded-[15px] font-medium text-[16px] py-[14px] px-4 min-h-[44px]';
      break;
    case 'default':
      styles += '';
      break;
    case 'rounded':
      styles += ' rounded-[60px] font-medium text-[16px] px-4 h-[48px] !w-1/2';
      break;
    default:
      break;
  }

  return (
    <button
      type={type}
      {...rest}
      className={`${styles} w-full transition-all duration-200 flex text-center justify-center items-center gap-x-2 ${classNames} ${
        loading || disabled ? 'opacity-90 cursor-not-allowed ' : ''
      }`}
      disabled={loading || disabled}
    >
      {loading ? (
        <AiOutlineLoading3Quarters className="animate-spin" />
      ) : (
        <>
          {prefix}
          {selected ? <FaCheck className="fill-white h-[24px]" /> : title}
          {suffix}
        </>
      )}
    </button>
  );
};

export default Button;
