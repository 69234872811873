export const dummyRequests = [
  {
    requestOwner: {
      firstName: 'John',
      lastName: 'Doe',
      images: [
        {
          url: 'https://res.cloudinary.com/dev-nexus/image/upload/v1662301313/images/circul/avatars/rc-upload-1662301138447-2.jpg',
        },
      ],
    },
    images: [],
    address: '11801 C F Hawn Fwy Dallas, TX',
    vin: '1234VIBES',
    createdAt: '2024-05-26T16:00:00',
    id: 123,
    status: 'SUBMITTED_TO_FLEET_MANAGER',
    description: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum id ab ea,
    reiciendis modi unde sint officiis, maxime odit magni tempora blanditiis vitae
    dicta. Blanditiis quos labore quia. Quam, suscipit.`,
    type: 'truck_repair',
  },
  {
    requestOwner: {
      firstName: 'John',
      lastName: 'Doe',
      images: [
        {
          url: 'https://res.cloudinary.com/dev-nexus/image/upload/v1662301313/images/circul/avatars/rc-upload-1662301138447-2.jpg',
        },
      ],
    },
    images: [],
    address: '11801 C F Hawn Fwy Dallas, TX',
    vin: '1234VIBES',
    createdAt: '2024-05-26T16:00:00',
    id: 1234,
    status: 'IN_PROGRESS',
    description: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum id ab ea,
    reiciendis modi unde sint officiis, maxime odit magni tempora blanditiis vitae
    dicta. Blanditiis quos labore quia. Quam, suscipit.`,
    type: 'tire_repair',
  },
  {
    requestOwner: {
      firstName: 'John',
      lastName: 'Doe',
      images: [
        {
          url: 'https://res.cloudinary.com/dev-nexus/image/upload/v1662301313/images/circul/avatars/rc-upload-1662301138447-2.jpg',
        },
      ],
    },
    images: [],
    address: '11801 C F Hawn Fwy Dallas, TX',
    vin: '1234VIBES',
    createdAt: '2024-05-26T16:00:00',
    id: 1235,
    status: 'SUBMITTED_TO_FLEET_MANAGER',
    description: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum id ab ea,
    reiciendis modi unde sint officiis, maxime odit magni tempora blanditiis vitae
    dicta. Blanditiis quos labore quia. Quam, suscipit.`,
    type: 'truck_repair',
  },
  {
    requestOwner: {
      firstName: 'John',
      lastName: 'Doe',
      images: [
        {
          url: 'https://res.cloudinary.com/dev-nexus/image/upload/v1662301313/images/circul/avatars/rc-upload-1662301138447-2.jpg',
        },
      ],
    },
    images: [],
    address: '11801 C F Hawn Fwy Dallas, TX',
    vin: '1234VIBES',
    createdAt: '2024-05-26T16:00:00',
    id: 1236,
    status: 'SUBMITTED_TO_FLEET_MANAGER',
    description: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum id ab ea,
    reiciendis modi unde sint officiis, maxime odit magni tempora blanditiis vitae
    dicta. Blanditiis quos labore quia. Quam, suscipit.`,
    type: 'trailer_repair',
  },
  {
    requestOwner: {
      firstName: 'John',
      lastName: 'Doe',
      images: [
        {
          url: 'https://res.cloudinary.com/dev-nexus/image/upload/v1662301313/images/circul/avatars/rc-upload-1662301138447-2.jpg',
        },
      ],
    },
    images: [],
    address: '11801 C F Hawn Fwy Dallas, TX',
    vin: '1234VIBES',
    createdAt: '2024-05-26T16:00:00',
    id: 1237,
    status: 'SUBMITTED_TO_FLEET_MANAGER',
    description: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum id ab ea,
    reiciendis modi unde sint officiis, maxime odit magni tempora blanditiis vitae
    dicta. Blanditiis quos labore quia. Quam, suscipit.`,
    type: 'truck_repair',
  },
];
