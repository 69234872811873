export const ServiceRequestStatusConverter = {
  INITIATED: 'initialized',
  SUBMITTED_TO_FLEET_MANAGER: 'New Request',
  APPROVED_BY_FLEET_MANAGER: 'Approved',
  ASSIGNED_TO_MECHANIC: 'Assigned To Mechanic',
  RECEIVED: 'Received By Mechanic',
  VIEWED: 'Viewed By Mechanic',
  SCHEDULED: 'Scheduled',
  ACCEPTED: 'Accepted By Mechanic',
  IN_PROGRESS: 'In Progress',
  RESCHEDULED: 'Rescheduled',
  REJECTED_BY_MECHANIC: 'Rejected By Mechanic',
  REJECTED: 'Rejected',
  CANCELLED_BY_DRIVER: 'Cancelled By Driver',
  COMPLETED: 'Completed',
  FEEDBACK_PROVIDED_BY_DRIVER: 'Feedback Provided By Driver',
  FEEDBACK_PROVIDED_BY_MECHANIC: 'Feedback Provided By Mechanic',
  FEEDBACK_NOT_PROVIDED_BY_DRIVER: 'Feedback Not Provided By Mechanic',
  FEEDBACK_NOT_PROVIDED_BY_MECHANIC: 'Feedback Not Provided By Mechanic',
  REVIEWED_BY_FLEET_MANAGER: 'Reviewed By Fleet Manager',
  SENT_FOR_REVIEW: 'Sent For Review',
  UNASSIGNED_FROM_MECHANIC: 'Unassigned From Mechanic',
  MECHANIC_ON_THE_WAY: 'Mechanic Enroute',
};
