import { motion } from 'framer-motion';
import useWindowSize from 'hooks/useWindowSize';
import { Skeleton } from 'antd';

const PartsTab = ({ offset }) => {
  const { isMobile, isSmallTablet, isTablet, isMediumTablet } = useWindowSize();
  return (
    <motion.div
      initial={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : -1000,
      }}
      animate={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : offset,
      }}
      exit={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : -1000,
      }}
      transition={{ bounce: 0 }}
      className="h-screen overflow-y-scroll scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-slate-300 sm:p-8 p-4 py-8 min-h-screen absolute w-full sm:w-[60vw] lg:max-w-2xl flex flex-col gap-y-12 rounded-e-2xl left-0 top-0 z-[1000] bg-white"
      style={{ boxShadow: '0px -4px 88px 0px rgba(58, 58, 58, 0.10)' }}
    >
      <div className="flex flex-col h-fit gap-y-16 justify-center">
        <div className="w-full">
          <p className="text-2xl font-semibold text-primary-2 mb-3">
            Coming Soon
          </p>
          <Skeleton
            paragraph={{
              rows: 10,
            }}
            active
          />
        </div>
      </div>
    </motion.div>
  );
};

export default PartsTab;
