import styled from '@emotion/styled';

export const Button = styled.div`
  display: inline-block;
  border-radius: 15px;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.38em;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;

  &:hover,
  &:active {
    color: ${p => `${p.theme.colors.accent}`};
  }
  cursor: pointer;
`;

export const ButtonBlue = styled.button`
  background-color: ${p => `${p.theme.colors.accent}`};
  padding-left: 22px;
  padding-right: 22px;
  color: ${p => `${p.theme.colors.whiteText}`};

  display: inline-block;
  border-radius: 15px;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.38em;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;
  border: 1px solid transparent;

  &:hover,
  &:active {
    color: ${p => `${p.theme.colors.accent}`};
    background-color: transparent;
    border: 1px solid ${p => `${p.theme.colors.accent}`};
  }
  cursor: pointer;
`;

export const modalStyles = {
  width: '30vw',
  '@media (maxWidth: 1024px)': {
    width: '40vw',
  },
  '@media (maxWidth: 767px)': {
    width: '90vw',
  },
};
