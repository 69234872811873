import React, { useEffect, useRef, useState } from 'react';
import logo from '../../../assets/fleet_dashboard_logo.svg';
import {
  dashboardKeys,
  dashboardRepairTabs,
  dashboardFleetTabs,
} from 'constants/dashboard/tabs';
import { useSearchParams } from 'react-router-dom';
import GetTabIcon from './GetTabIcon';
import { MdLogout } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { logout } from 'helpers/auth.helpers';
import { routes } from 'constants/routes.constants';
import { AnimatePresence } from 'framer-motion';
import MyTrucksDrawer from './MyTrucksDrawer/MyTrucksDrawer';
import PastCallsTab from './PastCallsTab';
import Products from './MyProducts/Products';
import NotificationsTab from './NotificationsTab';
import PartsTab from './PartsTab';
import ChatTab from './ChatTab';
import NewRequests from './RequestsTab';
import useWindowSize from 'hooks/useWindowSize';
import { Drawer } from 'antd';
import Support from './Support';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from 'redux/auth/selectors';
import { getProfile, logoutUser } from 'redux/auth/operations';
import { RepairSideBar } from '..';
import { DriverBreakdownSideBar } from '..';
import Settings from './Settings';
import FleetRequests from './FleetRequests';
import useMeasure from 'react-use-measure';
import { storeDrivers } from 'redux/fleet/fleet.slice';
import { useAuth } from 'context/SocketContext';
import { getCompanyPictureCategories } from 'redux/fleet/operations';
import MyTechniciansTab from './MyTechniciansTab';

/**
 *
 * @param {{tab: {key: string, label: string}}} param0
 * @returns
 */
const DashboardTab = ({ tab, switchTabs, isCurrentTab }) => {
  const [overlayHeight, setOverlayHeight] = useState();

  const buttonRef = useRef();

  useEffect(() => {
    if (buttonRef.current) {
      setOverlayHeight(buttonRef.current.clientHeight);
    }
  }, [buttonRef]);
  return (
    <button
      ref={buttonRef}
      onClick={() => {
        switchTabs(tab.key);
      }}
      // disabled
      className={`w-full text-start text-[14px] px-5 py-[14px] ${
        isCurrentTab ? 'bg-accent-2 text-accent-1' : 'bg-white text-primary-3'
      } font-medium transition-all flex items-center gap-x-2 relative`}
    >
      {isCurrentTab ? (
        <div
          className="absolute top-0 w-full left-[-97.5%] rounded-lg bg-accent-1"
          style={{
            height: overlayHeight,
          }}
        ></div>
      ) : (
        ''
      )}
      <GetTabIcon tab={tab} className="text-[20px] font-semibold" />
      {tab.label}
    </button>
  );
};

export const FleetDashboardNavigation = ({
  isSideBar,
  activeTab,
  setActiveTab,
  unitSelected,
  setUnitSelected,
  units,
  onVendorSearch,
  vendors,
}) => {
  const [ref, bounds] = useMeasure();
  const initialized = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [xOffset, setXOffset] = useState(198);
  const [tabsList, setTabsList] = useState(dashboardFleetTabs);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isMobile, isSmallTablet, isMediumTablet, isTablet } = useWindowSize();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { socket } = useAuth();

  //get company' picture categories and set them in Redux state
  const getPictureCategories = async () => {
    try {
      await dispatch(getCompanyPictureCategories());
    } catch (error) {
      console.error('error getting picture categories', { error });
      throw new Error(error.response?.data?.message || 'Request failed');
    }
  };

  useEffect(() => {
    getPictureCategories();
  }, []);

  useEffect(() => {
    if (searchParams.get('tab')) {
      setIsMenuOpen(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!initialized.current && !user?.id) {
      dispatch(getProfile());
      initialized.current = true;
    }
  }, [dispatch, initialized, user]);

  useEffect(() => {
    if (bounds?.width) {
      setXOffset(bounds?.width);
    }
  }, [bounds]);

  useEffect(() => {
    if (user.role === 'REPAIR_SHOP' || user.role === 'REPAIR_SHOP_MANAGER')
      setTabsList(dashboardRepairTabs);
  }, [user]);

  const handleSwitchTabs = tab => {
    setSearchParams({ tab });
    setActiveTab(tab);
    if (
      (isMobile || isSmallTablet || isMediumTablet || isTablet) &&
      [
        dashboardKeys.company,
        dashboardKeys.trucks,
        dashboardKeys.history,
        dashboardKeys.products,
        dashboardKeys.support,
        dashboardKeys.requests,
        dashboardKeys.home,
        dashboardKeys.settings,
        dashboardKeys.technicians,
        dashboardKeys.chat,
        dashboardKeys.notifications,
      ].includes(tab)
    ) {
      setIsMenuOpen(true);
    }
  };

  const navigate = useNavigate();

  return (
    <>
      <div
        ref={ref}
        className="flex flex-col pt-6 items-center w-full bg-white min-h-screen z-[1001] relative"
      >
        <div className="flex flex-col pt-6 items-center w-full gap-y-2">
          <img
            src={logo}
            alt="MyMechanic logo"
            className="w-[121px] h-[92px] sm:w-[121px] sm:h-[92px]"
          />
          <p className="text-18 font-medium text-center">
            {user?.company.name}
          </p>
        </div>
        <div className="w-full mt-[14px] mb-[y14px] h-[1px] bg-grey-1" />
        {/* Tabs */}
        <div className="w-full flex flex-col gap-y-0">
          {tabsList.map((tab, index) => (
            <React.Fragment key={tab.key}>
              <DashboardTab
                tab={tab}
                switchTabs={handleSwitchTabs}
                isCurrentTab={searchParams.get('tab') === tab.key}
              />
              {index === tabsList?.length - 4 && (
                <div className=" mt-[14px] mb-[14px] h-[1px] bg-grey-1"></div>
              )}
            </React.Fragment>
          ))}
          <button
            onClick={() => {
              logout();
              socket.disconnect();
              dispatch(storeDrivers([]));
              dispatch(logoutUser());
              navigate(routes.home, { replace: true });
            }}
            className="flex justify-start items-center gap-x-2 text-[14px] mt-10 text-primary-3 font-medium px-[25px]"
          >
            <MdLogout className="text-[20px]" />
            <span>Sign out</span>
          </button>
        </div>
      </div>
      {isMobile || isSmallTablet || isMediumTablet || isTablet ? (
        <Drawer
          title={false}
          placement="left"
          closable={false}
          open={isMenuOpen}
          zIndex={1000}
          width={isMobile ? '85vw' : '50%'}
          onClose={() => {
            setIsMenuOpen(false);
          }}
          styles={{
            body: {
              padding: 0,
            },
          }}
          destroyOnClose
        >
          {activeTab === dashboardKeys.trucks ? (
            <MyTrucksDrawer offset={xOffset} />
          ) : activeTab === dashboardKeys.history ? (
            <PastCallsTab offset={xOffset} />
          ) : activeTab === dashboardKeys.products ? (
            <Products offset={xOffset} />
          ) : activeTab === dashboardKeys.support ? (
            <Support offset={xOffset} />
          ) : activeTab === dashboardKeys.parts ? (
            <PartsTab offset={xOffset} />
          ) : activeTab === dashboardKeys.chat ? (
            <ChatTab offset={xOffset} />
          ) : activeTab === dashboardKeys.notifications ? (
            <NotificationsTab offset={xOffset} />
          ) : activeTab === dashboardKeys.requests &&
            (user.role === 'REPAIR_SHOP' ||
              user.role === 'REPAIR_SHOP_MANAGER') ? (
            <NewRequests offset={xOffset} />
          ) : activeTab === dashboardKeys.technicians &&
            (user.role === 'REPAIR_SHOP' ||
              user.role === 'REPAIR_SHOP_MANAGER') ? (
            <MyTechniciansTab offset={xOffset} />
          ) : activeTab === dashboardKeys.requests &&
            (user.role === 'FLEET' ||
              user.role === 'FLEET_MANAGER' ||
              user.role === 'FLEET_ADMIN') ? (
            <FleetRequests offset={xOffset} />
          ) : activeTab === dashboardKeys.home ? (
            user.role === 'REPAIR_SHOP' ||
            user.role === 'REPAIR_SHOP_MANAGER' ? (
              <RepairSideBar offset={xOffset} mechanics={[]} />
            ) : (
              <DriverBreakdownSideBar
                unitSelected={unitSelected}
                setUnitSelected={setUnitSelected}
                units={units}
                onVendorSearch={onVendorSearch}
                vendors={vendors}
              />
            )
          ) : activeTab === dashboardKeys.settings ? (
            <Settings offset={xOffset} />
          ) : (
            ''
          )}
        </Drawer>
      ) : isMobile !== undefined ? (
        <AnimatePresence mode="sync">
          {activeTab === dashboardKeys.trucks ? (
            <MyTrucksDrawer offset={xOffset} />
          ) : // ) : activeTab === dashboardKeys.company ? (
          //   <MyCompanyDrawer offset={xOffset} />
          activeTab === dashboardKeys.history ? (
            <PastCallsTab offset={xOffset} />
          ) : activeTab === dashboardKeys.products ? (
            <Products offset={xOffset} />
          ) : activeTab === dashboardKeys.chat ? (
            <ChatTab offset={xOffset} />
          ) : activeTab === dashboardKeys.support ? (
            <Support offset={xOffset} />
          ) : activeTab === dashboardKeys.requests &&
            (user.role === 'REPAIR_SHOP' ||
              user.role === 'REPAIR_SHOP_MANAGER') ? (
            <NewRequests offset={xOffset} />
          ) : activeTab === dashboardKeys.notifications ? (
            <NotificationsTab offset={xOffset} />
          ) : activeTab === dashboardKeys.parts ? (
            <PartsTab offset={xOffset} />
          ) : activeTab === dashboardKeys.technicians &&
            (user.role === 'REPAIR_SHOP' ||
              user.role === 'REPAIR_SHOP_MANAGER') ? (
            <MyTechniciansTab offset={xOffset} />
          ) : activeTab === dashboardKeys.requests &&
            (user.role === 'FLEET' ||
              user.role === 'FLEET_MANAGER' ||
              user.role === 'FLEET_ADMIN') ? (
            <FleetRequests offset={xOffset} />
          ) : activeTab === dashboardKeys.settings ? (
            <Settings offset={xOffset} />
          ) : (
            ''
          )}
        </AnimatePresence>
      ) : (
        <></>
      )}
    </>
  );
};
