import React, { useState } from 'react';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { signUpIndividual } from 'redux/auth/operations';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'component-library';
import { routes } from 'constants/routes.constants';
import { Loader } from 'components/common';

const initialState = {
  email: '',
  password: '',
  confirm: '',
};

export const ForDriver = ({ closeModal }) => {
  const [state, setState] = useState(initialState);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmError, setConfirmError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleInputChange = (name, value, setError) => {
    addInputValue(name, value);
    setState(prevState => ({ ...prevState, [name]: value }));
    setError('');
  };

  const addInputValue = (name, value) => {
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSignIn = async () => {
    let isValid = true;
    // const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=]).*$/;

    if (state.email.trim() === '') {
      setEmailError('Please enter your email address');
      isValid = false;
    } else if (!state.email.match(/\S+@\S+\.\S+/)) {
      setEmailError('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError('');
    }

    const password = state.password;
    if (password.trim() === '') {
      setPasswordError('Please enter a password');
      isValid = false;
    } else if (
      password.length < 8 ||
      !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/.test(password)
    ) {
      setPasswordError(
        'The length of the password must be more than 8 characters, have capital letter, small letter, a number and special character'
      );
      isValid = false;
    } else {
      setPasswordError('');
    }

    if (state.confirm.trim() === '') {
      setConfirmError('Please enter a password');
      isValid = false;
    } else if (state.password !== state.confirm) {
      setConfirmError('Passwords do not match');
      isValid = false;
    } else {
      setConfirmError('');
    }

    if (isValid) {
      register();
    }
  };

  const register = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        signUpIndividual({
          email: state.email,
          password: state.password,
          role: 'DRIVER',
        })
      );
      if (!response.payload.status) {
        toast.info(response.payload.message);
      } else {
        toast.success(response.payload.message);
        closeModal();
        navigate(routes.verifyEmail);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-y-6">
      {loading && <Loader />}
      <p className="text-center">
        Don't waste hours calling stores and towing companies. We will find a
        quick solution to your problem.
      </p>
      <form className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-y-3">
          <Input
            label="Email"
            htmlFor="email"
            name="email"
            id="email"
            value={state.email}
            onChange={e =>
              handleInputChange('email', e.target.value, setEmailError)
            }
            placeholder="Enter your email address"
            error={emailError}
          />
          <Input
            type={showPassword ? 'text' : 'password'}
            name="password"
            id="password"
            value={state.password}
            onChange={e =>
              handleInputChange('password', e.target.value, setPasswordError)
            }
            placeholder="Enter your password"
            label="Password"
            htmlFor="password"
            error={passwordError}
            suffix={
              <button type="button" onClick={togglePasswordVisibility}>
                {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </button>
            }
          />
          <Input
            type={showConfirmPassword ? 'text' : 'password'}
            name="confirm"
            id="confirm"
            label="Confirm Password"
            htmlFor="confirm"
            error={confirmError}
            suffix={
              <button type="button" onClick={toggleConfirmVisibility}>
                {showConfirmPassword ? (
                  <AiOutlineEye />
                ) : (
                  <AiOutlineEyeInvisible />
                )}
              </button>
            }
            value={state.confirm}
            onChange={e =>
              handleInputChange('confirm', e.target.value, setConfirmError)
            }
            placeholder="Re-enter your password"
          />
        </div>
        <Button title="Sign Up" loading={loading} onClick={handleSignIn} />
      </form>
    </div>
  );
};
