import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import useWindowSize from 'hooks/useWindowSize';
import { LogoLoader } from 'components/common';
import { IoIosSearch } from 'react-icons/io';
import { FaClockRotateLeft } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { selectMechanics } from 'redux/mechanics/selectors';
import MechanicCard from './components/MechanicCard';
import { Button } from 'component-library';
import { ModalAddMechanic } from 'components/DashboardRepair/ModalAddMechanic';

/**
 * Renders the MyTechniciansTab component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.offset - The offset value.
 * @returns {JSX.Element} The rendered MyTechniciansTab component.
 */
const MyTechniciansTab = ({ offset }) => {
  const { isMobile, isTablet, isSmallTablet, isMediumTablet } = useWindowSize();
  const [loading, setIsLoading] = useState(true);
  const mechanics = useSelector(selectMechanics);
  const [filteredMechanics, setFilteredMechanics] = useState([]);
  const [isAddMechanicModalOpen, setIsAddMechanicModalOpen] = useState(false);

  useEffect(() => {
    if (mechanics) {
      setFilteredMechanics(mechanics);
      setIsLoading(false);
    }
  }, [mechanics]);

  const handleSearch = event => {
    const inputValue = event.target.value.trim().toLowerCase();

    if (inputValue === '') {
      setFilteredMechanics(mechanics);
    } else {
      const filteredData = mechanics.filter(
        item =>
          (item.firstName &&
            item.firstName.toLowerCase().includes(inputValue)) ||
          (item.lastName && item.lastName.toLowerCase().includes(inputValue))
      );
      setFilteredMechanics(filteredData);
    }
  };

  return (
    <motion.div
      initial={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : -1000,
      }}
      animate={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : offset,
      }}
      exit={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : -1000,
      }}
      transition={{ bounce: 0 }}
      className="h-screen overflow-y-scroll scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-slate-300 sm:p-8 p-4 py-8 min-h-screen absolute w-full sm:w-[60vw] lg:max-w-2xl flex flex-col gap-y-12 rounded-e-2xl left-0 top-0 z-[1000] bg-white"
      style={{ boxShadow: '0px -4px 88px 0px rgba(58, 58, 58, 0.10)' }}
    >
      {loading ? (
        <LogoLoader />
      ) : (
        <div className="flex flex-col h-fit gap-y-16">
          {mechanics?.length > 0 ? (
            <div className="w-full flex-col justify-between">
              <div className="flex items-end">
                <div className="rounded-[30px] p-[13px] mt-3 mr-3 w-full flex flex-initial items-center h-12 bg-gray-100">
                  <IoIosSearch className="text-[25px] text-primary-3" />
                  <input
                    type="text"
                    placeholder="Search Technicians"
                    className="pl-[11px] text-primary-2 bg-gray-100 focus:outline-none placeholder:text-secondary-1"
                    onChange={handleSearch}
                  />
                </div>
                <Button
                  title="+ Add Technician"
                  size="rounded"
                  onClick={() => setIsAddMechanicModalOpen(true)}
                />
              </div>

              <div className="mt-10 mb-5 h-[1px] bg-grey-1"></div>
              {filteredMechanics
                ?.filter(mechanic => mechanic.role !== 'REPAIR_SHOP_MANAGER')
                .map((mechanic, index) => (
                  <MechanicCard key={index} mechanic={mechanic} />
                ))}

              <ModalAddMechanic
                isOpen={isAddMechanicModalOpen}
                setIsOpen={setIsAddMechanicModalOpen}
              />
            </div>
          ) : (
            <div className="flex flex-col text-center gap-x-4 items-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] opacity-70">
              <FaClockRotateLeft className="text-primary-3 text-9xl" />
              <span className="text-2xl text-primary-3">
                You have no technicians yet.
              </span>
            </div>
          )}
        </div>
      )}
    </motion.div>
  );
};

export default MyTechniciansTab;
