import { createSlice } from '@reduxjs/toolkit';
import { userFeedBacks } from './operations';
import { getVehicles } from './operations';

const initialState = {
  profile: {
    about: '',
    company: '',
    companyId: '',
    email: '',
    firstName: '',
    lastName: '',
    id: '',
    images: [],
    isActive: true,
    isEmailVerified: true,
    isPhoneVerified: true,
    phoneNo: '',
    role: '',
  },
  feedbacks:[],
  loading:false,
  trucks:[],
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateProfileState: (state, { payload }) => {
      console.log('state.profile', payload);
      state.profile = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userFeedBacks.fulfilled, (state, action) => {
        if (action?.payload?.data?.feedbacks){
          state.feedbacks = action.payload.data.feedbacks;
        }
      })
    builder.addCase(getVehicles.pending, (state, _) => {
      state.loading = true;
    });
    builder.addCase(getVehicles.fulfilled, (state, action) => {
      state.trucks = action.payload.data.vehicles;
      state.loading = false;
    });
    builder.addCase(getVehicles.rejected, (state, _) => {
      state.loading = false;
    });
  },
});

export const { updateProfileState } = profileSlice.actions;

export const profileReducer = profileSlice.reducer;
