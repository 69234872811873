import React from 'react';

const ProfileInput = ({ suffix = '', error, label, htmlFor, ...props }) => {
  return (
    <div className="flex flex-col gap-y-[10px]">
      <label htmlFor={htmlFor} className="font-medium">
        {label}
      </label>
      <div className="flex flex-col gap-y-[2px]">
        <div className="w-full py-[13px] px-[15px] text-[0.875rem] leading-[1.38em] rounded-xl border-primary-3 border flex flex-row">
          <input
            {...props}
            className="w-full justify-center focus-visible:outline-none focus-within:outline-none"
          />
          {suffix}
        </div>
        {error && <span className="text-error-1 text-[10px]">{error}</span>}
      </div>
    </div>
  );
};

export default ProfileInput;
