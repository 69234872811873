import React from 'react';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import { FiSearch } from 'react-icons/fi';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { FormikInput, Button, FormikSelectInput } from 'component-library';
import { LiteService } from 'services/lite.service';
import { useModalContext } from './AddNewRequestModal';
import { getCoordinatesFromAddress } from 'utils/utils';
import {
  repairType,
  requestDetailsInitialFields,
  requestDetailsInitialValues,
  requestDetailsValidationSchema,
  truckType,
  paymentMethod,
} from '../formData';

export const RequestDetailsForm = ({
  setIsOpen: setIsOpenProp,
  serviceRequestToken: serviceRequestTokenProp,
}) => {
  const context = useModalContext();
  const setIsOpen = setIsOpenProp || context.setIsOpen;
  const serviceRequestToken =
    serviceRequestTokenProp || context.serviceRequestToken;

  const handleAddRequest = async values => {
    try {
      const coordinates = await getCoordinatesFromAddress(values.address);
      const data = {
        ...values,
        serviceCategoryId: values.serviceCategoryId.value,
        type: values.serviceCategoryId.type,
        truckType: values.truckType.value,
        latitude: coordinates.results[0].geometry.location.lat,
        longitude: coordinates.results[0].geometry.location.lng,
      };

      const response = await LiteService.receiveRequestLite({
        token: serviceRequestToken,
        sendData: data,
      });

      if (response?.status) {
        toast.success(response.message);
        setIsOpen(false);
      } else {
        toast.error('Failed to send request. Please try again.');
      }
    } catch (error) {
      console.error('Error receiving request:', error);
      toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
    }
  };

  return (
    <Formik
      initialValues={requestDetailsInitialValues}
      validationSchema={requestDetailsValidationSchema}
      onSubmit={async (values, { resetForm }) => {
        await handleAddRequest(values);
        resetForm();
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        values,
        isValid,
        isSubmitting,
      }) => (
        <Form className="w-full flex flex-col gap-3" onSubmit={handleSubmit}>
          <LocationInput
            values={values}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
          <RepairTypeInput />
          <CompanyNameInput />
          <DescriptionInput />
          <VehicleInfoInputs />
          {values.serviceCategoryId.type === 'tire_repair' && (
            <TireInfoInputs />
          )}
          <PaymentMethodInput />
          <SubmitButton isValid={isValid} isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

// Components
const LocationInput = ({ values, setFieldValue, setFieldTouched }) => (
  <div className="flex flex-col gap-1">
    <label className="font-light text-sm leading-6 text-grey-2">
      Please enter driver's location
    </label>
    <div className="w-full py-[13px] px-[15px] h-[52px] flex items-center rounded-xl bg-accent-4">
      <FiSearch className="text-gray-400 text-[19px]" />
      <GooglePlacesAutocomplete
        selectProps={{
          value: values.address.value,
          placeholder: 'Enter your location',
          onChange: value => {
            setFieldValue('address', value.label);
            setFieldTouched('address', true);
          },
          styles: {
            input: base => ({
              ...base,
              color: 'black',
              width: '100%',
              height: '100%',
              border: 'none',
              outline: 'none',
              boxShadow: 'none',
              cursor: 'text',
              padding: '0 !important',
              fontSize: '16px',
            }),
            option: provided => ({
              ...provided,
              color: 'black',
              fontSize: '16px',
            }),
            singleValue: provided => ({
              ...provided,
              fontSize: '16px',
            }),
            control: (base, state) => ({
              ...base,
              border: state.isFocused ? 0 : 0,
              boxShadow: state.isFocused ? 0 : 0,
              cursor: 'text',
              borderColor: '#F1F3F8',
              backgroundColor: '#F1F3F8',
              padding: '0 !important',
            }),
            container: base => ({
              ...base,
              width: '100%',
              margin: '0 !important',
              padding: '0 !important',
            }),
            dropdownIndicator: base => ({
              ...base,
              display: 'none',
            }),
            clearIndicator: base => ({
              ...base,
              display: 'none',
            }),
            indicatorSeparator: base => ({
              ...base,
              display: 'none',
            }),
          },
          autoFocus: true,
        }}
        onLoadFailed={error =>
          console.error('Could not inject Google script', error)
        }
      />
    </div>
  </div>
);

const RepairTypeInput = () => (
  <div className="flex flex-col justify-start gap-1">
    <label className="font-light text-sm leading-6 text-grey-2">
      Repair Type
    </label>
    <FormikSelectInput
      options={repairType}
      isMulti={false}
      name={requestDetailsInitialFields.serviceCategoryId.name}
    />
  </div>
);

const CompanyNameInput = () => (
  <FormikInput
    name={requestDetailsInitialFields.companyName.name}
    label={requestDetailsInitialFields.companyName.label}
    placeholder={requestDetailsInitialFields.companyName.placeholder}
    labelClassName="font-light text-sm leading-6 text-grey-2 pb-1"
    inputClassName="font-normal text-base leading-6 text-primary-2"
    className="w-full"
  />
);

const DescriptionInput = () => (
  <FormikInput
    as="textarea"
    name={requestDetailsInitialFields.description.name}
    label={requestDetailsInitialFields.description.label}
    placeholder={requestDetailsInitialFields.description.placeholder}
    labelClassName="font-light text-sm text-grey-2 pb-1"
    inputClassName="font-normal text-base text-primary-2"
    className="w-full"
  />
);

const VehicleInfoInputs = () => (
  <div className="w-full flex gap-2 items-center">
    <div className="flex flex-col flex-1 gap-1">
      <label className="font-light text-sm text-grey-2">Type of vehicle</label>
      <FormikSelectInput
        options={truckType}
        isMulti={false}
        name={requestDetailsInitialFields.truckType.name}
      />
    </div>
    <div className="flex-1">
      <FormikInput
        name={requestDetailsInitialFields.unitNumber.name}
        label={requestDetailsInitialFields.unitNumber.label}
        placeholder={requestDetailsInitialFields.unitNumber.placeholder}
        labelClassName="font-light text-sm text-grey-2 pb-1"
        inputClassName="font-normal text-base text-primary-2"
      />
    </div>
  </div>
);

const TireInfoInputs = () => (
  <div className="flex gap-2">
    <div className="flex-1">
      <FormikInput
        name={requestDetailsInitialFields.tireSize.name}
        label={requestDetailsInitialFields.tireSize.label}
        placeholder={requestDetailsInitialFields.tireSize.placeholder}
        labelClassName="font-light text-sm text-grey-2 pb-1"
        inputClassName="font-normal text-base text-primary-2"
      />
    </div>
    <div className="flex-1">
      <FormikInput
        name={requestDetailsInitialFields.tirePosition.name}
        label={requestDetailsInitialFields.tirePosition.label}
        placeholder={requestDetailsInitialFields.tirePosition.placeholder}
        labelClassName="font-light text-sm text-grey-2 pb-1"
        inputClassName="font-normal text-base text-primary-2"
      />
    </div>
  </div>
);

const PaymentMethodInput = () => (
  <div
    role="group"
    aria-labelledby="my-radio-group"
    className="w-full flex flex-col gap-1"
  >
    <label className="text-sm font-normal text-grey-2">Payment Method</label>
    {paymentMethod.map((item, index) => (
      <label key={index}>
        <Field
          type="radio"
          name="paymentMethod"
          value={item.value}
          className="mr-2"
        />
        <span className="text-base font-normal text-primary-2">
          {item.label}
        </span>
      </label>
    ))}
  </div>
);

const SubmitButton = ({ isValid, isSubmitting }) => (
  <div className="flex w-full mt-4">
    <Button
      title="Submit"
      type="submit"
      disabled={!isValid}
      loading={isSubmitting}
    />
  </div>
);
