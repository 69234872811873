import React, { Fragment } from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import { ErrorMessage, Field } from 'formik';
import Input from 'react-phone-number-input/input';

//Custom phone input has to be passed as a component to Field to enable proper re-rendering after form submission
const FormikPhoneInput = ({ field, form, disabled = false, ...props }) => {
  return (
    <div className="flex flex-col gap-y-1">
      <Input
        name={field.name}
        placeholder={'Enter phone number'}
        country="US"
        value={field.value}
        onBlur={field.onBlur}
        onChange={value => {
          form.setFieldValue(field.name, value);
        }}
        className="rounded-[15px] p-[14px] border border-[#A6A9AD] text-primary-2  w-[100%] h-13 bg-white-100 focus:outline-none placeholder:text-secondary-1 placeholder:text-base text-base"
        disabled={disabled}
      />
      <ErrorMessage
        name={field.name}
        component="div"
        render={msg => (
          <div className="bg-[#FEEFEF] py-1.5 px-4 flex items-start rounded-[8px]">
            <IoMdInformationCircle
              color="#DA1414"
              className="flex-none mt-0.5"
            />
            <span className="text-[13px] text-[#DA1414] font-normal ml-1">
              {msg}
            </span>
          </div>
        )}
      />
    </div>
  );
};

export default FormikPhoneInput;
