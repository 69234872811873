import api from 'api';
import { toast } from 'react-toastify';
import { apiV2BaseUrl } from 'api';
import axios from 'axios';

const profileRoutes = {
  createRequestLite: 'service-requests-lite/initiate-service-request-lite',
  receiveRequestLite: 'service-requests-lite/receive-service-request-lite',
  getServiceRequest: 'service-requests-lite/my-service-request-lite',
  createFeedback: 'service-requests-lite/create-feedback-lite',
  cancelRequestLite: 'service-requests-lite/cancel-service-request-lite',
  updateRequestLite: 'service-requests-lite/update-service-request-lite',
  getAllActiveQuestions: 'questionnaire/get-all-active-questions',
  answersQuestionLite: 'questionnaire-answers/lite',
};

export class LiteService {
  /**
   *
   * @param {{nameOfDriver: string, phoneNumber: string, vin: string, type: string, description: string, mechanicId: number, serviceCategoryId:number, tools: string}} data
   */
  static async createRequestLite(data) {
    try {
      const { data: responseData } = await api.post(
        profileRoutes.createRequestLite,
        data
      );

      return responseData;
    } catch (error) {
      console.log('LiteService.createRequestLite', { error });
      toast.error(error.response.data.message);
      return null;
    }
  }

  /**
   *
   * @param {{data: object}} data
   */
  static async receiveRequestLite(data) {
    try {
      const { data: responseData } = await axios.post(
        `${apiV2BaseUrl}/service-requests-lite/receive-service-request-lite/${data.token}`,
        data.sendData,
        {
          headers: {
            'Content-Type': 'application/json',
            accept: '*/*',
          },
        }
      );
      return responseData;
    } catch (error) {
      const message = error.response.data.message;
      console.log('LiteService.receiveRequestLite', { error });
      if (message === 'Invalid service request token') {
        return toast.error(
          'Your link is not valid. Please contact the manager'
        );
      }
      toast.error(message);
      return null;
    }
  }

  /**
   *
   * @param {{data: object}} data
   */
  static async updateRequestLite(data) {
    try {
      const { data: responseData } = await axios.patch(
        `${apiV2BaseUrl}/${profileRoutes.updateRequestLite}/${data.token}`,
        data.sendData,
        {
          headers: {
            'Content-Type': 'application/json',
            accept: '*/*',
          },
        }
      );
      return responseData;
    } catch (error) {
      console.log('LiteService.updateRequestLite', { error });
      toast.error(error.response.data.message);
      return null;
    }
  }

  /**
   *
   * @param {{data: string}} data
   */
  static async getServiceRequest(data) {
    try {
      const { data: responseData } = await axios.get(
        `${apiV2BaseUrl}/${profileRoutes.getServiceRequest}/${data}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            accept: '*/*',
          },
        }
      );
      return responseData;
    } catch (error) {
      console.log('LiteService.getServiceRequest', { error });
      // toast.error(error.response.data.message);
      return null;
    }
  }

  static async getAllActiveQuestions() {
    try {
      const { data: responseData } = await axios.get(
        `${apiV2BaseUrl}/${profileRoutes.getAllActiveQuestions}?page=1&perPage=3`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            accept: '*/*',
          },
        }
      );
      return responseData;
    } catch (error) {
      console.log('LiteService.getAllActiveQuestions', { error });
      // toast.error(error.response.data.message);
      return null;
    }
  }

  /**
   *
   * @param {{data: object, token: string}} data
   */
  static async createFeedback(data) {
    try {
      const { data: responseData } = await axios.post(
        `${apiV2BaseUrl}/${profileRoutes.createFeedback}/${data.token}`,
        data.data,
        {
          headers: {
            'Content-Type': 'application/json',
            accept: '*/*',
          },
        }
      );
      return responseData;
    } catch (error) {
      console.log('LiteService.createFeedback', { error });
      toast.error(error.response.data.message);
      return null;
    }
  }
  /**
   *
   * @param {{data: object, token: string}} data
   */
  static async answersQuestionLite(data) {
    try {
      const { data: responseData } = await axios.post(
        `${apiV2BaseUrl}/${profileRoutes.answersQuestionLite}/${data.token}`,
        data.data,
        {
          headers: {
            'Content-Type': 'application/json',
            accept: '*/*',
          },
        }
      );
      return responseData;
    } catch (error) {
      console.log('LiteService.createFeedback', { error });
      toast.error(error.response.data.message);
      return null;
    }
  }
  /**
   *
   * @param {{data: object, token: string}} data
   */
  static async cancelRequestLite(data) {
    console.log('🚀 ~ LiteService ~ cancelRequestLite ~ data:', data.reason);
    try {
      const encodedReason = encodeURIComponent(data.reason);
      const { data: responseData } = await axios.post(
        `${apiV2BaseUrl}/${profileRoutes.cancelRequestLite}/${data.token}?reason=${encodedReason}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            accept: '*/*',
          },
        }
      );
      console.log(
        '🚀 ~ LiteService ~ cancelRequestLite ~ responseData:',
        responseData
      );
      return responseData;
    } catch (error) {
      console.log('LiteService.cancelRequestLite', { error });
      toast.error(error.response.data.message);
      return null;
    }
  }

  /**
   * Upload images for a service request
   * @param {FileList} files - List of image files to upload
   * @param {string} token - Service request token
   * @returns {Promise<object|null>} - Response data or null if an error occurs
   */
  static async uploadImages(files, token) {
    if (files.length > 5) {
      toast.error('You can only upload a maximum of 5 files');
      return null;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('images', files[i]);
    }

    try {
      const { data: responseData } = await api.post(
        `${apiV2BaseUrl}/image-upload?service-request-token=${token}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      return responseData;
    } catch (error) {
      console.log('LiteService.uploadImages', { error });
      toast.error(error.response.data.message);
      return null;
    }
  }
}
