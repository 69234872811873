import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const userFeedBacks = createAsyncThunk(
  'service-requests/user-feedbacks',
  async (_, thunkAPI) => {
    try {
      const res = await api.get('service-requests/user-feedbacks');
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const updateProfileUser = createAsyncThunk(
  'profiles/update-user',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.patch(`profiles/update-user`, credentials);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateProfileCompany = createAsyncThunk(
  'profiles/update-company',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.patch(`profiles/update-company`, credentials);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getUserProfile = createAsyncThunk(
  'user/get-details',
  async (_, thunkAPI) => {
    try {
      const res = await api.get(`user/get-details`);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const userDetails = createAsyncThunk(
  'profiles/user-details',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post(`profiles/user-details`, credentials);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addVehicle = createAsyncThunk(
  'vehicles/add-vehicle',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post(`vehicles/add-vehicle`, {
        ...credentials,
        type: 'TRUCK',
      });
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const updateVehicle = createAsyncThunk(
  'vehicles/update-vehicle',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.patch(`vehicles/update-vehicle`, {
        ...credentials,
      });
      toast('Update')
      getVehicles();
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const unassignVehicle = createAsyncThunk(
  'companies/unassign-driver-from-vehicle',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post(`companies/unassign-driver-from-vehicle`, {
        ...credentials,
      });
      getVehicles();
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getVehicles = createAsyncThunk(
  'vehicles/get-vehicles/TRUCK',
  async (_, thunkAPI) => {
    try {
      const res = await api.get(`vehicles/get-vehicles/TRUCK`,);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deactivateUser = createAsyncThunk(
  'profiles/deactivate-user',
  async (_, thunkAPI) => {
    try {
      const res = await api.delete(`profiles/deactivate-user`,);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

