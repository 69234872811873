import React, { createContext, useContext, useState, useEffect } from 'react';
import io from 'socket.io-client';
import { ACCESS_TOKEN_STORAGE_KEY } from 'constants/auth.constants';
import { useDispatch, useSelector } from 'react-redux';
import { apiBaseRootURL } from 'api';
import { selectAccessToken } from 'redux/auth/selectors';
import { updateAccessToken } from 'redux/auth/slice';

/**
 * Context object for managing socket connections.
 * @type {React.Context}
 */
const SocketContext = createContext();

/**
 * Provider component for managing socket connections.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components.
 * @returns {React.ReactNode} The rendered component.
 */
export const SocketProvider = ({ children }) => {
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Attempt to get the token from localStorage if not present in the Redux store
    const storedToken =
      accessToken || localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    if (storedToken && !accessToken) {
      // Update the Redux store with the token from localStorage
      dispatch(updateAccessToken(storedToken));
    }
    if (storedToken) {
      const newSocket = io(apiBaseRootURL, {
        query: { token: storedToken },
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        randomizationFactor: 0.5,
      });
      setSocket(newSocket);
      return () => {
        newSocket.disconnect();
      };
    } else if (socket) {
      socket.disconnect();
      setSocket(null);
    }
  }, [accessToken]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

/**
 * Custom hook for accessing the socket context.
 * @returns {Object} The socket context object.
 */
export const useAuth = () => useContext(SocketContext);
