export const theme = {
  colors: {
	accent: '#2D6DCA',
	accentDisabled: '#95BAF0',
	accentLight: '#DFECFB',
 
	primaryBg: '#FFFFFF',
 
	primaryText: '#1D1D1D',
	secondaryText: '#B8B8B8',
	whiteText: '#FFFFFF',
	liteText: '#A6A9AD',
 
	viewLigthGrey: '#E5E9EF',
	error: '#D22121',
  },
};
