import React, { useEffect, useRef, useState } from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Select } from 'component-library';
import { selectCountries, selectStates } from 'redux/reference/selectors';
import { getCities, getCountries, getStates } from 'redux/reference/operations';

/**
 * Renders a form for entering company address information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setValues - A function to set the form values.
 * @param {Function} props.setIsValid - A function to set the form validity.
 * @param {string} props.stateId - The initial state ID value.
 * @param {string} props.cityId - The initial city ID value.
 * @param {string} props.zip - The initial zip code value.
 * @param {string} props.address - The initial address value.
 * @returns {JSX.Element} The rendered component.
 */
export const CompanyAddress = ({
  onAddressChange,
  validationSchema,
  stateId,
  cityId,
  zip,
  address,
}) => {
  const dispatch = useDispatch();
  const states = useSelector(selectStates);
  const countries = useSelector(selectCountries);
  const initialized = useRef(false);
  const initialFields = {
    address: {
      name: 'address',
      placeholder: 'Enter your address',
      type: 'text',
      label: 'Address',
    },
    stateId: {
      name: 'stateId',
      placeholder: 'Select your state',
      type: 'select',
      label: 'State',
      selectOptions: [],
      containerClass: 'w-full ',
      onChange: stateId =>
        dispatch(getCities({ stateId })).then(resp => {
          const citiesOptions = resp.payload.data?.cities.map(city => ({
            key: city.id,
            label: city.name,
          }));

          setFields(oldValue => ({
            ...oldValue,
            cityId: {
              ...oldValue.cityId,
              selectOptions: citiesOptions?.length
                ? citiesOptions
                : [{ key: null, label: 'No Cities found', disabled: true }],
            },
          }));
        }),
    },
    cityId: {
      name: 'cityId',
      placeholder: 'City',
      type: 'select',
      label: 'City',
      selectOptions: [],
      containerClass: 'w-[49%] ',
    },
    zip: {
      name: 'zipCode',
      placeholder: 'Zip code',
      type: 'text',
      label: 'Zip Code',
    },
  };

  const [fields, setFields] = useState(initialFields);
  const initialValues = {
    [fields.stateId.name]: stateId,
    [fields.cityId.name]: cityId,
    [fields.zip.name]: zip,
    [fields.address.name]: address,
  };
  useEffect(() => {
    if (!initialized.current) {
      dispatch(getCountries());
      initialized.current = true;
    }
  }, [initialized, dispatch]);

  useEffect(() => {
    if (countries[0] && !states?.length) {
      dispatch(getStates({ countryId: countries[0].id })).then(resp => {
        setFields({
          ...fields,
          stateId: {
            ...fields.stateId,
            selectOptions: resp.payload.data?.states.map(state => ({
              key: state.id,
              label: state.name,
            })) || [{ label: 'States not found' }],
          },
        });
      });
    } else if (countries[0] && states.length && stateId) {
      dispatch(getCities({ stateId: stateId })).then(resp => {
        const citiesOptions = resp.payload.data?.cities
          .map(city => ({
            key: city.id,
            label: city.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        setFields(oldValue => ({
          ...oldValue,
          stateId: {
            ...fields.stateId,
            selectOptions: states || [{ label: 'States not found' }],
          },
          cityId: {
            ...oldValue.cityId,
            selectOptions: citiesOptions?.length
              ? citiesOptions
              : [{ key: null, label: 'No Cities found', disabled: true }],
          },
        }));
      });
    } else if (countries[0] && states.length && !stateId) {
      setFields(oldValue => ({
        ...oldValue,
        stateId: {
          ...fields.stateId,
          selectOptions: states || [{ label: 'States not found' }],
        },
      }));
    }
  }, [countries, dispatch, states, stateId]);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema}>
      {({ errors, values, setFieldValue, handleChange }) => (
        <Form className="w-full flex flex-col  scrollbar-none">
          <div className="w-full  pt-6  flex flex-row flex-wrap justify-between">
            {Object.keys(fields).map((field, index) => {
              const fieldData = fields[field];
              if (fieldData.type === 'select') {
                return (
                  <div
                    className={`flex flex-col ${fieldData.containerClass}`}
                    key={fieldData.name}
                  >
                    <label
                      className={'text-base font-normal text-primary-2  my-1'}
                    >
                      {fieldData.label}
                      <label className="text-[#DA1414]"> *</label>
                    </label>
                    <div className="flex flex-col scrollbar-none">
                      <Select
                        className={`max-h-10`}
                        values={fieldData.selectOptions}
                        placeholder={fieldData.placeholder}
                        selected={
                          fieldData.selectOptions?.find(
                            option => option.key === values[fieldData.name]
                          ) || null
                        }
                        setSelected={value => {
                          setFieldValue(fieldData.name, value.key);
                          onAddressChange({ [fieldData.name]: value.key });
                          if (typeof fieldData.onChange === 'function') {
                            fieldData.onChange(value.key);
                          }
                        }}
                      />
                      {errors[fieldData.name] && (
                        <div
                          className={`bg-[#FEEFEF] py-2 px-4 flex flex-row items-center rounded-[8px]`}
                        >
                          <IoMdInformationCircle color="#DA1414" />
                          <span className="text-[13px] text-[#DA1414] font-normal ml-1">
                            {errors[fieldData.name]}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              }

              return (
                <div
                  key={index}
                  className={`flex flex-col ${
                    index === 2 || index === 3 ? 'w-[49%]' : 'w-full'
                  }`}
                >
                  <label
                    className={'text-base font-normal text-primary-2  my-1'}
                  >
                    {fieldData.label}
                    <label className="text-[#DA1414]"> *</label>
                  </label>
                  <div className="flex flex-col ">
                    <div
                      className={`w-full py-[13px] px-[15px] text-[0.875rem] leading-[1.38em] rounded-xl  flex flex-row ${
                        !errors[fieldData.name]
                          ? 'border-primary-3 border'
                          : 'bg-[#FEEFEF] border-[#DA1414] border-[1px]'
                      }`}
                    >
                      <Field
                        name={fieldData.name}
                        className={`w-full justify-center focus-visible:outline-none focus-within:outline-none ${
                          errors[fieldData.name] && 'bg-[#FEEFEF] '
                        } `}
                        placeholder={fieldData.placeholder}
                        onChange={e => {
                          handleChange(e);
                          onAddressChange({ [fieldData.name]: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  {errors[fieldData.name] && (
                    <div
                      className={`bg-[#FEEFEF] py-2 px-2 mt-1 flex flex-row items-center rounded-[8px]`}
                    >
                      <IoMdInformationCircle color="#DA1414" />
                      <span className="text-[13px] text-[#DA1414] font-normal ml-1">
                        {errors[fieldData.name]}
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </Form>
      )}
    </Formik>
  );
};
