import { Modal } from 'antd';
import { Button } from 'component-library';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useState, Fragment, useEffect } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { DefaultService } from 'services/default.service';
import { reduceImageSizeAndQuality } from 'helpers/common.helpers';
import { toast } from 'react-toastify';
import { setMechanics } from 'redux/mechanics/mechanic.slice';
import { HomeServices } from 'services/home.service';
import { editMechanic } from 'redux/mechanics/operations';
import { options, initialFields, validationSchema } from '../formData';
import { NavigationTabs } from './NavigationTabs';
import { getMechanicProfile } from 'redux/mechanics/operations';
import { PersonalInformationTab } from './PersonalInformationTab';
import { EmployeeProfileTab } from './EmployeeProfileTab';
import { ServiceHistoryTab } from './ServiceHistoryTab';
import { FaUser } from 'react-icons/fa';

/**
 * Modal component for displaying and editing technician information.
 *
 * @component
 * @param {boolean} isOpen - Determines whether the modal is open or not.
 * @param {function} setIsOpen - Callback function to set the open state of the modal.
 * @param {object} mechanic - The technician object containing information such as name, phone number, etc.
 * @param {number} rating - The rating of the technician.
 * @returns {JSX.Element} MechanicInformationModal component.
 */
export const MechanicInformationModal = ({
  isOpen,
  setIsOpen,
  mechanic,
  rating,
}) => {
  console.log(mechanic);
  const [activeTab, setActiveTab] = useState('personal');
  const dispatch = useDispatch();
  const [url, setUrl] = useState(mechanic.profileImage?.url || null);
  const [serviceRequests, setServiceRequests] = useState([]);

  const initialValues = {
    [initialFields.firstName.name]: mechanic.firstName,
    [initialFields.lastName.name]: mechanic.lastName ?? '',
    [initialFields.phoneNumber.name]: mechanic.phoneNo,
    //map the service categories to the options array
    [initialFields.serviceCategoryIDs.name]: mechanic.services
      .map(item => {
        const option = options.find(
          opt => opt.value === item.serviceCategoryId
        );
        return option ? { ...option, isActive: item.isActive } : null;
      })
      .filter(option => option !== null),
    [initialFields.image.name]: mechanic.profileImage?.url ?? null,
    [initialFields.about.name]: mechanic.about,
    [initialFields.rate.name]: mechanic.rate,
    // TODO : check if this needs to be added
    // [initialFields.specialization.name]: mechanic.role,
    [initialFields.specialization.name]: 'Diesel Mechanic',
  };

  const handleImageUpload = async e => {
    try {
      const file = e.target ? e.target.files[0] : e;
      if (!file) {
        throw new Error('No file selected.');
      }

      const imageUrl = await reduceImageSizeAndQuality(file, 1100000);
      const formData = new FormData();
      formData.append('images', imageUrl);

      const responseImage = await DefaultService.imageUpload(formData);

      if (
        !responseImage ||
        !responseImage.images ||
        responseImage.images.length === 0
      ) {
        throw new Error('Image upload failed or response data is invalid.');
      }

      setUrl(responseImage.images[0]);
    } catch (error) {
      toast.error(
        error.message || 'An error occurred while uploading the image.'
      );
    }
  };

  const handleSubmit = async values => {
    const data = {
      mechanicId: mechanic.id,
      name: `${values.firstName} ${values.lastName}`,
      phoneNumber: values.phoneNumber,
      about: values.about,
      rate: values.rate,
      serviceCategoryIDs: values.serviceCategoryIDs
        .map(item => item.value)
        .join(','),
      image: url ?? null,
    };

    const response = await dispatch(editMechanic(data));

    if (response.payload.data) {
      toast.success(response.payload.message);
      getMechanics();
      setIsOpen(false);
    }
  };

  const getMechanics = async () => {
    try {
      const response = await HomeServices.getUsers();
      dispatch(setMechanics(response));
    } catch (error) {
      console.error('Error fetching mechanics:', error);
    }
  };

  useEffect(() => {
    const getServiceRequests = async () => {
      try {
        const response = await dispatch(getMechanicProfile(mechanic.id));
        setServiceRequests(response.payload.data.serviceRequests);
      } catch (error) {
        console.error('Error fetching mechanic:', error);
      }
    };
    getServiceRequests();
  }, [mechanic.id, dispatch]);

  return (
    <Modal
      title=""
      open={isOpen}
      onCancel={() => {
        setIsOpen(false);
      }}
      footer={false}
      zIndex={1002}
      width={600}
      className="font-poppins "
      centered
      transitionName=""
      destroyOnClose
      // bodyStyle={{ height: 700 }}
    >
      <div className="flex flex-col w-full items-center justify-center gap-y-6 transition-all mt-3">
        <h2 className="text-2xl font-semibold text-center">
          Technician Information
        </h2>
        {url ? (
          <img src={url} alt="profile" className="h-[100px] rounded-[6px]" />
        ) : (
          <div className=" flex justify-center items-center rounded-[10px] bg-accent-2 h-[100px] w-[100px]">
            <FaUser className="fill-accent-1" size={60} />
          </div>
        )}
        <NavigationTabs activeTab={activeTab} setActiveTab={setActiveTab} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => {
            handleSubmit(values);
          }}
        >
          {({
            isValid,
            isSubmitting,
            values,
            setFieldValue,
            form,
            dirty,
            ...props
          }) => {
            return (
              <Form className="w-full flex flex-col gap-y-3 items-center">
                {activeTab === 'personal' && (
                  <PersonalInformationTab
                    handleImageUpload={handleImageUpload}
                    setFieldValue={setFieldValue}
                  />
                )}
                {activeTab === 'profile' && (
                  <EmployeeProfileTab
                    mechanic={mechanic}
                    rating={rating}
                    serviceRequests={serviceRequests}
                  />
                )}
                {activeTab === 'history' && (
                  <ServiceHistoryTab data={serviceRequests} />
                )}
                <Button
                  classNames="mt-3"
                  type="submit"
                  title={
                    !isSubmitting ? (
                      'Save'
                    ) : (
                      <AiOutlineLoading
                        size={`24`}
                        className={`animate-spin`}
                      />
                    )
                  }
                  disabled={!isValid || isSubmitting || !dirty}
                />

                {/* <Button
                  title="Save"
                  type="submit"
                  classNames="mt-3"
                  disabled={!isValid || !dirty}
                /> */}
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
