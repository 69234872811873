import axios from 'axios';
import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api, { apiV2BaseUrl } from 'api';
import Papa from 'papaparse';

axios.defaults.baseURL = apiV2BaseUrl;

export const listDrivers = createAsyncThunk(
  'fleet/list-drivers',
  async (_, thunkAPI) => {
    try {
      const res = await api.get('fleet/list-drivers');
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const addDriverToFleet = createAsyncThunk(
  'companies/add-driver-to-fleet',
  async (phone, thunkAPI) => {
    try {
      const res = await api.post(
        `companies/add-driver-to-fleet?phoneNumber=${phone}`
      );
      listDrivers();
      toast('Successfully added driver');
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const bulkUploadVehicles = createAsyncThunk(
  'vehicles/mass-upload-decode',
  async (file, companyEmail) => {
    try {
      const reader = new FileReader();
      let responseData;

      // Event listener on reader when the file
      // loads, we parse it and set the data.
      reader.onload = async ({ target }) => {
        const csv = Papa.parse(target.result, {
          header: true,
        });

        console.log({ csv });

        const { data } = await api.post(
          'vehicles/mass-upload-decode',
          csv.data.filter(i => i.vin).map(i => ({ ...i, companyEmail }))
        );

        responseData = data;
      };
      reader.readAsText(file.originFileObj);

      return responseData;
    } catch (error) {
      console.log(
        '🚀 ~ file: operations.js:37 ~ error:',
        error.response.data.message
      );
      toast.error(error.response.data.message);
      return null;
    }
  }
);

export const joinCompany = createAsyncThunk(
  'companies/join-company',
  async (credentials, thunkAPI) => {
    try {
      const res = await api.post('companies/join-company', credentials);
      return res.data;
    } catch (error) {
      console.log(
        '🚀 ~ file: operations.js:37 ~ error:',
        error.response.data.message
      );
      toast.error(
        'An error occurred while joining the company. Please try again later.'
      );
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getCompanyPictureCategories = createAsyncThunk(
  'companies/picture-categories-for-company',
  async (_, thunkAPI) => {
    try {
      const res = await api.get('companies/picture-categories-for-company');
      return res.data;
    } catch (error) {
      console.error('error getting picture categories', { error });
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
