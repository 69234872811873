import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { HiChevronUpDown } from 'react-icons/hi2';
import { FaCheck } from 'react-icons/fa6';
import { AiOutlineLoading } from 'react-icons/ai';

/**
 *
 * @param {{selected: {key: string, label: string}, setSelected: () => void, values: {key: string, label: string}[]}} param0
 * @returns
 */
export default function Select({
  selected,
  setSelected,
  values,
  placeholder,
  loading,
  defaultValue,
}) {
  return (
    <Listbox
      value={selected}
      onChange={setSelected}
      disabled={loading}
      defaultValue={defaultValue}
    >
      <div className="relative">
        <Listbox.Button className="relative w-full cursor-default rounded-xl border-primary-3 border bg-white py-[13px] pl-[15px] text-left focus:outline-none sm:text-sm">
          <span className="block truncate">
            {loading ? (
              <AiOutlineLoading className="animate-spin text-xl" />
            ) : (
              selected?.label || placeholder
            )}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <HiChevronUpDown
              className="text-xl text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-[10000] scrollbar-thin scrollbar-thumb-slate-400">
            {values.map(value => (
              <Listbox.Option
                key={value.key}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-accent-2' : 'text-gray-900'
                  }`
                }
                value={value}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {value.label}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-accent-1">
                        <FaCheck className="text-lg" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
