import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loader, LogoLoader } from 'components/common';
import { Button, ProfileInput } from 'component-library';
import { validationSchema } from '../../DriverProfileNavigation/tabs/formData/settings';
import { fields as formFields } from '../../DriverProfileNavigation/tabs/formData/settings';
import { selectUser } from 'redux/auth/selectors';
import 'react-toggle/style.css';
import { ProfileService } from 'services/profile.service';
import { toast } from 'react-toastify';
import { DefaultService } from 'services/default.service';
import { FaShop, FaStar } from 'react-icons/fa6';
import { ModalDelete } from 'components/DashboardRepair/ModalDelete';
import { Modal } from 'antd';
import { logout } from 'helpers/auth.helpers';
import { logoutUser } from 'redux/auth/operations';
import { useNavigate } from 'react-router-dom';
import { routes } from 'constants/routes.constants';
import { getProfile } from 'redux/auth/operations';
import { userDetails, userFeedBacks } from 'redux/profile/operations';
import { selectFeedbacks } from 'redux/profile/selectors';
import { ReviewsModal } from './ReviewsModal';
import { HomeServices } from 'services/home.service';
import { storeDrivers } from 'redux/fleet/fleet.slice';
import { useAuth } from 'context/SocketContext';
import { modalStyles } from 'constants/CommonStyles';

const CompanyTab = () => {
  const [isPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const profileState = useSelector(selectUser);
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState({
    [formFields.company.name]: '',
    [formFields.address.name]: '',
    [formFields.firstName.name]: '',
    [formFields.lastName.name]: '',
    [formFields.email.name]: '',
    [formFields.phoneNo.name]: '',
    [formFields.about.name]: '',
    [formFields.rate.name]: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialized = useRef(false);
  const feedbacks = useSelector(selectFeedbacks);
  const [isEnabled, setIsEnabled] = useState(true);
  const { socket } = useAuth();

  const toggleSwitch = async () => {
    setIsEnabled(!isEnabled);
  };

  useEffect(() => {
    if (profileState && Object.keys(profileState).length > 0) {
      setValues(prevValues => ({
        ...prevValues,
        [formFields.company.name]: profileState.company?.name || '',
        [formFields.address.name]: profileState.company?.address || '',
        [formFields.firstName.name]: profileState.firstName || '',
        [formFields.lastName.name]: profileState.lastName || '',
        [formFields.email.name]: profileState.company.contactEmail || '',
        [formFields.phoneNo.name]: profileState.company.contactPhoneNo || '',
        [formFields.about.name]: profileState.company.about || '',
        [formFields.rate.name]: profileState.company.rate || '',
      }));
      setIsEnabled(profileState.company.allowDriversSelfRequest);
    }
  }, [profileState]);

  useEffect(() => {
    if (!initialized.current) {
      dispatch(userFeedBacks());
      initialized.current = true;
    }
  }, [initialized, dispatch]);

  useEffect(() => {
    const validateForm = async () => {
      try {
        await validationSchema.validate(values, { abortEarly: false });
        setIsFormValid(true);
      } catch (error) {
        console.log('🚀 ~ validateForm ~ error:', error);
        setIsFormValid(false);
      }
    };
    validateForm();
  }, [url, values, isEnabled]);

  const handleUpdateProfile = async e => {
    e.preventDefault();
    try {
      let response;
      setIsSubmitting(true);
      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        name: values.company,
        contactEmail: values.email,
        contactPhoneNo: values.phoneNo,
        address: values.address,
        about: values.about,
        rate: values.rate,
      };
      if (url) {
        data.picture = url;
      }

      const responseCompany = await ProfileService.updateProfileCompany(data);
      if (profileState.company.allowDriversSelfRequest !== isEnabled) {
        response = await HomeServices.selfRequestToggle();
      } else {
        response = true;
      }

      if (responseCompany && response) {
        toast.success('Profile updated');
        setUrl(null);
        dispatch(getProfile());
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageUpload = async e => {
    setLoading(true);
    try {
      const file = e.target.files[0];

      if (!file) {
        throw new Error('No file selected.');
      }

      const imageUrl = await reduceImageSizeAndQuality(file, 1100000);
      const formData = new FormData();
      formData.append('images', imageUrl);

      const responseImage = await DefaultService.imageUpload(formData);

      if (
        !responseImage ||
        !responseImage.images ||
        responseImage.images.length === 0
      ) {
        throw new Error('Image upload failed or response data is invalid.');
      }

      setUrl(responseImage.images[0]);
    } catch (error) {
      toast.error(
        error.message || 'An error occurred while uploading the image.'
      );
    } finally {
      setLoading(false);
    }
  };

  const reduceImageSizeAndQuality = (file, maxSizeBytes) => {
    return new Promise((resolve, reject) => {
      if (!file.type.match(/^image\/(jpeg|jpg)$/)) {
        reject(new Error('Only JPEG or JPG image formats are supported.'));
        return;
      }

      const reader = new FileReader();

      reader.onload = function (event) {
        const img = new Image();
        img.onload = function () {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;
          let quality = 1;

          while (width * height * quality > maxSizeBytes) {
            quality *= 0.9;
            width *= 0.9;
            height *= 0.9;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            blob => {
              const resizedImage = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              resolve(resizedImage);
            },
            file.type,
            quality
          );
        };
        img.src = event.target.result;
      };

      reader.readAsDataURL(file);
    });
  };

  const handleDelete = async e => {
    e.preventDefault();

    try {
      setLoading(true);
      await ProfileService.deactivateUser();
      logout();
      dispatch(storeDrivers([]));

      dispatch(storeDrivers([]));
      dispatch(logoutUser());
      socket.disconnect();
      navigate(routes.home, { replace: true });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {isPageLoading ? (
        <LogoLoader />
      ) : (
        <>
          <ReviewsModal
            isOpen={isOpen}
            setIsOpen={value => {
              setIsOpen(value);
            }}
            feedbacks={feedbacks}
          />
          {loading && <Loader />}
          <div className="flex flex-col items-center justify-center">
            {profileState.images?.length > 0 ? (
              <img
                src={profileState.images[profileState.images?.length - 1].url}
                alt="User"
                className="lg:ml-[-120px] w-[150px] h-[150px] rounded-[10px] bg-accent-2 object-cover"
              />
            ) : (
              <div className="lg:ml-[-120px] flex justify-center items-center mr-[10px] w-[150px] h-[150px] rounded-[10px] bg-accent-2 object-cover">
                <FaShop className="h-[120px] w-[120px] fill-accent-1" />
              </div>
            )}
            <div
              onClick={() => {
                setIsOpen(true);
              }}
              className="flex w-[150px] items-center justify-center lg:ml-[-120px] mt-[10px] cursor-pointer"
            >
              <FaStar className="text-base text-accent-1" />
              <p className="ml-[5px]">{userDetails?.rate ?? 0}</p>
            </div>
          </div>
          <form
            onSubmit={handleUpdateProfile}
            className="flex items-center justify-center flex-col gap-y-8 sm:grid sm:grid-cols-2 sm:gap-x-12 sm:gap-y-12 lg:pr-32"
          >
            {Object.keys(formFields).map(field => {
              const fieldData = formFields[field];
              if (
                profileState.role === 'FLEET_MANAGER' &&
                fieldData.name === 'rate'
              ) {
                return (
                  <label className="flex items-center  gap-y-[10px] cursor-pointer ml-[20px] sm:ml-0 lg:ml-0">
                    <div className="relative">
                      <input
                        type="checkbox"
                        className="sr-only"
                        checked={isEnabled}
                        onChange={toggleSwitch}
                      />
                      <div
                        className={`block w-14 h-8 rounded-full ${
                          isEnabled ? 'bg-green-500' : 'bg-gray-600'
                        }`}
                      ></div>
                      <div
                        className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform duration-200 ease-in-out ${
                          isEnabled ? 'transform translate-x-6' : ''
                        }`}
                      ></div>
                    </div>
                    <div className="ml-3 text-[16px]">
                      {isEnabled
                        ? 'Allow fleet drivers to send self request'
                        : "Don't allow fleet drivers to send self requests"}
                    </div>
                  </label>
                );
              }
              return (
                <ProfileInput
                  key={fieldData.name}
                  label={fieldData.label}
                  placeholder={fieldData.placeholder}
                  className="text-[16px]"
                  name={fieldData.name}
                  value={values[fieldData.name]}
                  onChange={e => {
                    setValues(prev => ({
                      ...prev,
                      [fieldData.name]: e.target.value,
                    }));
                  }}
                />
              );
            })}

            <div className="flex flex-col gap-y-[10px] ml-[120px] sm:ml-0 lg:ml-0">
              <label className="font-medium" htmlFor="pictureInput">
                Picture:
              </label>
              <input
                id="pictureInput"
                type="file"
                accept="image/*"
                onChange={e => handleImageUpload(e)}
              />
            </div>
            <div />
            {/* <div className="flex flex-col gap-y-[10px] ml-[120px] sm:ml-0 lg:ml-0">
              <label className="font-medium" htmlFor="pictureInput">
                Reviews:
              </label>
              <div className="flex flex-col">
                {feedbacks.length > 0 ? (
                  feedbacks.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="min-w-[75%] md:min-w-[45%] lg:min-w-[25%] flex justify-center items-center"
                      >
                        <ScrollTab data={item} />
                      </div>
                    );
                  })
                ) : (
                  <p>No reviews</p>
                )}
              </div>
            </div> */}
            <Button
              title="Save changes"
              type="submit"
              loading={isSubmitting}
              disabled={!isFormValid || loading}
              classNames="bg-accent-3 text-primary-1 text-[16px] py-[14px] rounded-[15px]"
            />
            <div></div>
            <button
              type="button"
              onClick={() => setShowModal(true)}
              className="text-error-1 text-[14px] py-[14px] mt-[-20px] border-error-1 border rounded-[15px] hover:text-white hover:bg-error-1"
            >
              Delete account
            </button>
          </form>

          <Modal
            title=""
            open={showModal}
            onCancel={() => {
              setShowModal(false);
            }}
            footer={false}
            zIndex={1002}
            style={modalStyles}
          >
            <ModalDelete
              handleDelete={handleDelete}
              handleCancel={() => setShowModal(false)}
              isMechanic={false}
            />
          </Modal>
        </>
      )}
    </div>
  );
};

export default CompanyTab;
