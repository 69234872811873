import { ErrorMessage, Form, Formik, Field } from 'formik';
import { FormikInput } from '../../../../../component-library';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import React, { Fragment, useState } from 'react';
import * as Yup from 'yup';
import CustomUpload from '../../../../common/CustomUpload';
import { AiOutlineLoading } from 'react-icons/ai';
import { Button } from 'component-library';
import Input from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { addMechanicToCompany } from 'redux/mechanics/operations';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { IoMdInformationCircle } from 'react-icons/io';
import { FormikPhoneInput } from '../../../../../component-library';
import { set } from 'lodash';
import { DefaultService } from 'services/default.service';
import { reduceImageSizeAndQuality } from 'helpers/common.helpers';
import { HomeServices } from 'services/home.service';
import { setMechanics } from 'redux/mechanics/mechanic.slice';

export const AddMechanicManually = ({ setIsOpen }) => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState(null);

  const initialFields = {
    firstName: {
      name: 'firstName',
      placeholder: 'First Name',
      type: 'text',
      label: 'First Name',
    },
    lastName: {
      name: 'lastName',
      placeholder: 'Last name',
      type: 'text',
      label: 'Last name',
    },
    phoneNumber: {
      name: 'phoneNumber',
      placeholder: 'Enter phone number',
      type: 'text',
      label: 'Phone Number',
    },
    serviceCategoryIDs: {
      name: 'serviceCategoryIDs',
      placeholder: 'Select capabilities',
      type: 'text',
      label: 'Capabilities',
    },
    image: {
      name: 'image',
      placeholder: 'Upload profile image',
      type: 'file',
      label: 'Profile Image',
    },
  };

  const initialValues = {
    [initialFields.firstName.name]: '',
    [initialFields.lastName.name]: '',
    [initialFields.phoneNumber.name]: '',
    [initialFields.serviceCategoryIDs.name]: [],
    [initialFields.image.name]: null,
  };

  const validationSchema = Yup.object().shape({
    [initialFields.firstName.name]: Yup.string()
      .trim()
      .min(2, `Minimum length for driver's name is 2 characters`)
      .max(30, `Maximum length for driver's name is 30 characters`)
      .required('First name is required'),
    [initialFields.lastName.name]: Yup.string()
      .trim()
      .min(2, `Minimum length for driver's name is 2 characters`)
      .max(30, `Maximum length for driver's name is 30 characters`)
      .required('Last name is required'),
    [initialFields.phoneNumber.name]: Yup.string()
      .required('Phone number is required')
      .test('is-valid-phone', 'Phone number is invalid', value => {
        return isValidPhoneNumber(value || '');
      }),
    [initialFields.serviceCategoryIDs.name]: Yup.array()
      .min(1)
      .required('Required'),
    [initialFields.image.name]: Yup.string().optional().nullable(),
  });

  //Custom select input has to be passed as a component to Field to enable proper re-rendering after form submission
  const SelectInput = ({ field, form, ...props }) => {
    const animatedComponents = makeAnimated();
    const customSelectStyle = {
      control: baseStyles => ({
        ...baseStyles,
        borderRadius: '15px',
        padding: '7px 0',
        borderColor: '#A6A9AD',
      }),

      multiValue: baseStyles => ({
        ...baseStyles,
        borderRadius: '8px',
        backgroundColor: '#F1F3F8',
        fontSize: '16px',
      }),
      multiValueRemove: baseStyles => ({
        ...baseStyles,
        color: '#B8B8B8',
      }),
      placeholder: baseStyles => ({
        ...baseStyles,
        color: '#A6A9AD',
        fontSize: '16px',
      }),
      menuList: baseStyles => ({
        ...baseStyles,
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        padding: '10px 8px',
      }),
      option: baseStyles => ({
        ...baseStyles,
        width: 'fit-content',
        borderRadius: '8px',
        backgroundColor: '#F1F3F8',
        transition: 'all 0.3s',
        fontSize: '13px',
        ':hover': {
          ...baseStyles[':hover'],
          backgroundColor: '#DFECFB',
          cursor: 'pointer',
          transition: 'all 0.3s',
        },
      }),
    };

    return (
      <Fragment>
        <Select
          components={animatedComponents}
          name={field.name}
          value={field.value}
          onChange={value => {
            form.setFieldTouched(field.name, true);
            form.setFieldValue(field.name, value);
          }}
          isMulti
          styles={customSelectStyle}
          placeholder={'Select'}
          options={[
            { value: 1, label: 'Tire replacement' },
            { value: 2, label: 'Trailer repair' },
            { value: 3, label: 'Truck repair' },
          ]}
        />
        <ErrorMessage
          name={field.name}
          className="text-error-1 text-[10px]"
          component="span"
        />
      </Fragment>
    );
  };

  const handleImageUpload = async e => {
    try {
      const file = e.target ? e.target.files[0] : e;
      if (!file) {
        throw new Error('No file selected.');
      }

      const imageUrl = await reduceImageSizeAndQuality(file, 1100000);
      const formData = new FormData();
      formData.append('images', imageUrl);

      const responseImage = await DefaultService.imageUpload(formData);

      if (
        !responseImage ||
        !responseImage.images ||
        responseImage.images.length === 0
      ) {
        throw new Error('Image upload failed or response data is invalid.');
      }

      setUrl(responseImage.images[0]);
    } catch (error) {
      toast.error(
        error.message || 'An error occurred while uploading the image.'
      );
    }
  };

  const getMechanics = async () => {
    try {
      const response = await HomeServices.getUsers();
      dispatch(setMechanics(response));
    } catch (error) {
      console.error('Error fetching mechanics:', error);
    }
  };

  const handleSubmit = async values => {
    const data = {
      name: `${values.firstName} ${values.lastName}`,
      phoneNumber: values.phoneNumber,
      serviceCategoryIDs: values.serviceCategoryIDs
        .map(item => item.value)
        .join(','),
      image: url ? url : null,
    };

    const response = await dispatch(addMechanicToCompany(data));

    if (response.payload.data) {
      toast.success(response.payload.message);
      getMechanics();
      setIsOpen(false);
    }
  };

  return (
    <div className={`w-full flex`}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        {({ isValid, isSubmitting, values, setFieldValue }) => (
          <Form className="w-full flex flex-col gap-4">
            <div className={`flex w-full flex-wrap justify-start gap-2`}>
              <div className="flex-1">
                <FormikInput
                  name={`firstName`}
                  label={`First Name`}
                  placeholder={`First name`}
                  labelClassName="font-light text-base leading-6 text-grey-2 pb-1"
                  inputClassName="font-normal text-base leading-6 text-primary-2"
                  required
                />
              </div>
              <div className="flex-1">
                <FormikInput
                  name={`lastName`}
                  label={`Last Name`}
                  placeholder={`Last name`}
                  labelClassName="font-light text-base leading-6 text-grey-2 pb-1"
                  inputClassName="font-normal text-base leading-6 text-primary-2"
                  required
                />
              </div>
            </div>
            <div className="w-full flex flex-col gap-1">
              <label className={'text-base font-normal text-grey-2'}>
                Technician's Phone Number
                <label className="text-[#DA1414]">*</label>
              </label>
              <Field name="phoneNumber" component={FormikPhoneInput} />
            </div>
            <div className={`w-full flex flex-col gap-1`}>
              <label className={`font-light text-base leading-6 text-grey-2`}>
                Capabilities
                <label className="text-[#DA1414]">*</label>
              </label>
              <Field name="serviceCategoryIDs" component={SelectInput} />
            </div>
            <div className={`w-full flex flex-col `}>
              <label className={`font-light text-base leading-6`}>
                Profile Image (optional)
              </label>
              <div className={`flex justify-between content-center`}>
                <CustomUpload
                  selectedFile={values.image}
                  inputName="image"
                  allowedTypes={['image/jpeg', 'image/png', 'image/svg+xml']}
                  setSelectedFile={e => {
                    setFieldValue('image', e);
                    handleImageUpload(e);
                  }}
                />
              </div>
            </div>
            <Button
              classNames="mt-3"
              type="submit"
              title={
                !isSubmitting ? (
                  'Save'
                ) : (
                  <AiOutlineLoading size={`24`} className={`animate-spin`} />
                )
              }
              disabled={!isValid || isSubmitting}
            />
            {/* <Button title="Save" type="submit" classNames="mt-3" /> */}
          </Form>
        )}
      </Formik>
    </div>
  );
};
