import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { BulkUploadTab } from '../..';
import { AddMechanicManually } from './AddMechanicManually';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { IoMdInformationCircle } from 'react-icons/io';
import { useFormikContext } from 'formik';
import { findMechanicByPhoneNumber } from 'redux/mechanics/operations';
import { useDispatch } from 'react-redux';
import { MechanicPreview } from './MechanicPreview';
import { Button } from 'component-library';
import { toast } from 'react-toastify';
import { addMechanicByPhoneNumber } from 'redux/mechanics/operations';
import { setMechanics } from 'redux/mechanics/mechanic.slice';
import { HomeServices } from 'services/home.service';
import { Field } from 'formik';
import { FormikPhoneInput } from 'component-library';

const initialFields = {
  phoneNumber: {
    name: 'phoneNumber',
    placeholder: `Enter Technician’s phone number`,
    type: 'text',
    label: 'Technician’s Phone number',
  },
};

const initialValues = {
  [initialFields.phoneNumber.name]: '',
};

const validationSchema = Yup.object().shape({
  [initialFields.phoneNumber.name]: Yup.string().test(
    'is-valid-phone',
    'Phone number is invalid',
    value => {
      return isValidPhoneNumber(value || '');
    }
  ),
});

const AutoFetchMechanic = ({ setFetchedMechanic, setNotFoundMessage }) => {
  const dispatch = useDispatch();
  // Grab values and submitForm from context
  const { values, isValid, dirty } = useFormikContext();

  React.useEffect(() => {
    const fetchMechanic = async () => {
      const response = await dispatch(
        findMechanicByPhoneNumber(encodeURIComponent(values.phoneNumber))
      );
      if (response.payload.data.mechanic) {
        setFetchedMechanic(response.payload.data);
        setNotFoundMessage('');
      } else {
        setFetchedMechanic({});
        setNotFoundMessage(response.payload.message);
      }
    };

    if (isValid && dirty) {
      fetchMechanic();
    }
  }, [values]);

  return null;
};

const AddMechanic = ({ isOpen, setIsOpen }) => {
  const [activeTab, setActiveTab] = useState('single');
  const [isAddMechanicManually, setIsAddMechanicManually] = useState(false);
  const [fetchedMechanic, setFetchedMechanic] = useState({});
  const [notFoundMessage, setNotFoundMessage] = useState('');

  const dispatch = useDispatch();

  const handleAddMechanic = async phoneNumber => {
    try {
      const response = await dispatch(
        addMechanicByPhoneNumber(encodeURIComponent(phoneNumber))
      );
      if (response.payload.data) {
        toast.success(response.payload.message);
        setIsOpen(false);
        getMechanics();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMechanics = async () => {
    try {
      const response = await HomeServices.getUsers();
      dispatch(setMechanics(response));
    } catch (error) {
      console.error('Error fetching mechanics:', error);
    }
  };

  return (
    <div className="flex flex-col w-full items-center justify-center px-6 gap-y-6 transition-all ">
      <img
        src="https://mymechanicimages.s3.amazonaws.com/web-assets/service-provider/add_mechanic_icon.svg"
        alt="main_icon"
      />
      <div className="flex flex-col w-full gap-y-3 items-center">
        <span className="text-[26px] leading-10 font-semibold text-center max-w-[75%]">
          Add a Technician
        </span>
        <span className="text-[15px] leading-[21.75px] font-normal text-[#A6A9AD] text-center max-w-[80%]">
          You can add a Technician by the phone number or massively upload all
          the drivers of your company in a couple of clicks.
        </span>
      </div>
      {/* Tabs */}
      <div className="flex w-full bg-grey-4 p-[1px] rounded-lg items-center h-[37px]">
        <button
          className={`w-full rounded-lg text-[13px] leading-5 h-full ${
            activeTab === 'single'
              ? 'border-[0.5px] border-[#2D6DCA] bg-white text-[#2D6DCA]'
              : 'bg-transparent text-primary-2'
          }`}
          onClick={() => {
            setActiveTab('single');
          }}
        >
          Add a Technician
        </button>
        <button
          className={`w-full rounded-lg text-[13px] leading-5 h-full ${
            activeTab === 'bulk'
              ? 'border-[0.5px] border-[#2D6DCA] bg-white text-[#2D6DCA]'
              : 'bg-transparent text-primary-2'
          }`}
          onClick={() => {
            setActiveTab('bulk');
          }}
        >
          Mass Upload
        </button>
      </div>
      {activeTab === 'single' ? (
        <div className="flex flex-col gap-y-4 pb-4 w-full">
          {!isAddMechanicManually && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={values => {
                handleAddMechanic(values.phoneNumber);
              }}
              validateOnMount
            >
              {({ isValid, dirty }) => (
                <Form className="w-full flex flex-col gap-y-2">
                  <div className="w-full flex flex-col gap-1">
                    <label className={'text-base font-normal text-primary-2'}>
                      Technician's Phone Number
                      <label className="text-[#DA1414]">*</label>
                    </label>
                    <Field name="phoneNumber" component={FormikPhoneInput} />
                  </div>
                  <AutoFetchMechanic
                    setFetchedMechanic={setFetchedMechanic}
                    setNotFoundMessage={setNotFoundMessage}
                  />
                  {notFoundMessage && (
                    <div
                      className={`bg-[#FEEFEF] py-1.5 px-4 flex flex-row items-center rounded-[8px]`}
                    >
                      <IoMdInformationCircle color="#DA1414" />
                      <span className="text-[13px] text-[#DA1414] font-normal ml-1">
                        {notFoundMessage}
                      </span>
                    </div>
                  )}
                  {fetchedMechanic.mechanic && (
                    <MechanicPreview mechanic={fetchedMechanic} />
                  )}

                  {notFoundMessage && (
                    <span
                      className="text-accent-1 cursor-pointer"
                      onClick={() =>
                        setIsAddMechanicManually(!isAddMechanicManually)
                      }
                    >
                      + Add a Technician Manually
                    </span>
                  )}
                  <Button
                    title="Save"
                    type="submit"
                    disabled={!isValid || notFoundMessage || !dirty}
                    classNames="mt-3"
                  />
                </Form>
              )}
            </Formik>
          )}

          {isAddMechanicManually && (
            <AddMechanicManually setIsOpen={setIsOpen} />
          )}
        </div>
      ) : (
        <div className="w-full flex flex-col gap-y-10">
          <div className="w-full flex flex-col">
            <BulkUploadTab
              onUpload={file => {
                console.log({ file });
              }}
            />
            <span className="text-sm leading-[17.5px] text-[#A6A9AD]">
              Maximum file size of 20 mb
            </span>
          </div>
          <Button title="See uploaded results" />
        </div>
      )}
    </div>
  );
};

export default AddMechanic;
