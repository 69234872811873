import React, { useEffect, useRef, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa6';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import { ScrollTab } from '../ScrollTab';
import { RequestDetails } from '../RequestDetails';
import { ProfileService } from 'services/profile.service';
import { Loader } from 'components/common';

export const ModalMechanic = ({ mechanic, option, showDelete }) => {
  const carousel = useRef();
  const [activeTab, setActiveTab] = useState(0);
  const [mechanicData, setMechanicData] = useState(null);
  const [loader, setLoader] = useState(false);
  const userDetails = mechanicData?.userDetails;
  const ratings = mechanicData?.ratingsReceivedWithProfileImage;
  const name = `${userDetails?.firstName} ${
    userDetails?.lastName ? userDetails.lastName : ''
  }`;

  useEffect(() => {
    const getUserData = async () => {
      try {
        setLoader(true);
        const result = await ProfileService.userDetails({
          userId: mechanic.id,
        });
        setMechanicData(result);
      } finally {
        setLoader(false);
      }
    };
    getUserData();
  }, [mechanic]);

  const scrollToTab = index => {
    if (carousel.current) {
      carousel.current.scrollLeft = 0;

      setActiveTab(index);
    }
  };

  return (
    <div className="flex flex-col gap-y-8">
      <div className="w-full flex flex-col gap-y-6 mt-[30px]">
        <div className="flex justify-between w-full">
          <div className="w-full flex flex-col gap-x-2">
            <span className="text-lg font-semibold mb-[15px]">{name}</span>
            {loader && <Loader />}
            <div className="flex items-center gap-x-2 mb-[5px]">
              <FaStar className="text-base text-accent-1" />
              <p>
                {mechanicData?.averageRating ? mechanicData?.averageRating : 0}{' '}
                ({mechanic?.jobCount} Jobs)
              </p>
            </div>
            <p>
              Rate:{' '}
              {userDetails?.rate ? `${userDetails?.rate}` : 'not specified'}
            </p>
          </div>
          {userDetails?.images?.length > 0 ? (
            <img
              src={userDetails.images[userDetails?.images?.length - 1].url}
              alt="User"
              className="w-[100px] h-[100px] object-cover rounded-xl"
            />
          ) : (
            <div className=" flex justify-center items-center w-[130px] h-[100px] rounded-[10px] bg-accent-2 object-cover">
              <FaUser className="h-[40px] w-[40px] fill-accent-1" />
            </div>
          )}
        </div>
      </div>

      {option ? (
        <RequestDetails driver={mechanic.serviceRequest[0]} />
      ) : (
        <>
          <hr className="text-primary-3" />
          <div>
            <p className=" mb-[25px] font-semibold">About:</p>
            <p>
              {userDetails?.about
                ? userDetails.about
                : 'No information provided'}
            </p>
          </div>

          <hr className="text-primary-3" />
          <div>
            <p className=" mb-[25px] font-semibold">Mechanic Capabilities:</p>

            {userDetails?.services?.length > 0 ? (
              userDetails?.services?.map((service, index) => {
                return (
                  <p
                    className="gap-5 mr-[5px] inline-flex text-[16px] font-medium px-[15px] py-[7px] rounded-[30px] border border-solid border-accent-1 bg-accent-2 text-accent-1 "
                    key={index}
                  >
                    {service.serviceCategory.title}
                  </p>
                );
              })
            ) : (
              <p>No capabilities</p>
            )}
          </div>

          <hr className="text-primary-3" />

          <div className="flex">
            <span className="w-full font-semibold">Reviews</span>

            {ratings?.length > 1 && (
              <div className="flex gap-x-1 items-end">
                <button
                  onClick={() => scrollToTab(activeTab - 1)}
                  className={`w-8 h-8 rounded-[50%] border border-[#7a859a] flex items-center justify-center text-[#212735] ${
                    activeTab === 0
                      ? 'opacity-50 cursor-not-allowed'
                      : 'active:text-white bg-white active:bg-[#212735] transition-all'
                  }`}
                  disabled={activeTab === 0}
                >
                  <FaChevronLeft />
                </button>
                <button
                  onClick={() => scrollToTab(activeTab + 1)}
                  className={`w-8 h-8 rounded-[50%] border border-[#7a859a] flex items-center justify-center text-[#212735] ${
                    activeTab === ratings.length - 1
                      ? 'opacity-50 cursor-not-allowed'
                      : 'active:text-white bg-white active:bg-[#212735] transition-all'
                  }`}
                  disabled={activeTab === ratings.length - 1}
                >
                  <FaChevronRight />
                </button>
              </div>
            )}
          </div>

          {ratings?.length > 0 ? (
            ratings?.length === 1 ? (
              <div className="min-w-[75%] md:min-w-[45%] lg:min-w-[25%] flex justify-center items-center">
                <ScrollTab data={ratings[0]} />
              </div>
            ) : (
              <div
                ref={carousel}
                className="flex gap-x-6 w-full overflow-x-scroll scrollbar-none justify-center items-center"
                style={{ scrollSnapType: 'x mandatory', overflowX: 'scroll' }}
              >
                {ratings?.map(
                  (item, index) => (
                    <div
                      key={item.id}
                      className={`min-w-[75%] md:min-w-[45%] lg:min-w-[25%] ${
                        index === activeTab ? 'block' : 'hidden'
                      }`}
                    >
                      <ScrollTab data={item} />
                    </div>
                  )
                )}
              </div>
            )
          ) : (
            <p>No reviews</p>
          )}

          <hr className="text-primary-3" />
          <button
            type="button"
            onClick={() => showDelete(mechanic.id, name)}
            className=" text-error-1 text-[14px] py-[14px]  border-error-1 border rounded-[15px] hover:text-white hover:bg-error-1"
          >
            Delete the mechanic
          </button>
        </>
      )}
    </div>
  );
};
