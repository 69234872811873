import { ThreeDots } from 'react-loader-spinner';
import { Box } from './Loader.styled';

export const Loader = () => {
  return (
    <Box>
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="rgb(76, 75, 75)"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </Box>
  );
};
