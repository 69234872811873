import { LuTruck } from 'react-icons/lu';
import { HiOutlineDocumentCheck } from 'react-icons/hi2';
import { IoMdCheckmark } from 'react-icons/io';
import { IoNotificationsOutline } from 'react-icons/io5';

export const onboardingSlugs = {
  ein: 'ein',
  addMechanics: 'add_mechanics',
  enableNotifications: 'enable_notifications',
  verification: 'verification',
};

export const AllOnboardingSteps = {
  verification: {
    title: 'Get verified',
    description: 'Verify your email or phone number',
    icon: <IoMdCheckmark />,
    slug: 'verification',
  },
  ein: {
    title: 'Enter EIN number',
    description: 'Verify your business account',
    icon: <HiOutlineDocumentCheck />,
    slug: 'ein',
  },
  addMechanics: {
    title: 'Upload Mechanics',
    description: 'Add mechanics to your company',
    icon: <LuTruck />,
    slug: 'add_mechanics',
  },
  enableNotifications: {
    title: 'Set notifications',
    description: 'Control how your receive updates and alerts ',
    icon: <IoNotificationsOutline />,
    slug: 'enable_notifications',
  },
};

export const ServiceProviderOnboardingSteps = [
  AllOnboardingSteps.verification,
  AllOnboardingSteps.ein,
  AllOnboardingSteps.addMechanics,
  AllOnboardingSteps.enableNotifications,
];

export const OnboardingSteps = {
  serviceProvider: ServiceProviderOnboardingSteps,
};
