import React, { useEffect, useRef, useState } from 'react';
import { IoImageOutline } from 'react-icons/io5';
import { IoMdInformationCircle } from 'react-icons/io';

const CustomUpload = ({
  selectedFile,
  setSelectedFile,
  allowedTypes,
  maxFileSize,
  inputName,
}) => {
  const fileInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleFileChange = e => {
    const file = e.target.files[0];
    const allowedTypesList = allowedTypes ?? [
      'image/jpeg',
      'image/png',
      'image/svg+xml',
    ];
    const maxSize = maxFileSize ?? 2 * 1024 * 1024; // 2MB
    if (
      file &&
      file.type &&
      allowedTypesList.includes(file.type) &&
      file.size <= maxSize
    ) {
      setSelectedFile(file);
      setErrorMessage('');
    } else {
      setSelectedFile(null);
      setErrorMessage('File must be less then 2 MB');
    }
  };
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  // useEffect(() => {
  //   isUploadFile(selectedFile ? false : true);
  // }, [selectedFile, isUploadFile]);

  return (
    <div className="w-full">
      <div className="flex flex-row justify-between items-center">
        <div
          onClick={handleButtonClick}
          className={`w-[48%] h-[90px] rounded-[20px]  flex justify-center items-center cursor-pointer border-secondary-1 border-[1px]`}
        >
          {selectedFile ? (
            <img
              ref={fileInputRef}
              src={URL.createObjectURL(selectedFile)}
              alt="profile"
              className="object-contain w-full h-full"
            />
          ) : (
            <>
              <input
                ref={fileInputRef}
                className="hidden"
                type="file"
                accept=".jpeg,.jpg,.png,.svg"
                name={inputName}
                onChange={handleFileChange}
              />
              <IoImageOutline size={24} />
              <span className="text-base font-normal text-primary-2 ml-2">
                Upload Image
              </span>
            </>
          )}
        </div>
        <span
          className={` text-center  font-normal text-sm w-[62%]  ${
            errorMessage ? 'text-[#DA1414]' : 'text-secondary-1'
          }`}
        >
          Less then 2 Mb, jpeg/svg/png
        </span>
      </div>
      <div>
        {selectedFile && (
          <p className="text-base text-xs font-normal text-primary-2 mt-4 mb-2">
            {selectedFile.name}
          </p>
        )}
        {selectedFile && (
          <p
            onClick={() => {
              fileInputRef.current.value = null;
              setSelectedFile(null);
            }}
            className="text-[#DA1414] text-base font-normal underline cursor-pointer"
          >
            Remove
          </p>
        )}
      </div>
      {errorMessage && (
        <div
          className={`bg-[#FEEFEF] py-2 px-4 flex flex-row items-center rounded-[8px] mt-1`}
        >
          <IoMdInformationCircle color="#DA1414" />
          <span className="text-[13px] text-[#DA1414] font-normal ml-1">
            {errorMessage}
          </span>
        </div>
      )}
    </div>
  );
};

export default CustomUpload;
