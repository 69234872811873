import { createSlice } from "@reduxjs/toolkit";
import { getRecommendedCompanies } from "./operations";

const initialState = {
  loading:false,
  recommendedCompanies:[]
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getRecommendedCompanies.pending, (state, _) => {
      state.loading = true;
    });
    builder.addCase(getRecommendedCompanies.fulfilled, (state, action) => {
      state.recommendedCompanies = action.payload.data.companies;
      state.loading = false;
    });
    builder.addCase(getRecommendedCompanies.rejected, (state, _) => {
      state.loading = false;
    });
  },
});


export const homeReducer = homeSlice.reducer;
