import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import useWindowSize from 'hooks/useWindowSize';
import { LogoLoader } from 'components/common';
import { IoIosSearch } from 'react-icons/io';
import { FaUser } from 'react-icons/fa';
import { FaClockRotateLeft } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Collapse } from 'antd';
import { dummyRequests } from 'dummy-data/dashboard/requests';
import { ServiceRequestStatusConverter } from 'helpers/dashboard.helpers';

const RepairTypeConverter = {
  truck_repair: 'Truck Repair',
  tire_repair: 'Tire Replacement and Repair',
  trailer_repair: 'Trailer Repair',
};

const items = data => {
  return [
    {
      key: '1',
      label: 'Request Details:',
      children: (
        <div className="flex flex-col gap-y-3">
          <div className="flex flex-col gap-y-1">
            <span className="text-[#A6A9AD] text-sm leading-[20.3px] font-medium">
              Vin:
            </span>
            <div className="w-full py-[8px] px-[16px] rounded-[10px] bg-white text-base leading-[23.2px] font-normal">
              {data.vin}
            </div>
          </div>
          <div className="flex flex-col gap-y-1">
            <span className="text-[#A6A9AD] text-sm leading-[20.3px] font-medium">
              Description:
            </span>
            <div className="w-full py-[8px] px-[16px] rounded-[10px] bg-white text-base leading-[23.2px] font-normal">
              {data.description}
            </div>
          </div>
        </div>
      ),
    },
  ];
};

const ItemView = ({ item }) => {
  return (
    <div
      style={{
        boxShadow: '0px 4px 12px 0px #00000040',
      }}
      className="rounded-2xl p-5 gap-y-4 w-full min-w-full min-h-[238px] flex flex-col"
    >
      <div className="flex justify-between">
        <div className="flex gap-x-4">
          <img
            src={item.requestOwner?.images[0]?.url}
            alt={item.requestOwner?.firstName}
            className="w-[80px] h-[80px] rounded-md object-cover"
          />
          <div className="flex flex-col justify-between">
            <div className="flex flex-col">
              <span className="text-lg font-medium text-primary-2">{`${item.requestOwner?.firstName} ${item.requestOwner?.lastName}`}</span>
              <span className="text-sm text-primary-2">{`${item.address}`}</span>
            </div>
            <span className="text-xs opacity-30">
              {moment(item.createdAt).format('DD.MM.YYYY, LT')}
            </span>
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <div className="flex flex-col">
            <span className="text-xs text-primary-2">Order Number</span>
            <span className="text-[17px] text-primary-2">#{item.id}</span>
          </div>
          <div className="rounded-[23px] py-[3px] px-[10px] bg-[#C7FFCA] text-[#51C656]">
            {ServiceRequestStatusConverter[item.status]}
          </div>
        </div>
      </div>
      <div className="bg-[#DFECFB] rounded-[23px] px-[10px] py-[3px] text-[#2D6DCA] w-fit text-xs">
        {RepairTypeConverter[item.type]}
      </div>
      <Collapse
        items={items(item)}
        accordion
        expandIconPosition="right"
        bordered={false}
        size="large"
        style={{
          backgroundColor: '#F1F3F8',
          color: '#1D1D1D',
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '22.08px',
        }}
      ></Collapse>
      <div></div>
    </div>
  );
};

const FleetRequests = ({ offset }) => {
  const { isMobile, isTablet, isSmallTablet, isMediumTablet } = useWindowSize();
  const [loading] = useState(false);
  // const dummyRequests = useSelector(state => state.requestData.requestData);
  const [filteredRequests, setFilteredRequests] = useState([]);

  useEffect(() => {
    if (dummyRequests) {
      setFilteredRequests(dummyRequests);
    }
  }, [dummyRequests]);

  const handleInputChange = event => {
    const inputValue = event.target.value.trim().toLowerCase();

    if (inputValue === '') {
      setFilteredRequests(dummyRequests);
    } else {
      const filteredData = dummyRequests.filter(
        item =>
          (item.requestOwner?.firstName &&
            item.requestOwner?.firstName.toLowerCase().includes(inputValue)) ||
          (item.requestOwner.lastName &&
            item.requestOwner.lastName.toLowerCase().includes(inputValue))
      );
      setFilteredRequests(filteredData);
    }
  };

  return (
    <motion.div
      initial={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : -1000,
      }}
      animate={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : offset,
      }}
      exit={{
        x: isMobile || isTablet || isMediumTablet || isSmallTablet ? 0 : -1000,
      }}
      transition={{ bounce: 0 }}
      className="h-screen overflow-y-scroll scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-slate-300 sm:p-8 p-4 py-8 min-h-screen absolute w-full sm:w-[60vw] lg:w-[45vw] flex flex-col gap-y-12 rounded-e-2xl bg-white left-0 top-0 z-[1000]"
    >
      {loading ? (
        <LogoLoader />
      ) : (
        <div>
          {dummyRequests?.length > 0 ? (
            <div className="w-full flex flex-col gap-y-8">
              <div className="flex flex-col gap-y-3">
                <span className="font-semibold">Search:</span>

                <div className="rounded-[10px] p-[13px] flex items-center w-auto h-12 bg-gray-100">
                  <IoIosSearch className="text-[25px] text-primary-3" />
                  <input
                    type="text"
                    placeholder="Search"
                    className="pl-[11px] text-primary-2 bg-gray-100 focus:outline-none placeholder:text-secondary-1"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="h-[1px] bg-grey-1"></div>

              <div className="flex flex-col gap-y-6">
                {filteredRequests.length > 0 &&
                  filteredRequests.map((item, index) => (
                    <ItemView item={item} key={item.id} />
                  ))}
              </div>
            </div>
          ) : (
            <div className="flex flex-col text-center gap-x-4 items-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] opacity-70">
              <FaClockRotateLeft className="text-primary-3 text-9xl" />
              <span className="text-2xl text-primary-3">
                You have no requests yet.
              </span>
            </div>
          )}
        </div>
      )}
    </motion.div>
  );
};

export default FleetRequests;
