export const favorites = [
  {
    name: 'G & G Truck Repair, LLC',
    address: '11801 C F Hawn Fwy, Dallas, TX 75253',
    rating: 5,
    id: '4c75829f-e65e-43fa-b130-d6f14014bafa',
    image:
      'https://images.unsplash.com/photo-1678984240142-28438aec347e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    name: 'TA Truck Service',
    address: '11801 C F Hawn Fwy, Dallas, TX 75253',
    rating: 3,
    id: '4c75829f-e65e-43fa-b130-d6f14014bafa',
    image:
      'https://images.unsplash.com/photo-1678984240142-28438aec347e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    name: 'Paul and Sons Repair',
    address: '11801 C F Hawn Fwy, Dallas, TX 75253',
    rating: 5,
    id: '4c75829f-e65e-43fa-b130-d6f14014bafa',
    image:
      'https://images.unsplash.com/photo-1678984240142-28438aec347e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    name: 'Maxwell Repairs',
    address: '11801 C F Hawn Fwy, Dallas, TX 75253',
    rating: 4.2,
    id: '4c75829f-e65e-43fa-b130-d6f14014bafa',
    image:
      'https://images.unsplash.com/photo-1678984240142-28438aec347e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
];
