import React from 'react';
import { FaStar } from 'react-icons/fa6';
import profileImagePlaceholder from '../../../assets/profile-image-placeholder.jpg';

export const MechanicRequestDetails = ({ data, index, onRequest }) => {
  return (
    <div className="flex items-start justify-between gap-x-2">
      <div className="flex gap-x-2 sm:gap-x-4 w-[50%]">
        <div>
          <img
            src={data.images[0] ? data.images[0].url : profileImagePlaceholder}
            alt={data.name}
            className="w-[60px] h-[60px] object-cover rounded-[10%]"
          />
          <span className="text-accent-1 text-sm">{data.distance.toFixed(2)}mi</span>
        </div>
        <div className="flex flex-col">
          <span className="font-bold text-sm">
            {index + 1}. {data.name}
          </span>
          <span className="text-semibold text-sm">
            {' '}
            ${data.rate ?? 'not specified'}
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-y-2 items-center">
        <div className="flex flex-col xl:flex-row gap-y-1 gap-x-1 items-center">
          <div className="flex gap-x-1 items-center">
            <FaStar className="text-base text-accent-1" />
            <span className="">{data.rating.toFixed(2) || 0}</span>
          </div>
          <span>{`(${data.jobCount} Jobs)`}</span>
        </div>
        <button
          type="button"
          className="px-3 py-2 border border-primary-3 rounded-md"
          onClick={() => {
            onRequest(data);
          }}
        >
          Request
        </button>
      </div>
    </div>
  );
};
