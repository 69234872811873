import React from 'react';
import { IoMdClose } from 'react-icons/io';
import profileImagePlaceholder from '../../assets/profile-image-placeholder.jpg';

export const MarkerDetails = ({ data, index, onClick }) => {
    return (
      <button
        // disabled={!isMobile && !isTablet}
        onClick={onClick}
        className="flex items-center gap-x-4"
      >
        <img
          src={data.images[0] || profileImagePlaceholder}
          className="w-[60px] h-[60px] rounded-[50%] cover"
          alt="driver"
        />
        <div className="flex items-center gap-x-2">
          <div className="flex flex-col gap-x-2 items-start">
            <span className="text-[16px] font-medium">Unit {index + 1}</span>
            <div className="flex flex-col text-[14px] text-left">
              <span className="">
                {data?.firstName} {data?.lastName}
              </span>
              {data?.vehicles?.length > 0 && (
                <span className="text-primary-3 text-left">{data.vehicles[0].vin}</span>
              )}
            </div>
          </div>
        </div>
        <div className="absolute right-2 top-2">
          <IoMdClose color="#B8B8B8" height={10} width={10} />
        </div>
      </button>
    );
  };