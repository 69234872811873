import apple from 'assets/download_apple.jpeg';
import google from 'assets/download_play.jpg';
import { FaRegCheckCircle } from 'react-icons/fa';

const ItemView = ({ title, subtitle, img, list, reverse = false }) => {
  return (
    <div
      className={`mb-4 w-full lg:flex items-center justify-between rounded-[20px] overflow-hidden 
      ${
        reverse
          ? `bg-gradient-to-tr from-blue-700 to-sky-400 `
          : `bg-gradient-to-br from-sky-100 to-sky-200 `
      }}`}
    >
      <div className="lg:pt-[32px] lg:pl-[40px] lg:pb-[32px] lg:pr-0 p-[10px] text-center lg:text-left">
        <p
          className={`mb-4 font-bold lg:text-[40px] text-[28px] 
          ${reverse ? `text-white` : `text-[#2D6DCA]`}`}
        >
          {title}
        </p>
        <p
          className={`text-base mb-9 
          ${reverse ? `text-white` : `text-[#2D6DCA]`}`}
        >
          {subtitle}
        </p>

        {list.split(',').map((item, index) => (
          <div key={index} className="flex items-center mb-4">
            <FaRegCheckCircle
              className={`h-[14px] w-[14px] 
              ${reverse ? `fill-white` : `fill-[#2D6DCA]`}`}
            />
            <div
              className={`ml-2 text-base
              ${reverse ? `text-white` : `text-[#2D6DCA]`}`}
            >
              {item.trim()}
            </div>
          </div>
        ))}
        <div className="flex mt-11">
          <a
            href="https://www.apple.com/uk/store"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={apple}
              alt="content"
              className="object-fit w-[132px] h-[41px] mr-2"
            />
          </a>

          <a
            href="https://play.google.com/store/games"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={google}
              alt="content"
              className="object-fit w-[132px] h-[41px]"
            />
          </a>
        </div>
      </div>
      <img
        src={img}
        alt="content"
        className="object-cover lg:w-[600px] lg:h-[429px] w-[307px] h-[215px] lg:mr-[5%]"
      />
    </div>
  );
};

export default ItemView;