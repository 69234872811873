export const ModalDelete = ({ handleDelete, handleCancel, isMechanic }) => {
  return (
    <div className="flex flex-col gap-y-8">
      <h2 className="text-lg font-semibold text-center mt-[30px]">
        Are you sure you want to delete{' '}
        {isMechanic ? `${isMechanic} from your company` : 'your profile'}?
      </h2>
      {!isMechanic && (
        <p className="text-center">
          You will not be able to independently restore the account. To do so,
          please contact technical support at support@mymechanic.app
        </p>
      )}
      <div className="flex  justify-around">
        <button
          type="button"
          onClick={() => handleCancel()}
          className="ml-[5px] w-[150px] h-12 text-[16px] font-[15px] px-[15px] py-[7px] rounded-[8px] border border-solid border-gray-300 focus:outline-none"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={e => handleDelete(e)}
          className="text-error-1 ml-[5px] w-[150px] h-12 text-[16px] font-[15px] px-[15px] py-[7px] rounded-[8px] border border-solid border-error-1 focus:outline-none"
        >
          Delete
        </button>
      </div>
    </div>
  );
};
