import React, { useState } from 'react';
import { FormikInput } from 'component-library';
import { FaStar } from 'react-icons/fa6';
import { Button } from 'component-library';
import { ReviewCard } from './ReviewCard';

/**
 * Renders the employee profile tab.
 * @param {Object} props - The component props.
 * @param {Array} props.serviceRequests - The list of service requests.
 * @param {number} props.rating - The rating of the employee.
 * @param {Object} props.mechanic - The mechanic object.
 * @returns {JSX.Element} The rendered component.
 */
export const EmployeeProfileTab = ({ serviceRequests, rating, mechanic }) => {
  const [showAllReviews, setShowAllReviews] = useState(false);
  const reviewsWithFeedback = serviceRequests.filter(
    review => review.feedback !== null
  );
  const visibleReviews = showAllReviews
    ? reviewsWithFeedback
    : reviewsWithFeedback.slice(0, 1);

  return (
    <div className={`flex w-full flex-wrap justify-start gap-4`}>
      <div className="flex-1">
        <FormikInput
          name="rate"
          label="Rate"
          placeholder="Rate"
          labelClassName="font-light text-sm leading-6 text-grey-2"
          inputClassName="font-normal text-base leading-6 text-primary-2"
          className={`text-base after:content-['$/h']`}
        />
      </div>
      <div className="w-full">
        <FormikInput
          as="textarea"
          name="about"
          label="About"
          placeholder="About"
          labelClassName="font-light text-sm leading-6 text-grey-2"
          inputClassName="font-normal text-base leading-6 text-primary-2"
        />
      </div>
      <div className={`w-full flex flex-col`}>
        <label className={`font-light text-sm leading-6 text-grey-2`}>
          Reviews
        </label>
        <div className="flex items-center gap-x-2 mb-[5px]">
          <FaStar className="text-base text-accent-1" />
          <p className="text-sm">
            {rating}
            <span className=" text-primary-3 text-xs font-normal ml-1">
              ({mechanic.ratingsReceived.length} reviews)
            </span>
          </p>
          {reviewsWithFeedback.length > 1 && (
            <Button
              title={showAllReviews ? 'View Less' : 'View All'}
              variant="text"
              size="default"
              classNames="text-sm text-accent-1 ml-auto !w-fit"
              onClick={() => setShowAllReviews(!showAllReviews)}
            />
          )}
        </div>
        {visibleReviews.map((review, index) => (
          <ReviewCard key={index} data={review.feedback} />
        ))}
      </div>
    </div>
  );
};
