import axios from 'axios';
import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { ACCESS_TOKEN_STORAGE_KEY } from 'constants/auth.constants';
import { apiV2BaseUrl } from 'api';
import { saveAuthTokensToStorage } from 'helpers/auth.helpers';

axios.defaults.baseURL = apiV2BaseUrl;
const setAuthHeader = token => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const generatePasswordResetLink = createAsyncThunk(
  'forgot-password',
  async (email, thunkAPI) => {
    const encodedEmail = encodeURIComponent(email);
    try {
      const res = await axios.post(
        `auth/forgot-password?email=${encodedEmail}`
      );
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const signUpCompany = createAsyncThunk(
  'sign-up-company',
  async (credentials, thunkAPI) => {
    try {
      const res = await axios.post('auth/sign-up-company', credentials);
      setAuthHeader(res.data.token);
      console.log('🚀 ~ file: operations.js:17 ~ res.data:', res.data);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const signUpCompanyV2 = createAsyncThunk(
  'sign-up-company-v2',
  async (credentials, thunkAPI) => {
    try {
      const res = await axios.post('auth/sign-up-company-v2', credentials);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const signUpIndividual = createAsyncThunk(
  'sign-up-individual',
  async (credentials, thunkAPI) => {
    try {
      const res = await axios.post('auth/sign-up-individual', credentials);
      setAuthHeader(res.data.token);
      console.log('🚀 ~ file: operations.js:35 ~ res.data:', res.data);
      return res.data;
    } catch (error) {
      console.log(
        '🚀 ~ file: operations.js:37 ~ error:',
        error.response.data.message
      );
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  'verify-email',
  async (credentials, thunkAPI) => {
    try {
      const res = await axios.post('auth/verify-email', credentials);
      setAuthHeader(res.data.token);
      return res.data;
    } catch (error) {
      console.log(
        '🚀 ~ file: operations.js:37 ~ error:',
        error.response.data.message
      );
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const signIn = createAsyncThunk(
  'sign-in',
  async (credentials, thunkAPI) => {
    try {
      const res = await axios.post('auth/sign-in', credentials);
      return res.data;
    } catch (error) {
      console.log(
        '🚀 ~ file: operations.js:37 ~ error:',
        error.response.data.message
      );
      toast.error(
        Array.isArray(error.response.data.message)
          ? error.response.data.message[0]
          : error.response.data.message
      );
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'reset-password',
  async (credentials, thunkAPI) => {
    try {
      console.log(credentials);
      const res = await axios.post('auth/reset-password', credentials);
      return res.data;
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getProfile = createAsyncThunk('get-profile', async thunkAPI => {
  try {
    const res = await api.get(
      '/user/get-details',
      {},
      {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN_STORAGE_KEY}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    if (error?.response.status === 401) {
      saveAuthTokensToStorage('', '');
    } else {
      console.log(
        '🚀 ~ file: operations.js:37 ~ error:',
        error.response.data.message
      );
      toast.error(
        Array.isArray(error.response.data.message)
          ? error.response.data.message[0]
          : error.response.data.message
      );
    }
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const logoutUser = createAsyncThunk('logout', async thunkAPI => {
  try {
    const res = await api.get(
      '/user/logout',
      {},
      {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN_STORAGE_KEY}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(
      '🚀 ~ file: operations.js:37 ~ error:',
      error.response.data.message
    );
    toast.error(
      Array.isArray(error.response.data.message)
        ? error.response.data.message[0]
        : error.response.data.message
    );
    return thunkAPI.rejectWithValue(error.message);
  }
});
