import { createSlice } from '@reduxjs/toolkit';
import { getFeedBackForUser } from './operations';

const initialState = {
  mechanics: [],
  userFeedBacks: [],
  loading: false,
};

export const mechanicSlice = createSlice({
  name: 'mechanic',
  initialState,
  reducers: {
    setMechanics: (state, { payload }) => {
      state.mechanics = payload;
    },
    setFeedBackForUser: (state, { payload }) => {
      state.userFeedBacks = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getFeedBackForUser.pending, (state, _) => {
      state.loading = true;
    });
    builder.addCase(getFeedBackForUser.fulfilled, (state, action) => {
      state.loading = false;
      state.userFeedBacks = action.payload.data.feedbackToUser;
    });
    builder.addCase(getFeedBackForUser.rejected, (state, _) => {
      state.loading = false;
    });
  },
});

export const { setMechanics, setFeedBackForUser, setMechanicsLocation } =
  mechanicSlice.actions;

export const mechanicReducer = mechanicSlice.reducer;
