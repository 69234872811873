import React from 'react';
import { Modal } from 'antd';
import { modalStyles } from 'constants/CommonStyles';
import AddMechanic from 'components/profile/modals/ServiceProvider/Team/AddMechanic';

/**
 * Modal component for adding a mechanic by phone number.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - The modal open state.
 * @param {Function} props.setIsOpen - The modal open state setter.
 * @returns {JSX.Element} The modal component.
 */
export const ModalAddMechanic = ({ isOpen, setIsOpen }) => {
  return (
    <Modal
      title=""
      open={isOpen}
      onCancel={() => {
        setIsOpen(false);
      }}
      footer={false}
      zIndex={1002}
      width={612}
      className="font-poppins"
      style={modalStyles}
      transitionName=""
      destroyOnClose
    >
      <AddMechanic isOpen={isOpen} setIsOpen={setIsOpen} />
    </Modal>
  );
};
