import { Link, useLocation } from 'react-router-dom';
import logo from '../../../assets/logo.svg';
import { routes } from 'constants/routes.constants';
import { SignIn, SignUp } from 'components/Home';
import { useState } from 'react';

const showAuthRoutes = [routes.home, routes.searchResults];

export const HomeHeader = () => {
  const location = useLocation();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);

  return (
    <div className="w-full flex flex-row justify-between px-4 sm:px-10 py-4">
      <Link to="/">
        <img
          src={logo}
          alt="MyMechanic logo"
          className="w-[150px] h-[29px] sm:w-[180px] sm:h-[39px]"
        />
      </Link>
      {/* <Items>
        <Item>Home</Item>
        <Item>Services</Item>
        <Item>Promotion</Item>
        <Item>Our Products</Item>
      </Items> */}

      {showAuthRoutes.includes(location.pathname) ? (
        <div className="flex gap-x-4">
          <SignUp
            setIsLoginModalOpen={setIsLoginModalOpen}
            isSignUpModalOpen={isSignUpModalOpen}
            setIsSignUpModalOpen={setIsSignUpModalOpen}
          />
          <SignIn
            isLoginModalOpen={isLoginModalOpen}
            setIsLoginModalOpen={setIsLoginModalOpen}
            isSignUpModalOpen={isSignUpModalOpen}
            setIsSignUpModalOpen={setIsSignUpModalOpen}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
