import api from 'api';
import { toast } from 'react-toastify';

const profileRoutes = {
  getProfile: 'user/get-details',
  updateProfileUser: 'profiles/update-user',
  updateProfileCompany: 'profiles/update-company',
  userDetails: 'profiles/user-details',
  deactivateUser: 'profiles/deactivate-user',
  addVehicle: 'vehicles/add-vehicle',
  getVehicles: 'vehicles/get-vehicles',
  pictureCategoriesForCompany: 'companies/picture-categories-for-company',
};

export class ProfileService {
  /**
   *
   * @param {{firstName: string, lastName: string, phoneNo: string}} data
   */
  static async updateProfileUser(data) {
    try {
      const { data: responseData } = await api.patch(
        profileRoutes.updateProfileUser,
        data
      );

      return responseData;
    } catch (error) {
      console.log('🥲🥲 ProfileService.updateProfileUser', { error });

      return null;
    }
  }

  /**
   *
   * @param {{ contactEmail: string, contactPhoneNo: string, address: string, name: string}} data
   */
  static async updateProfileCompany(data) {
    try {
      const { data: responseData } = await api.patch(
        profileRoutes.updateProfileCompany,
        data
      );

      return responseData;
    } catch (error) {
      console.log('🥲🥲 ProfileService.updateProfileCompany', { error });
      toast.error('Something went wrong, please try again later');
      return null;
    }
  }

  /**
   *
   * @returns {Promise<{userDetails: any, googleMapsAPIKey: string}>}
   */
  static async getUserProfile() {
    try {
      const { data: responseData } = await api.get(profileRoutes.getProfile);

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 ProfileService.getUserProfile', { error });

      return null;
    }
  }
  /**
   *
   * @returns {Promise<{userDetails: any, googleMapsAPIKey: string}>}
   */
  static async userDetails(data) {
    try {
      const { data: responseData } = await api.post(
        profileRoutes.userDetails,
        data
      );

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 ProfileService.userDetails', { error });

      return null;
    }
  }

  /**
   *
   * @param {"TRUCK" | "TRAILER"} vehicleType
   * @param {{vin: string, maker: string, model: string, color: string}} data
   */
  static async addVehicle(vehicleType = 'TRUCK', data) {
    try {
      const { data: responseData } = await api.post(profileRoutes.addVehicle, {
        ...data,
        type: vehicleType,
      });

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 ProfileService.addVehicle', { error });

      return null;
    }
  }

  /**
   *
   * @param {"TRUCK" | "TRAILER"} type
   * @returns
   */
  static async getVehicles(type = 'TRUCK') {
    try {
      const { data: responseData } = await api.get(
        `${profileRoutes.getVehicles}/${type}`
      );

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 ProfileService.getVehicles', { error });

      return null;
    }
  }

  static async deactivateUser() {
    try {
      const { data: responseData } = await api.delete(
        `${profileRoutes.deactivateUser}`
      );

      return responseData.data;
    } catch (error) {
      console.log('🥲🥲 ProfileService.deactivateUser', { error });
      toast.error(error.response.data.message);
      return null;
    }
  }

  static async pictureCategoriesForCompany() {
    try {
      const { data } = await api.get(
        `${profileRoutes.pictureCategoriesForCompany}`
      );
      return data;
    } catch (error) {
      console.error('ProfileService.pictureCategoriesForCompany', { error });
      throw new Error(error.response?.data?.message || 'Request failed');
    }
  }
}
