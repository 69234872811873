import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { IoMdInformationCircle } from 'react-icons/io';
import { AiOutlineEye } from 'react-icons/ai';
import { RiEyeCloseLine } from 'react-icons/ri';

const fields = {
  contactName: {
    name: 'name',
    placeholder: 'Name',
    type: 'text',
    label: 'Contact Name',
  },
  email: {
    name: 'email',
    placeholder: 'Email',
    type: 'text',
    label: 'Email',
  },
  password: {
    name: 'password',
    placeholder: 'Password (Min 8 character)',
    type: 'password',
    label: 'Create Password',
  },
  confirmPassword: {
    name: 'confirmPassword',
    placeholder: 'Password',
    type: 'password',
    label: 'Repeat Password',
  },
};

/**
 *
 * @component
 * @description A form component for collecting company information during sign up.
 * @param {Object} props - The component props.
 * @param {function} props.setIsValid - A function to set the validity of the form.
 * @param {function} props.onInformationChange - A function to set the form values.
 * @param {string} props.password - The password value.
 * @param {string} props.confirmPassword - The confirm password value.
 * @param {string} props.email - The email value.
 * @param {string} props.name - The name value.
 * @param {function} props.validationSchema - A function to validate the form schema.
 * @returns {JSX.Element} The CompanyInformation component.
 */
export const CompanyInformation = ({
  setIsValid,
  onInformationChange,
  password,
  confirmPassword,
  email,
  name,
  validationSchema,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const initialValues = {
    [fields.contactName.name]: name,
    [fields.email.name]: email,
    [fields.password.name]: password,
    [fields.confirmPassword.name]: confirmPassword,
  };
  const toggleConfirmVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={() => validationSchema()}
    >
      {({ isValid, errors, touched, handleChange }) => (
        <Form className="w-full flex flex-col ">
          <div className="w-full  pt-6  grid grid-cols-1">
            {Object.keys(fields).map((field, index) => {
              const fieldData = fields[field];
              const isPassword = fieldData.type === 'password';
              return (
                <div className={`flex flex-col`} key={index}>
                  <label
                    className={'text-base font-normal text-primary-2 my-1'}
                  >
                    {fieldData.label}
                    <label className="text-[#DA1414]"> *</label>
                  </label>
                  <div className="flex flex-col gap-y-[2px]">
                    <div
                      className={`w-full py-[13px] px-[15px] text-[0.875rem] leading-[1.38em] rounded-xl  flex flex-row ${
                        errors[fieldData.name] && touched[fieldData.name]
                          ? 'bg-[#FEEFEF] border-[#DA1414] border-[1px]'
                          : 'border-primary-3 border'
                      }`}
                    >
                      <Field
                        name={fieldData.name}
                        className={`w-full justify-center focus-visible:outline-none focus-within:outline-none ${
                          touched[fieldData.name] &&
                          errors[fieldData.name] &&
                          'bg-[#FEEFEF] border-[#DA1414]'
                        } `}
                        placeholder={fieldData.placeholder}
                        type={
                          isPassword
                            ? fieldData.name === fields.password.name
                              ? showPassword
                                ? 'text'
                                : 'password'
                              : showConfirmPassword
                              ? 'text'
                              : 'password'
                            : fieldData.type
                        }
                        onChange={e => {
                          handleChange(e);
                          onInformationChange({
                            [fieldData.name]: e.target.value,
                          });
                        }}
                      />
                      {isPassword ? (
                        <button
                          type="button"
                          onClick={
                            fieldData.name === fields.password.name
                              ? togglePasswordVisibility
                              : toggleConfirmVisibility
                          }
                        >
                          {fieldData.name === fields.password.name ? (
                            showPassword ? (
                              <AiOutlineEye
                                className="text-lg"
                                color="#858C94"
                              />
                            ) : (
                              <RiEyeCloseLine
                                className="text-lg"
                                color="#858C94"
                              />
                            )
                          ) : showConfirmPassword ? (
                            <AiOutlineEye className="text-lg" color="#858C94" />
                          ) : (
                            <RiEyeCloseLine
                              className="text-lg"
                              color="#858C94"
                            />
                          )}
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {errors[fieldData.name] && touched[fieldData.name] && (
                    <div
                      className={`bg-[#FEEFEF] py-2 px-4 flex flex-row items-center rounded-[8px]`}
                    >
                      <IoMdInformationCircle color="#DA1414" />
                      <span className="text-[13px] text-[#DA1414] font-normal ml-1">
                        {errors[fieldData.name]}
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </Form>
      )}
    </Formik>
  );
};
