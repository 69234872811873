import { Modal } from 'antd';
import { Button } from 'component-library';

export const ModalRemove = ({
  isOpen,
  setIsOpen,
  handleRemove,
  title,
  subTitle,
  removeButtonTitle,
  children,
}) => {
  return (
    <Modal
      title=""
      open={isOpen}
      footer={false}
      zIndex={1002}
      onCancel={() => setIsOpen(false)}
      className="font-poppins "
      transitionName=""
      destroyOnClose
    >
      <div className="flex flex-col w-full items-center justify-center gap-y-4 transition-all py-2">
        <div className="flex flex-col w-full gap-y-3 items-center">
          <span className="text-[26px] text-error-2 font-semibold text-center">
            {title}
          </span>
          <span className="text-base font-normal text-center text-primary-2">
            {subTitle}
          </span>
          {children}
        </div>
        <div className="flex w-full gap-4">
          <Button
            title="Cancel"
            onClick={() => setIsOpen(false)}
            variant="grey"
          />
          <Button
            title={removeButtonTitle}
            onClick={handleRemove}
            variant="danger"
          />
        </div>
      </div>
    </Modal>
  );
};
