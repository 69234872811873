import moment from 'moment';
import { FaStar } from 'react-icons/fa';
import { FaUser } from 'react-icons/fa';
import { Rate } from 'antd';

/**
 * Renders a review card component.
 *
 * @component
 * @param {Object} data - The review data.
 * @param {string} data.ratingByUserProfileImage - The URL of the user's profile image.
 * @param {string} data.ratingByUser.firstName - The first name of the user who gave the review.
 * @param {string} data.ratingByUser.lastName - The last name of the user who gave the review.
 * @param {number} data.score - The score given in the review.
 * @param {string} data.createdAt - The creation date of the review.
 * @param {string} data.comment - The comment given in the review.
 * @returns {JSX.Element} The review card component.
 */
export const ReviewCard = ({ data }) => {
  return (
    <div className="p-2 bg-accent-4 rounded-[15px] flex-col justify-start items-start gap-2.5 inline-flex mb-3">
      <div className="self-stretch justify-start items-start gap-3 inline-flex">
        {data.ratingByUserProfileImage ? (
          <img
            className="w-[45px] h-[45px] rounded-md"
            alt="profile"
            src={data.ratingByUserProfileImage}
          />
        ) : (
          <div className=" flex justify-center items-center rounded-[10px] bg-accent-2 w-[45px] h-[45px]">
            <FaUser className="fill-accent-1" size={30} />
          </div>
        )}

        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
          <div className="text-primary-2 text-[15px] font-medium">
            {data.ratingByUser.firstName} {data.ratingByUser.lastName}
          </div>
          <div className="flex gap-2 items-center">
            <div className="flex">
              {/* {Array.from({ length: 5 }, (_, index) => (
                <FaStar
                  key={index}
                  className={
                    index < data.score ? 'text-accent-1' : 'text-secondary-1'
                  }
                />
              ))} */}
              <Rate
                allowHalf
                defaultValue={data.score}
                disabled
                className="text-accent-1 text-[14px]"
                character={<FaStar />}
              />
            </div>
            <div className="text-secondary-1 text-sm font-normal">
              {moment(data.createdAt).fromNow()}
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch text-primary-2 text-sm font-normal font-['Poppins'] leading-tight">
        {data.comment}
      </div>
    </div>
  );
};
