/**
 * Calculates the brightness value of a given color.
 * @param {string} color - The hex string representing the color you wish to convert to a brightness value.
 * @returns {number} - The brightness value of the color.
 */
export const calculateBrightness = color => {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness;
};

/**
 * Calculates the distance between two sets of coordinates.
 * @param {number} lat1 - The latitude of the first coordinate.
 * @param {number} lon1 - The longitude of the first coordinate.
 * @param {number} lat2 - The latitude of the second coordinate.
 * @param {number} lon2 - The longitude of the second coordinate.
 * @returns {string} - The distance between the coordinates in miles.
 */
export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  if (!isValidCoordinates(lat1, lon1) || !isValidCoordinates(lat2, lon2)) {
    return 'The distance is unknown';
  }
  const R = 3959;

  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;
  const distanceRounded = parseFloat(distance.toFixed(1));
  const unit = distanceRounded === 1 ? 'mile' : 'miles';
  return `${distanceRounded} ${unit} away`;
};

/**
 * Checks if the given coordinates are valid.
 * @param {number} lat - The latitude coordinate.
 * @param {number} lon - The longitude coordinate.
 * @returns {boolean} - True if the coordinates are valid, false otherwise.
 */
export const isValidCoordinates = (lat, lon) =>
  lat >= -90 && lat <= 90 && lon >= -180 && lon <= 180;

/**
 * Formats a given date and time into a specific format.
 * @param {string} inputDateTime - The input date and time string.
 * @returns {string} - The formatted date and time string.
 */
export const formatDateTime = inputDateTime => {
  const inputDate = new Date(inputDateTime);

  const day = inputDate.getDate();
  const month = inputDate.toLocaleString('default', { month: 'short' });
  const year = inputDate.getFullYear();

  let hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const formattedOutput = `${month} ${day}, ${year}, ${hours}:${
    minutes < 10 ? '0' : ''
  }${minutes} ${ampm}`;

  return formattedOutput;
};

export const formatToTitleCase = text => {
  const words = text.toLowerCase().split('_');
  const formattedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return formattedWords.join(' ');
};
/**
 * Truncates a given text to a specified maximum length.
 * @param {string} text - The text to be truncated.
 * @param {number} maxLength - The maximum length of the truncated text.
 * @returns {string} - The truncated text.
 */
export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

export const reduceImageSizeAndQuality = (file, maxSizeBytes) => {
  return new Promise((resolve, reject) => {
    if (!file.type.match(/^image\/(jpeg|jpg)$/)) {
      reject(new Error('Only JPEG or JPG image formats are supported.'));
      return;
    }

    const reader = new FileReader();

    reader.onload = function (event) {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;
        let quality = 1;

        while (width * height * quality > maxSizeBytes) {
          quality *= 0.9;
          width *= 0.9;
          height *= 0.9;
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          blob => {
            const resizedImage = new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now(),
            });
            resolve(resizedImage);
          },
          file.type,
          quality
        );
      };
      img.src = event.target.result;
    };

    reader.readAsDataURL(file);
  });
};

export const mapRepairType = type => {
  switch (type) {
    case 'tire_repair':
      return 'Replacing tire';
    case 'trailer_repair':
      return 'Fixing trailer';
    case 'truck_repair':
      return 'Fixing truck';
    default:
      return type;
  }
};

export const mapRequestRepairType = type => {
  switch (type) {
    case 'tire_repair':
      return 'Tire Repair';
    case 'trailer_repair':
      return 'Trailer Repair';
    case 'truck_repair':
      return 'Truck Repair';
    default:
      return type;
  }
};

export const calculateAverageRating = ratings => {
  if (!ratings || ratings.length === 0) return 0;
  const scores = ratings.map(rating => rating?.score || 0);
  const sum = scores.reduce((sum, score) => sum + score, 0);
  return (sum / scores.length).toFixed(1);
};
