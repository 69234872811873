import * as Yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const repairType = [
  { value: 1, label: 'Truck Repair', type: 'truck_repair' },
  { value: 2, label: 'Tire Repair', type: 'tire_repair' },
  { value: 3, label: 'Trailer Repair', type: 'trailer_repair' },
];

export const truckType = [
  { value: 'TRUCK', label: 'Truck' },
  { value: 'TRAILER', label: 'Trailer' },
];

export const paymentMethod = [
  { value: 'CASH_CARD', label: 'Cash/card' },
  { value: 'LOCAL_HOUSE_ACCOUNT', label: 'Local/house account' },
  { value: 'NATIONAL_ACCOUNT', label: 'National account' },
];

export const initiateRequestInitialFields = {
  firstName: {
    name: 'firstName',
    placeholder: 'Enter first name',
    type: 'text',
    label: 'First Name',
  },
  lastName: {
    name: 'lastName',
    placeholder: 'Enter last name',
    type: 'text',
    label: 'Last Name',
  },
  phoneNumber: {
    name: 'phoneNumber',
    placeholder: 'Enter phone number',
    type: 'text',
    label: 'Phone Number',
  },
};

export const initiateRequestInitialValues = {
  [initiateRequestInitialFields.firstName.name]: '',
  [initiateRequestInitialFields.lastName.name]: '',
  [initiateRequestInitialFields.phoneNumber.name]: '',
};

export const initiateRequestValidationSchema = Yup.object().shape({
  [initiateRequestInitialFields.firstName.name]: Yup.string()
    .trim()
    .min(2, `Minimum length for driver's first name is 2 characters`)
    .max(30, `Maximum length for driver's first name is 30 characters`)
    .required('Driver first name is required'),
  [initiateRequestInitialFields.lastName.name]: Yup.string()
    .trim()
    .min(2, `Minimum length for driver's last name is 2 characters`)
    .max(30, `Maximum length for driver's last name is 30 characters`)
    .required('Driver last name is required'),
  [initiateRequestInitialFields.phoneNumber.name]: Yup.string()
    .required('Phone number is required')
    .test('is-valid-phone', 'Phone number is invalid', value => {
      return isValidPhoneNumber(value || '');
    }),
});

export const requestDetailsInitialFields = {
  address: {
    name: 'address',
    placeholder: 'Enter address',
    type: 'text',
    label: 'Address',
  },
  serviceCategoryId: {
    name: 'serviceCategoryId',
    placeholder: 'Select Repair Type',
    type: 'text',
    label: 'Repair Type',
  },
  companyName: {
    name: 'companyName',
    placeholder: 'Enter company name',
    type: 'text',
    label: 'Company Name (Optional)',
  },
  description: {
    name: 'description',
    placeholder: 'Enter description',
    type: 'textarea',
    label: 'Describe the issue',
  },
  paymentMethod: {
    name: 'paymentMethod',
    placeholder: 'Select payment method',
    type: 'text',
    label: 'Payment Method',
  },
  truckType: {
    name: 'truckType',
    placeholder: 'Select vehicle type',
    type: 'text',
    label: 'Vehicle Type',
  },
  unitNumber: {
    name: 'unitNumber',
    placeholder: 'Enter unit number',
    type: 'text',
    label: 'Unit Number',
  },
  tireSize: {
    name: 'tireSize',
    placeholder: 'Enter tire size',
    type: 'text',
    label: 'Tire Size',
  },
  tirePosition: {
    name: 'tirePosition',
    placeholder: 'Enter tire position',
    type: 'text',
    label: 'Tire Position',
  },
};

export const requestDetailsInitialValues = {
  longitude: '',
  latitude: '',
  address: '',
  vin: '1234567891012345H',
  type: '',
  description: '',
  serviceCategoryId: '',
  tools: 'Not specified',
  companyName: '',
  truckType: '',
  unitNumber: '',
  tireSize: '',
  tirePosition: '',
  paymentMethod: '',
  beforeRepairImageUrl: null,
  afterRepairImageUrl: null,
};

export const requestDetailsValidationSchema = Yup.object().shape({
  serviceCategoryId: Yup.object().required('Please select a repair type'),
  truckType: Yup.object().required('Please select a vehicle type'),
  description: Yup.string().required('Description is required'),
  address: Yup.string().required('Address is required'),
  paymentMethod: Yup.string().required('Please select a payment method'),
});
