import { routes } from 'constants/routes.constants';
import { useAuth } from 'context/SocketContext';
import { logout } from 'helpers/auth.helpers';
import { lowerCase, startCase } from 'lodash';
import React from 'react';
import { MdLogout } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { storeDrivers } from 'redux/fleet/fleet.slice';

export const driverProfileTabs = {
  data: 'my-data',
  trucks: 'my-trucks',
  trailers: 'my-trailers',
  // requests: 'my-requests',
  favorites: 'favorites',
  reviews: 'reviews',
  // help: 'help',
};

const TabButton = ({ onTabSwitch, activeTab, tab }) => {
  const [, setSearchParams] = useSearchParams();

  const handleSwitchTabs = tab => {
    setSearchParams({ tab });
    if (onTabSwitch) {
      onTabSwitch(tab);
    }
  };
  return (
    <button
      onClick={() => {
        handleSwitchTabs(tab);
      }}
      className="flex justify-start"
    >
      <span
        style={{
          opacity: activeTab === tab ? '100%' : '70%',
          fontWeight: activeTab === tab ? '500' : '400',
        }}
        className={`text-[20px] transition-all duration-200`}
      >
        {startCase(lowerCase(tab.replace(/-/gi, ' ')))}
      </span>
    </button>
  );
};

export const DriverProfileNavigation = ({ activeTab, onTabSwitch }) => {
  const { socket } = useAuth();

  const navigate = useNavigate();
  const dispatch=useDispatch();
  return (
    <div className="w-full md:min-w-[250px] px-2 mr-16 md:px-6 py-6 overflow-scroll scrollbar-thin flex flex-col gap-y-12">
      <div className="w-full flex flex-col gap-y-8 md:gap-y-6">
        <img
          src="https://res.cloudinary.com/dev-nexus/image/upload/v1698339479/Rectangle_47_kt529y.png"
          className="w-[100px] h-[100px] rounded-[50%]"
          alt="driver-profile"
        />
        {Object.values(driverProfileTabs).map(tab => (
          <TabButton
            activeTab={activeTab}
            onTabSwitch={onTabSwitch}
            tab={tab}
            key={tab}
          />
        ))}
      </div>
      <button
        onClick={() => {
          logout();
          dispatch(storeDrivers([]))

          socket.disconnect();
          navigate(routes.home, { replace: true });
        }}
        className="flex justify-start items-center gap-x-2 text-[20px] opacity-70"
      >
        <MdLogout />
        <span>Go out</span>
      </button>
    </div>
  );
};
