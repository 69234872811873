import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';

const { useState } = require('react');

const ReadMore = ({ charLength = 190, children, classNames }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <div className={`flex flex-col gap-y-4 ${classNames?.wrapper || ''}`}>
      {isReadMore
        ? `${text.slice(0, charLength)}${text.length > charLength ? '...' : ''}`
        : text}
      {text.length > charLength ? (
        <button
          type="button"
          onClick={toggleReadMore}
          className={`underline w-fit flex gap-x-2 items-center text-[14px] ${
            classNames?.button || ''
          }`}
        >
          {isReadMore ? 'show more' : 'show less'}
          {isReadMore ? <FaChevronDown /> : <FaChevronUp />}
        </button>
      ) : (
        ''
      )}
    </div>
  );
};

export default ReadMore;
