import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { IoIosCheckmark, IoMdInformationCircle } from 'react-icons/io';
import { AiOutlineEye } from 'react-icons/ai';
import { RiEyeCloseLine } from 'react-icons/ri';
import { Button } from 'component-library';

export const ForgotPassword = ({ handleSendPasswordResetLink }) => {
  const initialFields = {
    email: {
      name: 'email',
      placeholder: 'Email',
      type: 'text',
      label: 'Email',
    },
  };

  const initialValues = {
    [initialFields.email.name]: '',
  };

  const validationSchema = Yup.object().shape({
    [initialFields.email.name]: Yup.string()
      .matches(/\S+@\S+\.\S+/, 'Please enter a valid email')
      .required('Required'),
  });

  return (
    <div className="mt-1 w-full text-center">
      <span className=" text-base text-primary-3 font-normal  text-center">
        All good. Enter your email address and we’ll send you a link to reset
        your password
      </span>
      <div className="text-left">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => {
            handleSendPasswordResetLink(values);
          }}
        >
          {({ isValid, errors, handleChange }) => (
            <Form className="w-full flex flex-col ">
              <div className="w-full  pt-6  grid grid-cols-1">
                {Object.keys(initialFields).map((field, index) => {
                  const fieldData = initialFields[field];
                  return (
                    <div className={`flex flex-col gap-y-1 `} key={index}>
                      <label
                        className={'text-base font-normal text-primary-2 '}
                      >
                        {fieldData.label}
                        <label className="text-[#DA1414]"> *</label>
                      </label>
                      <div className="flex flex-col gap-y-[2px]">
                        <div
                          className={`w-full py-[13px] px-[15px] text-[0.875rem] leading-[1.38em] rounded-xl  flex flex-row ${
                            !errors[fieldData.name]
                              ? 'border-primary-3 border'
                              : 'bg-[#FEEFEF] border-[#DA1414] border-[1px]'
                          }`}
                        >
                          <Field
                            name={fieldData.name}
                            className={`w-full justify-center focus-visible:outline-none focus-within:outline-none ${
                              errors[fieldData.name] &&
                              'bg-[#FEEFEF] border-[#DA1414]'
                            } `}
                            placeholder={fieldData.placeholder}
                            type={fieldData.type}
                            onChange={e => {
                              handleChange(e);
                            }}
                          />
                        </div>
                      </div>
                      {errors[fieldData.name] && (
                        <div
                          className={`bg-[#FEEFEF] py-2 px-4 flex flex-row items-center rounded-[8px]`}
                        >
                          <IoMdInformationCircle color="#DA1414" />
                          <span className="text-[13px] text-[#DA1414] font-normal ml-1">
                            {errors[fieldData.name]}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <Button type="submit" title="Send Reset Link" classNames="mt-8" />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
